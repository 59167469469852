import { useContext } from 'react'
import { PhotosContext } from 'photo-module/photos/ui/photos-gallery/PhotoContext'

const usePhotosDelete = () => {
  const { multiSelectEnabled, setmultiSelectEnabled, photoIdsToDelete, dispatchPhotoIdsToDelete } = useContext(PhotosContext)
  const toggleMultiSelect = () => setmultiSelectEnabled(!multiSelectEnabled)

  const photosToDeleteActions = {
    add: id => dispatchPhotoIdsToDelete({ type: 'add', id }),
    delete: id => dispatchPhotoIdsToDelete({ type: 'delete', id }),
    reset: () => dispatchPhotoIdsToDelete({ type: 'reset' }),
  }

  return { multiSelectEnabled, toggleMultiSelect, photoIdsToDelete, photosToDeleteActions }
}

export default usePhotosDelete
