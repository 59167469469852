import { Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

const PublicPageText = ({ children }) => (
  <Box maxWidth="348px">
    <Typography variant="body2" paragraph>{ children }</Typography>
  </Box>
)

export default PublicPageText
