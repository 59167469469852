// commands
export const TAKE_PHOTO = 'takePhoto'
export const TAKE_VIDEO = 'takeVideo'
export const FORMAT_SD = 'formatSD'
export const UPDATE_GPS = 'updateGPS'
export const UPDATE_STATUS = 'updateStatus'
export const UPDATE_FIRMWARE = 'update'
// buttons
export const RESTORE_FACTORY_SETTINGS = 'factory'
export const DELETE_DEVICE = 'deleteDevice'
