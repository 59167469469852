import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import VoskerInfoIcon from 'assets/icons/navigation/VoskerInfoIcon'

const useStyles = makeStyles(theme => ({
  alert: {
    display: 'flex',
    alignItems: 'center',
  },
  alertText: {
    marginLeft: '10px',
    lineHeight: '135%',
    fontSize: '12px',
    fontWeight: '400',
    color: theme.palette.text.primary,
  },
}))

const SdCardWarning = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.alert}>
      <VoskerInfoIcon />
      <p className={classes.alertText}>{ t('add_camera.SD_card_warning') }</p>
    </div>
  )
}

export default SdCardWarning
