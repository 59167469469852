import { useTheme } from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'

const BigEraseIcon = () => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="BigEraseIcon" width="20" height="20" viewBox="0 0 20 20" fill={theme.palette.text.primary} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 11.7589L18.2411 20L20 18.2411L11.7589 10L20 1.75888L18.2411 0L10 8.24112L1.75888 0L0 1.75888L8.24112 10L0 18.2411L1.75888 20L10 11.7589Z"
        fill={theme.palette.text.primary}
      />
    </SvgIcon>
  )
}

export default BigEraseIcon
