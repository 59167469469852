import makeStyles from '@material-ui/core/styles/makeStyles'
import Slider from '@material-ui/core/Slider'
import VolumeUpRoundedIcon from '@material-ui/icons/VolumeUpRounded'
import VolumeOffRoundedIcon from '@material-ui/icons/VolumeOffRounded'

import { useStreaming } from '../../../core/liveStreaming.hooks'

const useStyles = makeStyles(theme => ({
  controlContainer: {
    display: 'flex',
    alignItems: 'center',
    width: 100,
    paddingLeft: '.25rem',
  },
  slider: {
    marginLeft: '.25rem',
  },
  icon: {
    fill: theme.palette.basic?.white + '!important',
  },
}))

const Volume = () => {
  const streaming = useStreaming()
  const classes = useStyles()

  const handleSliderChange = (event, newValue) => {
    streaming.changeVolume(newValue / 100)
  }

  return (
    <div className={classes.controlContainer}>
      { streaming.playerMuted
        ? (
          <VolumeOffRoundedIcon
            className={classes.icon}
            fontSize="medium"
            cursor="pointer"
            onClick={streaming.unMute}
            data-testid="unMute-button"
          />
          )
        : (
          <VolumeUpRoundedIcon
            className={classes.icon}
            fontSize="medium"
            cursor="pointer"
            onClick={streaming.mute}
            data-testid="mute-button"
          />
          ) }
      <Slider
        data-testid="volume-slider"
        className={classes.slider}
        value={streaming.playerVolume * 100}
        onChange={handleSliderChange}
        disabled={streaming.playerMuted}
      />
    </div>
  )
}

export default Volume
