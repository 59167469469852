import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Container from '@material-ui/core/Container'
import makeStyles from '@material-ui/core/styles/makeStyles'
import TransmissionPlanIcon from '@material-ui/icons/ShoppingCart'

import ActiveSubscription from './header/Header'
import webview from 'shared-module/webview/webview'
import Spinner from 'shared-module/components/Spinner'
import { doesCurrencyFitWithCountry } from 'shared-module/common-utils'
import { useCameraPlans, useCameraPlansActions } from '../core/cameraPlans.hook'

import { useLoadCameras } from 'camera-module/cameras/core/useCameras'
import useCameraActions from 'camera-module/camera/core/useCameraActions'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'

import CAppBar from 'storybook-component-module/src/components/containers/CAppBar'
import PlanWarningDiffCurrency from 'checkout-module/checkout/ui/shared/PlanWarningDiffCurrency'

import VoskerPlanList from 'vosker/src/camera-module/transmission-plan/ui/VoskerPlansList'
import VoskerAddonsList from 'vosker/src/camera-module/transmission-plan/ui/VoskerAddonsList'
import PlanDescriptor from 'vosker/src/camera-module/transmission-plan/ui/content/PlanDescriptor'
import PlanFinePrints from 'vosker/src/camera-module/transmission-plan/ui/components/PlanFinePrints'

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    marginTop: 150,
    paddingTop: 15,
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 96,
      paddingTop: 25,
    },
  },
  appbar: {
    background: theme.palette.background.default,
  },
  container: {
    marginBottom: 62,
    paddingTop: 35,
    [theme.breakpoints.down('md')]: {
      paddingTop: 16,
    },
  },
  divPageWebview: {
    marginTop: 15,
  },
}))

const VoskerPlansPage = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { id: cameraId } = useParams()

  const plans = useCameraPlans()
  const cameras = useLoadCameras()
  const camera = useSelectedCamera()
  const cameraActions = useCameraActions()
  const plansActions = useCameraPlansActions()
  const isWebview = webview.isWebview()

  const isOnTrial = camera && !camera.isCompletingTrial && camera?.subscription.isFree && !camera?.subscription.plan.isFree

  useEffect(() => {
    camera ? plansActions.get() : cameraActions.update(cameraId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camera, cameraId])

  if (!plans || !cameras || cameras.length === 0) return <Spinner />

  return (
    <div className={isWebview ? classes.divPageWebview : classes.pageContainer} data-testid="photos-meter">
      { !isWebview && (
        <CAppBar variant="h1" Icon={TransmissionPlanIcon} title={t('app:plan:page_title')} className={classes.appbar} showDivider />
      ) }

      <Container className={classes.container}>
        { !doesCurrencyFitWithCountry() && <PlanWarningDiffCurrency /> }
        <ActiveSubscription isNewUi />

        <VoskerPlanList />

        <PlanDescriptor camera={camera} />

        <VoskerAddonsList plans={plans} />

        <PlanFinePrints plans={plans} isOnTrial={isOnTrial} />
      </Container>
    </div>
  )
}

export default VoskerPlansPage
