import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import CircleAlert from '../../../../assets/icons/CircleAlert'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme) => ({
  root: ({ isNewUi }) => ({
    margin: '20px',
    width: isNewUi ? '10.25rem' : '200px',
    height: isNewUi && '7.25rem',
    textAlign: 'center',
  }),
  circleIcon: {
    fontSize: '1.1rem',
    marginRight: '5px',
  },
  value: ({ hasLowBattery, isNewUi }) => ({
    display: 'flex',
    margin: 'auto',
    fontSize: isNewUi ? '0.813rem' : '11pt',
    fontFamily: isNewUi && theme.typography.fontFamilyBold,
    fontWeight: !isNewUi && 'bold',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    color: isNewUi && hasLowBattery ? theme.palette.status.error : hasLowBattery ? theme.palette.error.main : '',
  }),
  statusText: ({ hasLowBattery, isNewUi }) => ({
    display: isNewUi ? '' : 'flex',
    marginTop: isNewUi ? '10px' : '4px',
    marginBottom: isNewUi ? '0px' : '',
    fontSize: '0.813rem',
    fontFamily: theme.typography.fontFamilyBold,
    textAlign: isNewUi ? 'none' : 'center',
    alignItems: isNewUi ? 'none' : 'center',
    justifyContent: isNewUi ? 'none' : 'center',
    color: isNewUi && hasLowBattery ? theme.palette.status.error : hasLowBattery ? theme.palette.error.main : '',
  }),
  label: ({ isNewUi }) => ({
    fontSize: isNewUi ? theme.typography.caption2?.fontSize : '10px',
    color: theme.palette.text.primary,
  }),
}))

const CameraStatusBatteryTile = ({ label, icon, value, isNewUi, batteryValue }) => {
  const { t } = useTranslation()
  const hasLowBattery = value >= 0 && value <= 34
  const classes = useStyles({ hasLowBattery, isNewUi })

  let statusKey = 'app:camera.battery_high'
  if (value <= 0) {
    statusKey = 'app:camera.battery_empty'
  } else if (value >= 1 && value <= 34) {
    statusKey = 'app:camera.battery_low'
  } else if (value >= 35 && value <= 74) {
    statusKey = 'app:camera.battery_medium'
  }

  const statusText = isNewUi ? t(statusKey) : batteryValue

  return (
    <div className={classes.root} data-testid="tile">
      { isNewUi
        ? (
          <Box pl={0.325} display="flex" justifyContent="center">
            <svg width="32" height="32" viewBox="0 0 32 32">{ icon }</svg>
          </Box>
          )
        : <div>{ icon }</div> }
      { isNewUi && hasLowBattery
        ? (
          <div className={classes.statusText}>
            { statusText }
          </div>
          )
        : (
          <div className={classes.statusText}>
            { hasLowBattery && <CircleAlert className={classes.circleIcon} /> } { statusText }
          </div>
          ) }
      <div className={classes.label}>{ t(label) }</div>
    </div>
  )
}

export default CameraStatusBatteryTile
