import { FormContext, useForm, useFormContext } from 'react-hook-form'

const useFormData = () => useFormContext()

const useFormField = (name) => {
  const form = useFormData()

  return {
    name: name,
    inputRef: form.register,
    control: form.control,
    error: form.errors[name],
    value: form.getValues()[name],
    onChange: () => {
      form.clearError(name)
    },
    setValue: value => form.setValue(name, value),
  }
}

const FormDataContext = ({ children, mode = 'onBlur', reValidateMode = 'onSubmit' }) => {
  const form = useForm({ mode: mode, reValidateMode: reValidateMode })

  return (
    <FormContext {...form}>
      { children }
    </FormContext>
  )
}

export { useFormData, useFormField, FormDataContext }
