import { useDispatch } from 'react-redux'
import photoSelected from 'photo-module/photo/core/photoSelected'
import photoState from 'photo-module/photo/core/photoState'

const usePhotoActions = () => {
  const dispatch = useDispatch()

  return {
    set: newPhoto => dispatch(photoSelected.set(newPhoto)),
    get: (cameraId, photoId) => dispatch(photoState.getPhoto(cameraId, photoId)),
    isPhotoReady: state => photoState.isPhotoReady(state),
    requestHdPhoto: (cameraId, photoId) => dispatch(photoState.requestHdPhoto(cameraId, photoId)),
    cantDeletePhoto: photo => photoState.cantDeletePhoto(photo),
  }
}

export default usePhotoActions
