import { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@material-ui/core'

import Grid from '@material-ui/core/Grid'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { THEME_MODE_CONTEXT } from 'shared-module/ThemeContextProvider'
import ThemeIcon from 'vosker/src/assets/icons/ThemeIcon'
import CTypography from 'storybook-component-module/src/components/typographies/CTypography'
import Switch from 'react-ios-switch'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  item: {
    paddingLeft: theme.spacing(4),
    '&:hover': {
      background: 'none',
    },
    '&:active': {
      background: 'none',
    },
  },
  title: {
    fontFamily: theme.typography.body1.fontFamily,
  },
  divider: {
    borderTop: `solid 1px ${theme.palette.secondary.main}`,
  },
}))

const VoskerThemeMenuItem = ({ closeMenu }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const theme = useTheme()

  const colorMode = useContext(THEME_MODE_CONTEXT)
  const [isChecked, setIsChecked] = useState(false)

  useEffect(() => {
    setIsChecked(colorMode.isDarkModeActive)
  }, [colorMode])

  const switchTheme = (checked) => {
    setIsChecked(checked)
    colorMode.toggleThemeMode()
    closeMenu()
  }

  return (
    <ListItem disableRipple button className={classes.item}>
      <ListItemIcon>
        <ThemeIcon fontSize="small" />
      </ListItemIcon>

      <Grid container className={classes.container}>
        <Grid item>
          <CTypography variant="body1" className={classes.title}>
            { t('menu.theme') }
          </CTypography>
        </Grid>

        <Grid item>
          <Switch
            checked={isChecked}
            onChange={switchTheme}
            offColor={theme.palette.secondary.light}
            onColor={theme.palette?.status?.info}
            style={{ transform: 'scale(0.8)' }}
          />
        </Grid>
      </Grid>
    </ListItem>
  )
}

export default VoskerThemeMenuItem
