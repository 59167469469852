import { useTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import VoskerPrimaryButton from 'vosker/src/components/buttons/VoskerPrimaryButton'

const useStyles = makeStyles(theme => ({
  container: {
    width: '85%',
    margin: '0.5rem 0 0.75rem',
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      minHeight: '2rem',
      fontSize: '0.75rem',
    },
  },
}))

const BuyNowButton = ({ onClick, disabled = false, label, plan }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid item className={classes.container}>
      <VoskerPrimaryButton
        id={plan.name + '-Plan-Button'}
        disabled={disabled}
        onClick={onClick}
        className={classes.button}
        data-testid="buy-now-button"
      >
        { label || t('app:plan.buttons.buy_now') }
      </VoskerPrimaryButton>
    </Grid>
  )
}

export default BuyNowButton
