import { CLEAR_CAMERA, SET_CAMERA } from 'camera-module/camera/core/cameraActionTypes'
import { SET_PURPOSE } from 'camera-module/camera/core/purposeActionType'
import cameraApi from 'camera-module/camera/api/cameraApi'

const get = id => dispatch => {
  return cameraApi.getById(id)
    .then(camera => dispatch(set(camera)))
}

const getPurpose = () => (dispatch) => {
  return cameraApi.getPurpose()
    .then(purpose => dispatch(setPurpose(purpose)))
}

const update = id => (dispatch, getState) => {
  const { selectedCamera } = getState()

  if (selectedCamera && selectedCamera.fetching) {
    return
  }

  return cameraApi
    .getById(id)
    .then(camera => dispatch(set(camera)))
}

const set = (camera) => ({ type: SET_CAMERA, camera: camera })
const setPurpose = (purpose) => ({ type: SET_PURPOSE, purpose: purpose })
const updateSettings = (camera, newSettings) => ({ type: SET_CAMERA, camera: { ...camera, config: newSettings } })
const clear = () => ({ type: CLEAR_CAMERA })

const cameraActions = { get, set, updateSettings, clear, update, getPurpose, setPurpose }
export default cameraActions
