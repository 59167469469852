import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

import { formatDate } from 'shared-module/common-utils'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import { getPlanTranslationKey } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.selectors'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(theme => ({
  currentPlan: {
    fontFamily: theme.typography.fontWeightMedium,
    fontWeight: 'bold',
    fontSize: '1em',
    color: theme.palette.text.primary,
  },
  planName: {
    color: theme.palette.text.primary,
    textAlign: 'end',
  },
  period: {
    color: theme.palette.text.secondary,
  },
  dates: {
    fontFamily: theme.typography.fontWeightMedium,
    fontWeight: 'bold',
    fontSize: '1em',
  },
  sectionContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '13.5px 0 13.5px 0',
  },
}))

const UpcomingPlan = () => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const camera = useSelectedCamera()

  const upcomingSubscription = camera?.upcomingSubscription
  const upcomingPlan = upcomingSubscription?.plan

  const translatedUpcomingPlanName = t('app:plans.' + getPlanTranslationKey(upcomingPlan?.name)).toLocaleUpperCase()
  if (!upcomingSubscription || !upcomingPlan) {
    return null
  }

  return (
    <>
      <Grid container className={classes.sectionContainer}>
        <Grid item xs={4} md={6}>
          <div className={classes.currentPlan}> { t('app:plan.current_subscription.upcoming_plan') }</div>
        </Grid>
        <Grid item xs={8} md={6}>
          <div className={classes.planName}>
            <Typography>
              { translatedUpcomingPlanName }
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Divider />
      <>
        <Grid container className={classes.sectionContainer}>
          <Grid item xs={4} md={6}>
            <div className={classes.currentPlan}>{ t('app:plan.frequency.period') }</div>
          </Grid>
          <Grid item xs={8} md={6}>
            <div className={classes.planName}>
              <Typography>
                <Typography display="inline">
                  { formatDate(upcomingSubscription.startDateBillingCycle, i18n, true, { month: 'short' }) }
                  { ' - ' }
                  { formatDate(upcomingSubscription.endDateBillingCycle, i18n, true, { month: 'short' }) }
                </Typography>
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Divider />
      </>
    </>

  )
}

export default UpcomingPlan
