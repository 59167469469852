import { useEffect, useRef, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@mui/material/Box'
import { useTheme } from '@material-ui/core'

const stampTime = (time) => {
  const stamp = new Date(time.hour * 3600 * 1000 + 5 * 3600 * 1000)
  return stamp
}
const useStyles = makeStyles((theme) => ({
  styleIcon: {
    color: theme.palette.basic?.grey,
    '& ::-webkit-calendar-picker-indicator': {
      cursor: 'pointer',
      filter: theme.palette.type === 'dark' ? 'invert(0.8)' : 'invert(0.3)',
      fontSize: '20px',
    },
    '& ::-webkit-datetime-edit-minute-field ': {
      color: theme.palette.basic?.grey,
    },
    width: '186px !important',
    height: '50px !important',
    paddingTop: '5px',
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.border?.secondary,
      borderWidth: '2px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '15.5px 14px',
      fontWeight: '600',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.border?.divider,
      borderWidth: '2px',
    },
    '& .MuiSvgIcon-root': {
      fill: `${theme.palette.text?.secondary} !important`,
    },
  },
}))

const TimeField = ({ id, name, time, onChange, dataTestId }) => {
  const classes = useStyles()
  const theme = useTheme()
  const transitTimeRef = useRef()
  const [value, setValue] = useState(stampTime(time))
  const step = time.minute === undefined ? 3600 : 60

  useEffect(() => {
    setValue(stampTime(time))
  }, [time])

  const timeToObject = (date) => {
    const time = new Date(date)
    const [hour = 0, minute = 0] = [time.getHours(), time.getMinutes()]
    return `${parseInt(hour)}:${parseInt(minute)}`
  }

  const timeChanged = (value) => {
    const time = timeToObject(value)
    setValue(time)
    onChange(time, name)
  }

  return (
    <Box ref={transitTimeRef} theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopTimePicker
          ampm
          id={id}
          name={name}
          value={value}
          onChange={timeChanged}
          className={classes.styleIcon}
          InputProps={{
            step: step,
          }}
          slots={{
            textField: (params) => <TextField name={name} data-testid={dataTestId} fullWidth variant="outlined" value={value} {...params} />,
          }}
          slotProps={{
            popper: {
              anchorEl: transitTimeRef.current,
            },
            desktopPaper: {
              sx: {
                inset: '0px auto auto 25px !important',
                width: '186px',
                marginTop: '6px',
                background: theme.palette.background.paper,
                '& .MuiClockNumber-root': {
                  color: theme.palette.text.primary,
                },
                '& .MuiMultiSectionDigitalClock-root': {
                  '& ::-webkit-scrollbar': {
                    display: 'none',
                  },
                  '& > li': {
                    color: theme.palette.text.primary,
                  },
                  '& > ul:not(:first-of-type)': {
                    borderLeft: 'none',
                  },
                  scrollBehavior: 'unset',
                  scrollbarWidth: 'none',
                  borderleft: 'none',
                  justifyContent: 'space-evenly',
                  '& .Mui-selected': {
                    borderRadius: '4px',
                    color: 'white',
                    width: '88%',
                    padding: '3px',
                  },
                },
              },
            },
          }}
          views={['hours', 'minutes']}
          fullWidth
          variant="outlined"
          margin="dense"
        />
      </LocalizationProvider>
    </Box>
  )
}

export default TimeField
