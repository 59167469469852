import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const PlayIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon {...props} data-testid="PlayIcon" width="28" height="28" viewBox="0 0 28 28">
      <g filter="url(#filter0_d)">
        <path
          d="M2.3 6.625C2.3 5.52043 3.19543 4.625 4.3 4.625H23.55C24.6546 4.625 25.55 5.52043 25.55 6.625V22C25.55 23.1046 24.6546 24 23.55 24H4.3C3.19543 24 2.3 23.1046 2.3 22V6.625Z"
          fill="transparent"
        />
        <path d="M19.7375 14.3125L9.56562 21.0242L9.56563 7.6008L19.7375 14.3125Z" fill={theme.palette.background.pureBlack} />
      </g>
      <defs>
        <filter id="filter0_d" x="0.75" y="3.75" width="29" height="25.5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="0.75" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </SvgIcon>
  )
}

export default PlayIcon
