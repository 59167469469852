import React, { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core'
import { green, grey, red } from '@material-ui/core/colors'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Alert from '@mui/material/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import InfoIcon from '@mui/icons-material/Info'
import SuccessMessageIcon from 'vosker/src/assets/icons/snackbar-message/SuccessMessageIcon'
import ErrorMessageIcon from 'vosker/src/assets/icons/snackbar-message/ErrorMessageIcon'
import EventPendingIcon from 'vosker/src/photos-module/photos-gallery/ui/events-overlay/icons/EventPendingIcon'
import CButton from '../buttons/CButton'
import webview from 'shared-module/webview/webview'

const SECOND = 1000

interface MessageActionObject {
  label: string
  handleAction: () => void
}

interface MessageObject {
  type: string
  text: string
  action: MessageActionObject
  countdown: number
}

export interface ICSnackbar {
  message: MessageObject
  autoHideDuration?: number
  snackbarIsOpen: boolean
  displayAction?: boolean
  closeSnackbar: () => void
  supportNewUi?: boolean
}

const useStyles = makeStyles(theme => ({
  grid: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  warningIcon: {
    width: '2rem',
    height: '2rem',
  },
  warningModern: {
    // @ts-ignore
    backgroundColor: theme.palette.status?.background?.warning + ' !important',
    // @ts-ignore
    border: `1px solid ${theme.palette.status?.warning} !important`,
    boxShadow: 'none',
    '& .MuiAlert-icon': {
      // @ts-ignore
      color: theme.palette.primary?.dark + ' !important',
      marginRight: 0,
      height: '2rem',
      padding: '0.75rem 0',
    },
    '& .MuiAlert-message': {
      minWidth: 'auto',
      overflow: 'hidden',
    },
    '& .MuiTypography-colorTextPrimary': {
      // @ts-ignore
      color: theme.palette.border?.neutral + ' !important',
    },
    '& .MuiSvgIcon-root': {
      // @ts-ignore
      fill: theme.palette.primary?.dark + ' !important',
    },
  },
  infoModern: {
    color: theme.palette.text.primary + ' !important',
    // @ts-ignore
    backgroundColor: theme.palette.status?.background?.info + ' !important',
    // @ts-ignore
    border: `1px solid ${theme.palette.status?.info} !important`,
    boxShadow: 'none',
    '& .MuiAlert-icon': {
      // @ts-ignore
      color: theme.palette.status?.info + ' !important',
    },
    '& .MuiAlert-message': {
      minWidth: 'auto',
      overflow: 'hidden',
    },
  },
  infoModernNoIcon: {
    color: theme.palette.text.primary + ' !important',
    // @ts-ignore
    backgroundColor: theme.palette.status?.background?.info + ' !important',
    // @ts-ignore
    border: `1px solid ${theme.palette.status?.info} !important`,
    boxShadow: 'none',
    '& .MuiAlert-action': {
      display: 'none !important',
    },
    '& .MuiAlert-message': {
      minWidth: 'auto',
      overflow: 'hidden',
    },
    '& .MuiSvgIcon-root': {
      // @ts-ignore
      fill: theme.palette.status?.info + ' !important',
    },
  },
  errorModern: {
    color: theme.palette.text.primary + ' !important',
    // @ts-ignore
    backgroundColor: theme.palette.status?.background?.error + ' !important',
    // @ts-ignore
    border: `1px solid ${theme.palette.status?.error} !important`,
    boxShadow: 'none',
    '& .MuiSvgIcon-root': {
      // @ts-ignore
      fill: theme.palette.status?.error + ' !important',
    },
    '& .MuiAlert-message': {
      minWidth: 'auto',
      overflow: 'hidden',
    },
  },
  successModern: {
    color: theme.palette.text.primary,
    // @ts-ignore
    backgroundColor: theme.palette.status?.background?.success + ' !important',
    // @ts-ignore
    border: `1px solid ${theme.palette.status?.success} !important`,
    boxShadow: 'none',
    '& .MuiSvgIcon-root': {
      // @ts-ignore
      fill: theme.palette.status?.success + ' !important',
    },
    '& .MuiAlert-message': {
      minWidth: 'auto',
      overflow: 'hidden',
    },
  },
  actionModern: {
    boxShadow: 'none',
    padding: '0px 16px !important',
    // @ts-ignore
    color: theme.palette.status?.info,
    background: 'transparent !important',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
    '& .MuiAlert-message': {
      minWidth: 'auto',
      overflow: 'hidden',
    },
  },
  warning: {
    backgroundColor: grey[800],
  },
  info: {
    backgroundColor: grey[800],
  },
  error: {
    backgroundColor: red[900],
  },
  success: {
    backgroundColor: green[800],
  },
  action: {
    boxShadow: 'none',
    color: theme.palette.primary.main,
    background: 'transparent !important',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
  },
}))

const CSnackbar = (props: ICSnackbar) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [timerId, setTimerId] = useState<any>()
  const [remainingTime, setRemainingTime] = useState<number>(props.message.countdown)

  const secondToText = `0:${(remainingTime / SECOND).toString().padStart(2, '0')}`

  const severityByType: any = {
    success: 'success',
    error: 'error',
    info: 'info',
    warning: 'warning',
  }

  const Message: ReactNode = (
    <Grid item xs>
      <Typography variant="body1" color="textPrimary">
        { t(props.message.text) }
        { props.message.countdown && secondToText }
      </Typography>
    </Grid>
  )

  const ActionButton: ReactNode = (
    <Grid item xs={4}>
      <CButton
        variety="primary"
        onClick={props.message?.action?.handleAction}
        className={props.supportNewUi ? classes.actionModern : classes.action}
      >
        { t(props.message?.action?.label) }
      </CButton>
    </Grid>
  )

  useEffect(() => {
    if (props.message.countdown) {
      clearTimeout(timerId)
      setRemainingTime(props.message.countdown)
    }
    // eslint-disable-next-line
  }, [props.message])

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (props.message.countdown) {
      remainingTime > 0
        ? setTimerId(setTimeout(() => { setRemainingTime(remainingTime - SECOND) }, SECOND))
        : clearTimeout(timerId)
    }
  }, [remainingTime])
  return (
    <Snackbar
      open={props.snackbarIsOpen}
      onClose={props.closeSnackbar}
      anchorOrigin={webview.isWebview()
        ? (window.location.pathname === '/account/management'
            ? { vertical: 'bottom', horizontal: 'center' }
            : { vertical: 'top', horizontal: 'center' })
        : { vertical: 'bottom', horizontal: 'left' }}
      autoHideDuration={props.message.countdown ? remainingTime : (props.autoHideDuration || 5000)}
      ClickAwayListenerProps={{ onClickAway: (e) => e.stopPropagation() }}
    >
      { props.supportNewUi
        ? (
          <Alert
            variant="outlined"
            onClose={props.closeSnackbar}
            severity={severityByType[props.message.type.toLowerCase()]}
            sx={{ width: '100%' }}
            iconMapping={{
              success: <SuccessMessageIcon />,
              error: <ErrorMessageIcon />,
              info: <InfoIcon />,
              warning: <EventPendingIcon className={classes.warningIcon} />,
            }}
            className={props.displayAction
              ? classes[props.message.type.toLowerCase() + 'ModernNoIcon']
              : classes[props.message.type.toLowerCase() + 'Modern']}
          >
            <Grid container spacing={2} className={classes.grid}>
              { Message }
              { props.displayAction && ActionButton }
            </Grid>
          </Alert>
          )
        : (
          <SnackbarContent
            className={classes[props.message.type.toLowerCase()]}
            message={Message}
            action={props.displayAction && ActionButton}
          />
          ) }
    </Snackbar>
  )
}

export default CSnackbar
