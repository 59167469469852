import { useDispatch } from 'react-redux'
import videoState from 'video-module/video-clip/core/videoState'

const useVideoActions = () => {
  const dispatch = useDispatch()

  return {
    requestHdVideo: (cameraId, photoId) => dispatch(videoState.requestHdVideo(cameraId, photoId)),
    clearHdVideo: (cameraId, photoId) => dispatch(videoState.clearHdVideo(cameraId, photoId)),
  }
}

export default useVideoActions
