import jwtDecode from 'jwt-decode'
import { eCommerceRefreshTokenID, eCommerceTokenID } from 'vosker/src/ecommerce-module/core/eCommerce.selectors'

const tokenId = 'token'
const keysToRemove = [tokenId, eCommerceTokenID, eCommerceRefreshTokenID]

const get = () => localStorage.getItem(tokenId)
const set = (token) => localStorage.setItem(tokenId, token)
const remove = () => keysToRemove.forEach(key => localStorage.removeItem(key))
const decodeUserId = () => jwtDecode(get()).user._id

export default { get, set, remove, decodeUserId }
