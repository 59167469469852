import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Badge from '@material-ui/core/Badge'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import useFilterCollection from 'photo-module/filters-bar/core/filters.hooks'

const useStyles = makeStyles(theme => ({
  badge: {
    marginLeft: 18,
    borderRadius: 14,
    transform: 'scale(1.2)',
    '& > span': {
      paddingBottom: 2,
      fontSize: 11,
      fontWeight: 'bold',
      lineHeight: 'normal',
      color: theme.palette.background?.shadow || theme.palette.background.default,
      background: theme.palette.primary.main,
    },
  },
  title: {
    margin: 3,
    textTransform: 'uppercase',
    fontSize: theme.typography.label?.fontSize,
    fontFamily: theme.typography.label?.fontFamily,
  },
}))

const FilterCounter = ({ style }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { selectedFilters } = useFilterCollection()

  return (
    <Box pl={2.25} display="flex" alignItems="center" style={style}>
      <Typography className={classes.title}>{ t('app:filters.title') }</Typography>
      { selectedFilters.length !== 0 && <Badge badgeContent={selectedFilters.length} className={classes.badge} /> }
    </Box>
  )
}

export default FilterCounter
