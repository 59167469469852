import { useState, useEffect } from 'react'
import countriesApi from 'shared-module/countries/countriesApi'
import useLanguage from 'shared-module/components/language/useLanguage'

const useCountries = () => {
  const language = useLanguage()
  const [countries, setCountries] = useState(null)
  const [currrentLanguage, setCurrentLanguage] = useState(language)

  useEffect(() => {
    const languageChanged = language !== currrentLanguage

    if (!countries || languageChanged) {
      setCurrentLanguage(language)
      countriesApi.getCountries(language)
        .then(list => setCountries(list))
    }
  }, [countries, language, currrentLanguage])

  return countries
}

export default useCountries
