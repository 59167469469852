import SvgIcon from '@material-ui/core/SvgIcon'

const HdIcon = (props) => (
  <SvgIcon {...props} data-testid="HdIcon">
    <path
      d="M19.078 2.25H5.04a1.948 1.948 0 00-.773.148c-.25.102-.473.247-.66.434A2 2 0 003 4.262v15.476a2 2 0 00.605 1.43 2.018 2.018 0 001.434.582h14.04c.534 0 1.05-.21 1.429-.586.379-.379.594-.89.597-1.426V4.262a2.037 2.037 0 00-.597-1.426 2.035 2.035 0 00-1.43-.586zM5.308 18.406V5.73h1.907v5.383h2.297V5.73h1.902v12.676H9.512V13.02H7.215v5.386zM12.75 5.715v12.691h3c.395.008.79-.066 1.156-.215a2.8 2.8 0 00.973-.656c.289-.281.516-.617.672-.988.152-.371.23-.77.23-1.172V8.746a2.923 2.923 0 00-.215-1.156 2.851 2.851 0 00-.656-.977 3.014 3.014 0 00-2.16-.898zM15.809 16.5h-1.153V7.621h1.153c.144 0 .289.027.425.082a1.078 1.078 0 01.59.602c.055.136.082.28.082.425v6.676c0 .14-.023.281-.078.414-.055.133-.137.25-.238.352a1.047 1.047 0 01-.781.328zm0 0"
      fillRule="evenodd"
    />
  </SvgIcon>
)

export default HdIcon
