import { useTranslation } from 'react-i18next'

import Typography from '@material-ui/core/Typography'

import { useCameraPlans } from '../core/cameraPlans.hook'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import AddOnCard from 'vosker/src/camera-module/transmission-plan/ui/content/AddOnCard'
import GridLayout from 'vosker/src/camera-module/transmission-plan/ui/components/layout/GridLayout'
import AttributeLabel from 'vosker/src/camera-module/transmission-plan/ui/components/layout/AttributeLabel'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  gridLayout: {
    marginTop: '0.5rem',
    padding: '0 1.125rem 0 1.75rem',
    [theme.breakpoints.down('md')]: {
      padding: '0 1rem 0 1.375rem',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 1.25rem',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '0.25rem',
      padding: 0,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      margin: 0,
    },
  },
  value: {
    fontSize: 16,
    display: 'inline',
    fontWeight: 'bold',
  },
  gridContainer: {
    margin: '32px 0',
    padding: '0 18px',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 16px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: '0.375rem',
    margin: '0 0.25rem',
    minWidth: '44.125rem',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      marginTop: '0.375rem',
      marginBottom: '1.125rem',
    },
  },
}))

const VoskerAddonsList = ({ plans }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const camera = useSelectedCamera()
  const classes = useStyles()
  const { addOns } = useCameraPlans()
  const breakpoint = useMediaQuery(theme.breakpoints.down('sm'))

  return plans.addOns.length !== 0 && (
    <GridLayout className={classes.gridLayout}>
      { !breakpoint && (
        <AttributeLabel>
          <Typography className={classes.value}>{ t('app:plan.item.extras.label') }</Typography>
        </AttributeLabel>
      ) }

      <Grid container item xs={12} sm className={classes.container}>
        { addOns.map(addOn => (
          <Grid container item className={classes.item}>
            <AddOnCard key={addOn.id} camera={camera} addOn={addOn} />
          </Grid>
        )) }
      </Grid>
    </GridLayout>
  )
}

export default VoskerAddonsList
