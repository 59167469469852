const defaultState = { anchorList: [], initialAnchors: [], isEditing: false, isDefaultZone: false, isEnabled: false, zoneName: '', initialZoneName: '', lastSavedZoneName: '' }

const set = (state, action) => ({ ...state, anchorList: action.anchorList })
const setInitialAnchors = (state, action) => ({ ...state, initialAnchors: action.initialAnchors })
const setIsEditing = (state, action) => ({ ...state, isEditing: action.isEditing })
const setIsDefaultZone = (state, action) => ({ ...state, isDefaultZone: action.isDefaultZone })
const setIsEnabled = (state, action) => ({ ...state, isEnabled: action.isEnabled })
const setZoneName = (state, action) => ({ ...state, zoneName: action.zoneName })
const setInitialZoneName = (state, action) => ({ ...state, initialZoneName: action.initialZoneName })
const setLastSavedZoneName = (state, action) => ({ ...state, lastSavedZoneName: action.zoneName })

const detectionZoneState = { defaultState, set, setInitialAnchors, setIsEditing, setIsDefaultZone, setIsEnabled, setZoneName, setInitialZoneName, setLastSavedZoneName }
export default detectionZoneState
