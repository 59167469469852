import { useTheme } from '@material-ui/core'

const MemoryIcon = ({ memory, disabled = false }) => {
  const theme = useTheme()

  const height = memory && !disabled ? 50 * (memory.percentage / 100) : 0
  const position = 63 - height

  const styleIcon = {
    display: 'block',
    margin: '0 auto',
  }

  const styleStorageLevel = {
    fill: theme.palette.text.primary,
    fillOpacity: '1',
    stroke: 'none',
  }

  const styleStorageBody = {
    fill: 'none',
    stroke: theme.palette.text.primary,
    strokeWidth: 10,
    strokeLinecap: 'butt',
    strokeLinejoin: 'round',
    strokeOpacity: 1,
  }

  return (
    <svg id="battery-status" width="20" height="20" viewBox="0 0 76 76" style={{ ...styleIcon, opacity: disabled ? 0.5 : 1 }}>
      <rect id="storage-level" width="50" height={height} x="13" y={position} style={styleStorageLevel} />
      <path id="storage-body" d="M 71,71 H 5 V 5 l 47.484579,0 18.96578,24.343165 z" style={styleStorageBody} />
    </svg>
  )
}

export default MemoryIcon
