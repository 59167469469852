import { useEffect, useRef, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'

import { useStreaming } from '../../core/liveStreaming.hooks'
import HlsPlayer from '../../../player/HlsPlayer'
import Message from './message/Message'
import Controls from './controls/ControlsBar'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'
import { useTranslation } from 'react-i18next'
import { IDLE_STREAM_WARNING_COUNT_DOWN, IDLE_STREAM_WARNING_TIME } from '../../core/liveStreaming.states'
import { alpha } from '@material-ui/core/styles/colorManipulator'

const useStyles = makeStyles(theme => ({
  playerContainer: {
    width: '100%',
    height: 'calc(100% + 50px)',
    marginTop: 0,
    background: theme.palette.background.pureBlack,
    border: `1px solid ${alpha(theme.palette.border?.light, 0.3)}`,
    borderRadius: '0.375rem',
  },
  controlsContainer: {
    position: 'absolute',
    bottom: -50,
    left: 0,
    width: '100%',
    height: 50,
    padding: '0 .5rem',
    background: theme.palette.background.pureBlack,

  },
}))

const LiveStreamingPlayer = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const streaming = useStreaming()
  const playerRef = useRef(null)

  let streamingInterval
  let streamingTimeout
  const [streamingDialogInterval, setStreamingDialogInterval] = useState()
  const [showStreamingDialog, setShowStreamingDialog] = useState(false)
  const [streamingDialogTimeout, setStreamingDialogTimeout] = useState()

  const setTimeoutActions = () => {
    clearTimeout(streamingTimeout)
    clearInterval(streamingInterval)
    clearTimeout(streamingDialogTimeout)

    if (!streaming.streamIsStopped) {
      streaming.stop()
    }
    setShowStreamingDialog(false)
  }

  const setIntervalActions = () => {
    if (streaming.streamActive) {
      setShowStreamingDialog(true)
    }
    streamingTimeout = setTimeout(setTimeoutActions, IDLE_STREAM_WARNING_COUNT_DOWN)
    setStreamingDialogTimeout(streamingTimeout)
  }

  useEffect(() => {
    if (streaming.streamStarted && streaming.streamActive) {
      streamingInterval = setInterval(setIntervalActions, IDLE_STREAM_WARNING_TIME)
      setStreamingDialogInterval(streamingInterval)
    }
  }, [streaming.streamStarted])

  useEffect(() => {
    if (streaming.errorMessage || streaming.streamIsStopped) {
      clearInterval(streamingInterval)
      clearInterval(streamingDialogInterval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streaming])

  const onStateChange = isPlaying => streaming.changePlayerPlayingState(isPlaying)

  const closeStreamingDialog = () => {
    setShowStreamingDialog(false)
    clearTimeout(streamingDialogTimeout)
    clearInterval(streamingDialogInterval)

    if (streaming.streamStarted && streaming.streamActive) {
      streamingInterval = setInterval(setIntervalActions, IDLE_STREAM_WARNING_TIME)
      setStreamingDialogInterval(streamingInterval)
    }
  }

  return (
    <div className={classes.playerContainer}>
      <Message inStreamingPage />
      { streaming.streamUrl && (
        <HlsPlayer
          volume={streaming.playerVolume || 0}
          muted={streaming.playerMuted}
          url={streaming.streamUrl}
          playerRef={playerRef}
          onStateChange={onStateChange}
        />
      ) }

      <div className={classes.controlsContainer}>
        <Controls />
      </div>

      <ConfirmationDialog
        open={showStreamingDialog}
        title={t('idle_stream_warning.title')}
        text={t('idle_stream_warning.message')}
        onConfirm={closeStreamingDialog}
        confirmText={t('idle_stream_warning.confirmation')}
      />
    </div>
  )
}

export default LiveStreamingPlayer
