import { Redirect, Route } from 'react-router-dom'
import userState from 'user-module/user/core/userState'
import useUser from 'user-module/user/core/useUser'

const PrivateRoute = ({ component: Component, redirect = '/login', search = '', ...options }) => {
  const user = useUser()

  const render = props => userState.isReady(user)
    ? <Component {...props} {...options} />
    : <Redirect to={{ pathname: redirect, search: search, state: { from: props.location } }} />

  return <Route {...options} render={render} />
}

export default PrivateRoute
