import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  styleIcon: {
    display: 'block',
    margin: '0 auto',
  },
  styleBarEmpty: {
    fill: 'none',
    fillOpacity: 1,
    stroke: theme.palette.text.primary,
    strokeWidth: 10,
    strokeLinejoin: 'round',
    strokeOpacity: 1,
  },
  styleBarFilled: {
    fill: theme.palette.text.primary,
    fillOpacity: 1,
    stroke: theme.palette.text.primary,
    strokeWidth: 10,
    strokeLinejoin: 'round',
    strokeOpacity: 1,
  },
}))

const CellularSignalIcon = ({ signal, disabled = false }) => {
  const classes = useStyles()
  const barStyle = (barNumber) => signal && signal.bar >= barNumber && !disabled ? classes.styleBarFilled : classes.styleBarEmpty

  return (
    <svg width="30" height="20" viewBox="0 0 215 110" className={classes.styleIcon} style={{ opacity: disabled ? 0.5 : 1 }}>
      <rect width="25" height="20" x="5" y="85" className={barStyle(1)} />
      <rect width="25" height="40" x="50" y="65" className={barStyle(2)} />
      <rect width="25" height="60" x="95" y="45" className={barStyle(3)} />
      <rect width="25" height="80" x="140" y="25" className={barStyle(4)} />
      <rect width="25" height="100" x="185" y="5" className={barStyle(5)} />
    </svg>
  )
}

export default CellularSignalIcon
