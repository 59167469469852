import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  caption: {
    display: 'flex',
    textAlign: 'justify',
    flexDirection: 'column',
    padding: '40px 18px 0 18px',
    [theme.breakpoints.only('md')]: {
      padding: '24px 16px 0 16px',
    },
    [theme.breakpoints.only('sm')]: {
      padding: '16px 16px 0 16px',
    },
    [theme.breakpoints.only('xs')]: {
      padding: 0,
    },
  },
  typography: {
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.625rem',
    },
  },
}))

const PlanFinePrints = ({ plans, isOnTrial }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return plans.addOns.length !== 0 && (
    <Box className={classes.caption}>
      <Box pt={0.5}>
        <Typography variant="caption" display="inline" className={classes.typography}>
          { t('app:plan:fine_prints_one') }
          { ' ' }
          { t('app:plan:fine_prints_two') }
          { ' ' }
          { isOnTrial && t('app:plan:fine_prints_four') }
        </Typography>
      </Box>

      <Box pt={2}>
        <Typography variant="caption" className={classes.typography}>
          { t('app:plan:fine_prints_link') }
        </Typography>
      </Box>

      <Box pt={0.25}>
        <Typography variant="caption" className={classes.typography}>
          { t('app:plan:fine_prints_note') }
        </Typography>
      </Box>
    </Box>
  )
}

export default PlanFinePrints
