import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'

import TextSmall from 'vosker/src/order-module/order/ui/order-lines/components/TextSmall'
import { getAddOnId } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.selectors'
import { apiCameraAddOns } from 'vosker/src/camera-module/transmission-plan/core/cameraAddOns.types'
import { useLoadCameraPlans } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.hook'
import { VoskerInputStyles } from 'vosker/src/components/buttons/VoskerInputField'

const quantities = () => Array.from({ length: 10 }, (value, key) => key + 1)

const useStyles = makeStyles(() => ({
  select: {
    '& .MuiOutlinedInput-input': {
      padding: '0.75rem 1.5rem',
    },
    '& .MuiTypography-root': {
      marginLeft: '-0.375rem',
    },
  },
}))

const AddonQuantityLine = ({ product, setQuantity }) => {
  const { t } = useTranslation()
  const plans = useLoadCameraPlans()
  const classes = useStyles()
  const importedClasses = VoskerInputStyles()

  const [addOn, setAddOn] = useState()

  useEffect(() => {
    setAddOn(plans?.addOns.find(addOn => addOn.id === getAddOnId(product.addOnId)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plans])

  const onQuantityChange = (event) => setQuantity(event.target.value)

  const renderAddonDescription = () => addOn &&
    product.addOnId === apiCameraAddOns.streaming &&
      <TextSmall>{ t('app:order_page.add_ons.description.live_streaming', { minute: addOn?.minutesOfSdStreaming }) }</TextSmall>

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={8}>
        { renderAddonDescription() }
      </Grid>
      <Grid item>
        <FormControl variant="outlined" margin="none" className={clsx(importedClasses.input, classes.select)}>
          <Select
            value={product.quantity}
            onChange={onQuantityChange}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
          >
            { quantities().map((quantity) => <MenuItem key={quantity} value={quantity}><TextSmall>{ quantity }</TextSmall></MenuItem>) }
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default AddonQuantityLine
