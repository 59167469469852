import { FETCH_CAMERA_MODELS, SET_CAMERA_MODELS } from 'camera-module/model/core/cameraModelsActionTypes'
import cameraModelsApi from 'camera-module/model/api/cameraModelsApi'

const getModels = () => dispatch => {
  dispatch({ type: FETCH_CAMERA_MODELS })
  return cameraModelsApi.get()
    .then(models => dispatch({ type: SET_CAMERA_MODELS, models: models }))
    .catch(() => dispatch({ type: SET_CAMERA_MODELS, models: {} }))
}

const cameraModelsActions = { getModels }
export default cameraModelsActions
