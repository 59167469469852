import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import { useTheme } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import makeStyles from '@material-ui/core/styles/makeStyles'

import LiveStreamIcon from 'assets/icons/navigation/LiveStreamIcon'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import VoskerPrimaryButton from 'vosker/src/components/buttons/VoskerPrimaryButton'

const useStyles = makeStyles(theme => ({
  button: {
    minHeight: '2.5rem',
    padding: '0.5rem 1rem',
    whiteSpace: 'nowrap',
  },
  icon: {
    '& path': {
      fill: theme.palette.basic?.dark + ' !important',
    },
    height: '1.25rem !important',
  },
}))

const LiveStreamButton = () => {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const history = useHistory()
  const camera = useSelectedCamera()
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const handleClick = () => history.push(`/camera/${camera.id}/live-streaming`)

  return camera.stream.available
    ? (
      <Grid item xs="auto">
        <VoskerPrimaryButton
          noWrap
          className={classes.button}
          onClick={handleClick}
          icon={<LiveStreamIcon color={theme.palette.basic?.dark} className={classes.icon} />}
        >
          { smallScreen
            ? t('app:event_viewer.request.short_stream_title')
            : t('app:event_viewer.request.stream_title') }

        </VoskerPrimaryButton>
      </Grid>
      )
    : null
}

export default LiveStreamButton
