import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { useField, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import InputAdornment from '@material-ui/core/InputAdornment'
import { Visibility, VisibilityOff } from '@mui/icons-material'

import { getFieldError } from '../util'
import ForwardBackLink from 'shared-module/components/FowardBackLink'
import webview from 'shared-module/webview/webview'
import NewWarningIcon from 'assets/icons/navigation/WarningIcon'
import InfoRoundedIcon from 'assets/icons/InfoRoundedIcon'

const useStyles = makeStyles((theme) => ({
  icon: {
    width: '1rem',
    top: '0.375rem',
    position: 'relative',
    marginRight: '0.313rem',
  },
  spypointIcon: {
    marginRight: '5px',
  },
  warningIcon: {
    width: '1rem',
    top: '0.375rem',
    position: 'relative',
    marginRight: '0.313rem',
  },
  eyeIcon: {
    color: theme.palette.text.secondary,
  },
  helper: {
    marginLeft: '-0.874rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  link: {
    fontFamily: theme.typography.caption1?.fontFamily,
    fontSize: theme.typography.caption1?.fontSize,
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.5,
  },
  noOutline: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.border?.secondary,
    },
    '& label.Mui-focused': {
      color: theme.palette.text?.secondary,
    },
  },
}))

const Input = ({
  name,
  type = 'text',
  maxLength,
  min,
  max,
  children,
  className,
  showErrorIcon = false,
  showInfoIcon = false,
  disabled,
  onChange,
  isNewUi,
  defaultValue,
  ...controlProps
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const inputRef = useRef()

  const [hasFocus, setHasFocus] = useState(false)
  const [inputType, setInputType] = useState(type)
  const [showPassword, setShowPassword] = useState(false)

  const handleOnClick = () => {
    setShowPassword(!showPassword)
    setInputType(inputType === 'password' ? 'text' : 'password')
  }

  const { isSubmitting } = useFormikContext()
  const [field, meta] = useField(name)
  const error = getFieldError(meta, t)

  useEffect(() => {
    if (hasFocus) {
      webview.scrollViewForAndroidInputField(inputRef, () => setHasFocus(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFocus])

  const helperText = error &&
    (
      <><NewWarningIcon className={isNewUi ? classes.icon : classes.spypointIcon} />{ error }
      </>
    )

  const inputProperties = showInfoIcon
    ? {
        endAdornment: (
          <InputAdornment position="start">
            <InfoRoundedIcon style={{ width: '14px', height: '14px' }} />
          </InputAdornment>
        ),
      }
    : {
        endAdornment: type === 'password' && (
          <InputAdornment position="end">
            <IconButton
              color="secondary"
              onClick={handleOnClick}
              disabled={disabled || field.disabled || isSubmitting}
            >
              { showPassword
                ? (
                  <Visibility fontSize="small" className={classes.eyeIcon} />
                  )
                : (
                  <VisibilityOff fontSize="small" className={classes.eyeIcon} />
                  ) }
            </IconButton>
          </InputAdornment>
        ),
      }

  const handleChange = (e) => {
    if (onChange) {
      onChange(e)
    } else field.onChange(e)
  }

  const passwordLink = (
    <Box display="flex" flexDirection="column" mt={1.5} mb={1}>
      <ForwardBackLink
        pathname="/reset-password"
        align="right"
        disabled={disabled}
        className={clsx(classes.link, disabled || field.disabled ? classes.disabled : null)}
      >
        { t('common:login.forgot_password') }
      </ForwardBackLink>
    </Box>
  )

  return (
    <Box display="flex" flexDirection="column" className={clsx(classes.noOutline, className)} ref={inputRef} onBlur={() => setHasFocus(false)}>
      <TextField
        defaultValue={defaultValue}
        fullWidth
        variant="outlined"
        type={inputType}
        inputProps={{ maxLength, min, max }}
        InputProps={inputProperties}
        {...controlProps}
        {...field}
        value={field.value ?? ''}
        error={!!error}
        helperText={helperText}
        disabled={disabled || field.disabled || isSubmitting}
        onFocus={() => setHasFocus(true)}
        onChange={(e) => handleChange(e)}
      >
        { children }
      </TextField>

      { type === 'password' && passwordLink }
    </Box>
  )
}

export default Input
