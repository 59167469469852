import { useField } from 'formik'
import { useTranslation } from 'react-i18next'
import Divider from '@material-ui/core/Divider'
import MenuItem from '@material-ui/core/MenuItem'

import Input from './Input'

const Select = ({
  name,
  native = false,
  allowEmpty = false,
  options,
  children,
  onChange,
  defaultValue,
  ...controlProps
}) => {
  const [field, , helpers] = useField(name)
  const { t } = useTranslation()

  const onMuiSelectChange = async selectChangeEvent => {
    if (onChange) {
      await onChange(selectChangeEvent)
      if (selectChangeEvent.defaultPrevented) {
        return
      }
    }
    helpers.setTouched(true, false)
    field.onChange(selectChangeEvent)
  }

  const renderOptions = () => {
    if (children) {
      return children
    }
    if (!Array.isArray(options)) {
      return null
    }
    return options.map(({ label, value }) => (
      <MenuItem
        key={value?.toString()}
        value={value}
      >
        { label }
      </MenuItem>
    ))
  }

  return (
    <Input
      defaultValue={defaultValue}
      name={name}
      {...controlProps}
      select
      SelectProps={{
        defaultValue: defaultValue,
        native,
        ...field,
        value: field.value ?? '',
        onChange: onMuiSelectChange,
      }}
    >
      { allowEmpty && native && <option /> }
      { allowEmpty && !native && <MenuItem value=""><em>{ t('form.none') }</em></MenuItem> }
      { allowEmpty && !native && <Divider /> }

      { renderOptions() }
    </Input>
  )
}

export default Select
