import { useTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'

import useUser from 'user-module/user/core/useUser'
import Amount from 'shared-module/components/amount/Amount'

const useStyles = makeStyles(theme => ({
  priceYearlyTotal: {
    padding: '0 0.5rem',
    marginBottom: '1rem',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    fontSize: theme.typography.caption1?.fontSize,
    fontFamily: theme.typography.caption1?.fontFamily,
  },
}))

const AnnualPrice = ({ annualPrice }) => {
  const user = useUser()
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid item>
      <Typography className={classes.priceYearlyTotal}>
        { t('app:plan.item.yearly_payment_prefix') }
        { ' ' }
        <Amount value={annualPrice} currencyCode={user.currencyCode} hideZerosDecimals noSpace />
      </Typography>
    </Grid>
  )
}

export default AnnualPrice
