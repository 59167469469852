import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import messageState from 'shared-module/message/messageState'
import messageActions from 'shared-module/message/messageActions'
import CSnackbar from 'storybook-component-module/src/components/snackbar/CSnackbar'

const SnackbarMessage = ({ autoHideDuration, supportNewUi }) => {
  const dispatch = useDispatch()
  const message = useSelector(state => state.message)

  const [snackbarIsOpen, setSnackbarIsOpen] = useState(false)

  if (!messageState.hasMessageToDisplay(message)) {
    return null
  }

  if (message.text && !snackbarIsOpen) {
    setSnackbarIsOpen(true)
  }

  const closeSnackbar = () => {
    dispatch(messageActions.clear())
    setSnackbarIsOpen(false)
  }

  return (
    <CSnackbar
      message={message}
      autoHideDuration={autoHideDuration}
      snackbarIsOpen={snackbarIsOpen}
      displayAction={!!message.action}
      closeSnackbar={closeSnackbar}
      supportNewUi={supportNewUi}
    />
  )
}

export default SnackbarMessage
