import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import Grid from '@material-ui/core/Grid'
import { Box, Typography } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import makeStyles from '@material-ui/core/styles/makeStyles'

import VoskerInputField, { VoskerInputStyles } from 'vosker/src/components/buttons/VoskerInputField'
import ProfileCountry from 'shared-module/user/ProfileCountry'
import ProfileTerritory from 'shared-module/user/ProfileTerritory'
import ProfileCell, { validateCell } from 'shared-module/user/ProfileCell'
import { useEffect, useState } from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '150px',
  },
  rowSpacing: {
    marginBottom: 4,
  },
  title: {
    fontWeight: 600,
    marginBottom: 16,
    paddingLeft: 24,
    paddingTop: 10,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  containerPadding: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  gridItem: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

const ProfileForm = ({ form }) => {
  const classes = useStyles()
  const inputClasses = VoskerInputStyles()
  const { t } = useTranslation()
  const formik = useFormikContext()

  const [territoryLabel, setTerritoryLabel] = useState()
  const [postalCodeLabel, setPostalCodeLabel] = useState()

  const postalLabelByCountry = {
    US: 'input.zip_code',
    CA: 'input.postal_code_only',
  }

  const territoryLabelByCountry = {
    US: 'input.state',
    CA: 'input.province',
  }

  useEffect(() => {
    setPostalCodeLabel(postalLabelByCountry[formik.values.country] || 'input.postal_code')
    setTerritoryLabel(territoryLabelByCountry[formik.values.country] || 'input.territory')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.country])

  return (
    <div>
      <Box className={classes.title}>
        <Typography variant="h2">{ t('input.general_info') }</Typography>
      </Box>

      <Container style={{ paddingTop: 12 }} className={classes.containerPadding}>
        <Grid container spacing={2} item xs={12} className={classes.rowSpacing}>
          <Grid item xs={12} sm={6} className={classes.gridItem}>
            <VoskerInputField required name="firstName" label={t('input.first_name')} showErrorIcon />
          </Grid>

          <Grid item xs={12} sm={6} className={classes.gridItem}>
            <VoskerInputField required name="lastName" label={t('input.last_name')} showErrorIcon />
          </Grid>
        </Grid>

        <Grid container spacing={2} item xs={12} className={classes.rowSpacing}>
          <Grid item xs={12} sm={6} className={classes.gridItem}>
            <VoskerInputField name="company" label={t('input.company')} showErrorIcon />
          </Grid>

          <Grid item xs={12} sm={6} className={classes.gridItem}>
            <ProfileCell
              required
              validations
              form={form}
              formik={formik}
              name="cell"
              label={t('input.phone')}
              showErrorIcon
              className={inputClasses.input}
              validate={validateCell}
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} spacing={2} className={classes.rowSpacing}>
          <Grid item xs={12} sm={12} className={classes.gridItem}>
            <ProfileCountry
              required
              name="country"
              formik={formik}
              form={form}
              label={t('input.country')}
              showErrorIcon
              className={inputClasses.input}
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} spacing={2} className={classes.rowSpacing}>
          <Grid item xs={12} sm={9} className={classes.gridItem}>
            <VoskerInputField required name="address" label={t('input.address')} showErrorIcon />
          </Grid>

          <Grid item xs={12} sm={3} className={classes.gridItem}>
            <VoskerInputField name="apartment" label={t('input.apartment')} showErrorIcon />
          </Grid>
        </Grid>

        <Grid container item xs={12} spacing={2} className={classes.rowSpacing}>
          <Grid item xs={12} sm={12} className={classes.gridItem}>
            <VoskerInputField required name="city" label={t('input.city')} showErrorIcon />
          </Grid>
        </Grid>

        <Grid container item xs={12} spacing={2} className={classes.rowSpacing}>
          <Grid item xs={12} sm={9} className={classes.gridItem}>
            <ProfileTerritory
              required
              name="territory"
              formik={formik}
              form={form}
              label={t(territoryLabel)}
              showErrorIcon
              className={inputClasses.input}
            />
          </Grid>

          <Grid item xs={12} sm={3} className={classes.gridItem}>
            <VoskerInputField required name="postalCode" label={t(postalCodeLabel)} showErrorIcon />
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default ProfileForm
