import init from '@elastic/apm-rum'
import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'
import apmInactive from 'shared-module/monitoring/apmInactive'

const apmConfig = {
  serviceName: 'webapp',
  serverUrl: window.location.origin,
  pageLoadTransactionName: 'webapp',
  instrument: true,
}

const apmActive = () => {
  const apm = init(apmConfig)

  apm.log = (action, data = []) => {
    const transaction = apm.startTransaction(action, 'data')
    data.forEach(d => transaction.addLabels(d))
    transaction.end()
  }

  return apm
}

const apm = appConfig.apm.switch === switches.on
  ? apmActive()
  : apmInactive

export default apm
export { apmConfig }
