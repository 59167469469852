import { useTheme } from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'

const PlanCheckedIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="PlanCheckedIcon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99935 18.3337C14.6017 18.3337 18.3327 14.6027 18.3327 10.0003C18.3327 5.39795 14.6017 1.66699 9.99935 1.66699C5.39697 1.66699 1.66602 5.39795 1.66602 10.0003C1.66602 14.6027 5.39697 18.3337 9.99935 18.3337ZM14.6409 7.83642L9.08532 13.392C8.92254 13.5548 8.70178 13.6462 8.47154 13.6462C8.24131 13.6462 8.02052 13.5548 7.85773 13.392L5.46106 10.9941L6.66602 9.74486L8.47154 11.5505L13.4133 6.6088L14.6409 7.83642Z"
        fill={theme.palette.text.primary}
      />

    </SvgIcon>
  )
}

export default PlanCheckedIcon
