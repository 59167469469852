import { useState } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import CreditCardField from 'vosker/src/user-module/payment-information/ui/payment-information/form/CreditCardField'
import SavePaymentInformationButton from 'vosker/src/user-module/payment-information/ui/payment-information/form/SavePaymentInformationButton'
import { useTranslation } from 'react-i18next'
import CreditCardImages from 'vosker/src/user-module/payment-information/ui/payment-information/form/CreditCardImages'
import VoskerSecondaryButton from 'vosker/src/components/buttons/VoskerSecondaryButton'

const PaymentInformationForm = ({ onSuccess, onCancel, setFocusOnCardElement }) => {
  const { t } = useTranslation()
  const [error, setError] = useState(null)

  const showButtons = onSuccess !== undefined || onCancel !== undefined

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <CreditCardImages />
      </Grid>
      <Grid item>
        <CreditCardField
          error={error}
          onChange={() => setError(false)}
          setFocusOnCardElement={setFocusOnCardElement}
        />
      </Grid>
      <Grid item>
        { showButtons && (
          <Box mt={3}>
            <Grid container justifyContent="flex-end" spacing={1}>
              { onCancel && <Grid item xs sm={4}><VoskerSecondaryButton onClick={onCancel}>{ t('app:payment_information_page.buttons.cancel') }</VoskerSecondaryButton></Grid> }
              { onSuccess && <Grid item xs={12} sm={4}><SavePaymentInformationButton onSuccess={onSuccess} onError={(error) => setError(error)} /></Grid> }
            </Grid>
          </Box>
        ) }
      </Grid>
    </Grid>
  )
}

export default PaymentInformationForm
