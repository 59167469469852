import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const ExpandedIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon {...props} data-testid="ExpandedIcon">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.50087 0.206748C5.77653 -0.0689161 6.22347 -0.0689161 6.49913 0.206748L11.7933 5.50087C12.0689 5.77653 12.0689 6.22347 11.7933 6.49913C11.5176 6.7748 11.0706 6.7748 10.795 6.49913L6 1.70415L1.20502 6.49913C0.929352 6.7748 0.482412 6.7748 0.206748 6.49913C-0.0689161 6.22347 -0.0689161 5.77653 0.206748 5.50087L5.50087 0.206748Z"
        fill={props.color || theme.palette.secondary.main}
      />
    </SvgIcon>
  )
}

export default ExpandedIcon
