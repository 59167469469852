import photosState from 'photo-module/photos/core/photosState'
import messageActions from 'shared-module/message/messageActions'
import photos from 'photo-module/photos/core/photos'
import fetchingPhotos from 'photo-module/photos/core/fetchingPhotos'
import lastDate from 'photo-module/photos/core/lastDate'
import allPhotosLoaded from 'photo-module/photos/core/allPhotosLoaded'
import photoApi from 'photo-module/photo/api/photoApi'
import photoSelected from 'photo-module/photo/core/photoSelected'
import fixVideoClipStatus from 'photo-module/photo/core/fixVideoClipStatus'
import handleError from 'shared-module/api/apiError'

export const resetPhotos = () => dispatch => {
  dispatch(lastDate.reset())
  dispatch(allPhotosLoaded.set(false))
  dispatch(photos.set([]))
}

const setPhotos = (galleryPhoto, photoCollection) => (dispatch) => {
  if (photoCollection.length < 100 || photoCollection.length === 0) {
    dispatch(allPhotosLoaded.set(true))
  } else {
    const lastPhoto = photoCollection[photoCollection.length - 1]
    dispatch(lastDate.set(lastPhoto.date))
  }
  const allPhotos = [...galleryPhoto.photos, ...photoCollection]
  dispatch(photos.set(allPhotos))
}

export const getPhotos = (filters, isShared) => async (dispatch, getState) => {
  const { galleryPhoto } = getState()
  const isVideoClip = getState().selectedCamera?.camera?.videoCapability

  dispatch(fetchingPhotos.set(true))
  const apiFilters = {
    camera: filters.cameras,
    dateEnd: galleryPhoto.lastDate,
    hd: filters.hd,
    favorite: filters.favorite,
    tag: filters.species,
    limit: 100,
  }

  photoApi.getAllPhotos(apiFilters, isShared)
    .then((newPhotos) => {
      newPhotos.forEach(photo => { fixVideoClipStatus(photo, isVideoClip) })
      dispatch(setPhotos(galleryPhoto, newPhotos))
    })
    .finally(() => dispatch(fetchingPhotos.set(false)))
}

export const getPhotosFiltered = (filters, isShared) => async (dispatch, getState) => {
  const { galleryPhoto } = getState()
  const isVideoClip = getState().selectedCamera?.camera?.videoCapability
  const filterCollection = getState().filterCollection.filters
  dispatch(fetchingPhotos.set(true))

  const apiFilters = isShared
    ? {
        camera: [getState().selectedCamera.camera.id],
        dateEnd: galleryPhoto.lastDate,
        hd: filters.hd,
        favorite: filters.fav,
        tag: [],
        limit: 100,
        mediaTypes: [],
        species: [],
      }
    : {
        camera: [getState().selectedCamera.camera.id],
        dateEnd: galleryPhoto.lastDate,
        hd: filters.hd,
        favorite: filters.fav,
        tag: filters.species,
        limit: 100,
        mediaTypes: [],
        species: [],
      }

  if (filterCollection && !isShared) {
    filters.forEach(filter => {
      if (filterCollection.favorite.includes(filter)) apiFilters.favorite = true
      if (filterCollection.mediaTypes.includes(filter)) apiFilters.mediaTypes.push(filter)
      if (filterCollection.species.some(species => species.nameId === filter)) apiFilters.species.push(filter)
    })
  } else if (isShared) {
    filters.forEach(filter => {
      apiFilters.tag.push(filter)
    })
  }
  photoApi.getAllPhotos(apiFilters, isShared)
    .then((newPhotos) => {
      dispatch(setPhotos(galleryPhoto, newPhotos))
      newPhotos.forEach(photo => { fixVideoClipStatus(photo, isVideoClip) })
    })
    .catch(() => dispatch(allPhotosLoaded.set(true)))
    .finally(() => {
      dispatch(fetchingPhotos.set(false))
    })
}
export const getAllPhotosByCamera = (camera, filters) => (dispatch, getState) => {
  const { galleryPhoto } = getState()
  const dateEnd = galleryPhoto.lastDate

  photoApi.getAllPhotosByCamera(camera, filters, dateEnd)
    .then(photoCollection => dispatch(setPhotos(galleryPhoto, photoCollection))).catch((error) => {
      dispatch(allPhotosLoaded.set(true))
      dispatch(fetchingPhotos.set(false))
      handleError(error)
    })
}

export const deleteLocalPhotos = (photoIds) => (dispatch, getState) => {
  const { galleryPhoto } = getState()
  return dispatch(photos.set(photosState.deletePhotosState(galleryPhoto.photos, photoIds)))
}

export const deleteRemotePhotos = (cameraId, photoIds) => dispatch => {
  return photoApi.deletePhotos(cameraId, photoIds)
    .then(() => dispatch(deleteLocalPhotos(photoIds)))
    .catch(() => dispatch(messageActions.showError('error')))
}

export const setFavoritesPhotos = (cameraId, photoIds) => dispatch => {
  return photoApi.setFavorite(cameraId, photoIds)
    .then(() => dispatch(setLocalFavoritePhotos(photoIds)))
    .catch(() => dispatch(messageActions.showError('error')))
}

const setLocalFavoritePhotos = (photoIds) => (dispatch, getState) => {
  const { galleryPhoto, photo: photoState } = getState()
  const currentPhoto = photoState.photo
  if (photoIds.length === 1 && currentPhoto.id === photoIds[0]) {
    const newPhoto = { ...currentPhoto, favorite: true }
    dispatch(photoSelected.set(newPhoto))
  }
  dispatch(photos.set(photosState.setFavoritePhotos(galleryPhoto.photos, photoIds)))
}

export const deleteFavoritePhotos = (cameraId, photoIds) => dispatch => {
  return photoApi.deleteFavorite(cameraId, photoIds)
    .then(() => dispatch(deleteLocalFavoritePhotos(photoIds)))
    .catch(() => dispatch(messageActions.showError('error')))
}

const deleteLocalFavoritePhotos = (photoIds) => (dispatch, getState) => {
  const { galleryPhoto, photo: photoState } = getState()
  const currentPhoto = photoState.photo
  if (photoIds.length === 1 && currentPhoto.id === photoIds[0]) {
    const newPhoto = { ...currentPhoto, favorite: false }
    dispatch(photoSelected.set(newPhoto))
  }
  dispatch(photos.set(photosState.unsetFavoritePhotos(galleryPhoto.photos, photoIds)))
}

export const updatePhotoInList = (photoId, photoOptions) => (dispatch, getState) => {
  const { galleryPhoto, photo: photoState } = getState()
  const photo = photoState.photo
  let newPhoto = photo
  if (photo.id === photoId) {
    newPhoto = { ...photo, ...photoOptions }
    dispatch(photoSelected.set(newPhoto))
  }

  dispatch(photos.set(photosState.updatePhotoInList(galleryPhoto.photos, newPhoto)))
}
