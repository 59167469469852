/* eslint-disable no-constant-condition */
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import BatteryIcon from 'camera-module/cameras/ui/cameras-page/icons/BatteryIcon'
import CellularSignalIcon from 'camera-module/cameras/ui/cameras-page/icons/CellularSignalIcon'
import MemoryIcon from 'camera-module/cameras/ui/cameras-page/icons/MemoryIcon'
import cameraActions from 'camera-module/camera/core/cameraActions'
import useCameraModelsAndVariants from '../../../model/core/useCameraModelsAndVariants'
import CameraNotificationMessage from './camera-notification-message/CameraNotificationMessage'
import CameraExpirationNotice from './camera-expiration-notice/CameraExpirationNotice'
import { useCriticalNotification } from 'notifications-module/core/notifications.hooks'
import ManagePlanButton from 'camera-module/cameras/ui/cameras-page/ManagePlanButton'
import CameraUpdateMessage from 'camera-module/cameras/ui/cameras-page/camera-updating-message/CameraUpdateMessage'

import V100 from 'camera-module/cameras/ui/cameras-page/icons/camera_model/V100.png'
import V150 from 'camera-module/cameras/ui/cameras-page/icons/camera_model/V150.png'
import V200 from 'camera-module/cameras/ui/cameras-page/icons/camera_model/V200.png'
import V300 from 'camera-module/cameras/ui/cameras-page/icons/camera_model/V300.png'
import V300Ultimate from 'camera-module/cameras/ui/cameras-page/icons/camera_model/V300-ultimate.png'
import VKX from 'camera-module/cameras/ui/cameras-page/icons/camera_model/VKX.png'
import VoskerNoImgIcon from 'camera-module/cameras/ui/cameras-page/icons/VoskerNoImgIcon'

const useStyles = makeStyles((theme) => ({
  tile: {
    minWidth: 256,
    maxWidth: '15.666667%',
    margin: '0.75rem 0.5rem !important',
    [theme.breakpoints.only('md')]: {
      minWidth: 224,
    },
    [theme.breakpoints.only('sm')]: {
      minWidth: 218,
    },
    flexDirection: 'column',
  },
  innerTile: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    background: 'none',
  },
  cameraNameItem: {
    borderRadius: '0.375rem 0.375rem 0 0',
    background: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
  cameraName: {
    overflow: 'hidden',
    whiteSpace: 'noWrap',
    textOverflow: 'ellipsis',
    padding: '0.344rem 2.5rem 0.344rem 1rem',
    fontWeight: 'bold',
    fontSize: theme.typography.label?.fontSize,
    fontFamily: theme.typography.label?.fontFamily,
  },
  frame: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '0 0 0.375rem 0.375rem',
    background: theme.palette.background.paper,
  },
  photoWrapper: {
    width: 130,
    height: 200,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1.5rem 0',
  },
  photoWrapperNoImg: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 200,
    height: 200,
    margin: '0.1rem 0',
    borderRadius: '0.375rem',
    background: 'transparent',
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: '0.5rem 0',
  },
  cameraImage: {
    width: 'inherit',
  },
  noImgIcon: {
    width: '3.75rem',
    height: '3.75rem',
  },
}))

const CameraTile = ({ camera, isNewUi }) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const cameraModel = useCameraModelsAndVariants(camera.status.model)
  const imageByModel = { V100: V100, V150: V150, V200: V200, V300: V300, 'V300-ULTIMATE': V300Ultimate, VKX: VKX }
  const criticalNotification = useCriticalNotification(camera.id)

  const handleCameraClick = () => {
    dispatch(cameraActions.set(camera))
    history.push('/camera/' + camera.id)
  }

  const renderCameraTileMessage = () => {
    return (
      <>
        { criticalNotification && <CameraNotificationMessage notification={criticalNotification} /> }

        <CameraExpirationNotice camera={camera} />

        { camera.status.updateInProgress && <CameraUpdateMessage /> }
      </>
    )
  }

  return (
    <Box component={Grid} container item xs sm={3} md={1} lg={2} xl={1} className={classes.tile}>
      <Grid container id={camera.id} onClick={handleCameraClick} className={classes.innerTile}>
        <Grid container className={classes.cameraNameItem}>
          <Grid container direction="row" xs={8} sm={8} md={8} lg={8} xl={8} item>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography title={camera.config.name} className={classes.cameraName}>
                { camera.config.name }
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container item className={classes.frame}>
          { cameraModel && !!imageByModel[cameraModel.name]
            ? (
              <Grid item className={classes.photoWrapper}>
                <img
                  src={imageByModel[cameraModel.name]}
                  alt={camera.status.model}
                  className={classes.cameraImage}
                />
              </Grid>
              )
            : (
              <Grid item className={classes.photoWrapperNoImg}>
                <VoskerNoImgIcon className={classes.noImgIcon} />
              </Grid>
              ) }

          <Grid container item className={classes.status}>
            <Grid item data-testid={'battery-status-' + camera.id}>
              <BatteryIcon
                battery={camera.status.model === 'FLEX' && camera.status.batteryType === '12V'
                  ? camera.status.solarBattery
                  : camera.status.mainBattery}
              />
            </Grid>

            <Grid item data-testid={'cellular-signal-status-' + camera.id}>
              <CellularSignalIcon signal={camera.status.signal} />
            </Grid>

            <Grid item data-testid={'memory-status-' + camera.id}>
              <MemoryIcon memory={camera.status.memory} />
            </Grid>
          </Grid>

          <Grid container item>
            <ManagePlanButton isNewUi={isNewUi} camera={camera} />
          </Grid>
        </Grid>

      </Grid>

      { renderCameraTileMessage() }
    </Box>
  )
}
export default CameraTile
