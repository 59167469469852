import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const FilterSettingIcon = () => {
  const theme = useTheme()

  return (
    <SvgIcon style={{ width: 24, height: 24, marginBottom: 6, marginTop: 6 }} data-testid="FilterSettingIcon">
      <path d="M20 4.58366H0L1.94957e-05 4.33958C1.94957e-05 4.33958 7.67907e-05 3.97134 7.67907e-05 3.75033C7.67907e-05 3.52931 2.30471e-05 3.16107 2.30471e-05 3.16107L0 2.91699H20V3.16107C20 3.16107 20.0001 3.52931 20.0001 3.75033C20.0001 3.97134 20 4.33958 20 4.33958C20 4.20032 20 4.58366 20 4.58366Z" fill={theme.palette.text.primary} />
      <path d="M18.0494 8.75065H1.94933L1.94922 8.4372C1.94922 8.4372 1.9494 8.13833 1.9494 7.91732C1.9494 7.6963 1.94947 7.40301 1.94947 7.40301L1.9494 7.08398H18.0494V7.386C18.0494 7.386 18.0494 7.6963 18.0494 7.91732C18.0494 8.13833 18.0494 8.54293 18.0494 8.54293V8.75065Z" fill={theme.palette.text.primary} />
      <path d="M13.5091 17.0837H6.49219L6.49229 16.7736C6.49229 16.7736 6.49242 16.4713 6.49242 16.2503C6.49242 16.0293 6.49236 15.7385 6.49236 15.7385L6.49234 15.417H13.5091V15.7756C13.5091 15.7756 13.5091 16.0293 13.5091 16.2503C13.5091 16.4713 13.5091 16.7534 13.5091 16.7534V17.0837Z" fill={theme.palette.text.primary} />
      <path d="M16.0916 12.9167H3.90831L3.90833 12.6435C3.90833 12.6435 3.90831 12.3043 3.90831 12.0833C3.90831 11.8623 3.9082 11.5132 3.9082 11.5132L3.90831 11.25H16.0916V11.5303C16.0916 11.5303 16.0916 11.8623 16.0916 12.0833C16.0916 12.3043 16.0916 12.5693 16.0916 12.5693V12.9167Z" fill={theme.palette.text.primary} />
    </SvgIcon>
  )
}

export default FilterSettingIcon
