import SvgIcon from '@material-ui/core/SvgIcon'

const FaExclamationTriangle = ({ style }) => (
  <SvgIcon data-testid="fal-exclamation-triangle" width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path d="M24.9603 21.4463C25.7073 22.741 24.7697 24.3594 23.2779 24.3594H3.85864C2.36394 24.3594 1.4307 22.7385 2.17617 21.4463L11.8859 4.61121C12.6332 3.31592 14.5049 3.31827 15.2508 4.61121L24.9603 21.4463ZM13.5684 17.9657C12.5403 17.9657 11.7069 18.7991 11.7069 19.8271C11.7069 20.8552 12.5403 21.6886 13.5684 21.6886C14.5964 21.6886 15.4298 20.8552 15.4298 19.8271C15.4298 18.7991 14.5964 17.9657 13.5684 17.9657ZM11.8011 11.2748L12.1013 16.7782C12.1153 17.0357 12.3282 17.2373 12.5861 17.2373H14.5506C14.8085 17.2373 15.0214 17.0357 15.0355 16.7782L15.3357 11.2748C15.3508 10.9966 15.1294 10.7627 14.8508 10.7627H12.2859C12.0073 10.7627 11.7859 10.9966 11.8011 11.2748Z" fill="white" />
  </SvgIcon>

)

export default FaExclamationTriangle
