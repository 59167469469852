import {
  STREAM_READY_INTERVAL,
  KEEP_ALIVE_INTERVAL,
  FIRST_KEEPALIVE,
  ECO_MODE_BUFFER,
  ECO_MODE_DEEP_SLEEP,
  streamStatus,
  streamErrors,
  streamEcoModeWarnings,
  streamQuality,
} from './liveStreaming.states'
import powerModes from 'camera-module/camera/core/powerModeSettings'

const getLiveStreaming = state => state.liveStreaming
const getCamera = state => state.selectedCamera?.camera
const getUser = state => state.user

export const getUserStreamingTime = state => {
  const user = getUser(state)
  return {
    [streamQuality.sd]: user?.addOns?.sdStreamingSeconds || 0,
    [streamQuality.hd]: user?.addOns?.hdStreamingSeconds || 0,
  }
}

export const getStreamStatus = state => getLiveStreaming(state).stream.status
export const getStreamQuality = state => getLiveStreaming(state).stream.quality
export const getStreamRequestedQuality = state => getLiveStreaming(state).stream.requestedQuality
export const getStreamUrl = state => getLiveStreaming(state).stream.url
export const getStreamRemainingTime = state => getLiveStreaming(state).stream.remainingTime
export const getIntervalId = state => getLiveStreaming(state).stream.intervalId
export const getElapsedTime = state => getLiveStreaming(state).stream.elapsedTime
export const getStreamError = state => getLiveStreaming(state).stream.error
export const isCameraReady = state => getLiveStreaming(state).camera.isReady
export const hasCameraAcknowledge = state => getLiveStreaming(state).camera.hasAcknowledged
export const getCameraSessionLastUpdate = state => getLiveStreaming(state).camera.lastUpdate
export const isPlayerPlaying = state => getLiveStreaming(state).player.isPlaying
export const getPlayerVolume = state => getLiveStreaming(state).player.volume
export const isPlayerMuted = state => getLiveStreaming(state).player.isMuted

/* stream */

export const isStreamStarting = state => getStreamStatus(state) === streamStatus.starting
export const isStreamStarted = state => getStreamStatus(state) === streamStatus.started
export const isStreamStopping = state => getStreamStatus(state) === streamStatus.stopping
export const isStreamStopped = state => getStreamStatus(state) === streamStatus.stopped

export const getPreCheckErrors = state => {
  const camera = getCamera(state)
  const user = getUser(state)
  const cameraPlan = camera?.subscription?.plan?.id
  const cameraHasPaidPlan = cameraPlan && cameraPlan !== 'Empty'

  if (!camera || !user) {
    return
  }
  if (!cameraHasPaidPlan) {
    return streamErrors.noPlan
  }
  const userRemainingStreamingTime = user?.addOns?.sdStreamingSeconds || 0
  if (userRemainingStreamingTime <= 0) {
    return streamErrors.noStreamTime
  }
  const streamingEnabled = camera?.config?.streamEnabled || false
  if (!streamingEnabled) {
    return streamErrors.streamDisabled
  }
}

export const ranOutOfMinutes = state => {
  const quality = getStreamQuality(state)
  const userStreamingTime = getUserStreamingTime(state)
  const userRanOutOfMinutes = userStreamingTime[quality] <= 0
  const sessionRanOutOfMinutes = getStreamError(state) === streamErrors.noStreamTime
  return userRanOutOfMinutes || sessionRanOutOfMinutes
}

export const isStreamingAvailable = state => !getPreCheckErrors(state)
export const isStreamActive = state => isStreamStarting(state) || isStreamStarted(state)

export const shouldSendIsReadyRequest = state => {
  const starting = isStreamStarting(state)
  const ready = isCameraReady(state)
  const elapsedTime = getElapsedTime(state)
  const isTimeToSend = elapsedTime % STREAM_READY_INTERVAL === 0
  return starting && !ready && isTimeToSend
}

export const shouldSendKeepAliveRequest = state => {
  const active = isStreamActive(state)
  const ready = isCameraReady(state)
  const elapsedTime = getElapsedTime(state)
  const isTimeToSend = (
    elapsedTime >= FIRST_KEEPALIVE &&
    ((elapsedTime - FIRST_KEEPALIVE) % KEEP_ALIVE_INTERVAL) === 0
  )
  return ready && active && isTimeToSend
}

export const shouldConsumeStreamingTime = state => {
  const started = isStreamStarted(state)
  const playerPlaying = isPlayerPlaying(state)
  const sendingKeepAlive = shouldSendKeepAliveRequest(state)
  return started && playerPlaying && !sendingKeepAlive
}

/* player */

export const shouldShowSpinner = state => {
  const camera = getCamera(state)
  const user = getUser(state)
  return !camera || !user || (isStreamStarting(state) && !getStreamError(state)) || isStreamStopping(state)
}

export const getErrorMessage = state => {
  return getPreCheckErrors(state) || getStreamError(state) || getCameraEcoModeWarning(state)
}

export const shouldDisableControls = state => {
  const streamAvailable = isStreamingAvailable(state)
  const starting = isStreamStarting(state)
  const started = isStreamStarted(state)
  const stopping = isStreamStopping(state)
  const cameraAcknowledge = hasCameraAcknowledge(state)

  if (!streamAvailable || starting || stopping) {
    return true
  }
  return started && !cameraAcknowledge
}

export const shouldShowPlayButton = state => {
  return isStreamStarting(state) || isStreamStopped(state)
}

export const getPlayerQuality = state => {
  return getStreamRequestedQuality(state) || getStreamQuality(state)
}

/* streaming time */

export const getStreamingInitialTime = state => {
  const quality = getPlayerQuality(state)
  return getUserStreamingTime(state)[quality]
}

export const getPlayerRemainingTime = state => {
  const stopped = isStreamStopped(state)
  const quality = getPlayerQuality(state)
  const userStreamingTime = getUserStreamingTime(state)[quality]
  const sessionStreamingTime = getStreamRemainingTime(state)[quality]

  if (ranOutOfMinutes(state)) {
    return 0
  }
  if (stopped) {
    if (!sessionStreamingTime) {
      return userStreamingTime
    }
    return Math.min(
      getUserStreamingTime(state)[quality],
      getStreamRemainingTime(state)[quality],
    )
  }
  return getStreamRemainingTime(state)[quality] || 0
}

export const getPlayerRemainingTimePercentage = state => {
  const stopped = isStreamStopped(state)
  const remainingTime = getPlayerRemainingTime(state)
  const initialTime = getStreamingInitialTime(state)

  if (ranOutOfMinutes(state)) {
    return 0
  }
  if (stopped) {
    return 100
  }
  if (!initialTime) {
    return 0
  }
  return (remainingTime / initialTime) * 100
}

/* camera */

export const getCameraId = state => getCamera(state)?.id
const isCameraEcoModeEnabled = state => getCamera(state)?.config.powerMode === powerModes.Eco
const getCameraLastUpdate = state => {
  const lastUpdate = getCameraSessionLastUpdate(state) || getCamera(state)?.status.lastUpdate
  return lastUpdate ? lastUpdate.replace('Z', '') : undefined // hack to fix invalid UTC date (pushed by camera?)
}
const getCameraPowerModeTimeoutSec = state => getCamera(state)?.config.powerModeTimeoutSec

const getEcoModeRemainingTime = state => {
  const ecoModeEnabled = isCameraEcoModeEnabled(state)
  const lastUpdate = getCameraLastUpdate(state)
  const powerModeTimeoutSec = getCameraPowerModeTimeoutSec(state)

  if (!ecoModeEnabled || !lastUpdate || !powerModeTimeoutSec) {
    return Infinity
  }

  const lastUpdateTimestamp = new Date(lastUpdate).getTime()
  const powerModeTimeout = powerModeTimeoutSec * 1000

  return ((lastUpdateTimestamp - ECO_MODE_BUFFER) + powerModeTimeout) - Date.now()
}

export const cameraEcoModeDeadline = state => {
  const ecoModeRemainingTime = getEcoModeRemainingTime(state)

  if (ecoModeRemainingTime === Infinity || ecoModeRemainingTime <= 0) {
    return
  }

  const dealine = new Date(Date.now() + ecoModeRemainingTime)

  const remainingHours = dealine.getHours().toString().padStart(2, '0')
  const remainingMinutes = dealine.getMinutes().toString().padStart(2, '0')

  return `${remainingHours}:${remainingMinutes}`
}

export const getCameraEcoModeWarning = state => {
  const streamStarting = isStreamStarting(state)
  const streamStopped = isStreamStopped(state)
  const shouldDisplayWarning = streamStarting || streamStopped
  const ecoModeRemainingTime = getEcoModeRemainingTime(state)

  if (!shouldDisplayWarning || ecoModeRemainingTime > 0) {
    return
  }
  const timeoutMs = Math.abs(ecoModeRemainingTime)

  if (timeoutMs >= ECO_MODE_DEEP_SLEEP) {
    return streamEcoModeWarnings.ecoModeDeepSleep
  }
  return streamEcoModeWarnings.ecoModeExpired
}
