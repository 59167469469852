import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'

import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import useDetectionZone from 'detection-zone-module/core/detectionZone.hooks'
import { anchorListsAreEqual } from 'detection-zone-module/core/detectionZone.selectors'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'
import webview from 'shared-module/webview/webview'
import { useTheme } from '@material-ui/core'

const DetectionZoneArrowBackButton = ({ anchorList, isNewUi }) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const theme = useTheme()
  const history = useHistory()
  const dialogActions = useConfirmationDialogActions()
  const { initialAnchorList, setIsEditing } = useDetectionZone()

  const returnToSettings = () => {
    if (webview.isWebview()) {
      webview.postMessage({ action: 'close' })
    }
    setIsEditing(false)
    history.push(`/camera/${id}/settings`)
  }

  const onClick = () => anchorListsAreEqual(initialAnchorList, anchorList)
    ? returnToSettings()
    : dialogActions.open()

  const onConfirm = () => returnToSettings()
  const onClose = () => dialogActions.close()

  return (
    <>
      <IconButton onClick={onClick}>
        <ArrowBackIcon data-testid="ArrowBackIcon" style={{ color: theme.palette.text.primary }} />
      </IconButton>

      <ConfirmationDialog
        isNewUi={isNewUi}
        open={dialogActions.isOpen}
        onClose={onClose}
        title={t('detection-zone.dialog.unsavedChange.title')}
        text={t('detection-zone.dialog.unsavedChange.message')}
        onConfirm={onConfirm}
        onCancel={onClose}
        confirmText={t('detection-zone.dialog.unsavedChange.confirm')}
        cancelText={t('detection-zone.dialog.unsavedChange.cancel')}
      />
    </>
  )
}

export default DetectionZoneArrowBackButton
