import restapi from 'shared-module/api/restapi'
import handleError from 'shared-module/api/apiError'

const getUserPaymentHistory = (lastLoadedInvoiceId) => restapi
  .get('/api/v3/user/payment-history', { params: { lastLoadedInvoiceId } })
  .then(payload => payload.data)
  .catch(handleError)

const userPaymentMethodsApi = { getUserPaymentHistory }
export default userPaymentMethodsApi
