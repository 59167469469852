import makeStyles from '@material-ui/core/styles/makeStyles'

import useCameraModels from 'camera-module/model/core/useCameraModels'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import { apiCameraPlans } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.types'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      height: 80,
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  picture: {
    minWidth: 60,
    '&> img': {
      height: '3.8rem',
      position: 'absolute',
      top: '49%',
      left: '45%',
      transform: 'translate(-50%, -50%)',
    },
  },
  textContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginLeft: '1rem',
    textTransform: 'uppercase',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'start',
    },
  },
  cameraName: {
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: '1rem',
    width: '100%',
    lineHeight: 1.5,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
  modelName: {
    fontFamily: theme.typography.label,
  },
  pictureContainer: ({ hasPlan }) => ({
    flex: 1,
    background: hasPlan ? theme.palette.status?.warning : theme.palette.primary?.dark,
    width: '100%',
    height: 88,
    borderRadius: '6px 0px 0px 6px',
    padding: 24,
    marginLeft: '-14px',
    marginTop: '-14px',
    position: 'relative',
    '&::before': {
      content: "''",
      position: 'absolute',
      top: '0',
      left: '51px',
      transform: 'rotate(72deg)',
      borderTop: '21px  solid transparent',
      borderRight: '60px  solid transparent',
      borderBottom: `25px  solid ${theme.palette.background.paper}`,
      borderLeft: `60px  solid ${theme.palette.background.paper}`,
    },
  }),
}))

const SelectedCamera = () => {
  const camera = useSelectedCamera()
  const models = useCameraModels() || {}

  const planId = camera?.subscription?.plan?.id
  const hasPlan = planId && planId !== apiCameraPlans?.empty ? apiCameraPlans : null

  const classes = useStyles({ hasPlan })

  const cameraModel = models[camera?.status?.model]

  if (!cameraModel) {
    return <div className={classes.root} />
  }

  const modelPicture = <img src={cameraModel.icon} alt={cameraModel.name} />

  return (
    <div className={classes.root} data-testid="selected-camera">
      <div className={classes.pictureContainer}>
        <div className={classes.picture}>{ modelPicture }</div>
      </div>
      <div className={classes.textContainer}>
        <div className={classes.cameraName}>{ camera.config.name }</div>
        <div className={classes.modelName}>{ camera?.status?.model }</div>
      </div>
    </div>
  )
}

export default SelectedCamera
