import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const WarningIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="WarningIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7453 2.46065C12.6042 2.17833 12.3157 2 12 2C11.6843 2 11.3958 2.17833 11.2547 2.46065L2.08798 20.794C1.95882 21.0523 1.97262 21.3591 2.12446 21.6047C2.2763 21.8504 2.54452 22 2.83333 22H21.1667C21.4555 22 21.7237 21.8504 21.8756 21.6047C22.0274 21.3591 22.0412 21.0523 21.912 20.794L12.7453 2.46065ZM12.8333 9.5H11.1667V15.3333H12.8333V9.5ZM11.1667 17H12.8333V18.6667H11.1667V17Z"
        fill={theme.palette.status?.error}
      />
    </SvgIcon>
  )
}

export default WarningIcon
