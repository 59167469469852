import { useTranslation } from 'react-i18next'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import useSelectedPhoto from 'photo-module/photo/core/useSelectedPhoto'
import useVoskerPhotoActions from 'video-module/video-clip/core/hd-photo-state/useVoskerPhotoActions'
import downloadStatus from 'photo-module/download/downloadStatus'

const DialogHdPhotoRequest = ({ open, onClose }) => {
  const { t } = useTranslation()
  const photo = useSelectedPhoto()
  const camera = useSelectedCamera()
  const actions = useVoskerPhotoActions()

  const acknowledgeRequest = async () => {
    onClose()

    if (photo.hdStatus === downloadStatus.error) {
      await actions.clearHdPhoto(photo.cameraId, photo.id)
    }

    actions.requestHdPhoto(camera.id, photo.id)
  }

  return (
    <ConfirmationDialog
      isNewUi
      open={open}
      onClose={onClose}
      title={t('photo_viewer.request_full_hd_photo.title')}
      text={t('photo_viewer.request_full_hd_photo.text', {
        remaining: camera?.subscription.plan.hdCountPerMonth - camera?.subscription.hdPhotoCount,
      })}
      confirmText={t('photo_viewer.request_full_hd_photo.confirm')}
      onConfirm={acknowledgeRequest}
      onCancel={onClose}
    />
  )
}

export default DialogHdPhotoRequest
