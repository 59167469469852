import { useState, useEffect } from 'react'
import { Stage } from 'react-konva'
import { useTheme } from '@material-ui/core'

import {
  getInitialDetectionZoneAnchorList,
  getNormalStageOptions,
  getPortraitStageOptions,
  getLandscapeStageOptions,
  getNormalImageRect,
  getCustomImageRect,
  translateAnchorList,
  translateAnchorListForExport,
  addGuttersToStage,
  GUTTER_SIZE,
} from './LegacyDetectionZoneHelper'
import DetectionZoneCanvasEditor from './LegacyDetectionZoneEditor'
import DetectionZoneCanvasImage from './LegacyDetectionZoneCanvasImage'
import { useDetectionZone } from './../core/detectionZone.hooks'

const LegacyDetectionZoneCanvas = (props) => {
  const theme = useTheme()

  const [stageOptions, setStageOptions] = useState({ width: 0, height: 0, scale: 1 })
  const [imageRect, setImageRect] = useState({ x: 0, y: 0, width: 0, height: 0 })
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 })
  const [anchorList, setAnchorList] = useState([])
  const { saveAnchorList } = useDetectionZone()

  useEffect(() => {
    updateMeasurements()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.size, imageSize])

  const updateMeasurements = () => {
    if (imageSize.width === 0) return

    let newAnchorList = anchorList
    let newStageOptions = addGuttersToStage(getNormalStageOptions(imageSize))
    let newImageRect = getNormalImageRect(imageSize)

    if (props.size.height < imageSize.height + GUTTER_SIZE * 2 && props.size.height < props.size.width) {
      newStageOptions = getLandscapeStageOptions(imageSize, props.size.height)
    } else if (props.size.width < imageSize.width + GUTTER_SIZE * 2 && props.size.height > props.size.width) {
      newStageOptions = getPortraitStageOptions(imageSize, props.size.width)
    }

    if (newStageOptions.scale !== 1) {
      newImageRect = getCustomImageRect(newStageOptions)
      newStageOptions = addGuttersToStage(newStageOptions)
    }

    if (imageRect.width === 0) {
      newAnchorList = getInitialDetectionZoneAnchorList(newImageRect)
    } else {
      newAnchorList = translateAnchorList(anchorList, imageRect, newImageRect, newStageOptions.landscape)
    }

    setAnchorList(newAnchorList)
    setStageOptions(newStageOptions)
    setImageRect(newImageRect)
  }

  const imageLoaded = (size) => {
    const initialImageRect = getNormalImageRect(size)
    setImageSize(size)
    setImageRect(initialImageRect)
  }

  const anchorListUpdated = (list) => {
    setAnchorList(list)
    saveAnchorList(translateAnchorListForExport(list, imageSize, stageOptions.scale))
  }

  return (
    <Stage
      width={stageOptions.width}
      height={stageOptions.height}
      style={{
        backgroundColor: theme.palette.background.default,
      }}
    >
      <DetectionZoneCanvasImage
        photoURL={props.photoURL}
        position={{ x: imageRect.x, y: imageRect.y }}
        scale={stageOptions.scale}
        imageLoaded={imageLoaded}
      />
      <DetectionZoneCanvasEditor imageRect={imageRect} anchorList={anchorList} anchorListUpdated={anchorListUpdated} />
    </Stage>
  )
}

export default LegacyDetectionZoneCanvas
