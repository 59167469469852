import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'
import cameraApi from 'camera-module/camera/api/cameraApi'
import useCamerasActions from 'camera-module/cameras/core/useCamerasActions'
import messageActions from 'shared-module/message/messageActions'
import { useHistory } from 'react-router-dom'

const DeleteCameraDialog = ({ camera, open, onClose }) => {
  const { t } = useTranslation()
  const camerasActions = useCamerasActions()
  const dispatch = useDispatch()
  const history = useHistory()
  const deleteCamera = () => {
    cameraApi
      .remove(camera.id)
      .then(() => {
        camerasActions.get()
        history.push('/')
      })
      .catch(() => dispatch(messageActions.showError()))
  }

  return camera.subscription?.isFree
    ? (
      <ConfirmationDialog
        onClose={onClose}
        open={open}
        title={t('camera.delete.confirmation.title', { name: camera.config.name })}
        text={t('camera.delete.confirmation.text')}
        confirmText={t('camera.delete.confirmation.confirm')}
        onConfirm={deleteCamera}
        onCancel={onClose}
      />
      )
    : (
      <ConfirmationDialog
        open={open}
        onClose={onClose}
        title={t('camera.delete.cannot_delete_dialog.title')}
        text={t('camera.delete.cannot_delete_dialog.text')}
        confirmText={t('camera.delete.cannot_delete_dialog.confirm')}
        onConfirm={onClose}
      />
      )
}
export default DeleteCameraDialog
