import { combineReducers } from 'redux'
import photos from 'photo-module/photos/core/photos'
import fetchingPhotos from 'photo-module/photos/core/fetchingPhotos'
import lastDate from 'photo-module/photos/core/lastDate'
import allPhotosLoaded from 'photo-module/photos/core/allPhotosLoaded'

const reducer = combineReducers({
  photos: photos.reducer,
  lastDate: lastDate.reducer,
  fetching: fetchingPhotos.reducer,
  allPhotosLoaded: allPhotosLoaded.reducer,
})

export default { reducer: reducer }
