import useCountries from 'shared-module/countries/useCountries'
import ProfileSelect from 'shared-module/user/ProfileSelect'

const ProfileCountry = (props) => {
  const countries = useCountries()

  if (!countries) return null

  const preferredSelectItems = countries
    .filter(country => country.isoCode === 'CA' || country.isoCode === 'US')
    .map(country => ({
      key: `preferred-${country.isoCode}`,
      name: country.name,
      value: country.isoCode,
    }))
  const selectItems = countries
    .map(country => ({
      key: country.isoCode,
      name: country.name,
      value: country.isoCode,
    }))

  return (
    <ProfileSelect preferredItems={preferredSelectItems} items={selectItems} {...props} />
  )
}

export default ProfileCountry
