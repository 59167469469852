import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import FormControl from '@material-ui/core/FormControl'
import { useFormData } from 'shared-module/new-components/form/FormDataContext'
import { FORBIDDEN } from 'shared-module/api/httpStatusCode'
import useLanguage from 'shared-module/components/language/useLanguage'
import ForwardBackLink from 'shared-module/components/FowardBackLink'
import useUserActions from 'user-module/user/core/useUserActions'
import messageActions from 'shared-module/message/messageActions'
import CButton from 'storybook-component-module/src/components/buttons/CButton'
import VoskerPrimaryButton from 'vosker/src/components/buttons/VoskerPrimaryButton'

const RegisterButton = ({ isNewUi = false, checked, consentEnabled, checkedEmail }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const language = useLanguage()
  const history = useHistory()
  const location = useLocation()
  const form = useFormData()
  const userActions = useUserActions()

  const SubmitButton = isNewUi ? VoskerPrimaryButton : CButton

  const buildApiUser = data => ({
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    password: data.password,
    cell: data.phone?.replace(/\D+/g, ''),
    country: data.country,
    language: language,
    consent: consentEnabled ? checked : undefined,
  })
  const register = (data) => {
    const user = buildApiUser(data)
    userActions.register(user)
      .then(() => {
        const { from } = location.state || { from: { pathname: '/' } }
        history.replace(from)
        dispatch(messageActions.showMessage(t('register.success')))
      })
      .catch(error => {
        if (error.response.status === FORBIDDEN) {
          form.setError([{
            name: 'email',
            message: error.response.data.message.includes('deleted')
              ? t('register.email_is_deleted')
              : <>{ t('register.email_already_exist') }<ForwardBackLink underline="always" color="error" pathname="/login">{ t('register.please_login') }</ForwardBackLink></>,
          }])
        }
      })
  }

  return (
    <FormControl margin="dense">
      <SubmitButton id="btn_signup" color="primary" variety="primary" type="submit" onClick={form.handleSubmit(register)} disabled={form.formState.isSubmitting || (!checked && consentEnabled)}>
        { t('register.form.button') }
      </SubmitButton>
    </FormControl>
  )
}

export default RegisterButton
