const type = 'TOGGLE_SPECIES_FILTER'
const toggle = (species) => ({ type, species })
const reducer = (state = [], action) => action.type === type ? toggleState(state, action.species) : state

const toggleState = (state, species) => state.includes(species)
  ? (state.filter(s => s !== species))
  : ([...state, species])

const selected = state => state.length > 0

const speciesFilterState = { selected: selected, toggle: toggle, reducer: reducer }
export default speciesFilterState
