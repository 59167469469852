import useUser from 'user-module/user/core/useUser'
import useLanguage from 'shared-module/components/language/useLanguage'

import { FREQUENCY } from './discount.types'
import { formatDiscount, getRawDiscount } from './discount.selectors'
import { useCameraPlans } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.hook'

export const useDiscount = (cameraPlan, frequency = FREQUENCY.yearly) => {
  const language = useLanguage()
  const currency = useUser()?.currency || 'USD'

  return formatDiscount(getRawDiscount(cameraPlan, frequency), currency, language)
}

export const useBestYearlyDiscount = () => {
  let bestDiscount = 0
  const language = useLanguage()
  const currency = useUser()?.currency || 'USD'
  const { plans: cameraPlans } = useCameraPlans()

  cameraPlans.forEach(cameraPlan => {
    const discount = getRawDiscount(cameraPlan, FREQUENCY.yearly)
    if (discount?.value > (bestDiscount?.value || 0)) {
      bestDiscount = discount
    }
  })

  return formatDiscount(bestDiscount, currency, language)
}
