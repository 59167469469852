import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'
import SwitchToggle from 'shared-module/ui/switch-toggle/vosker'
import { useBestYearlyDiscount } from 'vosker/src/camera-module/transmission-plan/core/discount/useDiscounts'
import DiscountIcon from '../../../../assets/icons/DiscountIcon'

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: 32,
    marginLeft: 120,
    [theme.breakpoints.down('xs')]: {
      marginTop: 32,
      marginBottom: 8,
      marginLeft: 0,
    },
  },
  prefix: {
    display: 'inline',
    fontFamily: theme.typography.fontFamily,
    fontSize: '1.2em',
    fontWeight: '600',
    color: theme.palette.status?.success,
    marginRight: '3px',
    paddingLeft: '12px',
  },
  suffix: {
    display: 'inline',
    fontFamily: theme.typography.fontFamily,
    fontSize: '1.2em',
    color: theme.palette.status?.success,
  },
  iconContainer: {
    position: 'relative',
    width: '23px',
    height: '23px',
    left: '-30px',
    bottom: '-25px',
  },
  vectorIcon: {
    transform: 'rotate(90deg)',
    width: '19.66px',
    height: '19.67px',
    color: theme.palette.status?.success,
  },
  monetizationIcon: {
    position: 'absolute',
    bottom: 4,
    right: 3,
    color: theme.palette.status?.success,
    backgroundColor: theme.palette.background.default,
    borderRadius: '50%',
    width: '10px',
    height: '10px',
  },
}
))

const PlanFrequencySwitch = ({ displayMonthly, setDisplayMonthly }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const bestYearlyDiscount = useBestYearlyDiscount()

  const testId = displayMonthly ? 'plan-monthly' : 'plan-yearly'

  const onToggleChange = () => setDisplayMonthly(value => !value)

  return (
    <Box display="flex" flexDirection="column" alignItems="center" className={classes.container} data-testid={testId}>
      { bestYearlyDiscount && (
        <Box display="flex" alignItems="center" py={2} ml={1} mt={4} textAlign="center">
          <DiscountIcon />
          <Typography className={classes.prefix}>
            { t('app:plan.list.yearly_plan_rebate_prefix', { rebate: bestYearlyDiscount }).toLocaleUpperCase() }
          </Typography>
          { ' ' }
          <Typography className={classes.suffix}>
            { t('app:plan.list.yearly_plan_rebate_suffix') }
          </Typography>
        </Box>
      ) }
      <SwitchToggle
        firstID="monthly_switch"
        firstOption={t('app:plan.frequency.monthly')}
        secondId="yearly_switch"
        secondOption={t('app:plan.frequency.yearly')}
        switchToggleState={displayMonthly}
        onChange={onToggleChange}
      />
    </Box>
  )
}

export default PlanFrequencySwitch
