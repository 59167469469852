import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const Copy = ({ className }) => {
  const theme = useTheme()
  return (
    <SvgIcon width="10" height="18" viewBox="0 0 10 18" className={className} data-testid="copy">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.91668 2.55555V0.333328H3.27779C2.7889 0.333328 2.3889 0.733329 2.3889 1.22222V10.5556C2.3889 11.0444 2.7889 11.4444 3.27779 11.4444H9.94446C10.4334 11.4444 10.8334 11.0444 10.8334 10.5556V3.80555H8.16669C7.4778 3.80555 6.91668 3.24444 6.91668 2.55555ZM8.1667 3.08333H10.6667C10.7584 3.08333 10.8334 3.00833 10.8334 2.91667C10.8334 2.68889 10.7306 2.47222 10.5556 2.325L8.41392 0.541664C8.27781 0.427775 8.01948 0.336108 7.8417 0.336108C7.72781 0.336108 7.63615 0.427775 7.63615 0.541664V2.55556C7.63892 2.84722 7.87503 3.08333 8.1667 3.08333ZM1.61107 2.1111V11.3333C1.61107 11.8222 2.01107 12.2222 2.49996 12.2222H9.05552V12.7778C9.05552 13.2667 8.65552 13.6667 8.16663 13.6667H1.05552C0.566626 13.6667 0.166626 13.2667 0.166626 12.7778V2.99999C0.166626 2.5111 0.566626 2.1111 1.05552 2.1111H1.61107Z"
        fill={theme.palette.border?.secondary}
      />

    </SvgIcon>
  )
}

export default Copy
