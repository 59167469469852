import { useTranslation } from 'react-i18next'
import Title from 'shared-module/new-components/layout/Title'
import AddonQuantityLine from 'vosker/src/order-module/order/ui/camera-add-on/components/AddonQuantityLine'

const AddonDescriptionSection = ({ product, setProduct }) => {
  const { t } = useTranslation()

  const setQuantity = (quantity) => setProduct({ ...product, quantity })

  return (
    <>
      <Title h="h2">{ t('app:order_page.order_summary.title') }</Title>
      <Title h="h3" mb={1.5}>{ t('app:order_page.add_ons.title') }</Title>
      <AddonQuantityLine product={product} setQuantity={setQuantity} />
    </>
  )
}

export default AddonDescriptionSection
