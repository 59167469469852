import { useState, useEffect } from 'react'
import { useField, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

import Button from '@material-ui/core/Button'
import DialogBox from 'shared-module/components/DialogBox'
import SettingsSchedule from './SettingsSchedule'
import Grid from '@material-ui/core/Grid'
import VoskerSecondaryButton from 'vosker/src/components/buttons/VoskerSecondaryButton'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  styleDialogPadding: {
    '& .MuiDialog-paper': {
      padding: '0px !important',
    },
  },
}))

const ScheduleField = ({ name, isNewUi }) => {
  const { setFieldValue, setTouched } = useFormikContext()
  const [field] = useField(name)
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState('')
  const { t } = useTranslation()
  const classes = useStyles()

  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(false)

  const EditButton = isNewUi ? VoskerSecondaryButton : Button

  useEffect(() => {
    setValue(field.value)
  }, [field.value])

  const onChange = event => {
    const updatedValue = event.target.value
    setValue(updatedValue)
    setFieldValue(name, updatedValue, false)
    setTouched({ name: true })
  }

  return (
    <div>
      <Grid display="flex" flexdirection="row" justifyContent="flex-end" container spacing={1}>
        <Grid item xs={6} sm={3}>
          <EditButton onClick={onOpen} color="primary" variant="outlined">
            { t('camera.settings.buttons.edit') }
          </EditButton>
        </Grid>
      </Grid>
      <DialogBox
        title={t('app:camera.settings.fields.schedule.label')}
        open={open}
        onClose={onClose}
        className={classes.styleDialogPadding}
      >
        <SettingsSchedule settings={{ [name]: value }} onChange={onChange} />
      </DialogBox>
    </div>
  )
}

export default ScheduleField
