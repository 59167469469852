import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const MediaTypesIcon = () => {
  const theme = useTheme()

  return (
    <SvgIcon style={{ width: 32, height: 32, marginTop: 6 }} data-testid="MediaTypesIcon">
      <path
        d="M15.5546 3.05652H5.09425C4.68167 3.05652 4.34708 3.36788 4.34708 3.75205V5.71247H5.46783V4.19479H6.58859V5.71247H7.70934V4.19479H12.9395V11.4038H15.5546C15.9672 11.4038 16.3018 11.0924 16.3018 10.7083V3.75205C16.3018 3.36788 15.9672 3.05652 15.5546 3.05652ZM15.181 10.2655H14.0603V8.93756H15.181V10.2655ZM15.181 7.7993H14.0603V6.66103H15.181V7.7993ZM15.181 5.52276H14.0603V4.19479H15.181V5.52276ZM4.34708 10.645C4.49485 10.645 4.63931 10.6005 4.76218 10.5171C4.88505 10.4337 4.98082 10.3152 5.03737 10.1765C5.09392 10.0379 5.10872 9.88528 5.07989 9.73807C5.05106 9.59087 4.9799 9.45566 4.87541 9.34953C4.77091 9.24341 4.63778 9.17113 4.49284 9.14185C4.34791 9.11257 4.19768 9.1276 4.06115 9.18504C3.92462 9.24247 3.80793 9.33974 3.72583 9.46453C3.64373 9.58932 3.59991 9.73603 3.59991 9.88612C3.59991 10.0874 3.67863 10.2804 3.81875 10.4227C3.95887 10.565 4.14892 10.645 4.34708 10.645ZM11.0716 6.85074H2.10557C1.90741 6.85074 1.71736 6.93069 1.57724 7.073C1.43712 7.21531 1.3584 7.40833 1.3584 7.60958L1.3584 14.4392C1.3584 14.6404 1.43712 14.8335 1.57724 14.9758C1.71736 15.1181 1.90741 15.198 2.10557 15.198H11.0716C11.2698 15.198 11.4598 15.1181 11.5999 14.9758C11.7401 14.8335 11.8188 14.6404 11.8188 14.4392V7.60958C11.8188 7.40833 11.7401 7.21531 11.5999 7.073C11.4598 6.93069 11.2698 6.85074 11.0716 6.85074ZM10.698 12.5421L8.347 10.1543C8.27695 10.0832 8.18197 10.0433 8.08293 10.0433C7.98389 10.0433 7.8889 10.0832 7.81885 10.1543L5.46783 12.5421L4.61116 11.672C4.5411 11.6009 4.44612 11.561 4.34708 11.561C4.24804 11.561 4.15305 11.6009 4.083 11.672L2.47915 13.3009V7.98901H10.698V12.5421Z"
        fill={theme.palette.text.primary}
      />
    </SvgIcon>
  )
}

export default MediaTypesIcon
