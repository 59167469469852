import MenuItem from 'shared-module/navigation/menu/MenuItem'
import HelpIcon from 'assets/icons/navigation/HelpIcon'
import apm from 'shared-module/monitoring/apm'
import useLanguage from 'shared-module/components/language/useLanguage'

const VoskerHelpMenuItem = ({ onClick }) => {
  const language = useLanguage()

  const showHelp = () => {
    onClick()
    apm.log('help')
    window.open(`https://www.vosker.com/support/?lang=${language}`, '_blank')
  }

  return <MenuItem Icon={HelpIcon} text="menu.help" onClick={showHelp} />
}

export default VoskerHelpMenuItem
