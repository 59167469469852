import { useDispatch } from 'react-redux'
import { useActions } from 'shared-module/actions/ActionsContext'
import commonUserActions from 'user-module/user/core/userActions'

const useUserActions = () => {
  const dispatch = useDispatch()
  const userActions = useActions().userActions

  return {
    register: user => dispatch(commonUserActions.register(user, userActions)),
    reload: user => dispatch(commonUserActions.reload(user.id)),
    login: (email, password) => dispatch(commonUserActions.loginUser(email, password, userActions)),
    autologin: token => dispatch(commonUserActions.login(token, userActions)),
    logout: () => dispatch(commonUserActions.logout()),

    deleteUser: () => dispatch(commonUserActions.deleteUser()),
    validatePassword: password => dispatch(commonUserActions.validatePassword(password)),
    updatePassword: (password, newPassword) => dispatch(commonUserActions.updatePassword(password, newPassword)),
    update: user => dispatch(userActions.update(user, useActions)),
    updateExtended: user => dispatch(userActions.updateExtended(user, useActions)),
  }
}

export default useUserActions
