import TextField from '@material-ui/core/TextField'
/*
* this is a temporary Input
* TO BE REMOVED AFTER migration to Formik
* */

const LegacyInput = ({ name, label, className, ...controlProps }) => {
  return (
    <TextField
      fullWidth
      name={name}
      label={label}
      className={className}
      {...controlProps}
    />
  )
}

export default LegacyInput
