const VoskerLoadImageIcon = ({ className, props }) => {
  return (
    <svg data-testid="VoskerLoadImageIcon" width="68" height="64" viewBox="0 0 68 64" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.4902 63.3427C63.8218 63.3427 67.3333 59.8312 67.3333 55.4996L67.3333 8.44078C67.3333 4.10914 63.8218 0.597648 59.4902 0.597648L8.50983 0.597643C4.17818 0.597643 0.666692 4.10913 0.666692 8.44078L0.666688 55.4996C0.666687 59.8312 4.17818 63.3427 8.50982 63.3427L59.4902 63.3427ZM11.805 41.7471L33.3736 41.7471L54.9423 41.7471L39.2469 18.4991L34.2695 25.2218L29.2922 31.9445L24.3148 25.2218L11.805 41.7471Z"
        fill="#B3B8CC"
        fillOpacity="0.5"
      />
    </svg>
  )
}
export default VoskerLoadImageIcon
