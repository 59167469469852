import cameraApi from 'camera-module/camera/api/cameraApi'
import messageActions from 'shared-module/message/messageActions'

const type = 'SET_CAMERAS'
const set = (cameras) => ({ type, cameras })
const get = () => dispatch => cameraApi.getAll()
  .then((cameras) => dispatch(set(cameras)))
  .catch(() => {
    dispatch(messageActions.showError())
    dispatch(set([]))
  })
const getNonCellular = (cameras) => cameras ? cameras.filter(camera => !camera.isCellular) : []

const reducer = (state = null, action) => action.type === type ? action.cameras : state

const camerasState = { get: get, getNonCellular: getNonCellular, reducer: reducer }
export default camerasState
