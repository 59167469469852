import { useEffect, useState } from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Input from 'form-module/ui/inputs/Input'
import SubmitButton from 'form-module/ui/inputs/SubmitButton'

import SecondaryButton from 'shared-module/new-components/buttons/SecondaryButton'
import ProfileCountry from 'shared-module/user/ProfileCountry'
import ProfileTerritory from 'shared-module/user/ProfileTerritory'

import VoskerPrimaryButton from 'vosker/src/components/buttons/VoskerPrimaryButton'
import VoskerSecondaryButton from 'vosker/src/components/buttons/VoskerSecondaryButton'
import VoskerInputField, { VoskerInputStyles } from 'vosker/src/components/buttons/VoskerInputField'
import ProfileCell, { validateCell } from 'shared-module/user/ProfileCell'

const BillingAddressForm = ({ user, onCancel, isNewUi = false, setIsPurchaseDisabled, setValidationOnChange = {} }) => {
  const classes = VoskerInputStyles()
  const { t } = useTranslation()
  const formik = useFormikContext()

  const [territoryLabel, setTerritoryLabel] = useState()
  const [postalCodeLabel, setPostalCodeLabel] = useState()

  const postalLabelByCountry = {
    US: 'input.zip_code',
    CA: 'input.postal_code_only',
  }

  const territoryLabelByCountry = {
    US: 'input.state',
    CA: 'input.province',
  }

  useEffect(() => {
    setIsPurchaseDisabled && setIsPurchaseDisabled(!user.isAddressComplete || formik.dirty)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, formik])

  useEffect(() => {
    setPostalCodeLabel(postalLabelByCountry[formik.values.country] || 'input.postal_code')
    setTerritoryLabel(territoryLabelByCountry[formik.values.country] || 'input.territory')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.country])

  const InputField = isNewUi ? VoskerInputField : Input
  const SubmitFieldButton = isNewUi ? VoskerPrimaryButton : SubmitButton
  const CancelFieldButton = isNewUi ? VoskerSecondaryButton : SecondaryButton

  return (
    <Box mx={1} my={2}>
      <Grid container spacing={2}>
        <Grid container item spacing={1}>
          <Grid item xs={12} sm={6}>
            <InputField
              required
              name="firstName"
              label={t('input.first_name')}
              showErrorIcon
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <InputField required name="lastName" label={t('input.last_name')} showErrorIcon />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <ProfileCountry
            required
            name="country"
            formik={formik}
            form="billing-address-form"
            label={t('input.country')}
            showErrorIcon
            className={isNewUi ? classes.input : undefined}
          />
        </Grid>

        <Grid container item spacing={1}>
          <Grid item xs={12} sm={7}>
            <InputField required name="address" label={t('input.address')} showErrorIcon />
          </Grid>

          <Grid item xs={12} sm={5}>
            <InputField name="apartment" label={t('input.apartment')} showErrorIcon />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <InputField required name="city" label={t('input.city')} showErrorIcon />
        </Grid>

        <Grid container item spacing={1}>
          <Grid item xs={12} sm={7}>
            <ProfileTerritory
              required
              name="territory"
              formik={formik}
              form="billing-address-form"
              label={t(territoryLabel)}
              showErrorIcon
              className={isNewUi ? classes.input : undefined}
            />
          </Grid>

          <Grid item xs={12} sm={5}>
            <InputField required name="postalCode" label={t(postalCodeLabel)} showErrorIcon />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <ProfileCell
            required
            name="cell"
            formik={formik}
            form="billing-address-form"
            label={t('input.phone')}
            showErrorIcon
            className={isNewUi ? classes.input : undefined}
            validate={validateCell}
          />
        </Grid>
      </Grid>

      <Box my={2}>
        <Grid container justifyContent="flex-end" spacing={2}>
          { user.isAddressComplete && (
            <Grid item xs={12} sm={4}>
              <CancelFieldButton style={{ width: '100%' }} onClick={onCancel}>
                { t('button.cancel') }
              </CancelFieldButton>
            </Grid>
          ) }

          <Grid item xs={12} sm={4}>
            <SubmitFieldButton
              color="primary"
              type="submit"
              style={{ width: '100%' }}
              disabled={(user.isAddressComplete && !formik.dirty) || !formik.isValid}
              onClick={setValidationOnChange}
            >
              { t('button.save') }
            </SubmitFieldButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default BillingAddressForm
