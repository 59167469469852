import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import useLanguage from 'shared-module/components/language/useLanguage'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  link: { color: theme.palette.status?.info, opacity: 1 },
}))

const TermsAndConditions = ({ product }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const language = useLanguage()

  const href = language === 'en' ? 'https://www.vosker.com/terms-of-service' : 'https://www.vosker.com/conditions-dutilisation'
  const text = product.id === 'AddOn' ? 'app:order_page.place_order.buy' : 'app:order_page.place_order.subscribe'

  return (
    <Box mb={2}>
      <Typography>
        { t('app:order_page.place_order.terms', { button: t(text) }) }
        <Link href={href} target="_blank" rel="noreferrer" className={classes.link}>
          { t('app:order_page.place_order.terms_link') }
        </Link>
        .
      </Typography>
    </Box>
  )
}

export default TermsAndConditions
