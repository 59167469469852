import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import useUser from 'user-module/user/core/useUser'
import TextSmall from 'vosker/src/order-module/order/ui/order-lines/components/TextSmall'
import Amount from 'shared-module/components/amount/Amount'
import Box from '@material-ui/core/Box'
import TextSmallBold from 'vosker/src/order-module/order/ui/order-lines/components/TextSmallBold'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({ note: { fontSize: '0.75rem' } })

const OrderLine = ({ line, bold, className, variant }) => {
  const user = useUser()
  const classes = useStyles()
  const Text = bold ? TextSmallBold : TextSmall
  const templateNote = line.note && `(${line.note})`

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs>
        <Text variant={variant} className={className}>
          { line.text }
        </Text>
      </Grid>
      { line.note && (
        <Grid item xs>
          <Box textAlign="right">
            <Typography className={classes.note}>{ templateNote }</Typography>
          </Box>
        </Grid>
      ) }
      <Grid item xs={3} sm={2}>
        { line.amount !== undefined && (
          <Box textAlign="right" pl={1}>
            <Text variant={variant} className={className}>
              <Amount value={line.amount} currencyCode={user.currencyCode} />
            </Text>
          </Box>
        ) }
      </Grid>
    </Grid>
  )
}
export default OrderLine
