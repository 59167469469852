import restapi from 'shared-module/api/restapi'

const redeem = promoCode => restapi
  .post(`api/v3/promocode/referral-program/${promoCode}`)
  .then(response => {
    if (response.data.status !== 1) { throw new Error('invalid-promocode') }
    return ({ spydollars: Number.parseFloat((response.data.credit / 1000).toFixed(2)) })
  })
  .catch(() => { throw new Error('invalid-promocode') })

const verify = promoCode => restapi
  .post('/api/v3/promocode/test', { type: 'CAM', promocode: promoCode })
  .then(response => { if (response.data.status !== 1) { throw new Error('invalid-promocode') } })
  .catch(() => { throw new Error('invalid-promocode') })

const verifyEmail = (email, cameraId) => restapi
  .post('/api/v3/promocode/referral-program/email', { email: email, ucid: cameraId })
  .catch(() => { throw new Error('invalid-referrer') })

const promoCodesApi = {
  redeem: redeem,
  verify: verify,
  verifyEmail: verifyEmail,
}
export default promoCodesApi
