import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@mui/material'

const NewSmallCartLinkIcon = (props) => {
  const theme = useTheme()
  return (
    <SvgIcon
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      data-testid="SmallCartLinkIcon"
    >
      <path
        d="M15 15.4766C14.1328 15.4766 13.4375 16.1719 13.4375 17.0391C13.4375 17.4535 13.6021 17.8509 13.8951 18.1439C14.1882 18.4369 14.5856 18.6016 15 18.6016C15.4144 18.6016 15.8118 18.4369 16.1049 18.1439C16.3979 17.8509 16.5625 17.4535 16.5625 17.0391C16.5625 16.6247 16.3979 16.2272 16.1049 15.9342C15.8118 15.6412 15.4144 15.4766 15 15.4766ZM2.5 2.97656V4.53906H4.0625L6.875 10.4687L5.8125 12.3828C5.69531 12.6016 5.625 12.8594 5.625 13.1328C5.625 13.5472 5.78962 13.9446 6.08264 14.2377C6.37567 14.5307 6.7731 14.6953 7.1875 14.6953H16.5625V13.1328H7.51562C7.46382 13.1328 7.41415 13.1122 7.37752 13.0756C7.34089 13.039 7.32031 12.9893 7.32031 12.9375C7.32031 12.8984 7.32812 12.8672 7.34375 12.8437L8.04687 11.5703H13.8672C14.4531 11.5703 14.9687 11.2422 15.2344 10.7656L18.0312 5.71094C18.0859 5.58594 18.125 5.45312 18.125 5.32031C18.125 5.11311 18.0427 4.9144 17.8962 4.76789C17.7497 4.62137 17.5509 4.53906 17.3437 4.53906H5.78906L5.05469 2.97656H2.5ZM7.1875 15.4766C6.32031 15.4766 5.625 16.1719 5.625 17.0391C5.625 17.4535 5.78962 17.8509 6.08264 18.1439C6.37567 18.4369 6.7731 18.6016 7.1875 18.6016C7.6019 18.6016 7.99933 18.4369 8.29235 18.1439C8.58538 17.8509 8.75 17.4535 8.75 17.0391C8.75 16.6247 8.58538 16.2272 8.29235 15.9342C7.99933 15.6412 7.6019 15.4766 7.1875 15.4766Z"
        fill={theme.palette.basic?.lightGrey}
      />
    </SvgIcon>
  )
}

export default NewSmallCartLinkIcon
