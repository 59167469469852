import { useState, useEffect } from 'react'
import { Layer, Image } from 'react-konva'

const LegacyDetectionZoneCanvasImage = (props) => {
  const [backgroundImage, setBackgroundImage] = useState(null)

  useEffect(() => {
    if (!props.photoURL) return

    const image = new window.Image()
    image.src = props.photoURL
    image.addEventListener('load', () => {
      setBackgroundImage(image)
      props.imageLoaded({ width: image.width, height: image.height })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.photoURL])

  return (
    <Layer>
      <Image x={props.position.x} y={props.position.y} scaleX={props.scale} scaleY={props.scale} image={backgroundImage} />
    </Layer>
  )
}

export default LegacyDetectionZoneCanvasImage
