const buildIsReady = data => !!data.isReady
const buildUrl = data => data.url
const buildQuality = data => data.quality
const buildRemainingStreamingTime = data => ({ sd: data.sdStreamingSeconds || 0, hd: data.hdStreamingSeconds || 0 })
const buildCameraHasAcknowledged = data => !!data.cameraHasAcknowledged
const buildLastUpdateResponse = data => data.lastUpdate

export const buildIsReadyResponse = data => ({
  isReady: buildIsReady(data),
  lastUpdate: buildLastUpdateResponse(data),
})

export const buildStartResponse = data => ({
  url: buildUrl(data),
  quality: buildQuality(data),
  remainingStreamingTime: buildRemainingStreamingTime(data),
  lastUpdate: buildLastUpdateResponse(data),
})

export const buildStopResponse = data => ({
  remainingStreamingTime: buildRemainingStreamingTime(data),
  lastUpdate: buildLastUpdateResponse(data),
})

export const buildKeepAliveResponse = data => ({
  url: buildUrl(data),
  quality: buildQuality(data),
  remainingStreamingTime: buildRemainingStreamingTime(data),
  cameraHasAcknowledged: buildCameraHasAcknowledged(data),
  lastUpdate: buildLastUpdateResponse(data),
})
