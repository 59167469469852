import useUser from 'user-module/user/core/useUser'
import userPaymentInformationApi from 'vosker/src/user-module/payment-information/api/userPaymentInformationApi'
import { useDispatch } from 'react-redux'
import userPaymentInformationState from 'vosker/src/user-module/payment-information/core/userPaymentInformationState'
import { CardElement } from '@stripe/react-stripe-js'

const addToken = (user, token, dispatch) => userPaymentInformationApi
  .post(user.id, token)
  .then(info => dispatch(userPaymentInformationState.set(info)))

const addPaymentMethodInStripe = (user, dispatch, stripe, elements) => {
  const cardElement = elements.getElement(CardElement)

  return stripe.createToken(cardElement)
    .then(result => {
      if (result.token) {
        return userPaymentInformationApi
          .post(user.id, result.token.id)
          .then(info => dispatch(userPaymentInformationState.set(info)))
      } else if (result.error) {
        return Promise.reject(result.error.message)
      }
    })
}

const useUserPaymentInformationActions = () => {
  const dispatch = useDispatch()
  const user = useUser()

  const addPaymentMethod = (stripe, elements) => addPaymentMethodInStripe(user, dispatch, stripe, elements)
  const add = token => addToken(user, token, dispatch)
  const get = () => dispatch(userPaymentInformationState.get(user.id))

  return { add: add, addPaymentMethod: addPaymentMethod, get: get }
}

export default useUserPaymentInformationActions
