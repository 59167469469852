import ReactTimeAgo from 'react-timeago'
import frenchStrings from 'react-timeago/lib/language-strings/fr'
import englishStrings from 'react-timeago/lib/language-strings/en'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import { useTranslation } from 'react-i18next'

const TimeAgo = ({ timestamp }) => {
  const { i18n } = useTranslation()
  const formatter = buildFormatter(i18n.language === 'fr' ? frenchStrings : englishStrings)
  return <ReactTimeAgo date={timestamp} formatter={formatter} />
}

export default TimeAgo
