import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'

import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import { getPlanTranslationKey } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.selectors'
import InformationBarElement from 'photo-module/photos/ui/photos-gallery/information-bar/InformationBarElement'
import PlanCheckedIcon from 'vosker/src/assets/icons/PlanCheckedIcon'

const useStyles = makeStyles(theme => ({
  text: {
    textTransform: 'uppercase',
    color: theme.palette.text.primary,
  },
}))

const VoskerTransmissionPlanNameElement = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const camera = useSelectedCamera()

  if (!camera) {
    return null
  }

  const planName = t(`app:plans.${getPlanTranslationKey(camera.subscription.plan.name)}`)
  return (
    <Tooltip arrow title={t('app:photo_gallery_page.tooltip.plan')} enterDelay={300} leaveDelay={100}>
      <Box px={1} display="flex" alignItems="center">
        <InformationBarElement text={planName} Icon={PlanCheckedIcon} className={classes.text} />
      </Box>
    </Tooltip>
  )
}

export default VoskerTransmissionPlanNameElement
