import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Tooltip from '@material-ui/core/Tooltip'

import HdIcon from 'assets/icons/video/HdIcon'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import InformationBarElement from 'photo-module/photos/ui/photos-gallery/information-bar/InformationBarElement'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  text: {
    color: theme.palette.text.primary,
  },
}))

const VoskerHdPhotoCountElement = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const camera = useSelectedCamera()

  return camera && camera.subscription.plan.hdCountPerMonth !== 0
    ? (
      <Tooltip arrow title={t('app:photo_gallery_page.tooltip.nbPhotoHD')} enterDelay={300} leaveDelay={100}>
        <Box px={1} display="flex" alignItems="center">
          <InformationBarElement
            Icon={HdIcon}
            className={classes.text}
            text={`${camera.subscription.hdPhotoCount}/${camera.subscription.plan.hdCountPerMonth} photos`}
          />
        </Box>
      </Tooltip>
      )
    : null
}

export default VoskerHdPhotoCountElement
