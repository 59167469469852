import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Divider from '@material-ui/core/Divider'
import MenuItem from '@material-ui/core/MenuItem'

import CInput from '../../components/inputs/CInput'
import useCountries from 'shared-module/countries/useCountries'
import { useFormField } from 'shared-module/new-components/form/FormDataContext'

export interface ICountryField {
  fieldName: string
  label?: string
  validations?: boolean
  validationSchema?: Object
  onChange?: (e) => any
  className?: string
  disabled?: boolean
  isNewUi?: boolean
  margin?: 'dense' | 'normal' | 'none'
  userInformation?: { country: string, territory: string }
}

const CountryField = (props: ICountryField) => {
  const { t } = useTranslation()
  const countries = useCountries()
  const field = useFormField(props.fieldName)
  const territoryField = useFormField('territory')

  const [country, setCountry] = useState(props.userInformation ? props.userInformation.country : '')

  const defaultValidation = { required: t('input.country_required') }

  const onChange: any = ([e]) => {
    territoryField?.setValue('')
    field.onChange()
    setCountry(e.target.value)
    props.onChange && props.onChange(e)
    return e.target.value
  }

  const buildMenuItem = country => <MenuItem key={country.isoCode} value={country.isoCode}>{ country.name }</MenuItem>

  const CountryInput = (
    <CInput select name={field.name} label={props.label ?? t('register.form.country')} margin={props.margin} isNewUi={props?.isNewUi}>
      { countries && buildMenuItem(countries.find(x => x.isoCode === 'US')) }
      { countries && buildMenuItem(countries.find(x => x.isoCode === 'CA')) }

      <Divider />

      { countries && countries.map(country => buildMenuItem(country)) }
    </CInput>
  )

  return countries && (
    <Controller
      name={field.name}
      className={props.className}
      as={CountryInput}
      defaultValue={country}
      control={field.control}
      rules={defaultValidation}
      onChange={onChange}
      error={field.error}
      disabled={props.disabled}
    />
  )
}

export default CountryField
