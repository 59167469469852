import { useTranslation } from 'react-i18next'
import { useFormData } from 'shared-module/new-components/form/FormDataContext'
import PublicPageText from 'shared-module/new-components/public-page/PublicPageText'
import ForwardBackLink from 'shared-module/components/FowardBackLink'

const ChangePasswordSuccess = () => {
  const { t } = useTranslation()
  const form = useFormData()
  const success = form.formState.touched.success
  const error = form.formState.touched.error

  if (success) {
    return (
      <>
        <PublicPageText>{ t('change_password.success.text') }</PublicPageText>
        <PublicPageText><ForwardBackLink pathname="/login">{ t('change_password.success.please_login') }</ForwardBackLink></PublicPageText>
      </>
    )
  } else if (error) {
    return (
      <>
        <PublicPageText>{ t('change_password.error.text') }</PublicPageText>
        <PublicPageText><ForwardBackLink pathname="/reset-password">{ t('change_password.error.try_again') }</ForwardBackLink></PublicPageText>
      </>
    )
  } else { return null }
}

export default ChangePasswordSuccess
