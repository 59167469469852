import { useDispatch, useSelector } from 'react-redux'

import cameraPlansActions from './cameraPlans.actions'
import useLoad from 'shared-module/loading/useLoad'
import useReload from 'shared-module/loading/useReload'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'

export const useCameraPlans = () => useSelector(state => state.cameraPlans)
export const useLoadCameraPlans = () => useLoad('camera-plan', useCameraPlans(), useCameraPlansActions().get)
export const useReloadCameraPlans = () => useReload(useCameraPlans(), useCameraPlansActions().get)

export const useCameraPlansActions = () => {
  const dispatch = useDispatch()
  const camera = useSelectedCamera()

  return {
    get: () => dispatch(cameraPlansActions.getPlans(camera.id)),
    reset: () => dispatch(cameraPlansActions.clearCameraPlans()),
  }
}
