import restapi from 'shared-module/api/restapi'
import handleError from 'shared-module/api/apiError'

const requestCommand = (id, command, unset) => {
  return restapi.post('/api/v3/camera/requests/' + id,
    null, {
      params: {
        command,
        unset,
      },
    })
    .then(data => data.data)
    .catch(handleError)
}
const requestCommandWithBody = (id, requestBody) => {
  return restapi.post('/api/v3/camera/requests/' + id, requestBody)
    .then(data => data.data)
    .catch(handleError)
}

const getCommandForm = (id) => {
  return restapi.get('/api/v3/camera/commands-form/' + id)
    .then(data => data.data)
    .catch(handleError)
}

const cameraCommandsApi = {
  requestCommand: requestCommand,
  getCommandForm: getCommandForm,
  requestCommandWithBody: requestCommandWithBody,
}

export default cameraCommandsApi
