import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const NotificationsOutlinedIconActive = ({ ...props }) => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="NotificationActiveBell" width="26" height="26" viewBox="0 0 26 26" {...props}>
      <path
        d="M24.4238 7.99172C24.4238 10.6283 22.2864 12.7657 19.6499 12.7657C17.0133 12.7657 14.8759 10.6283 14.8759 7.99172C14.8759 5.35514 17.0133 3.21777 19.6499 3.21777C22.2864 3.21777 24.4238 5.35514 24.4238 7.99172Z"
        fill={theme.palette.error.main}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0311 14.3403C15.799 14.0292 13.2718 11.3057 13.2718 7.99192C13.2718 6.62664 13.7008 5.36155 14.4313 4.32409C14.0417 4.15961 13.6345 4.02512 13.2128 3.92333V3.10877C13.2128 2.17784 12.3983 1.36328 11.4673 1.36328C10.5364 1.36328 9.72184 2.17784 9.72184 3.10877V3.92333C6.34723 4.73789 3.90355 7.64704 3.90355 11.2544V17.6545L1.57623 19.9818V21.1455H21.3584V19.9818L19.0311 17.6545V14.3403ZM11.9328 24.6364H11.4673C10.1873 24.6364 9.14001 23.5892 9.14001 22.3091H13.7946C13.7946 22.6582 13.6783 23.0073 13.5619 23.2401C13.3292 23.9383 12.7474 24.5201 11.9328 24.6364Z"
        fill={theme.palette.primary.main}
      />

    </SvgIcon>
  )
}

export default NotificationsOutlinedIconActive
