import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import videoState from 'video-module/video-clip/core/videoState'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'

const DeletePhotoDialog = ({ open, onClose, onConfirm, photo, photoIdsToDelete, isNewUi = false }) => {
  const { t } = useTranslation()
  const selectedCamera = useSelectedCamera()
  const { photos } = useSelector(state => state.galleryPhoto)

  const [cameraModel, setCameraModel] = useState('')

  useEffect(() => {
    if (selectedCamera?.camera) {
      setCameraModel(selectedCamera.camera.status.model)
    }
  }, [selectedCamera])

  const getDeleteType = () => {
    if (cameraModel !== 'FLEX') {
      return photo || photoIdsToDelete.length === 1 ? 'delete' : 'delete_multiple'
    } else {
      if (photo || photoIdsToDelete.length === 1) {
        const photoToDelete = photo || photos.find((photo) => photo.id === photoIdsToDelete[0])
        if (videoState.isAVideo(photoToDelete)) {
          return 'delete_video'
        } else if (videoState.hasPreview(photoToDelete)) {
          return 'delete_preview'
        } else {
          return 'delete'
        }
      } else {
        return t('delete_multiple_items')
      }
    }
  }

  return (
    <ConfirmationDialog
      isNewUi={isNewUi}
      open={open}
      onConfirm={onConfirm}
      onCancel={onClose}
      onClose={onClose}
      title={t(`app:photo.${getDeleteType()}.confirmation.title`, { count: photoIdsToDelete?.length })}
      text={isNewUi ? t(`app:photo.${getDeleteType()}.confirmation.text`) : undefined}
      confirmText={t(`app:photo.${getDeleteType()}.confirmation.confirm`)}
    />
  )
}

export default DeletePhotoDialog
