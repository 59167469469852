import { Fragment, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { useTheme } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import CheckmarkIcon from 'vosker/src/assets/icons/CheckmarkIcon'
import GridLayout from 'vosker/src/camera-module/transmission-plan/ui/components/layout/GridLayout'
import AttributeLabel from 'vosker/src/camera-module/transmission-plan/ui/components/layout/AttributeLabel'
import DescriptorItem from 'vosker/src/camera-module/transmission-plan/ui/components/layout/DescriptorItem'
import * as helpers from 'vosker/src/camera-module/transmission-plan/ui/helper/PlanHelper'
import { apiCameraPlans } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.types'
import { useCameraPlans } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.hook'
import { cameraAddOns } from 'vosker/src/camera-module/transmission-plan/core/cameraAddOns.types'

const useStyles = makeStyles(theme => ({
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  footNoteIndex: {
    fontSize: 10,
    marginLeft: 4,
    display: 'inline',
  },
  value: {
    fontSize: 16,
    display: 'inline',
    fontWeight: 'bold',
  },
  gridLayout: {
    padding: '0 16px 0 18px',
    [theme.breakpoints.down('md')]: {
      padding: '0 18px 0 16px',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '0.25rem',
      padding: '0 1.25rem 0 0.875rem',
    },
  },
  box: ({ count }) => ({
    margin: count > 3 ? '0 1.5rem 0 0.875rem' : '0 1.438rem',
    paddingBottom: '1rem',
    [theme.breakpoints.down('md')]: {
      margin: '0 1.25rem',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 1.25rem',
      paddingBottom: '0.75rem',
    },
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  }),
  container: {
    margin: '1.5rem -1.25rem 0 -1rem',
    [theme.breakpoints.down('md')]: {
      margin: '1.5rem -1.375rem 0 -0.875rem',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 -1.25rem 0 -1rem',
    },
  },
  accordion: {
    border: 'none',
    boxShadow: 'none',
    background: 'transparent',
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: '3rem !important',
    },
  },
  summary: {
    maxHeight: 48,
    boxShadow: 'none',
    borderRadius: '0.375rem',
    background: theme.palette.background.light,
    [theme.breakpoints.between('lg', 'xl')]: {
      padding: '0 1rem 0 2rem',
    },
  },
  icon: {
    fontSize: '2.25rem !important',
    color: theme.palette.text.primary,
  },
  divider: {
    height: 1.5,
    width: '85%',
    top: '1.125rem',
    position: 'relative',
    background: theme.palette.border?.primary,
  },
  firstVerticalItem: {
    borderRadius: '0.375rem 0.375rem 0rem 0rem',
  },
  lastHorizontalItem: {
    borderRadius: '0rem 0rem 0.375rem 0.375rem',
  },
  firstHorizontalItem: {
    borderRadius: '0.375rem 0 0 0.375rem',
  },
  lastVerticalItem: {
    borderRadius: '0 0.375rem 0.375rem 0rem',
  },
  descriptionContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
    },
  },
}))

const PlanDescriptor = ({ camera }) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const { plans: allPlans, addOns } = useCameraPlans()
  const plans = useMemo(() => allPlans.filter(plan => plan.id !== apiCameraPlans.empty), [allPlans])
  const classes = useStyles({ count: plans.length })

  const smallDisplay = useMediaQuery(theme.breakpoints.only('xs'))
  const mediumLargeDisplay = useMediaQuery(theme.breakpoints.up('md'))

  const [expanded, setExpanded] = useState(false)
  const handleExpansionChange = (panel) => (event, isExpanded) => setExpanded(isExpanded ? panel : false)

  const getClassName = (index, className) => {
    if (mediumLargeDisplay) return className
    if (index === 0) return classes.firstHorizontalItem
    if (index === plans.length - 1) return classes.lastVerticalItem

    if (smallDisplay && plans.filter(plan => plan.id === 'Free').length > 0 && index === 1) {
      return classes.firstHorizontalItem
    }

    return ''
  }

  const renderLine = (label, action, className, showDivider = false) => (
    <GridLayout className={classes.gridLayout}>
      <AttributeLabel>
        <Typography className={classes.value}>{ t(`app:plan.item.${label}.label`) }</Typography>
        { label === 'videoCount' && <Typography className={classes.footNoteIndex}>1</Typography> }
      </AttributeLabel>

      <Box component={smallDisplay ? Grid : Fragment} container className={classes.descriptionContainer}>
        { plans.map((plan, index) => {
          if (smallDisplay && plan.name === apiCameraPlans.free) {
            return null
          }
          return (
            <DescriptorItem key={plan.id} plans={plans} className={getClassName(index, className)}>
              <Typography className={classes.value}>{ action(t, camera, plan) }</Typography>

              { showDivider && <Divider className={classes.divider} /> }
            </DescriptorItem>
          )
        }) }
      </Box>
    </GridLayout>
  )

  const renderAddOn = ({ addOn, className, showDivider = false }) => (
    <GridLayout key={addOn.id} className={classes.gridLayout}>
      <AttributeLabel>
        <Typography className={classes.value}>{ t(`app:plan.addon.${addOn.id}.label`) }</Typography>
      </AttributeLabel>

      <Box component={smallDisplay ? Grid : Fragment} container className={classes.descriptionContainer}>
        { plans.map((plan, index) => (
          <DescriptorItem key={plan.id} plans={plans} plan={plan} className={getClassName(index, className)} horizontalAlignment>
            <CheckmarkIcon />
            { showDivider && <Divider className={classes.divider} /> }
          </DescriptorItem>
        )) }
      </Box>
    </GridLayout>
  )

  return (
    <Box className={classes.box}>
      <Accordion
        expanded={expanded === 'panel'}
        onChange={handleExpansionChange('panel')}
        className={classes.accordion}
      >
        <AccordionSummary className={classes.summary} expandIcon={<ArrowDropDownIcon className={classes.icon} />}>
          <Typography variant="h2">{ t('app:plan.item.details') }</Typography>
        </AccordionSummary>

        <Box className={classes.container}>
          { renderLine('photoCount', helpers.getMonthlyPhotosText, classes.firstVerticalItem, mediumLargeDisplay) }

          { renderLine('history', helpers.getHistoryText, '', mediumLargeDisplay) }

          { plans.some(plan => plan.hdCountPerMonth > 0) &&
            renderLine('hdPhotoCount', helpers.getMonthlyHdPhotosText, classes.lastHorizontalItem) }

          { plans.some(plan => plan.videoCountPerMonth > 0) &&
            renderLine('videoCount', helpers.getMonthlyVideoText, '', mediumLargeDisplay) }

          { addOns.map(addOn => addOn.id === cameraAddOns.streaming &&
            renderAddOn({ addOn, className: classes.lastHorizontalItem })) }

        </Box>
      </Accordion>
    </Box>
  )
}

export default PlanDescriptor
