const defaultUser = { isAuthenticated: false, isFetching: false }

const fetching = () => ({ isAuthenticated: false, isFetching: true })
const set = user => ({ ...user, isAuthenticated: true, isFetching: false })
const isReady = user => user.isAuthenticated
const isFetching = user => user.isFetching

const userState = {
  defaultUser: defaultUser,
  fetching: fetching,
  set: set,
  isReady: isReady,
  isFetching: isFetching,
}
export default userState
