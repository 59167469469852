const FREQUENCY = {
  monthly: 'monthly',
  yearly: 'annual',
}

const DISCOUNT_TYPE = {
  percentage: 'percent',
  amount: 'amount',
}

export { FREQUENCY, DISCOUNT_TYPE }
