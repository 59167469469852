import makeStyles from '@material-ui/styles/makeStyles'

import VideoPlayerBackButton from './BackButton'

const useStyles = makeStyles(theme => ({
  actionsBarContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: 78,
    background: `linear-gradient(360deg, rgba(0, 0, 0, 0) 24.74%, ${theme.palette.background.viewer} 196.91%)`,
    zIndex: 999,
  },
  backActionContainer: {
    marginLeft: 20,
  },
}))

const CameraStreamActionBar = () => {
  const classes = useStyles()

  return (
    <div className={classes.actionsBarContainer}>
      <div className={classes.backActionContainer}>
        <VideoPlayerBackButton />
      </div>
    </div>
  )
}

export default CameraStreamActionBar
