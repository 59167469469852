import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const FullHdVideoCrossedOutIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="FullHdVideoCrossedOutIcon" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25789 0.985436L2.25567 0.987656L19.5708 18.304L18.5918 19.2812L17.5761 18.2655C17.4179 18.3097 17.2501 18.3335 17.0763 18.3335H3.88183C2.93458 18.3335 2.16665 17.6275 2.16665 16.7567V3.2436C2.16665 3.12441 2.18104 3.00831 2.2083 2.89669L1.16812 1.85644L2.14941 0.876953L2.25789 0.985436ZM4.52953 7.77362V5.21807L5.71305 6.40167H5.51676V7.77362H4.52953ZM9.86839 10.5573L8.14607 8.83486V11.8208H6.22385V8.78986H4.53229V16.2595H6.22385V13.2286H8.14607V16.2595H9.86839V10.5573ZM12.7913 13.4803L11.0549 11.7439V16.2595H13.8019C14.3958 16.2595 14.8862 16.1591 15.2746 15.9638L14.1187 14.8079C14.0154 14.8379 13.8987 14.8516 13.7701 14.8516H12.7913V13.4803Z"
        fill={theme.palette.background.default}
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.89551 1.66683L7.58201 4.35351V3.74087H8.56924V5.3408L9.30052 6.07213V3.74087H10.2877V6.65109C10.2877 6.77952 10.2718 6.90048 10.241 7.01269L12.0181 8.78986H13.8019C15.5385 8.78986 16.391 9.64864 16.391 11.2254V13.1631L18.7914 15.5637V3.2436C18.7914 2.37279 18.0235 1.66683 17.0763 1.66683H4.89551ZM15.1484 6.87693V3.74087H14.1612V7.77362H16.2819V6.87693H15.1484ZM12.1247 3.74087H11.1375V7.77362H13.2582V6.87693H12.1247V3.74087Z"
        fill={theme.palette.background.default}
      />

      <path
        d="M14.6542 11.4261V11.155C14.6542 10.4511 14.3226 10.1976 13.7701 10.1976H13.4258L14.6542 11.4261Z"
        fill={theme.palette.background.default}
      />
    </SvgIcon>
  )
}

export default FullHdVideoCrossedOutIcon
