import withStyles from '@material-ui/core/styles/withStyles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import MenuIcon from '@material-ui/icons/Menu'
import { isExternalWebApp } from 'shared-module/webview/isExternalWebApp'
import NotificationsCenter from 'notifications-module/ui/NotificationsCenter'
import switches from 'shared-module/config/switches'
import appConfig from 'shared-module/config/appConfig'
import CButton from 'storybook-component-module/src/components/buttons/CButton'
import { useTranslation } from 'react-i18next'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useHistory, useLocation } from 'react-router-dom'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -8,
    marginRight: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  menuButtonWithJoinTheClub: {
    marginLeft: -8,
    marginRight: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '@media only screen and (max-width: 500px)': {
      marginLeft: '-12px',
      marginRight: '0px',
    },
  },
  appBar: {
    background: theme.palette.background.default,
    marginTop: '0px',
    zIndex: '101',
    height: '64px!important',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  joinTheClubBtnWrapper: {
    alignSelf: 'center',
    marginLeft: '4px',
  },
  joinTheClubButton: {
    whiteSpace: 'nowrap',
  },
  itemGap: {
    gap: '16px',
  },
})

const Navbar = ({
  onClick,
  classes,
  Logo,
  BackButton,
  CartButton,
  notificationIcon,
  notificationIconActive,
  showSpyPointJoinInsidersClubBtn,
  overrideClasses = undefined,
  isNewUi = false,
}) => {
  const history = useHistory()
  const { t } = useTranslation()
  const location = useLocation()
  const smallDevice = useMediaQuery('(max-width: 768px)')

  const isWebBrowser = !isExternalWebApp()
  const displayCart = isWebBrowser && CartButton
  const onMemberShipPage = location.pathname === '/membership' || location.pathname === '/membership-v2'

  const toggleMainMenu = () => onClick()

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={clsx(classes.appBar, overrideClasses?.navbar)}>
        <Toolbar style={{ top: 0 }}>
          { isWebBrowser && (
            <IconButton
              id="main-menu-button"
              className={showSpyPointJoinInsidersClubBtn ? classes.menuButtonWithJoinTheClub : classes.menuButton}
              aria-label="Menu"
              onClick={toggleMainMenu}
            >
              <MenuIcon />
            </IconButton>
          ) }
          { isExternalWebApp() && BackButton && <BackButton /> }

          <Box className={clsx(classes.grow, overrideClasses?.logo)}>
            <Logo
              style={{
                width: '100%',
                maxWidth: '150px',
                height: 'auto',
                marginTop: '2px',
                flexGrow: 1,
              }}
            />
          </Box>

          <Box display="flex" className={!smallDevice ? classes.itemGap : undefined}>
            { showSpyPointJoinInsidersClubBtn && !onMemberShipPage && (
              <div className={classes.joinTheClubBtnWrapper}>
                <CButton
                  variety="primary"
                  color="primary"
                  type="button"
                  size="small"
                  onClick={() => history.push('/membership')}
                  className={classes.joinTheClubButton}
                >
                  { t('app:join_the_club.title') }
                </CButton>
              </div>
            ) }
            { appConfig.notificationCenter.switch === switches.on && (
              <NotificationsCenter
                notificationIcon={notificationIcon}
                notificationIconActive={notificationIconActive}
                isNewUi={isNewUi}
              />
            ) }
            { displayCart && <CartButton /> }
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default withStyles(styles)(Navbar)
