import clsx from 'clsx'

import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '0 20px',
    [theme.breakpoints.down('md')]: {
      padding: '0 16px',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
}))

const GridLayout = ({ className, style, children }) => {
  const classes = useStyles()

  return (
    <Grid container className={clsx(classes.container, className)} style={style}>
      { children }
    </Grid>
  )
}

export default GridLayout
