import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'

import { useFormData } from 'shared-module/new-components/form/FormDataContext'
import PasswordField from 'storybook-component-module/src/wrappers/fields/PasswordField'
import ChangePasswordButton from 'user-module/user/ui/change-password/components/ChangePasswordButton'
import { VoskerInputStyles } from 'vosker/src/components/buttons/VoskerInputField'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  input: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.background?.paper,
    },
  },
}))

const ChangePasswordForm = ({ isComplexPassword = false, isNewUi }) => {
  const { t } = useTranslation()
  const form = useFormData()
  const classes = useStyles()
  const importedClasses = VoskerInputStyles()

  const success = form.formState.touched.success
  const error = form.formState.touched.error

  return (
    <Box display={success || error ? 'none' : 'flex'} flexDirection="column">
      <PasswordField
        className={isNewUi ? clsx(importedClasses.input, classes.input) : ''}
        isNewUi={isNewUi}
        showHints
        validations
        fieldName="password"
        label={t('change_password.form.password')}
        isComplexPassword={isComplexPassword}
      />

      <ChangePasswordButton isNewUi={isNewUi} />
    </Box>
  )
}
export default ChangePasswordForm
