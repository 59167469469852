import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import WarningIcon from 'assets/icons/navigation/WarningIcon'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'
import { TAKE_PHOTO, TAKE_VIDEO, UPDATE_FIRMWARE, FORMAT_SD } from '../../../core/cameraSettingsButtonInputTypes'
import { useHistory } from 'react-router-dom'

const DialogCameraCommandsRequest = ({
  camera,
  commandName,
  open,
  onClose,
  onConfirm,
}) => {
  const { t } = useTranslation()
  const PHOTO_DEDUCTION_FOR_VIDEO = 3
  const history = useHistory()
  const isStandard = camera?.config?.operationMode === 'standard'
  const captureModeVideo = camera?.config?.captureMode === 'video'
  const isFlexSCamera = camera?.status?.model === 'FLEX-S'
  const isVKXCamera = camera?.status?.model === 'VKX'
  const hasLimit = camera?.subscription?.plan?.photoCountPerMonth > 0
  const outOfPhotos = commandName === TAKE_PHOTO && hasLimit &&
    camera?.subscription?.photoCount >= camera?.subscription?.plan?.photoCountPerMonth
  const outOfVideos = commandName === TAKE_VIDEO && hasLimit &&
    camera?.subscription?.photoCount + PHOTO_DEDUCTION_FOR_VIDEO > camera?.subscription?.plan?.photoCountPerMonth

  const popupText = () => {
    if (commandName === UPDATE_FIRMWARE) {
      return (
        <>
          { t(`app:camera.settings.fields.${commandName}.note`) + ' ' + t(`camera.settings.popup.confirmation.firmware.${camera.config.operationMode}`) }
          <br />
          <br />
          <Grid spacing={2} container direction="row" wrap="nowrap" alignItems="center">
            <Grid item><WarningIcon /></Grid>
            <Grid item>{ t('camera.settings.popup.confirmation.firmware.warning') }</Grid>
          </Grid>
        </>
      )
    } else if (commandName === FORMAT_SD) {
      return t('camera.settings.popup.format_storage.body.instant')
    } else if (commandName === TAKE_VIDEO) {
      return t(`camera.settings.popup.confirmation.video.${camera.config.operationMode}`)
    } else if (commandName === TAKE_PHOTO) {
      return t(`camera.settings.popup.confirmation.photo.${camera.config.operationMode}`)
    } else {
      return t(`camera.settings.popup.confirmation.body.${camera.config.operationMode}`)
    }
  }
  const popupTitle = () => {
    if (commandName === UPDATE_FIRMWARE) {
      return t('camera.settings.popup.confirmation.firmware.title')
    } else if (commandName === FORMAT_SD) {
      return t('camera.settings.popup.format_storage.title')
    } else if (isStandard) {
      return t('camera.settings.popup.confirmation.titleStandard')
    } else {
      return t('camera.settings.popup.confirmation.title')
    }
  }
  const handleNoPhotos = () => {
    onClose()
    history.push(`/camera/${camera.id}/plan`)
  }
  const goCameraSettings = () => {
    onClose()
    history.push(`/camera/${camera.id}/settings`)
  }

  if (outOfPhotos || outOfVideos) {
    if (isFlexSCamera) {
      return (
        <ConfirmationDialog
          open={open}
          onClose={onClose}
          title={t('camera.settings.popup.out_of_photos.title')}
          text={captureModeVideo ? t('camera.settings.popup.out_of_photos.body_is_flex_s_video_case', { photo: captureModeVideo ? 'video' : 'photo' }) : t('camera.settings.popup.out_of_photos.body_is_flex_s', { photo: captureModeVideo ? 'video' : 'photo' })}
          confirmText={t('camera.settings.popup.out_of_photos.confirm')}
          onConfirm={handleNoPhotos}
          cancelText={t('camera.settings.buttons.cancel')}
          onCancel={onClose}
        />
      )
    } else {
      return (
        <ConfirmationDialog
          open={open}
          onClose={onClose}
          title={t('camera.settings.popup.out_of_photos.title')}
          text={captureModeVideo ? t('camera.settings.popup.out_of_photos.body_video_case', { photo: captureModeVideo ? 'video' : 'photo' }) : t('camera.settings.popup.out_of_photos.body', { photo: captureModeVideo ? 'video' : 'photo' })}
          confirmText={t('camera.settings.popup.out_of_photos.confirm')}
          onConfirm={handleNoPhotos}
          cancelText={t('camera.settings.buttons.cancel')}
          onCancel={onClose}
        />
      )
    }
  } if (isStandard && commandName !== UPDATE_FIRMWARE && commandName !== FORMAT_SD) {
    return (
      <ConfirmationDialog
        open={open}
        onClose={onClose}
        title={popupTitle()}
        text={popupText()}
        confirmText={t('camera.settings.popup.confirmation.settings')}
        onConfirm={goCameraSettings}
        cancelText={t('button.close')}
        onCancel={onClose}
      />
    )
  } else {
    return (
      <ConfirmationDialog
        open={open}
        onClose={onClose}
        title={popupTitle()}
        text={popupText()}
        confirmText={t(`app:camera.settings.fields.${commandName}.label`)}
        onConfirm={onConfirm}
        cancelText={t('camera.settings.buttons.cancel')}
        onCancel={onClose}
        model={isVKXCamera}
      />
    )
  }
}

export default DialogCameraCommandsRequest
