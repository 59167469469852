export const isFormLoading = form => form.isSubmitting || form.isValidating

export const canSubmitForm = form => {
  const displayedErrors = Object.keys(form.errors).filter(field => !!(form.touched)[field])
  return displayedErrors.length === 0 && !form.isSubmitting && !form.isValidating
}

export const isFormTouched = (form) => {
  return Object.keys(form.touched).some((field) => form.touched[field])
}

export const hasFieldError = fieldMeta => !!(fieldMeta.touched && fieldMeta.error)

export const getFieldError = (fieldMeta, t) => {
  if ((!fieldMeta.touched && !fieldMeta.error) || (fieldMeta.touched && !fieldMeta.error)) {
    return
  }
  const { key, values = {} } = typeof fieldMeta.error === 'string' ? { key: fieldMeta.error } : fieldMeta.error
  return t(key, values)
}
