import { useState, useRef, useEffect } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'

import SecondaryButton from 'shared-module/components/button/SecondaryButton'
import { useDetectionZone } from './../core/detectionZone.hooks.js'

const useStyles = makeStyles(() => ({
  hidden: {
    display: 'none',
  },
}))

const ExportButton = (props) => {
  const classes = useStyles()
  const downloadRef = useRef()
  const { anchorList } = useDetectionZone()
  const [objectURL, setObjectURL] = useState('')

  useEffect(() => {
    if (!objectURL) return
    downloadRef.current.click()
  }, [objectURL])

  const onExport = () => {
    const blob = new Blob([JSON.stringify(anchorList)], {
      type: 'application/json',
    })
    setObjectURL(URL.createObjectURL(blob))
  }

  return (
    <div>
      <SecondaryButton value="export" disabled={props.disabled} onClick={onExport} />
      <a ref={downloadRef} href={objectURL} download={props.photoName + '.json'} className={classes.hidden} />
    </div>
  )
}

export default ExportButton
