import { parse } from 'qs'
import { useLocation } from 'react-router-dom'

const useQueryParams = () => {
  const urlParams = useLocation().search.replace('?', '')
  const params = parse(urlParams, { encode: false, decoder: (str, decoder, charset) => paramDecoder(str, decoder, charset) })

  return params
}

const paramDecoder = (str, decoder, charset) => {
  const strWithoutPlus = str.replace(/\+/g, ' ')
  if (charset === 'iso-8859-1') {
    return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape)
  }

  if (/^(\d+|\d*\.\d+)$/.test(str)) {
    return parseFloat(str)
  }

  const keywords = {
    true: true,
    false: false,
    null: null,
    undefined,
  }
  if (str in keywords) {
    return keywords[str]
  }

  try {
    return decodeURIComponent(strWithoutPlus)
  } catch (e) {
    return strWithoutPlus
  }
}

export default useQueryParams
