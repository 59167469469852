import SvgIcon from '@material-ui/core/SvgIcon'

const DeleteIcon = (props) => (
  <SvgIcon {...props} data-testid="DeleteIcon">
    <path
      d="M14.395 18.176h.957c.261 0 .476-.211.476-.469V9.242a.475.475 0 00-.476-.472h-.957a.475.475 0 00-.477.472v8.465c0 .258.215.469.477.469zm6.523-13.164h-3.277l-1.352-2.223a1.922 1.922 0 00-1.637-.914h-4.011c-.668 0-1.29.348-1.637.914L7.652 5.012H4.375a.63.63 0 00-.637.625v.625c0 .347.285.629.637.629h.637v13.168c0 1.035.855 1.878 1.91 1.878h11.45c1.054 0 1.91-.843 1.91-1.878V6.89h.636a.63.63 0 00.633-.63v-.624a.626.626 0 00-.633-.625zm-10.344-1.14a.243.243 0 01.203-.118h3.739c.082 0 .16.047.203.117l.695 1.137H9.88zm7.797 16.187H6.921V6.89h11.45zm-8.43-1.883h.957c.262 0 .477-.211.477-.469V9.242a.475.475 0 00-.477-.472h-.957a.475.475 0 00-.476.472v8.465c0 .258.215.469.476.469zm0 0"
      fillRule="evenodd"
    />
  </SvgIcon>
)

export default DeleteIcon
