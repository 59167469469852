import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const NotificationsOutlinedIcon = ({ ...props }) => {
  const theme = useTheme()
  return (
    <SvgIcon data-testid="NotificationBell" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path d="M15.416 8.75033C15.416 6.16699 13.666 4.08366 11.2493 3.50033V2.91699C11.2493 2.25033 10.666 1.66699 9.99935 1.66699C9.33268 1.66699 8.74935 2.25033 8.74935 2.91699V3.50033C6.33268 4.08366 4.58268 6.16699 4.58268 8.75033V13.3337L2.91602 15.0003V15.8337H17.0827V15.0003L15.416 13.3337V8.75033ZM9.99935 18.3337C10.0827 18.3337 10.2494 18.3337 10.3327 18.3337C10.916 18.2503 11.3327 17.8337 11.4993 17.3337C11.5827 17.167 11.666 16.917 11.666 16.667H8.33268C8.33268 17.5837 9.08268 18.3337 9.99935 18.3337Z" fill={theme.palette.text.primary} />
    </SvgIcon>
  )
}

export default NotificationsOutlinedIcon
