import restapi from 'shared-module/api/restapi'
import buildNotification from './notificationBridge'

const getAllNotifications = () =>
  restapi.get('/api/v3/notification/all')
    .then(response => (response.data !== undefined) ? response.data.map(buildNotification) : [])

const setAsRead = (notification) =>
  restapi.patch(`/api/v3/notification/${notification.id}/read`, notification)

const notificationApi = {
  getAllNotifications,
  setAsRead,
}

export default notificationApi
