import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom'

import VoskerLogoLight from 'vosker/src/assets/icons/VoskerLogoLight'
import { isExternalWebApp } from 'shared-module/webview/isExternalWebApp'

const VoskerClickableLogo = () => {
  const history = useHistory()
  const isWebview = isExternalWebApp()

  const handlesClick = () => {
    if (isWebview) return

    history.push('/')
  }

  return (
    <Button onClick={handlesClick}>
      <VoskerLogoLight style={{ width: '150px', height: '100%', marginTop: '2px', flexGrow: 1 }} />
    </Button>
  )
}
export default VoskerClickableLogo
