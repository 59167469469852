import dialogState from 'shared-module/dialog/dialogState'
import { CLEAR_DIALOG_MESSAGE, SET_DIALOG_MESSAGE } from 'shared-module/dialog/dialogActionsTypes'

const dialogReducer = (state = dialogState.defaultState, action) => {
  switch (action.type) {
    case SET_DIALOG_MESSAGE:
      return dialogState.set(action.dialogMessage)
    case CLEAR_DIALOG_MESSAGE:
      return dialogState.clear()
    default:
      return state
  }
}

export default dialogReducer
