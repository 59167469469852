import makeStyles from '@material-ui/core/styles/makeStyles'
import CButton from 'storybook-component-module/src/components/buttons/CButton'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  button: {
    width: '100%',
    height: '3rem',
    borderRadius: 6,
    '&:hover': {
      background: theme.palette.secondary.light,
      transition: 'opacity .3s',
    },
  },
}))

const VoskerSecondaryButton = ({ className, children, ...props }) => {
  const classes = useStyles()

  return (
    <CButton {...props} variety="primary" color="secondary" disableElevation className={clsx(classes.button, className)}>
      { children }
    </CButton>
  )
}

export default VoskerSecondaryButton
