import CameraMessage from '../camera-message/CameraMessage'
import { useNotificationsActions } from 'notifications-module/core/notifications.hooks'

const CameraNotificationMessage = ({ notification }) => {
  const notificationsActions = useNotificationsActions()

  const onMessageClick = notification?.action ? () => notificationsActions.handleNotificationAction(notification) : null

  return <CameraMessage onClick={onMessageClick} severity="error" message={notification.content} />
}

export default CameraNotificationMessage
