import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const BatteryMedium = ({ props }) => {
  const theme = useTheme()
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
      <path
        stroke="none"
        fillRule="nonzero"
        fill={theme.palette.text.primary}
        fillOpacity={1}
        d="M32 14a2.67 2.67 0 0 0-2.668-2.668H28V10a2.67 2.67 0 0 0-2.668-2.668H2.668A2.67 2.67 0 0 0 0 10v12a2.67 2.67 0 0 0 2.668 2.668h22.664A2.67 2.67 0 0 0 28 22v-1.332h1.332A2.67 2.67 0 0 0 32 18Zm-2.668 3.668A.335.335 0 0 1 29 18h-2.332a1.336 1.336 0 0 0-1.336 1.332v2a.683.683 0 0 1-.664.668H3.332a.665.665 0 0 1-.664-.668V10.668A.665.665 0 0 1 3.332 10h21.336a.665.665 0 0 1 .664.668v2A1.334 1.334 0 0 0 26.668 14H29a.335.335 0 0 1 .332.332Zm0 0"
      />
      <path
        stroke="none"
        fillRule="nonzero"
        fill={theme.palette.text.primary}
        fillOpacity={1}
        d="M14 11.332H4.668c-.371 0-.668.3-.668.668v8c0 .367.297.668.668.668H14a.67.67 0 0 0 .668-.668v-8a.67.67 0 0 0-.668-.668Zm0 0"
      />
    </SvgIcon>
  )
}

export default BatteryMedium
