const creditCardBrand = {
  americanExpress: 'american express',
  dinersClub: 'diners club',
  discover: 'discover',
  jcb: 'jcb',
  mastercard: 'mastercard',
  unionpay: 'unionpay',
  visa: 'visa',
  unknown: 'unknown',
}

export default creditCardBrand
