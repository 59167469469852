import { useState } from 'react'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { alpha } from '@material-ui/core/styles/colorManipulator'

import FavoriteButton from 'photo-module/photo/ui/photo-viewer/actions-bar/FavoriteButton'
import DeleteButton from 'photo-module/photo/ui/photo-viewer/actions-bar/DeleteButton'
import BackButton from 'photo-module/photo/ui/photo-viewer/actions-bar/BackButton'
import FullHdVideoButton from 'video-module/video-clip/ui/full-hd-video-status-button/FullHdVideoButton'
import HdPhotoButton from 'vosker/src/photos-module/photo/ui/photo-viewer/hd-photo-status-button/HdPhotoButton'
import VoskerButtonWrapper from 'vosker/src/photos-module/photo/ui/actions-bar/VoskerButtonWrapper'
import LiveStreamButton from 'vosker/src/photos-module/photo/ui/photo-viewer/hd-photo-status-button/buttons/LiveStreamButton'
import useSelectedPhoto from 'photo-module/photo/core/useSelectedPhoto'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'

export const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  viewerActionsBar: {
    top: '0',
    zIndex: 1,
    position: 'absolute',
    width: '100%',
    height: '64px',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0.75rem 1rem',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.8) 0.01%, rgba(0, 0, 0, 0) 100%)',
    [theme.breakpoints.up('sm')]: {
      borderBottom: `1px solid ${theme.palette.background.shadow}`,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '1rem 0.75rem 1rem 0.25rem',
    },
    '@media (orientation: landscape)': {
      borderBottom: 'none',
    },
  },
  photoStatusActionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexWrap: 'nowrap',
    gap: '1rem',
    '& .MuiButton-outlined': {
      color: theme.palette.basic?.lightGrey,
    },
    '& svg': {
      '& path': {
        fill: theme.palette.basic?.lightGrey,
      },
      height: '1.5rem',
      width: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      gap: '0.5rem',
    },
  },
  photoStatusActionsSmallScreen: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '5.5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    '& svg': {
      '& path': {
        fill: theme.palette.basic?.lightGrey,
      },
      height: '25px',
      width: 'auto',
    },
  },
  actionBarButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%',
    padding: '0px 53px',
  },
  actionBarRequest: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '0.5rem',
    '& .MuiButton-outlined': {
      border: '1px solid rgb(255 255 255 / 53%)',
      '&:hover': {
        border: '1px solid rgb(255 255 255)',
      },
      color: theme.palette.basic?.lightGrey,
    },
    '& svg': {
      '& path': {
        fill: theme.palette.basic?.lightGrey,
      },
      height: '25px',
      width: 'auto',
    },
  },
  backActionContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divider: {
    height: '1.25rem',
    backgroundColor: alpha(theme.palette.border?.light, 0.3),
    marginRight: '0.5rem',
  },
}))

const VoskerViewerActionsBar = () => {
  const classes = useStyles()
  const [isDisabled, setIsDisabled] = useState(false)
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const photo = useSelectedPhoto()
  const camera = useSelectedCamera()
  const supportsBothPhotoVideoRequest = camera?.status?.capability?.video && camera?.status?.capability?.hdRequest && !camera?.status?.capability?.stream
  const shouldPrioritizeHDVideoRequest = supportsBothPhotoVideoRequest ? photo?.urls?.preview : true

  return (
    <>
      <Box className={classes.viewerActionsBar}>
        <Box className={classes.backActionContainer}>
          <BackButton isNewUi />

          { smallScreen && (
            <Box className={classes.actionBarRequest}>
              <FullHdVideoButton canVkxRequestFullHdVideo={shouldPrioritizeHDVideoRequest} />
              <HdPhotoButton canVkxRequestFullHdPhoto={!shouldPrioritizeHDVideoRequest} />
              <LiveStreamButton />
            </Box>
          ) }

          { /* { smallScreen && <LiveStreamButton /> } */ }
        </Box>

        { !smallScreen && (
          <Box component={Grid} container className={classes.photoStatusActionsContainer}>
            <VoskerButtonWrapper>
              <FavoriteButton />
            </VoskerButtonWrapper>

            <VoskerButtonWrapper isDisabled={isDisabled}>
              <DeleteButton isNewUi setIsDisabled={setIsDisabled} />
            </VoskerButtonWrapper>

            <Divider orientation="vertical" className={classes.divider} />

            <FullHdVideoButton shouldPrioritizeHDVideoRequest={shouldPrioritizeHDVideoRequest} />
            <HdPhotoButton shouldPrioritizeHDVideoRequest={supportsBothPhotoVideoRequest ? !shouldPrioritizeHDVideoRequest : true} />
            <LiveStreamButton />
          </Box>
        ) }
      </Box>

      { smallScreen && (
        <Box className={classes.photoStatusActionsSmallScreen}>
          <Box className={classes.actionBarButtons}>
            <VoskerButtonWrapper>
              <FavoriteButton />
            </VoskerButtonWrapper>

            <VoskerButtonWrapper>
              <DeleteButton isNewUi />
            </VoskerButtonWrapper>
          </Box>
        </Box>
      ) }

    </>
  )
}

export default VoskerViewerActionsBar
