import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const PlusIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon {...props} data-testid="PlusIcon">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.4707 13.9219C3.4707 13.2229 4.01636 12.6563 4.68945 12.6563H12.1871V4.87019C12.1871 4.17121 12.7328 3.60457 13.4059 3.60457C14.079 3.60457 14.6246 4.17121 14.6246 4.87019L14.6246 12.6562L22.1223 12.6563C22.7954 12.6562 23.3411 13.2229 23.3411 13.9219C23.3411 14.6209 22.7954 15.1875 22.1223 15.1875L14.6246 15.1875L14.6246 22.9736C14.6246 23.6725 14.079 24.2392 13.4059 24.2392C12.7328 24.2392 12.1871 23.6725 12.1871 22.9736L12.1871 15.1875L4.68945 15.1875C4.01636 15.1875 3.4707 14.6209 3.4707 13.9219Z"
        fill={theme.palette.status?.info}
      />
    </SvgIcon>
  )
}

export default PlusIcon
