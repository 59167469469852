import paymentFrequency from 'vosker/src/order-module/order/core/paymentFrequency'
import EmptyLineIcon from 'vosker/src/assets/icons/EmptyLineIcon'

const isCurrentPlan = (camera, plan) => camera.subscription.plan.id === plan.id
export const onYearlyBillingCycle = camera => camera.subscription.paymentFrequency === paymentFrequency.yearly
const cycleUpgradeable = (camera, monthly) => onYearlyBillingCycle(camera) ? !onYearlyBillingCycle(camera) === monthly : true
const planIsCheaper = (camera, plan) => camera.subscription.plan.pricePerMonthIfPaidPerMonth <= plan.pricePerMonthIfPaidPerMonth

export const onFreePlan = camera => !camera.isCompletingTrial && camera.subscription.isFree
export const showCurrentPlan = (camera, plan, monthly) => isCurrentPlan(camera, plan) && !onYearlyBillingCycle(camera) === monthly
export const planIsUpgradeable = (camera, plan, monthly) => planIsCheaper(camera, plan) && cycleUpgradeable(camera, monthly) && !camera.isCompletingTrial

export const getHistoryText = (t, camera, plan) => {
  const historyDays = plan.historyDays[camera.status.model]

  return historyDays === 0
    ? t('app:plan.item.history.unlimited')
    : historyDays <= 2
      ? t('app:plan.item.history.hours', { hours: historyDays * 24 })
      : t('app:plan.item.history.days', { days: historyDays })
}

export const getMonthlyPhotosText = (t, camera = undefined, plan) => {
  return plan.photoCountPerMonth === 0
    ? t('app:plan.quantity.unlimited_photos')
    : plan.photoCountPerMonth
}

export const getMonthlyHdPhotosText = (t, camera, plan) => {
  return plan.hdCountPerMonth === 0
    ? <EmptyLineIcon props={{ marginBottom: -8, marginLeft: 8 }} />
    : plan.hdCountPerMonth
}

export const getMonthlyVideoText = (t, camera, plan) => plan.videoCountPerMonth
