import { useTheme } from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'

const AddCameraIcon = ({ className, color }) => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="AddCameraIcon" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <g clipPath="url(#clip0_2659_133228)">
        <rect width="48" height="48" rx="7.2" fill={color || theme.palette.border?.primary} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.75 25.2496V33.9996H25.25V25.2496H34V22.7496H25.25V13.9996H22.75V22.7496H14V25.2496H22.75Z"
          fill={theme.palette.text.primary}
        />
      </g>
      <defs>
        <clipPath id="clip0_2659_133228">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

export default AddCameraIcon
