import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const Coordinates = ({ props }) => {
  const theme = useTheme()
  return (
    <SvgIcon width="24" height="32" viewBox="0 0 24 32" {...props} data-testid="Coordinates">
      <path d="M12 0C9.01631 0 6.15483 1.10625 4.04505 3.07538C1.93526 5.04451 0.75 7.71523 0.75 10.5C0.75 18 12 30 12 30C12 30 23.25 18 23.25 10.5C23.25 7.71523 22.0647 5.04451 19.955 3.07538C17.8452 1.10625 14.9837 0 12 0ZM12 14.25C11.2053 14.25 10.4285 14.0301 9.7678 13.618C9.10707 13.206 8.59209 12.6203 8.28798 11.9351C7.98388 11.2498 7.90431 10.4958 8.05934 9.76841C8.21437 9.04098 8.59704 8.3728 9.15895 7.84835C9.72085 7.3239 10.4368 6.96675 11.2162 6.82205C11.9955 6.67736 12.8034 6.75162 13.5376 7.03545C14.2717 7.31928 14.8992 7.79993 15.3407 8.41661C15.7822 9.0333 16.0179 9.75832 16.0179 10.5C16.0179 10.9925 15.9139 11.4801 15.712 11.9351C15.5101 12.39 15.2141 12.8034 14.8411 13.1517C14.468 13.4999 14.025 13.7761 13.5376 13.9645C13.0501 14.153 12.5276 14.25 12 14.25Z" fill={theme.palette.text.primary} />

    </SvgIcon>
  )
}

export default Coordinates
