import Box from '@material-ui/core/Box'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import useCameraModelsAndVariants from 'camera-module/model/core/useCameraModelsAndVariants'
import Title from 'shared-module/new-components/layout/Title'

const CameraInfo = () => {
  const camera = useSelectedCamera()
  const model = useCameraModelsAndVariants(camera.status.model)

  return model
    ? (
      <Box pl={2} display="flex">
        <Box height={70} width={70} overflow="hidden" style={{ position: 'relative' }}>
          <img src={model.icon} alt={model.name} style={{ position: 'absolute', bottom: '0', width: '70%' }} />
        </Box>
        <Box display="flex" flexDirection="column" mt={2}>
          { !camera?.OwnerName && <Title h="h2" mb={1} noWrap maxWidth="250px">{ camera.config.name }</Title> }
          { camera?.OwnerName && <Title h="h2" mb={1} noWrap maxWidth="250px">{ `${camera.name} (${camera?.OwnerName})` }</Title> }
          <Title h="h3" mb={0}>{ model.name }</Title>
        </Box>
      </Box>
      )
    : null
}

export default CameraInfo
