import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const FirmwareIcon = ({ props }) => {
  const theme = useTheme()
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" {...props} data-testid="firmwareIcon">
      <g clip-path="url(#clip0_7565_3299)">
        <path
          d="M3.35497 5.63014C3.35497 4.17755 4.49807 3 5.90816 3H24.0996C25.5097 3 26.6528 4.17755 26.6528 5.63014V8.26027H30.0039V9.57534H26.6528V14.3261H30.0039V15.6412L16.5255 15.6408L12.0466 19.0683C11.6714 18.7743 11.2032 18.5998 10.6954 18.5998C9.46157 18.5998 8.46135 19.6301 8.46135 20.9011C8.46135 22.1721 9.46157 23.2025 10.6954 23.2025C11.9292 23.2025 12.9294 22.1721 12.9294 20.9011C12.9294 20.6304 12.8841 20.3706 12.8007 20.1294L16.9478 16.9559L26.6528 16.9559V20.3918H30.0039V21.7068H26.6528V24.3699C26.6528 25.8225 25.5097 27 24.0996 27H5.90816C4.49807 27 3.35497 25.8225 3.35497 24.3699V21.7068H0.00390625V20.3918H3.35497V15.6412H0.00390625V14.3261L13.4597 14.3258L17.9386 10.8984C18.3138 11.1923 18.782 11.3669 19.2898 11.3669C20.5236 11.3669 21.5239 10.3365 21.5239 9.0655C21.5239 7.79449 20.5236 6.76413 19.2898 6.76413C18.056 6.76413 17.0558 7.79449 17.0558 9.0655C17.0558 9.3362 17.1011 9.59598 17.1845 9.83722L13.0374 13.0107L3.35497 13.0107V9.57534H0.00390625V8.26027H3.35497V5.63014Z"
          fill={theme.palette.text.primary}
        />
      </g>
      <defs>
        <clipPath id="clip0_7565_3299">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

export default FirmwareIcon
