
import { useTheme } from '@material-ui/core'

const WrongIcon = ({ color }) => {
  const theme = useTheme()
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 10.583L16.417 18L18 16.417L10.583 9L18 1.58299L16.417 0L9 7.41701L1.58299 0L0 1.58299L7.41701 9L0 16.417L1.58299 18L9 10.583Z"
        fill={theme.palette.status?.error}
      />
    </svg>
  )
}
export default WrongIcon
