import Paper from '@material-ui/core/Paper'
import makeStyles from '@material-ui/core/styles/makeStyles'

import webview from 'shared-module/webview/webview'
import ProfilePreferences from './preferences/ProfilePreferences'

const useStyles = makeStyles((theme) => ({
  preferences: {
    padding: '24px 16px 32px 12px',
    marginTop: 24,
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
}))
const FormPreferenceSection = () => {
  const classes = useStyles()

  return !webview.isWebview() && (
    <Paper elevation={1} id="profilepreferences" className={classes.preferences}>
      <ProfilePreferences form="formProfile" />
    </Paper>
  )
}

export default FormPreferenceSection
