const hdErrorBuilder = (apiError) => {
  const error = apiError
  error.incompatibleCamera = buildIncompatibleCamera(apiError)

  return error
}

const buildIncompatibleCamera = (apiError) => {
  if (apiError?.response?.status === 400) {
    return true
  }

  return false
}

export default hdErrorBuilder
