import { useEffect } from 'react'

const useReload = (prop, get) => {
  useEffect(() => {
    get()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return prop
}

export default useReload
