import { useState } from 'react'

import { Drawer } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'

import FilterSettingIcon from 'assets/icons/FilterSettingIcon'
import FilterDrawerContent from './FilterDrawerContent'

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    top: 0,
    bottom: 0,
    height: 'auto',
    overflow: 'auto',
    width: '100%',
    '@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))': {
      background: theme.palette.background.paper,
      backdropFilter: 'blur(5px)',
      borderRadius: '4px',
    },
  },
  iconButton: {
    margin: '-8px 8px',
  },
}))

const FilterDrawer = ({ filterKeys, filterValues, FilterList }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const toggleOpen = () => setOpen(!open)
  const onClick = () => setOpen(wasOpen => !wasOpen)

  return (
    <>
      <IconButton onClick={onClick} className={classes.iconButton}>
        <FilterSettingIcon />
      </IconButton>
      <Drawer
        open={open}
        onOpen={toggleOpen}
        onClose={toggleOpen}
        anchor="bottom"
        classes={{ paper: classes.drawerPaper }}
        BackdropProps={{ invisible: true }}
      >
        <FilterDrawerContent filterKeys={filterKeys} filterValues={filterValues} close={toggleOpen} FilterList={FilterList} />
      </Drawer>
    </>
  )
}

export default FilterDrawer
