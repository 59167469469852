import React from 'react'
import cameraModelsState from 'camera-module/model/core/cameraModelsState'
import { useFormikContext } from 'formik'
import useCameraModels from 'camera-module/model/core/useCameraModels'
import ActivationHelp from 'camera-module/camera/ui/add-camera-form/activation-help/ActivationHelp'

const CameraActivationCard = () => {
  const models = useCameraModels()
  const { values } = useFormikContext()
  const isCellular = cameraModelsState.hasModels(models) ? models[values.deviceModel]?.isCellular : false

  return (
    <ActivationHelp
      isSelected={values.deviceModel !== ''}
      helpImage={models[values.deviceModel]?.helpImage}
      isCell={isCellular}
    />
  )
}

export default CameraActivationCard
