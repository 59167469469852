import useLanguage from 'shared-module/components/language/useLanguage'
import { formatAmount } from '../../common-utils'

const Amount = ({
  value = 0,
  currencyCode = 'USD',
  hideZerosDecimals = false,
  noSpace = false,
}) => {
  const language = useLanguage()

  return formatAmount(
    value,
    {
      currency: currencyCode,
      language,
      hideZerosDecimals,
      noSpace,
    },
  )
}

export default Amount
