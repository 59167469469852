import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Chip from '@material-ui/core/Chip'
import List from '@material-ui/core/List'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'

import useUser from 'user-module/user/core/useUser'
import LiveStreamIcon from 'assets/icons/navigation/LiveStreamIcon'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import LinkMenuItem from 'shared-module/navigation/menu/items/LinkMenuItem'
import { apiCameraPlans } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.types'

const LiveStreamMenuItem = ({ onClose }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const camera = useSelectedCamera()
  const user = useUser()

  const goToPlans = () => {
    history.push(`/camera/${camera.id}/plan`)
    onClose()
  }

  const goToAddons = () => {
    history.push(`/camera/${camera.id}/plan#addons`)
    onClose()
  }

  if (!camera.stream.available) {
    return null
  }

  const hasPlan = camera && camera.subscription.plan.id !== apiCameraPlans.empty
  const hasStreamingTime = user?.addOns?.sdStreamingSeconds > 0

  return (
    <List>
      <LinkMenuItem Icon={LiveStreamIcon} text="menu.livestream" path={`/camera/${camera.id}/live-streaming`} onClick={onClose} />
      <ListItemSecondaryAction>
        { !hasPlan && (
          <Chip
            size="small"
            color="primary"
            onClick={goToPlans}
            label={t('menu.livestream_chip_no_plan')}
            style={{ cursor: 'pointer', fontWeight: '600', fontSize: '9px' }}
          />
        ) }

        { hasPlan && !hasStreamingTime && (
          <Chip
            size="small"
            color="primary"
            onClick={goToAddons}
            label={t('menu.livestream_chip_no_time')}
            style={{ cursor: 'pointer', fontWeight: '600', fontSize: '9px' }}
          />
        ) }
      </ListItemSecondaryAction>
    </List>
  )
}

export default LiveStreamMenuItem
