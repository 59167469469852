/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import VoskerSecondaryButton from 'vosker/src/components/buttons/VoskerSecondaryButton'
import DialogCameraCommandsRequest from '../camera-settings-page/commands/DialogCameraCommandsRequest'
import { FORMAT_SD, UPDATE_FIRMWARE } from '../../core/cameraSettingsButtonInputTypes'
import useSelectedCamera from '../../core/useSelectedCamera'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'
import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'

const useStyles = makeStyles(theme => ({
  root: ({ isNewUi }) => ({
    margin: '20px',
    width: isNewUi ? '10.25rem' : '200px',
    height: isNewUi && '7.25rem',
    textAlign: 'center',
  }),
  icon: {
    width: '35px',
    filter: 'invert(100%) sepia(0%) saturate(13%) hue-rotate(235deg) brightness(104%) contrast(104%)',
  },
  value: ({ isNewUi }) => ({
    fontSize: isNewUi ? '0.813rem' : '0.916rem',
    fontFamily: isNewUi && theme.typography.fontFamilyBold,
    fontWeight: !isNewUi && 'bold',
    marginTop: '5px',
    whiteSpace: 'nowrap',
  }),
  label: ({ isNewUi }) => ({
    fontSize: isNewUi ? theme.typography.caption2?.fontSize : '13px',
    color: theme.palette.text.primary,
  }),
  FormatButtons: {
    width: 'auto',
    height: '32px',
    marginTop: 8,
  },
}))
const CameraStatusTile = ({ label, icon, value, useSVGIcon = false, isNewUi, firmwareUpdate, requested, sendCommands, commandName }) => {
  const { t } = useTranslation()
  const classes = useStyles({ isNewUi })
  const camera = useSelectedCamera()
  const dialogActions = useConfirmationDialogActions()
  const FormatButton = VoskerSecondaryButton
  const UpdateButton = VoskerSecondaryButton
  const VKXModel = camera?.status?.powerSources?.length > 0
  const updateFirmwareEnabled = appConfig.updateFirmware?.switch === switches.on
  const formatStorageEnabled = appConfig.formatStorage?.switch === switches.on

  return (
    <div className={classes.root} data-testid="tile">
      { useSVGIcon
        ? (
          <div style={{ paddingLeft: '3px' }}>
            <svg width="32" height="32" viewBox="0 0 32 32"> { icon } </svg>
          </div>
          )
        : <div><img src={icon} className={classes.icon} alt="icon" /></div> }
      <div className={classes.value}>
        { value }
      </div>
      <div className={classes.label}>
        { t(label) }
        { isNewUi && VKXModel && (
          <Grid item sm="auto" xs={12}>
            { formatStorageEnabled && label === 'app:camera.sd_card' && (
              <Grid>
                <Grid container xs={12} justifyContent="center" item>
                  { requested
                    ? (
                      <FormatButton
                        color="primary"
                        variant="outlined"
                        disabled={requested}
                        className={classes.FormatButtons}
                      >
                        { commandName === 'formatSD' ? t('camera.settings.buttons.formating') : t('camera.settings.buttons.format') }
                      </FormatButton>
                      )
                    : (
                      <>
                        <FormatButton
                          color="primary"
                          variant="outlined"
                          className={classes.FormatButtons}
                          onClick={() => dialogActions.open()}
                        >{ t('camera.settings.buttons.format') }
                        </FormatButton>
                        <DialogCameraCommandsRequest
                          camera={camera}
                          commandName={FORMAT_SD}
                          open={dialogActions.isOpen}
                          onClose={dialogActions.close}
                          onConfirm={() => {
                            sendCommands(FORMAT_SD, false)
                            dialogActions.close()
                          }}
                        />
                      </>
                      ) }
                </Grid>
              </Grid>
            ) }
            { updateFirmwareEnabled && (firmwareUpdate || camera.status?.updateInProgress) && label === 'camera.modem_firmware' && (
              <Grid>
                <Grid container xs={12} justifyContent="center" item>
                  { requested
                    ? (
                      <UpdateButton color="primary" variant="outlined" className={classes.FormatButtons} disabled={requested}>
                        { commandName === 'update' ? t('camera.settings.buttons.updating') : t('camera.settings.buttons.update') }
                      </UpdateButton>
                      )
                    : (
                      <UpdateButton
                        color="primary"
                        variant="outlined"
                        className={classes.FormatButtons}
                        onClick={() => { sendCommands(UPDATE_FIRMWARE, false) }}
                      >
                        { t('camera.settings.buttons.update') }
                      </UpdateButton>
                      ) }

                </Grid>
              </Grid>
            ) }
          </Grid>
        ) }
      </div>
    </div>
  )
}

export default CameraStatusTile
