import { useTheme } from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'

const CameraIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon
      data-testid="CameraIcon"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.64788 2.0791C2.77551 2.02646 2.91231 1.99957 3.05037 2C3.32896 2 3.59614 2.11067 3.79313 2.30766C3.99012 2.50466 4.10079 2.77184 4.10079 3.05043V15.6228H3.99413C3.99413 15.6228 1.99994 15.6228 1.99994 13.6287V3.05043C1.99952 2.91236 2.02639 2.77558 2.07903 2.64794C2.13167 2.52031 2.20902 2.40434 2.30665 2.30672C2.40428 2.20909 2.52024 2.13173 2.64788 2.0791Z"
        fill={theme.palette.text.primary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9873 6.15399L20.8264 7.29282C21.1756 7.43624 21.4739 7.68077 21.683 7.995C21.8921 8.30923 22.0025 8.67881 22 9.05625V15.9964C21.9999 16.3746 21.8866 16.7441 21.6747 17.0573C21.4627 17.3705 21.1617 17.613 20.8107 17.7535L17.9715 18.8955C17.7476 18.9844 17.5089 19.0304 17.268 19.0311H10.8673C10.6264 19.0307 10.3877 18.9846 10.1639 18.8955L7.32469 17.7535C6.9736 17.613 6.67266 17.3705 6.46068 17.0573C6.2487 16.7441 6.1354 16.3746 6.13539 15.9964V9.05625C6.13489 8.6759 6.249 8.30422 6.46283 7.98967C6.67666 7.67511 6.98029 7.43228 7.33415 7.29282L10.1733 6.15399C10.3972 6.06485 10.6359 6.01883 10.8768 6.01835H17.2838C17.5247 6.01913 17.7633 6.06514 17.9873 6.15399ZM18.5402 10.9523H15.4833V14.0092H18.5402V10.9523Z"
        fill={theme.palette.text.primary}
      />
    </SvgIcon>
  )
}

export default CameraIcon
