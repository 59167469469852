import { useHistory } from 'react-router-dom'
import makeStyles from '@material-ui/core/styles/makeStyles'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'

const useStyles = makeStyles(theme => ({
  button: {
    '&:hover': {
      background: theme.palette.background.tile,
    },
  },
  arrowBackIcon: {
    color: theme.palette.text.primary,
  },
}))

const BackButton = () => {
  const classes = useStyles()
  const history = useHistory()
  const camera = useSelectedCamera()

  if (!camera) {
    return null
  }

  const onClick = () => {
    history.push(`/camera/${camera.id}`)
  }

  return (
    <IconButton
      data-testid="back-button"
      color="secondary"
      className={classes.button}
      onClick={onClick}
    >
      <ArrowBackIcon className={classes.arrowBackIcon} />
    </IconButton>
  )
}

export default BackButton
