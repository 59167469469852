import { FETCH_USER, SET_USER } from 'user-module/user/core/userActionTypes'
import userState from 'user-module/user/core/userState'

const userReducers = (state = userState.defaultUser, action) => {
  switch (action.type) {
    case FETCH_USER:
      return userState.fetching()
    case SET_USER: {
      return userState.set(action.user)
    }
    default:
      return state
  }
}

export default userReducers
