import Divider from '@material-ui/core/Divider'
import { Box } from '@material-ui/core'

const PublicPageDivider = () => (
  <Box display="flex" flexDirection="column" mx={-10} my={3}>
    <Divider />
  </Box>
)

export default PublicPageDivider
