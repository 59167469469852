import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'

import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import { useTheme } from '@material-ui/core/styles'
import makeStyles from '@material-ui/core/styles/makeStyles'

import DetectionZoneCanvas from './DetectionZoneCanvas'
import DetectionZoneTopActionBar from 'detection-zone-module/ui/editor-top-bar/DetectionZoneTopActionBar'
import cameraApi from 'camera-module/camera/api/cameraApi'

import { getPhotos } from 'photo-module/photos/core/photosActions'
import messageActions from 'shared-module/message/messageActions'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import useCameraActions from 'camera-module/camera/core/useCameraActions'
import useDetectionZone from 'detection-zone-module/core/detectionZone.hooks'
import { evaluateLimitsAnchorList, formatAnchorListForSetting } from 'detection-zone-module/ui/helper/DetectionZoneHelper'
import useFilters from 'photo-module/filters/core/useFilters'
import useFiltersActions from 'photo-module/filters/core/useFiltersActions'
import webview from 'shared-module/webview/webview'
import webViewDevice from 'checkout-module/checkout/ui/shared/web-view-bridge/webViewDevice'

const PORTRAIT_PADDING_CANVAS = 18
const PORTRAIT_PADDING_TOP_BAR = 150
const PORTRAIT_PADDING_WEBVIEW = 150
const LANDSCAPE_PADDING_WEBVIEW = 120
const PORTRAIT_PADDING_IOS_WEBVIEW = 250

const useStyles = makeStyles(theme => ({
  mainGrid: ({ isWebview, screenDisplaySize, iosLandscapeOrientation }) => ({
    height: isWebview
      ? iosLandscapeOrientation ? screenDisplaySize.width * 0.7 : screenDisplaySize.height - PORTRAIT_PADDING_IOS_WEBVIEW
      : window.screen.height - PORTRAIT_PADDING_TOP_BAR,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    overflowY: 'hidden',
  }),
  mainGridAndroidLandscape: ({ screenDisplaySize }) => ({
    height: screenDisplaySize.height - LANDSCAPE_PADDING_WEBVIEW,
  }),
  mainGridAndroidPortrait: ({ screenDisplaySize }) => ({
    height: screenDisplaySize.height - PORTRAIT_PADDING_WEBVIEW,
  }),
  actionBar: {
    width: '100%',
  },
  canvasContainer: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    flexGrow: 1,
    background: theme.palette.background.default,
  },
  divider: {
    backgroundColor: theme.palette.secondary.main,
  },
}))

const DetectionZonePage = ({ isNewUi }) => {
  const theme = useTheme()
  const { id } = useParams()
  const history = useHistory()

  const filters = useFilters()
  const dispatch = useDispatch()
  const canvasContainerRef = useRef()
  const camera = useSelectedCamera()
  const cameraActions = useCameraActions()
  const filtersActions = useFiltersActions()
  const { photos } = useSelector(state => state.galleryPhoto)
  const { anchorList: anchorsInState, saveAnchorList, isDefaultZone, resetAnchorList, setIsEditing, setIsDefaultZone, zoneName } = useDetectionZone()

  const isWebview = webview.isWebview()
  const [scale, setScale] = useState(1)
  const [photoURL, setPhotoURL] = useState('')
  const [anchorList, setAnchorList] = useState([])
  const [photoIsSet, setPhotoIsSet] = useState(false)
  const [canvasContainerSize, setCanvasContainerSize] = useState({ width: 0, height: 0 })
  const [iosLandscapeOrientation, setIosLandscapeOrientation] = useState(window.innerWidth > window.innerHeight)
  const [screenDisplaySize, setScreenDisplaySize] = useState({ width: window.screen.width, height: window.screen.height })
  const [isLandscape, setIsLandscape] = useState(false)
  const [initialImageRect, setInitialImageRect] = useState({ width: 0, height: 0 })

  const classes = useStyles({ isWebview, screenDisplaySize, iosLandscapeOrientation })

  const classesByViewType = () => {
    if (isWebview && isLandscape && webViewDevice().isAndroidAppWebview) {
      return classes.mainGridAndroidLandscape
    } else if (isWebview && webViewDevice().isAndroidAppWebview) {
      return classes.mainGridAndroidPortrait
    }
    return classes.mainGrid
  }

  const gridContainerClasses = classesByViewType()

  const handleWindowResize = () => {
    if (!canvasContainerRef.current) return

    setScreenDisplaySize({ width: window.screen.width, height: window.screen.height })
    setIsLandscape(window.screen.width > window.screen.height)

    const rect = canvasContainerRef.current.getBoundingClientRect()
    const isSmallScreen = screenDisplaySize.width < theme.breakpoints.values.md

    if (isSmallScreen && (!isLandscape || !iosLandscapeOrientation)) {
      setCanvasContainerSize({ width: rect.width - PORTRAIT_PADDING_CANVAS * 2, height: rect.height })
    } else {
      setCanvasContainerSize({ width: rect.width, height: rect.height })
    }
  }

  useLayoutEffect(() => {
    handleWindowResize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasContainerRef.current])

  useEffect(() => {
    anchorsInState && anchorsInState.length !== 0 && anchorList.length === 0 && setAnchorList(anchorsInState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorsInState])

  useEffect(() => {
    setIsEditing(isDefaultZone)
    if (photos?.length > 0 && photos.map(photo => photo.cameraId === id).includes(true)) {
      setPhotoURL(photos.find(photo => photo.cameraId === id)?.urls?.large)
      setPhotoIsSet(true)
    } else if (!photoIsSet) {
      filtersActions.setCamerasFilter([id])
      dispatch(getPhotos(filters))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camera, photos, anchorsInState])

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    window.screen.orientation?.addEventListener('change', (e) => {
      setIosLandscapeOrientation(e.currentTarget.type.startsWith('landscape'))
      handleWindowResize()
    })

    window.addEventListener('orientationchange', e => {
      setIosLandscapeOrientation(e.target.innerWidth > e.target.innerHeight)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleWindowResize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iosLandscapeOrientation])

  const returnToSettings = () => {
    if (isWebview) {
      webview.postMessage({ action: 'zoneSaved' })
    }
    setIsEditing(false)
    history.push(`/camera/${id}/settings`)
  }

  const saveDetectionZone = async (exitDetectionZoneEditor = true) => {
    const translatedList = evaluateLimitsAnchorList(formatAnchorListForSetting(anchorList, scale), initialImageRect)
    const newSettings = camera.config
    newSettings.detectionZone.anchors = translatedList
    newSettings.detectionZone.isDefaultZone = false
    newSettings.detectionZone.name = zoneName

    await cameraApi
      .saveSettings(camera.id, newSettings)
      .then(() => {
        saveAnchorList(anchorList)
        setIsDefaultZone(false)
        cameraActions.updateSettings(camera, newSettings)
        exitDetectionZoneEditor && returnToSettings()
        dispatch(messageActions.showSuccess('detection-zone.request.message'))
      })
      .catch(() => dispatch(messageActions.showError('errors.catch_all')))
  }

  const resetDetectionZone = async () => {
    const newSettings = camera.config
    newSettings.detectionZone = { anchors: null, name: zoneName }
    delete newSettings.detectionZoneNotificationEnabled

    await cameraApi
      .saveSettings(camera.id, newSettings)
      .then(() => {
        resetAnchorList()
        setIsDefaultZone(true)
        cameraActions.updateSettings(camera, newSettings)
        cameraActions.update(id)
        setAnchorList([])
        dispatch(messageActions.showWarning('camera.settings.messages.delayedSave'))
      })
      .catch(() => dispatch(messageActions.showError('errors.catch_all')))
  }

  return (
    <Grid container className={gridContainerClasses}>
      <Grid item xs={12} className={classes.actionBar}>
        <DetectionZoneTopActionBar
          anchorList={anchorList}
          saveDetectionZone={saveDetectionZone}
          resetDetectionZone={resetDetectionZone}
          isNewUi={isNewUi}
          isLandscape={isWebview && webViewDevice().isiOSAppWebview ? iosLandscapeOrientation : isLandscape}
        />

        { !(webview.isWebview() || (window.innerWidth < theme.breakpoints.values.md && isLandscape)) && <Divider className={classes.divider} /> }
      </Grid>

      <Grid item xs={12} className={classes.canvasContainer} ref={canvasContainerRef}>
        <DetectionZoneCanvas
          photoURL={photoURL}
          size={canvasContainerSize}
          anchorList={anchorList}
          setAnchorList={setAnchorList}
          setScale={setScale}
          saveDetectionZone={saveDetectionZone}
          isLandscape={isWebview && webViewDevice().isiOSAppWebview ? iosLandscapeOrientation : isLandscape}
          iosLandscapeOrientation={iosLandscapeOrientation}
          setInitialImageRect={setInitialImageRect}
        />
      </Grid>
    </Grid>
  )
}

export default DetectionZonePage
