import { useSelector } from 'react-redux'

import useFilters from 'photo-module/filters/core/useFilters'
import useFiltersActions from 'photo-module/filters/core/useFiltersActions'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'

const usePhotos = () => {
  const filters = useFilters()
  const camera = useSelectedCamera()
  const filtersActions = useFiltersActions()
  const galleryPhotoState = useSelector(state => state.galleryPhoto)

  if (filters.cameras.length === 0) {
    filtersActions.setCamerasFilter([camera?.id])
  }

  return galleryPhotoState
}

export default usePhotos
