import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'

import FullHdVideoIcon from 'assets/icons/video/FullHdVideoIcon'

const useStyles = makeStyles(theme => ({
  typography: {
    color: theme.palette.basic?.lightGrey + ' !important',
    marginRight: '0.75rem !important',
  },
  icon: {
    marginTop: '0.25rem',
    marginRight: '0.5rem',
  },
}))

const HdPhotoAvailableButton = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box component={Grid} container item mr={1.5} display="flex" justifyContent="space-between" alignItems="center">
      <Grid item className={classes.icon}>
        <FullHdVideoIcon />
      </Grid>

      <Grid item>
        <Typography variant="button" className={classes.typography}>{ t('photo_viewer.hd_photo.available') }</Typography>
      </Grid>
    </Box>
  )
}

export default HdPhotoAvailableButton
