import Box from '@material-ui/core/Box'
import VideoClipPlayer from 'video-module/video-clip/ui/video-clip-player/VideoClipPlayer'
import videoState from 'video-module/video-clip/core/videoState'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  box: {
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.only('sm')]: {
      zIndex: 0,
    },
  },
}))

const VideoClipPlayerContainer = ({ photo }) => {
  const classes = useStyles()
  const maxWidth = videoState.isHd(photo) || videoState.isAStream(photo) ? 1280 : 640

  return (
    <Box margin="auto" maxWidth={maxWidth} className={classes.box}>
      <VideoClipPlayer photo={photo} />
    </Box>
  )
}

export default VideoClipPlayerContainer
