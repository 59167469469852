import SvgIcon from '@material-ui/core/SvgIcon'

const FavoriteShadowIcon = (props) => {
  return (
    <SvgIcon {...props} data-testid="FavoriteShadowIcon" viewBox="0 1 16 17">
      <g filter="url(#filter0_ddd)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.9588 14.6575C14.023 15.0487 13.7714 15.4201 13.3968 15.4871C13.2477 15.5138 13.0944 15.4884 12.9605 15.415L9.68771 13.6189L6.41759 15.4171C6.08132 15.602 5.66517 15.4672 5.48811 15.1161C5.41754 14.9761 5.39316 14.8158 5.41874 14.6599L6.04375 10.8508L3.39478 8.14862C3.12292 7.8713 3.11782 7.41635 3.38338 7.13245C3.48896 7.01959 3.62718 6.94615 3.7767 6.92346L7.43377 6.36868L9.07022 2.90109C9.23822 2.54511 9.65075 2.39875 9.99164 2.57418C10.1276 2.64417 10.2377 2.75909 10.3047 2.90109L11.9411 6.36868L15.5982 6.92346C15.9743 6.98052 16.2349 7.34515 16.1803 7.73789C16.1585 7.89403 16.0882 8.03837 15.9801 8.14863L13.3338 10.8481L13.9588 14.6575Z"
        />
      </g>
      <defs>
        <filter id="filter0_ddd" x="-1.3125" y="-1" width="22" height="22" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
          <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="0.75" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="effect2_dropShadow" result="effect3_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow" result="shape" />
        </filter>
      </defs>
    </SvgIcon>
  )
}

export default FavoriteShadowIcon
