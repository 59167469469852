import SvgIcon from '@material-ui/core/SvgIcon'

const FaBell = ({ style }) => (
  <SvgIcon data-testid="fal-bell" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path d="M20.5258 11.2633C20.5258 7.67406 18.0944 4.77953 14.7367 3.96906V3.15859C14.7367 2.23234 13.9262 1.42188 13 1.42188C12.0737 1.42188 11.2633 2.23234 11.2633 3.15859V3.96906C7.9056 4.77953 5.47419 7.67406 5.47419 11.2633V17.6313L3.15857 19.9469V21.1047H22.8414V19.9469L20.5258 17.6313V11.2633ZM13 24.5781C13.1158 24.5781 13.3473 24.5781 13.4631 24.5781C14.2736 24.4623 14.8525 23.8834 15.084 23.1887C15.1998 22.9571 15.3156 22.6098 15.3156 22.2625H10.6844C10.6844 23.5361 11.7264 24.5781 13 24.5781Z" fill="white" />
  </SvgIcon>

)

export default FaBell
