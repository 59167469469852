import { useTheme } from '@mui/material'
import SvgIcon from '@material-ui/core/SvgIcon'

const TrashActiveIcon = ({ props }) => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="TrashActiveIcon" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.6427 6.53223L4.37793 6.56217L5.39388 17.7764C5.42829 18.1573 5.60433 18.5114 5.8872 18.7688C6.17007 19.0263 6.5392 19.1682 6.92165 19.1667H14.0564C14.4388 19.1682 14.808 19.0263 15.0908 18.7688C15.3737 18.5114 15.5497 18.1573 15.5841 17.7764L16.6427 6.53223ZM9.15221 16.4931H8.00637V9.61805H9.15221V16.4931ZM12.9716 16.4931H11.8258V9.61805H12.9716V16.4931Z"
        fill={theme.palette.basic?.lightGrey}
      />
      <path
        d="M18.8337 3.8886H14.5108C14.4601 3.8886 14.4116 3.86848 14.3758 3.83266C14.3399 3.79685 14.3198 3.74827 14.3198 3.69762V1.63301C14.3198 1.19118 13.9616 0.833009 13.5198 0.833011L7.48094 0.833039C7.03911 0.833041 6.68094 1.19121 6.68094 1.63304V3.69762C6.68094 3.74827 6.66082 3.79685 6.62501 3.83266C6.58919 3.86848 6.54062 3.8886 6.48997 3.8886H2.16699V5.41638H18.8337V3.8886ZM8.20872 3.69762V2.66082C8.20872 2.49513 8.34303 2.36082 8.50872 2.36082H12.492C12.6577 2.36082 12.792 2.49513 12.792 2.66082V3.69762C12.792 3.74827 12.7719 3.79685 12.7361 3.83266C12.7003 3.86848 12.6517 3.8886 12.6011 3.8886H8.39969C8.34904 3.8886 8.30046 3.86848 8.26465 3.83266C8.22884 3.79685 8.20872 3.74827 8.20872 3.69762Z"
        fill={theme.palette.basic?.lightGrey}
      />

    </SvgIcon>
  )
}

export default TrashActiveIcon
