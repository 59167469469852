import { Button, SubmitButton } from 'form-module/ui'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import CameraSettingsSection from './CameraSettingsSection'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'
import Spinner from 'shared-module/components/Spinner.js'
import { cameraSettingsAlertContents } from './helpers/cameraSettings.helper'
import cameraSettingsSchema from './cameraSettings.schema'
import getInputs from './helpers/settingInputFactory'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useCameraSettings } from 'camera-module/camera/core/cameraSettings.hooks'
import { useDidMountEffect } from '../../core/cameraSettings.hooks'
import { useFormikContext } from 'formik'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'
import VoskerSecondaryButton from 'vosker/src/components/buttons/VoskerSecondaryButton'
import Grid from '@material-ui/core/Grid'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material'
import { VoskerInputStyles } from 'vosker/src/components/buttons/VoskerInputField'

const useStyles = makeStyles((theme) => ({
  input: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.background?.paper,
    },
  },
  saveButtonContainer: {
    justifyContent: 'end',
    marginTop: 16,
    marginBottom: 16,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      margin: '0 0 24px 0',
    },
  },
  formButtons: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      marginTop: 8,
    },
  },
}))

const CameraSettingsFormContent = ({ setValidationSchema, onReset, isNewUi, dialogActions }) => {
  const theme = useTheme()
  const classes = useStyles()
  const { t } = useTranslation()
  const importedClasses = VoskerInputStyles()
  const camera = useSelectedCamera()
  const { values, setValues } = useFormikContext()
  const settings = useCameraSettings(camera, values)
  const [alertContent, setAlertContent] = useState()
  const [powerConsumption, setPowerConsumption] = useState(values)

  const flexFeatureDisabled = appConfig.flex.switch === switches.off
  const detectionZoneEnabled = appConfig.detectionZone.switch === switches.on
  const restrictInstantModeTo12V = appConfig.restrictInstantMode.switch === switches.on
  const isMobileResolution = useMediaQuery(theme.breakpoints.only('xs'))
  const getSpacingByResolution = isMobileResolution ? 0 : 2
  const close = () => {
    setAlertContent(undefined)
    setPowerConsumption(values)
  }

  const inputs = useMemo(() => getInputs({ settings, t, isNewUi, importedClasses, classes, dialogActions }), [settings, t, importedClasses, dialogActions])
  const ResetButton = isNewUi ? VoskerSecondaryButton : Button

  useDidMountEffect(() => {
    if (values.batteryType === '12V') setAlertContent(cameraSettingsAlertContents.batteryType)
  }, [values.batteryType])

  useDidMountEffect(() => {
    if (values.delay === 'instant') setAlertContent(cameraSettingsAlertContents.batteryType)
  }, [values.delay])

  useDidMountEffect(() => {
    if (values.transmitFreq === 0) setAlertContent(cameraSettingsAlertContents.batteryType)
  }, [values.transmitFreq])

  useDidMountEffect(() => {
    if (powerConsumption.delaySecond !== powerConsumption.transmitFreq) {
      if (values.delaySecond === values.transmitFreq) {
        setAlertContent(cameraSettingsAlertContents.batteryType)
      }
    }
  }, [values])

  useDidMountEffect(() => {
    if (!flexFeatureDisabled && camera.status.model === 'FLEX' && values.captureMode === 'video') {
      setAlertContent(cameraSettingsAlertContents.captureModeVideo)
    }
  }, [values.captureMode])

  useDidMountEffect(() => {
    if (camera.status.model === 'FLEX-S' && values.operationMode === 'instant') {
      setAlertContent(cameraSettingsAlertContents.restrictedInstantWith12V)
    }
    if (!flexFeatureDisabled && camera.status.model === 'FLEX' && values.operationMode === 'instant') {
      if (restrictInstantModeTo12V) {
        if (camera.status.batteryType !== '12V') {
          setAlertContent(cameraSettingsAlertContents.restrictedInstantWithout12V)
          values.operationMode = 'standard'
        } else {
          setAlertContent(cameraSettingsAlertContents.restrictedInstantWith12V)
        }
      } else {
        setAlertContent(cameraSettingsAlertContents.batteryType)
      }
    }
  }, [values.operationMode])

  useEffect(() => {
    if (settings) {
      setValidationSchema(cameraSettingsSchema(settings))
    }

    setPowerConsumption(values)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings])

  useEffect(() => {
    setValues(camera.config)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camera.config])

  useEffect(() => {
    if (detectionZoneEnabled && camera.config.detectionZone) {
      setValues({ ...values, detectionZoneNotificationEnabled: camera.config.detectionZone.isEnabled })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camera])

  if (!settings || !camera) {
    return <Spinner />
  }

  return (
    <Box display="flex" justifyContent="center">
      <Box display="flex" justifyContent="center" width={1} maxWidth={800}>
        <Box width={1}>
          { Object.keys(settings).map((sectionKey) => (
            <CameraSettingsSection key={sectionKey} sectionKey={sectionKey} isNewUi={isNewUi}>
              { settings[sectionKey].map((groupSetting) => {
                if (!inputs[groupSetting.name]) {
                  return null
                }
                return (
                  <Box key={groupSetting.name} py={1.5}>
                    { inputs[groupSetting.name] }
                  </Box>
                )
              }) }
            </CameraSettingsSection>
          )) }
          <Box display="flex" className={classes.saveButtonContainer}>
            <Grid spacing={getSpacingByResolution} container justifyContent="flex-end" xs={12}>{ flexFeatureDisabled && (
              <Grid item sm="auto" xs={12}>
                <ResetButton color="primary" variant="outlined" onClick={onReset} className={classes.formButtons}>
                  { t('camera.settings.buttons.resetSettings') }
                </ResetButton>
              </Grid>
            ) }
              <Grid item sm="auto" xs={12}>
                <SubmitButton color="primary" className={classes.formButtons} isNewUi={isNewUi}>
                  { t('camera.settings.buttons.save') }
                </SubmitButton>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <ConfirmationDialog
        isNewUi={isNewUi}
        open={!!alertContent}
        title={alertContent?.title}
        text={alertContent?.text}
        onConfirm={close}
        confirmText={alertContent?.confirmText}
      />
    </Box>
  )
}

export default CameraSettingsFormContent
