import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const Memory = ({ props }) => {
  const theme = useTheme()
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" {...props} data-testid="Memory">
      <path
        d="M24.332 2H13.2852L5 10.2852V26.8555C5 27.6211 5.26432 28.2682 5.79297 28.7969C6.33984 29.3438 6.99609 29.6172 7.76172 29.6172H24.332C25.0977 29.6172 25.7448 29.3438 26.2734 28.7969C26.8203 28.2682 27.0938 27.6211 27.0938 26.8555V4.76172C27.0938 3.99609 26.8203 3.34896 26.2734 2.82031C25.7448 2.27344 25.0977 2 24.332 2ZM16.0469 10.2852H13.2852V4.76172H16.0469V10.2852ZM20.2031 10.2852H17.4414V4.76172H20.2031V10.2852ZM24.332 10.2852H21.5703V4.76172H24.332V10.2852Z"
        fill={theme.palette.text.primary}
      />
    </SvgIcon>
  )
}

export default Memory
