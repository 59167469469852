import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const DetectionZoneIcon = () => {
  const theme = useTheme()

  return (
    <SvgIcon style={{ width: 32, height: 32, marginTop: 6 }} data-testid="DetectionZoneIcon">
      <path
        d="M15.2153 12.429V5.50644C16.1861 5.25493 16.9061 4.38097 16.9061 3.3316C16.9061 2.08637 15.8969 1.07715 14.6517 1.07715C13.6023 1.07715 12.7284 1.79752 12.4769 2.76799H5.55429C5.30278 1.79752 4.42883 1.07715 3.37945 1.07715C2.13422 1.07715 1.125 2.08637 1.125 3.3316C1.125 4.38097 1.84501 5.25493 2.81584 5.50644V12.4294C1.84501 12.6805 1.125 13.5545 1.125 14.6038C1.125 15.8491 2.13422 16.8583 3.37945 16.8583C4.42883 16.8583 5.30278 16.1383 5.55429 15.1675H12.4772C12.7287 16.1383 13.6027 16.8583 14.6521 16.8583C15.8973 16.8583 16.9065 15.8491 16.9065 14.6038C16.9061 13.5545 16.1861 12.6805 15.2153 12.429ZM12.4769 14.0402H5.55429C5.34998 13.2508 4.73282 12.6337 3.94306 12.429V5.50644C4.73247 5.30213 5.34963 4.68497 5.55429 3.89521H12.4772C12.6815 4.68462 13.2987 5.30178 14.0884 5.50644V12.4294C13.2987 12.6337 12.6815 13.2508 12.4769 14.0402Z"
        fill={theme.palette.text.primary}
      />
    </SvgIcon>
  )
}

export default DetectionZoneIcon
