import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const NotificationArrow = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon width="9" height="17" viewBox="0 0 9 17" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.471363 16.0609C0.105247 15.6947 0.105247 15.1012 0.471363 14.735L6.68345 8.52295L0.471364 2.31086C0.105248 1.94475 0.105248 1.35115 0.471364 0.985036C0.837481 0.61892 1.43107 0.61892 1.79719 0.985036L8.67219 7.86004C9.03831 8.22615 9.03831 8.81975 8.67219 9.18586L1.79719 16.0609C1.43107 16.427 0.83748 16.427 0.471363 16.0609Z"
        fill={theme.palette.text.primary}
      />
    </SvgIcon>
  )
}

export default NotificationArrow
