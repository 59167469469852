import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@mui/material'

const ErrorMessageIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="ErrorMessageIcon" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7453 2.56161C12.6042 2.27929 12.3157 2.10095 12 2.10095C11.6843 2.10095 11.3958 2.27929 11.2547 2.56161L2.08798 20.895C1.95882 21.1533 1.97262 21.46 2.12446 21.7057C2.2763 21.9514 2.54452 22.101 2.83333 22.101H21.1667C21.4555 22.101 21.7237 21.9514 21.8756 21.7057C22.0274 21.46 22.0412 21.1533 21.912 20.895L12.7453 2.56161ZM12.8333 9.60095H11.1667V15.4343H12.8333V9.60095ZM11.1667 17.101H12.8333V18.7676H11.1667V17.101Z"
        fill={theme.palette.status?.error}
      />

    </SvgIcon>
  )
}

export default ErrorMessageIcon
