import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import useCamera from 'camera-module/camera/core/useCamera'
import Spinner from 'shared-module/components/Spinner'
import useSingleProductParam from 'checkout-module/order/core/useSingleProductParam'
import VoskerPrivatePageLayout from 'vosker/src/shared-module/ui/private-page/VoskerPrivatePageLayout'
import StripeProvider from 'vosker/src/user-module/payment-information/ui/payment-information/form/StripeProvider'
import OrderPaymentSection from 'vosker/src/order-module/order/ui/place-order/OrderPaymentSection'
import useLanguage from 'shared-module/components/language/useLanguage'
import orderApi from 'vosker/src/order-module/order/api/orderApi'
import messageActions from 'shared-module/message/messageActions'
import OrderSection from 'vosker/src/order-module/order/ui/order-lines/OrderSection'
import OrderLinesSection from 'vosker/src/order-module/order/ui/order-lines/OrderLinesSection'
import ProductDescriptionSection from 'vosker/src/order-module/order/ui/ProductDescriptionSection'
import PromoCodeSection from 'vosker/src/order-module/order/ui/promo-code/PromoCodeSection'
import VoskerBillingAddress from 'vosker/src/order-module/order/ui/billing-address/VoskerBillingAddress'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material'
import CAppBar from 'storybook-component-module/src/components/containers/CAppBar'
import CheckoutCardIcon from 'vosker/src/assets/icons/filters/CheckoutCardIcon'
import webview from 'shared-module/webview/webview'

const useStyles = makeStyles(theme => ({
  appbar: {
    background: theme.palette.background.default,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
  item: {
    width: '100%',
  },
}))

const VoskerOrderPage = () => {
  const theme = useTheme()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const language = useLanguage()

  const productParam = useSingleProductParam()
  const [order, setOrder] = useState(null)
  const [product, setProduct] = useState(productParam)
  const [promocode, setPromocode] = useState(undefined)
  const [isPurchaseDisabled, setIsPurchaseDisabled] = useState(false)
  const camera = useCamera(product.cameraId)

  const isMobileResolution = useMediaQuery(theme.breakpoints.only('xs'))
  const isTabletResolution = useMediaQuery(theme.breakpoints.only('sm'))

  const getSpacingByResolution = isMobileResolution ? 2 : isTabletResolution ? 0 : 6

  const options = {
    fonts: [
      {
        family: 'exo2-regular',
        src: 'url(https://fonts.gstatic.com/s/exo2/v20/7cH1v4okm5zmbvwkAx_sfcEuiD8jvvKsOdC_jJ7bpAhL.woff2) format("woff2")',
      },
    ],
  }

  const isWebview = webview.isWebview()

  useEffect(() => {
    if (promocode) {
      createPreviewWithPromocode(promocode)
    } else {
      createOrderPreview()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, product])

  const resetPreview = () => {
    setPromocode(undefined)
    createOrderPreview()
  }

  const createOrderPreview = () => {
    orderApi
      .createPreview(language, [product])
      .then((response) => setOrder(response))
      .catch(() => dispatch(messageActions.showError()))
  }

  const createPreviewWithPromocode = (promocode) => {
    setPromocode(promocode)
    orderApi
      .createPreview(language, [product], promocode)
      .then((response) => setOrder(response))
      .catch(() => dispatch(messageActions.showError()))
  }

  return camera && order
    ? (
      <VoskerPrivatePageLayout maxWidth="lg" isWebview={isWebview}>
        { !isWebview && <CAppBar Icon={CheckoutCardIcon} className={classes.appbar} title={t('app:order_page.title')} variant="h1" /> }
        <Grid container wrap="nowrap" className={classes.container} spacing={getSpacingByResolution}>
          <Grid item xs={12} sm={7} md={5} className={classes.item}>
            <OrderSection>
              <ProductDescriptionSection camera={camera} product={product} setProduct={setProduct} />
              <OrderLinesSection order={order} />
            </OrderSection>
          </Grid>

          <Grid item xs={12} sm={7} md={5} className={classes.item}>
            <OrderSection>
              <VoskerBillingAddress setIsPurchaseDisabled={setIsPurchaseDisabled} createOrderPreview={createOrderPreview} />
            </OrderSection>

            <PromoCodeSection resetPreview={resetPreview} createPreviewWithPromocode={createPreviewWithPromocode} product={product} />

            <StripeProvider options={options}>
              <OrderPaymentSection product={product} promocode={promocode} isPurchaseDisabled={isPurchaseDisabled} />
            </StripeProvider>
          </Grid>
        </Grid>
      </VoskerPrivatePageLayout>
      )
    : <Spinner />
}

export default VoskerOrderPage
