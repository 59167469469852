import Typography from '@material-ui/core/Typography'
import { Box } from '@material-ui/core'

const Title = ({ externalPageVariant, h = 'h2', mb = 3, noWrap = false, children, ...props }) => (
  <Box mb={mb} {...props}>
    <Typography variant={externalPageVariant || h} noWrap={noWrap}>{ children }</Typography>
  </Box>
)

export default Title
