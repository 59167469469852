import { useEffect, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import useLanguage from 'shared-module/components/language/useLanguage'
import userPaymentInformationApi from 'vosker/src/user-module/payment-information/api/userPaymentInformationApi'

const StripeProvider = ({ children, options }) => {
  const [stripe, setStripe] = useState(null)
  const language = useLanguage()

  useEffect(() => {
    userPaymentInformationApi.getStripePublicKey()
      .then((publicKey) => setStripe(loadStripe(publicKey, { locale: language })))
    // stripe does not support reloading when it is already loaded
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return stripe
    ? (
      <Elements stripe={stripe} options={options}>
        { children }
      </Elements>
      )
    : null
}

export default StripeProvider
