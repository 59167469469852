import { useEffect } from 'react'
import Hls from 'hls.js'

const FASTFORWARD_THRESHOLD_SEC = 7

const HlsPlayer = ({ volume, playerRef, url, onStateChange, ...props }) => {
  useEffect(() => {
    let hls = null

    const initPlayer = () => {
      if (hls != null) {
        hls.destroy()
      }

      const newHls = new Hls({ enableWorker: false })

      newHls.attachMedia(playerRef.current)

      newHls.on(Hls.Events.MEDIA_ATTACHED, () => {
        newHls.loadSource(url)

        newHls.on(Hls.Events.BUFFER_APPENDED, () => {
          fastforward()
        })

        newHls.on(Hls.Events.MANIFEST_PARSED, () => {
          playerRef.current.play()
        })
      })

      newHls.on(Hls.Events.ERROR, (event, data) => {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              newHls.startLoad()
              break
            case Hls.ErrorTypes.MEDIA_ERROR:
              newHls.recoverMediaError()
              break
            default:
              initPlayer()
              break
          }
        }
      })

      hls = newHls
    }

    initPlayer()
    return () => {
      hls.destroy()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const player = playerRef.current
    player.volume = volume
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [volume])

  const fastforward = () => {
    if (!playerRef?.current) {
      return
    }
    const player = playerRef.current
    const currentTime = player.currentTime
    const duration = player.duration
    if (currentTime === 0 || duration === Infinity) {
      return
    }
    const lag = duration - currentTime

    if (lag >= FASTFORWARD_THRESHOLD_SEC) {
      playerRef.current.currentTime = duration
    }
  }

  const onVideoPlayingStateChange = playing => {
    onStateChange && onStateChange(playing)
  }

  return (
    <video
      data-testid="hls-player"
      ref={playerRef}
      width="100%"
      height="auto"
      {...props}
      onPlaying={() => { onVideoPlayingStateChange(true) }}
      onWaiting={() => { onVideoPlayingStateChange(false) }}
      style={{ outline: 'none', borderRadius: '0.375rem 0.375rem 0 0' }}
    />
  )
}

export default HlsPlayer
