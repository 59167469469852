import { useTranslation } from 'react-i18next'

import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  current: {
    margin: '1.5rem 0',
    lineHeight: '1.5rem',
    textTransform: 'uppercase',
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    fontSize: theme.typography.label?.fontSize,
    fontFamily: theme.typography.label?.fontFamily,
    [theme.breakpoints.down('xs')]: {
      margin: '1rem 0',
      lineHeight: '1.313rem',
      fontSize: theme.typography.caption1?.fontSize,
      fontFamily: theme.typography.caption1?.fontFamily,
    },
  },
}))

const CurrentItem = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Typography className={classes.current} data-testid="current-plan">
      { t('app:plan.item.current') }
    </Typography>
  )
}

export default CurrentItem
