import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const CheckmarkIcon = ({ className, props }) => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="CheckmarkIcon" className={className} style={{ ...props }} viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2817 3.86173C17.8413 4.26592 17.9674 5.04728 17.5632 5.60694L9.43818 16.8569C9.23351 17.1403 8.91953 17.3251 8.57241 17.3663C8.22529 17.4076 7.87675 17.3016 7.61134 17.0741L3.23634 13.3241C2.71218 12.8749 2.65148 12.0857 3.10076 11.5616C3.55003 11.0374 4.33916 10.9767 4.86332 11.426L8.20687 14.2919L15.5365 4.14321C15.9407 3.58356 16.722 3.45753 17.2817 3.86173Z"
        fill={theme.palette.status?.info}
      />
    </SvgIcon>
  )
}

export default CheckmarkIcon
