import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { alpha } from '@material-ui/core/styles/colorManipulator'

import videoState from 'video-module/video-clip/core/videoState'
import formatStreamingTime from 'video-module/live-streaming/ui/streamingTime/streamingTimeFormatter'

const useStyles = makeStyles(theme => ({
  durationWrapper: {
    position: 'absolute',
    bottom: 8,
    right: 8,
    padding: '0.125rem 0.5rem',
    borderRadius: '0.2rem',
    background: alpha(theme.palette.background?.shadow, 0.7),
  },
  duration: {
    fontWeight: 'bold',
    color: theme.palette.basic?.white,
  },
}))

const SavedStreamStatusOverlay = ({ photo }) => {
  const classes = useStyles()
  const isAStream = videoState.isAStream(photo)

  return isAStream
    ? (
      <Grid item className={classes.durationWrapper}>
        <Typography variant="caption" className={classes.duration}>{ formatStreamingTime(photo.streamVideoDuration) }</Typography>
      </Grid>
      )
    : null
}

export default SavedStreamStatusOverlay
