import { deleteFavoritePhotos, deleteRemotePhotos, resetPhotos, setFavoritesPhotos } from 'photo-module/photos/core/photosActions'
import { useDispatch } from 'react-redux'
import { useState } from 'react'

const usePhotosActionsOld = (cameraId) => {
  const dispatch = useDispatch()

  const [isLoading, setLoading] = useState(false)

  const deletePhotos = (ids) => dispatch(deleteRemotePhotos(cameraId, ids))

  const setFavorite = (ids) => {
    setLoading(true)
    dispatch(setFavoritesPhotos(cameraId, ids))
    setLoading(false)
  }
  const deleteFavorite = (ids) => {
    setLoading(true)
    dispatch(deleteFavoritePhotos(cameraId, ids)).then(() => {
      dispatch(resetPhotos())
    })
    setLoading(false)
  }

  return { isLoading, deletePhotos, setFavorite, deleteFavorite }
}

export default usePhotosActionsOld
