import { useEffect, useRef } from 'react'
import videojs from 'video.js'
import 'video.js/dist/video-js.min.css'
import videoState from 'video-module/video-clip/core/videoState'
import './VideoClipPlayer.css'

const VideoClipPlayer = ({ photo }) => {
  const playerRef = useRef(null)
  useEffect(() => {
    const player = videojs(playerRef.current, { autoplay: false, muted: true, controls: true })

    player.on('ended', () => {
      player.load()
    })
  }, [])

  useEffect(() => {
    const player = videojs(playerRef.current)
    player.poster(photo.urls.large)
    player.src(videoState.getVideoUrl(photo))

    player.one('durationchange', () => {
      const newDuration = Math.round(player.duration())
      player.duration = () => newDuration
      player.currentTime(0)
    })

    return () => {
      player.pause()
      player.reset()
    }
  }, [photo])

  return (
    <div data-testid="videoplayer" data-vjs-player>
      <video
        ref={playerRef}
        className="video-js vjs-16-9 vjs-big-play-centered"
        style={{ outline: 'none' }}
      />
    </div>
  )
}

export default VideoClipPlayer
