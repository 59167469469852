import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const CreditCardLast4Digits = ({ last4 }) => (
  <Typography component="div">
    <Box fontWeight="fontWeightBold">
      { `**** ${last4}` }
    </Box>
  </Typography>
)

export default CreditCardLast4Digits
