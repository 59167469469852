import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const ExpansionIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon {...props} data-testid="ExpansionIcon">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.50087 6.49918C5.77653 6.77485 6.22347 6.77485 6.49913 6.49918L11.7933 1.20507C12.0689 0.929403 12.0689 0.482463 11.7933 0.206799C11.5176 -0.0688658 11.0706 -0.0688658 10.795 0.206799L6 5.00178L1.20502 0.206799C0.929352 -0.0688658 0.482412 -0.0688658 0.206748 0.206799C-0.0689161 0.482463 -0.0689161 0.929403 0.206748 1.20507L5.50087 6.49918Z"
        fill={props.color || theme.palette.secondary.main}
      />
    </SvgIcon>
  )
}

export default ExpansionIcon
