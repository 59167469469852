import { useTheme } from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'

const VoskerEventIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="VoskerEventIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2975_8975)">
        <path
          d="M6.26087 4.5003L6.26087 19.2695C5.70737 19.2695 5.17655 19.0519 4.78517 18.6644C4.39379 18.277 4.17391 17.7515 4.17391 17.2035L4.17392 6.56631C4.17392 6.01837 4.39379 5.49287 4.78517 5.10542C5.17655 4.71797 5.70737 4.5003 6.26087 4.5003Z"
          fill={theme.palette.text.primary}
        />
        <path
          d="M2.08696 4.5003L2.08696 19.2695C1.53346 19.2695 1.00264 19.0519 0.611256 18.6644C0.219875 18.277 1.32714e-07 17.7515 1.80617e-07 17.2035L1.11055e-06 6.56631C1.15845e-06 6.01837 0.219877 5.49287 0.611257 5.10542C1.00264 4.71797 1.53346 4.5003 2.08696 4.5003Z"
          fill={theme.palette.text.primary}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.1538 19.2695C23.1734 19.2695 24 18.4429 24 17.4233L24 6.3464C24 5.3268 23.1734 4.50025 22.1538 4.50024L10.1538 4.50024C9.13424 4.50024 8.30769 5.32679 8.30769 6.3464L8.30769 17.4233C8.30769 18.4429 9.13424 19.2695 10.1538 19.2695L22.1538 19.2695ZM10.9295 14.1862L16.0064 14.1862L21.0833 14.1862L17.3889 8.71397L16.2173 10.2964L15.0457 11.8788L13.8741 10.2964L10.9295 14.1862Z"
          fill={theme.palette.text.primary}
        />
      </g>
      <defs>
        <clipPath id="clip0_2975_8975">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>

    </SvgIcon>
  )
}

export default VoskerEventIcon
