import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import LanguageIcon from '@material-ui/icons/Language'
import useUser from 'user-module/user/core/useUser'
import useUserActions from 'user-module/user/core/useUserActions'
import { useNotificationsActions } from 'notifications-module/core/notifications.hooks'
import LinkMenuItem from 'shared-module/navigation/menu/items/LinkMenuItem'
import { HashLink } from 'react-router-hash-link'

const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
    marginBottom: 10,
  },
  item: {
    paddingLeft: theme.spacing(4),
    '&:hover': {
      background: theme.palette.background.paper,
    },
  },
  languageButton: {
    background: 'transparent',
    border: 'none',
    color: theme.palette.text.primary,
    cursor: 'pointer',
    fontSize: theme.typography.body1.fontSize,
    fontFamily: theme.typography.body1.fontFamily,
    paddingLeft: 0,
    '&::after': {
      content: '"|"',
      display: 'inline-block',
      marginLeft: 14,
      marginRight: 10,
      fontWeight: 'normal',
      color: theme.palette.text.primary,
      cursor: 'default',
    },
    '&:last-child::after': {
      display: 'none',
    },
  },
  hashLink: {
    textDecoration: 'unset',
    color: 'inherit',
  },
  active: {
    textDecoration: 'underline',
  },
}))

const LanguageButton = ({ closeMenu, code, children }) => {
  const classes = useStyles()
  const { i18n } = useTranslation()

  const user = useUser()
  const actions = useUserActions()
  const notificationActions = useNotificationsActions()

  const changeLanguage = () => {
    i18n.changeLanguage(code).then(() => {
      const updatedUser = user
      updatedUser.language = i18n.language
      actions.update(updatedUser).then(() => notificationActions.getAllNotifications())
    })

    closeMenu && closeMenu()
  }

  return (
    <button
      onClick={() => changeLanguage()}
      className={clsx(
        classes.languageButton,
        i18n.language === code && classes.active,
      )}
    >
      { children }
    </button>
  )
}

const LanguageOptions = ({ closeMenu, newLanguages }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return newLanguages
    ? (
      <HashLink className={classes.hashLink} to="/account/profile#profilepreferences">
        <LinkMenuItem
          Icon={LanguageIcon}
          onClick={closeMenu}
          path="/account/profile#profilepreferences"
          text={t('languages')}
        />
      </HashLink>
      )
    : (
      <ListItem button className={classes.item}>
        <ListItemIcon>
          <LanguageIcon fontSize="small" />
        </ListItemIcon>
        <div>
          <LanguageButton closeMenu={closeMenu} code="en">
            { t('language.english') }
          </LanguageButton>
          <LanguageButton closeMenu={closeMenu} code="fr">
            { t('language.french') }
          </LanguageButton>
        </div>
      </ListItem>
      )
}

export default LanguageOptions
