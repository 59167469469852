import { useTranslation } from 'react-i18next'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'

import PrimaryButton from 'shared-module/new-components/buttons/PrimaryButton'
import VoskerPrimaryButton from 'vosker/src/components/buttons/VoskerPrimaryButton'

import usePaymentInformation from './UsePaymentInformation'

const SavePaymentInformationButton = ({ onSuccess, onError }) => {
  const { t } = useTranslation()

  const {
    submitting,
    paymentError,
    closePaymentErrorDialog,
    savePaymentInformation,
  } = usePaymentInformation({ onSuccess, onError })

  return (
    <>
      { paymentError && (
        <Dialog open onClose={closePaymentErrorDialog} maxWidth="xs">
          <DialogTitle>{ t('error_short') }</DialogTitle>
          <DialogContent>
            <DialogContentText>{ paymentError }</DialogContentText>
            <DialogActions>
              <PrimaryButton onClick={closePaymentErrorDialog}>{ t('button.ok') }</PrimaryButton>
            </DialogActions>
          </DialogContent>
        </Dialog>
      ) }

      <VoskerPrimaryButton disabled={submitting} onClick={savePaymentInformation}>
        { t('app:payment_information_page.buttons.save') }
      </VoskerPrimaryButton>
    </>
  )
}

export default SavePaymentInformationButton
