import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import useUser from 'user-module/user/core/useUser'
import webview from 'shared-module/webview/webview'
import useUserActions from 'user-module/user/core/useUserActions'
import cameraActions from 'camera-module/camera/core/cameraActions'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'

const OrderSuccessDialog = ({ isOpen, isAddOnStreaming, isAddOnAddOn }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const camera = useSelectedCamera()
  const user = useUser()
  const { reload } = useUserActions()

  const onClose = () => {
    if (webview.isWebview()) {
      webview.postMessage({ action: 'navigate', to: 'account' })
    } else {
      dispatch(cameraActions.get(camera.id))
      reload(user)
      history.push(`/camera/${camera.id}`)
    }
  }

  return (
    <ConfirmationDialog
      isNewUi
      open={isOpen}
      onClose={onClose}
      onCancel={onClose}
      title={t('app:order_page.success.title')}
      text={
        isAddOnStreaming
          ? t('app:order_page.success.add_ons.streaming')
          : isAddOnAddOn
            ? t('app:order_page.success.add_ons.addon')
            : t('app:order_page.success.text')
      }
      cancelText={t('app:order_page.success.close')}
    />
  )
}

export default OrderSuccessDialog
