import { makeStyles } from '@material-ui/core/styles'

export const MobileAppSplashScreenStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    maxWidth: 500,
    flexFlow: 'column',
    boxSizing: 'border-box',
    background: theme.palette.background.paper,
    '& header, main': {
      padding: '20px',
    },
    '& header': {
      height: '71px',
      boxSizing: 'border-box',
      background: 'black',
      '& img': {
        width: '180px',
        height: 'auto',
      },
    },
    '& main': {
      '& p:nth-of-type(1), p:nth-of-type(2)': {
        fontFamily: theme.typography.h3.fontFamily,
        color: theme.palette.text.primary,
        width: '75%',
        lineHeight: '115%',
      },
      '& p:nth-of-type(1)': {
        margin: '0 0 8px 0',
        fontSize: '32px',
        fontWeight: 'bold',
      },
      '& p:nth-of-type(2)': {
        margin: '0 0 30px 0',
        fontSize: '18px',
      },
      '& p:nth-of-type(3), p:nth-of-type(4)': {
        margin: '0',
        fontFamily: theme.typography.body1.fontFamily,
        color: theme.palette.background.filter,
        fontSize: '14px',
        lineHeight: '150%',
      },
      '& p:nth-of-type(4)': {
        marginTop: '15px',
      },
    },
    '& footer': {
      display: 'flex',
      padding: '0 20px',
      '& span': {
        flex: '3 0 0',
        '& a': {
          display: 'block',
        },
      },
      '& span:first-of-type': {
        flex: '2 0 0',
        marginTop: 85,
        '& a': {
          margin: '20px 0',
        },
      },
      '& img': {
        display: 'block',
        maxWidth: '100%',
        margin: 'auto',
      },
      '& img.img-phone': {
        maxWidth: 180,
      },
    },
  },
}))
