import PropTypes from 'prop-types'
import { alpha, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  switch: {
    width: '240px',
    '& label': {
      '-webkit-user-select': 'none',
      '-moz-user-select': 'none',
      '-ms-user-select': 'none',
      userSelect: 'none',
      cursor: 'pointer',
      display: 'inline-block',
      paddingTop: '5px',
      paddingBottom: '5px',
      color: alpha(theme.palette.background.paper, 0.7),
      '-webkit-transition': 'all .2s',
      '-moz-transition': 'all .2s',
      transition: 'all .2s',
      fontSize: '14px',
    },
    '& input': {
      position: 'absolute',
      left: '-999px',
      visibility: 'hidden',
      zIndex: '-9',
      '&:checked': {
        '&+ label': {
          '&> span': {
            '&:before': {
              '-webkit-transform': 'translateX(0)',
              '-moz-transform': 'translateX(0)',
              '-ms-transform': 'translateX(0)',
              transform: 'translateX(0)',
              background: theme.palette?.switch?.backgroundActive || theme.palette.background.paper,
              left: '2.5px',
              top: '1.469rem',
            },
          },
          '& .on-switch': {
            color: theme.palette.text.primary,
          },
          '& .off-switch': {
            color: theme.palette.text.primary,
          },
        },
      },
      '&+ label': {
        '& .off-switch': {
          color: theme.palette.text.primary,
        },
      },
    },
  },
  switcher: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    height: '2.063rem',
    width: '14.5rem',
    padding: '2px',
    background: theme.palette?.switch?.background || theme.palette.secondary.main,
    '-webkit-transition': 'all .4s',
    '-moz-transition': 'all .4s',
    transition: 'all .4s',
    borderRadius: '0.375rem',
    zIndex: '1',
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      zIndex: '9',
      top: '1.469rem',
      left: '2px',
      right: '7.5px',
      border: 'none',
      width: '113.5px',
      height: '28px',
      borderRadius: '0.375rem',
      background: theme.palette?.switch?.backgroundActive || theme.palette.background.paper,
      flex: 'none',
      order: '0',
      alignSelf: 'stretch',
      flexGrow: '1',
      '-webkit-transition': 'all .2s',
      '-moz-transition': 'all .2s',
      transition: 'all .2s',
      marginTop: ' -21px',
      '-webkit-transform': 'translateX(100%)',
      '-moz-transform': 'translateX(100%)',
      '-ms-transform': 'translateX(100%)',
      transform: 'translateX(100%)',
    },
    '& span': {
      display: 'inline-block',
      textAlign: 'center',
      textIndent: '30px',
      width: '95px',
      zIndex: '15',
      position: 'relative',
      fontWeight: '600',
      color: theme.palette.text.primary,
    },
    '& .on-switch': {
      right: '8px',
    },
    '& .off-switch': {
      left: '12px',
    },
  },
  switchOption: {
    display: 'inline-block',
    textAlign: 'center',
    width: '95px',
    zIndex: '15',
    position: 'relative',
    fontWeight: '600',
  },
}))

const SwitchToggle = ({ onChange, switchToggleState, firstOption, secondOption, firstID, secondId }) => {
  const classes = useStyles()
  const makeDOMId = (id) => {
    return id + '-' + makeRandomNumber().toString()
  }

  const makeRandomNumber = () => Math.floor(Math.random() * 10000000)

  const switchDOMId = makeDOMId('switch-toggle')

  return (
    <div className={classes.switch}>
      <input
        type="checkbox"
        checked={switchToggleState && 'checked'}
        value={switchToggleState}
        onChange={onChange}
        id={switchDOMId}
        data-testid="switch-toggle-input"
      />
      <label htmlFor={switchDOMId}>
        <span className={classes.switcher}>
          <span id={firstID} className="on-switch">
            { firstOption }
          </span>
          <span id={secondId} className="off-switch">
            { secondOption }
          </span>
        </span>
      </label>
    </div>
  )
}

SwitchToggle.propTypes = {
  onChange: PropTypes.func.isRequired,
  switchToggleState: PropTypes.bool.isRequired,
  firstOption: PropTypes.string.isRequired,
  secondOption: PropTypes.string.isRequired,
}

export default SwitchToggle
