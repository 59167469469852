import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid, Typography } from '@material-ui/core'
import InfoRoundedIcon from '@material-ui/icons/InfoRounded'
import VoskerTooltip from 'vosker/src/components/VoskerTooltip'
import VoskerSecondaryButton from 'vosker/src/components/buttons/VoskerSecondaryButton'
import ProfilePasswordEdit from 'user-module/user/ui/password/ProfilePasswordEdit'
import * as Yup from 'yup'

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 600,
    marginBottom: 16,
  },
  paper: {
    background: theme.palette.primary.contrastText,
    padding: 32,
  },
  itemGap: {
    gap: 6,
    paddingBottom: 8,
  },
  paddingY: {
    paddingBottom: 8,
  },
  userEmail: {
    fontWeight: '600',
  },
  infoIcon: {
    fill: theme.palette.border?.secondary + ' !important',
  },
}))

const LoginInformation = ({ inherentClasses, elevation = 3, userProfile }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const [showPasswordEditForm, setShowEditPasswordForm] = useState(false)
  const handleEditPassword = () => setShowEditPasswordForm(true)

  const validationSchema = Yup.object().shape({
    current: Yup.string().required(t('register.form.hints.required')),
    new: Yup.string()
      .matches(/[a-z]/, t('register.form.hints.lower'))
      .matches(/[A-Z]/, t('register.form.hints.upper'))
      .matches(/[0-9]/, t('register.form.hints.number'))
      .matches(/[^a-zA-z0-9 ]/, t('register.form.hints.special'))
      .min(10, t('register.form.hints.min'))
      .max(24, t('register.form.hints.max'))
      .required('password_required'),
    confirm: Yup.string()
      .required(t('register.form.hints.required'))
      .oneOf([Yup.ref('new')], t('register.form.hints.match')),
  })

  return (
    <Box>
      <Box className={classes.title}>
        <Typography variant="h2">{ t('input.login_information') }</Typography>
      </Box>
      <Paper className={inherentClasses ? inherentClasses.overridePaper : classes.paper} square elevation={elevation}>
        <Box className={classes.paddingY}>
          <Grid container item xs={12} className={classes.itemGap}>
            <Typography color="textPrimary">{ t('input.email_address') }</Typography>
            <VoskerTooltip title={t('input.can_not_be_updated')} arrow>
              <InfoRoundedIcon className={classes.infoIcon} />
            </VoskerTooltip>
          </Grid>
          <Grid container item xs={12} className={classes.itemGap}>
            <Typography className={classes.userEmail} variant="body1">
              { userProfile?.email }
            </Typography>
          </Grid>
        </Box>
        <Grid container justifyContent="space-between" alignItems="center" item xs={12}>
          { !showPasswordEditForm && (
            <Grid item>
              <Box className={classes.paddingY}>
                <Typography color="textPrimary">{ t('input.password') }</Typography>
              </Box>
              <Box>
                <Typography color="textPrimary">*************</Typography>
              </Box>
            </Grid>
          ) }
          { showPasswordEditForm && (
            <Grid item xs={12}>
              <ProfilePasswordEdit
                elevation={0}
                padding="0 30px"
                inherentClasses={classes}
                validationSchema={validationSchema}
                setShowEditPasswordForm={setShowEditPasswordForm}
              />
            </Grid>
          ) }
          { !showPasswordEditForm && (
            <Box>
              <VoskerSecondaryButton color="secondary" size="large" onClick={handleEditPassword}>
                { t('button.edit') }
              </VoskerSecondaryButton>
            </Box>
          ) }
        </Grid>
      </Paper>
    </Box>
  )
}

export default LoginInformation
