import { useTranslation } from 'react-i18next'

import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'
import Form from 'form-module/ui/Form'

import useUserActions from 'user-module/user/core/useUserActions'
import ProfilePasswordForm from 'user-module/user/ui/password/ProfilePasswordForm'

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 16,
  },
  paper: {
    background: theme.palette.primary.contrastText,
    padding: 32,
  },
}))

const ProfilePasswordEdit = ({ inherentClasses, elevation = 3, validationSchema, setShowEditPasswordForm }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const initialValues = {
    current: '',
    new: '',
    confirm: '',
  }

  const { updatePassword } = useUserActions()

  const onSubmit = (values, actions) => {
    updatePassword(values.current, values.new).then((value) => {
      if (value.messageType === 'SUCCESS') {
        actions.resetForm()
        setShowEditPasswordForm(false)
      } else {
        actions.setFieldError('current', t('input.password_is_incorrect'))
      }
    })
  }
  return (
    <Box>
      <Box className={classes.title}>
        <Typography color="textPrimary">{ t('input.password') }</Typography>
      </Box>
      <Paper className={inherentClasses ? inherentClasses.overridePaper : classes.paper} square elevation={elevation}>
        <Form
          id="password-form"
          validationSchema={validationSchema}
          data={initialValues}
          validateOnBlur
          validateOnChange
          onSubmit={(values, actions) => onSubmit(values, actions)}
        >
          <ProfilePasswordForm setShowEditPasswordForm={setShowEditPasswordForm} />
        </Form>
      </Paper>
    </Box>
  )
}

export default ProfilePasswordEdit
