import { createContext, useEffect, useMemo, useState } from 'react'
import { ThemeProvider } from '@material-ui/core/styles'

export const THEME_MODE_CONTEXT = createContext({ isDarkModeActive: false, toggleThemeMode: () => {} })

const ThemeContextProvider = ({ darkTheme, lightTheme, children, currentTheme }) => {
  const localStorageTheme = localStorage.getItem('theme')
  const [mode, setMode] = useState(localStorageTheme || 'dark')

  useEffect(() => {
    if (localStorageTheme) {
      localStorageTheme !== mode && setMode(localStorageTheme)
    } else {
      localStorage.setItem('theme', 'dark')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (mode !== localStorageTheme) localStorage.setItem('theme', mode)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode])

  const themeMode = useMemo(() => ({
    isDarkModeActive: mode === 'dark',
    toggleThemeMode: () => setMode(prevMode => prevMode === 'light' ? 'dark' : 'light'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [mode])

  const theme = useMemo(() => {
    if (currentTheme === 'light') {
      localStorage.setItem('theme', 'light')
      return lightTheme
    } else if (currentTheme === 'dark') {
      localStorage.setItem('theme', 'dark')
      return darkTheme
    } else {
      return mode === 'light' ? lightTheme : darkTheme
    }
  }, [currentTheme, mode, lightTheme, darkTheme])

  return (
    <THEME_MODE_CONTEXT.Provider value={themeMode}>
      <ThemeProvider theme={theme}>
        { children }
      </ThemeProvider>
    </THEME_MODE_CONTEXT.Provider>
  )
}

export default ThemeContextProvider
