import { useTranslation } from 'react-i18next'

const useLanguage = () => {
  const { i18n } = useTranslation()

  if (i18n.language === 'fr') { return 'fr' }
  if (i18n.language === 'en') { return 'en' }
  if (i18n.language === 'de') { return 'de' }
  if (i18n.language === 'es') { return 'es' }
  return 'en'
}
export default useLanguage
