const defaultState = { filters: null, selectedFilters: [] }
const hasFetchFilterCollection = state => state.filters

const set = (state, action) => ({ ...state, filters: action.filters })
const setSelectedFilters = (state, action) => ({ ...state, selectedFilters: [...state.selectedFilters, action.filter] })
const removeSelectedFilters = (state, action) => ({ ...state, selectedFilters: state.selectedFilters.filter(filter => filter !== action.filter) })
const clearSelectedFilters = (state) => ({ ...state, selectedFilters: [] })

const filterCollectionState = { defaultState, hasFetchFilterCollection, set, setSelectedFilters, removeSelectedFilters, clearSelectedFilters }
export default filterCollectionState
