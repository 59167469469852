import Box from '@material-ui/core/Box'

const FullScreenBackground = ({ image, children, marginTop, paddingBottom }) => {
  return (
    <Box overflowy="scroll" style={{ backgroundImage: `url("${image}")`, backgroundSize: 'cover', marginTop, paddingBottom }}>
      { children }
    </Box>
  )
}

export default FullScreenBackground
