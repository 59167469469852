import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const MinusIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon {...props} data-testid="MinusIcon">
      <path
        d="M3.4707 13.9219C3.4707 13.2229 4.01636 12.6562 4.68945 12.6562H12.1871H22.1223C22.7954 12.6562 23.3411 13.2229 23.3411 13.9219C23.3411 14.6209 22.7954 15.1875 22.1223 15.1875H12.1871L4.68945 15.1875C4.01636 15.1875 3.4707 14.6209 3.4707 13.9219Z"
        fill={theme.palette.status?.info}
      />

    </SvgIcon>
  )
}

export default MinusIcon
