import { useTranslation } from 'react-i18next'
import Link from '@material-ui/core/Link'
import PublicPageText from 'shared-module/new-components/public-page/PublicPageText'
import { useFormData } from 'shared-module/new-components/form/FormDataContext'

const ResetPasswordSuccess = () => {
  const { t } = useTranslation()
  const form = useFormData()
  const email = form.getValues().email

  return form.formState.touched.reset
    ? (
      <>
        <PublicPageText>{ t('reset_password.success.text', { email: email }) }</PublicPageText>
        <PublicPageText>{ t('reset_password.success.note') }</PublicPageText>
        <PublicPageText><Link href="#" onClick={() => form.reset()}>{ t('reset_password.success.try_again') }</Link></PublicPageText>
      </>
      )
    : null
}

export default ResetPasswordSuccess
