import { useTranslation } from 'react-i18next'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'

const DialogBuyAddonConfirm = ({ open, onClose, onConfirm, ecoModeCameras }) => {
  const { t } = useTranslation()

  const dialogText = () => (
    <>
      { t('app:plan.addon.confirmation.text') }
      <ul>
        { ecoModeCameras?.map(ca => (<li key={ca.id}>{ ca.config?.name }</li>)) }
      </ul>
      { t('app:plan.addon.confirmation.warning') }
    </>
  )

  return (
    <ConfirmationDialog
      isNewUi
      open={open}
      onClose={onClose}
      title={t('app:plan.addon.confirmation.title')}
      text={dialogText()}
      confirmText={t('app:plan.addon.confirmation.confirm')}
      onConfirm={onConfirm}
      onCancel={onClose}
    />
  )
}

export default DialogBuyAddonConfirm
