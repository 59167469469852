import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import CTypography from 'storybook-component-module/src/components/typographies/CTypography'
import WarningIcon from 'assets/icons/navigation/WarningIcon'

const useStyles = makeStyles(theme => ({
  noPlanContainer: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  noPlanTextTitle: {
    fontWeight: 600,
  },
  noPlanTextContent: {
    color: theme.palette.text.secondary,
    marginTop: '4px',
    fontWeight: 400,
  },
  noPlan: {
    color: theme.palette.primary.main,
    fontSize: '.9em',
    lineHeight: '1em',
  },
  noPlanTitle: {
    fontFamily: theme.typography.fontFamilyBold,
    fontSize: '1.25em',
    lineHeight: '1.15em',
    marginBottom: '.25rem',
  },
  warningIcon: {
    width: '1rem',
    top: '0.375rem',
    position: 'relative',
    marginRight: '0.313rem',
  },
}))

const NoPlan = ({ isNewUi }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return isNewUi
    ? (
      <Box className={classes.noPlanContainer}>
        <CTypography variant="body1" color="textPrimary" style={{ fontWeight: 600 }}><WarningIcon className={classes.warningIcon} /> { t('photo_gallery.plan_expires') }</CTypography>
        <CTypography variant="body2" className={classes.noPlanTextContent}>{ t('photo_gallery.no_plan') }</CTypography>
      </Box>
      )
    : (
      <div className={classes.noPlanContainer}>
        <Typography className={clsx(classes.noPlan, classes.noPlanTitle)}>
          { t('app:plan.current_subscription.no_plan.title') }
        </Typography>
        <Typography className={classes.noPlan}>
          { t('app:plan.current_subscription.no_plan.content') }
        </Typography>
      </div>
      )
}

export default NoPlan
