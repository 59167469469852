import { useSelector } from 'react-redux'
import useLoadingActions from 'shared-module/loading/useLoadingActions'

const useLoad = (key, state, get) => {
  const loading = useSelector(state => state.loading)
  const loadingActions = useLoadingActions()

  if (state === null && !loadingActions.isLoading(loading, key)) {
    loadingActions.add(key)
    get().finally(() => loadingActions.remove(key))
  }

  return state
}

export default useLoad
