import { useState } from 'react'
import { useField, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import TextField from '@material-ui/core/TextField'
import TextMaskInput from 'react-text-mask'

import { getFieldError } from '../util'

const secToString = duration => {
  const hours = Math.floor(duration / 3600)
  const minutes = (duration - (hours * 3600)) / 60
  return `${hours.toString().padStart(2, '0')}h${minutes.toString().padStart(2, '0')}m`
}

const stringToSec = duration => {
  const match = duration.match(/^([0-9]{2})h([0-9]{2})/)
  if (duration.includes('_') || match === null) {
    return false
  }
  const hours = parseInt(match[1])
  if (hours > 24) {
    return false
  }
  const minutes = parseInt(match[2])
  return (hours * 3600) + (minutes * 60)
}

const MaskedInput = props => {
  const { inputRef, ...inputProps } = props
  return (
    <TextMaskInput
      {...inputProps}
      mask={[/[0-2]/, /\d/, 'h', /[0-5]/, /\d/, 'm']}
      placeholderChar="_"
      placeholder="00h05m"
      guide
    />
  )
}

const DurationInput = ({
  name,
  children,
  min = 0,
  max = 86400,
  defaultValue,
  ...controlProps
}) => {
  const { isSubmitting, setFieldValue, setFieldError, setFieldTouched } = useFormikContext()
  const [field, meta] = useField(name)
  const [value, setValue] = useState(secToString(field.value))
  const { t } = useTranslation()

  const onChange = (event) => {
    const fieldValue = event.target.value
    setValue(fieldValue)

    let sec = stringToSec(fieldValue)
    if (sec === false) {
      if (fieldValue === '') {
        setFieldValue(name, '', true)
      } else {
        setFieldError(name, t('form.validation.default'))
      }
      return
    }
    if (sec < min) {
      sec = Math.max(sec, min)
      setValue(secToString(sec))
    }
    if (sec > max) {
      sec = Math.min(sec, max)
      setValue(secToString(sec))
    }
    setFieldValue(name, sec, true)
  }

  const onBlur = event => {
    if (value !== '' && stringToSec(value) === false) {
      setFieldTouched(name, true, false)
      setFieldError(name, t('form.validation.default'))
    }
  }

  const error = getFieldError(meta, t)

  return (
    <TextField
      defaultValue={defaultValue}
      fullWidth
      variant="outlined"
      {...controlProps}
      {...field}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      error={!!error}
      helperText={error}
      disabled={field.disabled || isSubmitting}
      InputProps={{
        inputComponent: MaskedInput,
      }}
    >
      { children }
    </TextField>
  )
}

export default DurationInput
