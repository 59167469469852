import { CLEAR_MESSAGE, SET_MESSAGE } from 'shared-module/message/messageActionsTypes'
import { ERROR, INFO, SUCCESS, WARNING } from 'shared-module/message/messageTypes'

const showMessage = (message, action, countdown) => ({ type: SET_MESSAGE, message, messageType: INFO, action, countdown })
const showError = (message = 'error') => ({ type: SET_MESSAGE, message, messageType: ERROR })
const showSuccess = (message) => ({ type: SET_MESSAGE, message, messageType: SUCCESS })
const showWarning = (message) => ({ type: SET_MESSAGE, message, messageType: WARNING })
const clear = () => ({ type: CLEAR_MESSAGE })

const messageActions = { showMessage, showError, showSuccess, showWarning, clear }
export default messageActions
