import restapi from 'shared-module/api/restapi'
import handleError from 'shared-module/api/apiError'

const FREQUENCY_ANNUAL = 'annual'
const FREQUENCY_MONTHLY = 'month_by_month'

const PRODUCT_FREQUENCY = [
  FREQUENCY_ANNUAL,
  FREQUENCY_MONTHLY,
]

const format = products => products.map(product => ({
  ...product,
  quantity: toInt(product.quantity),
  frequency: PRODUCT_FREQUENCY.includes(product.frequency) ? product.frequency : FREQUENCY_MONTHLY,
}))

const toInt = value => value ? parseInt(value) : value

const createPreview = (language, products, promocode) => restapi
  .post('/api/v3/orders/preview', { language: language, products: format(products), promocode: promocode })
  .then(response => response.data)
  .catch(handleError)

const placeOrder = (language, products, promocode) => restapi
  .post('/api/v3/orders', { language: language, products: format(products), promocode: promocode })
  .then(response => response)
  .catch(handleError)

const verify = (promocode, products) => restapi
  .post(`api/v3/promocode/${promocode}`, { products: products })

const orderApi = { createPreview: createPreview, placeOrder: placeOrder, verify: verify }
export default orderApi
