import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'
import { apiCameraPlans } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.types'

const DialogFullHdVideoCountError = ({ open, onClose }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const camera = useSelectedCamera()

  const confirmActions = () => {
    onClose()
    history.push(`/camera/${camera.id}/plan`)
  }

  return (
    <ConfirmationDialog
      isNewUi
      open={open}
      onClose={onClose}
      title={t('photo_viewer.request_full_hd_video.request_full_hd_video_other_plan.title')}
      text={t('photo_viewer.request_full_hd_video.request_full_hd_video_other_plan.text')}
      confirmText={t('photo_viewer.request_full_hd_video.request_full_hd_video_other_plan.confirm')}
      onConfirm={confirmActions}
      onCancel={camera.subscription.plan.name === apiCameraPlans.elite ? null : onClose}
    />
  )
}

export default DialogFullHdVideoCountError
