import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import { getPlanTranslationKey } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.selectors'
import { formatDate } from 'shared-module/common-utils'
import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles((theme) => ({
  currentPlan: {
    fontFamily: theme.typography.fontWeightMedium,
    fontWeight: 'bold',
    fontSize: '1em',
    color: theme.palette.text.primary,
  },
  planName: {
    color: theme.palette.text.primary,
    textAlign: 'end',
  },
  period: {
    color: theme.palette.text.secondary,
  },
  dates: {
    fontFamily: theme.typography.fontWeightMedium,
    fontWeight: 'bold',
    fontSize: '1em',
  },
  sectionContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '13.5px 0 13.5px 0',
  },
}))

const CurrentPlan = () => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const camera = useSelectedCamera()

  const subscription = camera?.subscription
  const plan = subscription?.plan

  const isCompletingTrial = camera.isCompletingTrial
  const onFreePlan = !isCompletingTrial && plan.isFree
  const onTrial = !isCompletingTrial && subscription.isFree && !plan.isFree

  const translatedPlanName = t('app:plans.' + getPlanTranslationKey(plan?.name)).toLocaleUpperCase()

  return (
    <>
      <Grid item container className={classes.sectionContainer}>
        <Grid item xs={4} md={6}>
          <div className={classes.currentPlan}> { t('app:plan.current_subscription.current_plan') }</div>
        </Grid>
        <Grid item xs={8} md={6}>
          <div className={classes.planName}>
            <Typography>
              { onTrial
                ? t('app:plan.current_subscription.trial_of_plan', { planName: translatedPlanName })
                : translatedPlanName }
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Divider />
      { !onFreePlan && (
        <>
          <Grid container className={classes.sectionContainer}>
            <Grid item xs={4} md={6}>
              <div className={classes.currentPlan}>{ t('app:plan.frequency.period') }</div>
            </Grid>
            <Grid item xs={8} md={6}>
              <div className={classes.planName}>
                <Typography>
                  <Typography display="inline">
                    { formatDate(subscription.startDateBillingCycle, i18n, true, { month: 'short' }) }
                    { ' - ' }
                    { formatDate(subscription.endDateBillingCycle, i18n, true, { month: 'short' }) }
                  </Typography>
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Divider />
        </>
      ) }
    </>
  )
}

export default CurrentPlan
