import clsx from 'clsx'
import React, { ReactNode } from 'react'

import Badge from '@material-ui/core/Badge'
import { makeStyles } from '@material-ui/core/styles'

export interface ICBadge {
  children?: ReactNode
  badgeColor?: 'primary' | 'secondary' | 'error'
  badgeContent?: any
  isShowingLabel?: boolean
  classes?: string
}

const useStyles = makeStyles(() => ({
  badge: {
    height: 12,
    minWidth: 12,
    borderRadius: 6,
    padding: '0 4px',
  },
  content: {
    paddingTop: 0.8,
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: 'normal',
  },
}))

const CBadge = (props: ICBadge) => {
  const classes = useStyles()

  return props.badgeContent
    ? <Badge color={props.badgeColor} badgeContent={props.badgeContent} children={props.children} classes={{ badge: clsx(props.classes, classes.content) }} />
    : props.isShowingLabel
      ? <Badge color={props.badgeColor} badgeContent="" classes={{ badge: clsx(props.classes, classes.badge) }} children={props.children} />
      : <Badge color={props.badgeColor} overlap="circular" variant="dot" classes={{ badge: clsx(props.classes, classes.badge) }} children={props.children} />
}

export default CBadge
