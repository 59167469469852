import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import makeStyles from '@material-ui/styles/makeStyles'
import Dialog from '@material-ui/core/Dialog'

import useCameraActions from 'camera-module/camera/core/useCameraActions'
import useUser from 'user-module/user/core/useUser'
import useUserActions from 'user-module/user/core/useUserActions'
import { useStreaming } from '../core/liveStreaming.hooks'
import ActionBar from './actionBar/ActionBar'
import LiveStreamingPlayer from './player/LiveStreamingPlayer'

const getPadding = (padding, unit) => {
  const width = padding
  const height = padding * (16 / 9)
  return `${width}${unit} ${height}${unit}`
}

const useStyles = makeStyles(theme => ({
  streamingDialog: {
    padding: 0,
    '&.MuiPaper-root': {
      backgroundColor: theme.palette.basic?.black + '!important',
    },
  },
  aspectRatioContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    width: '100vw',
    maxWidth: '177.78vh', /* vertical aspect ratio 16/9 */
    height: '56.25vw', /* horizontal aspect ratio 16/9 */
    maxHeight: '100vh',
    margin: 'auto',
  },
  container: {
    display: 'flex',
    flexGrow: 1,
    padding: getPadding(8, 'vh'),
    '@media (orientation: portrait)': {
      padding: getPadding(2, 'vw'),
    },
  },
  content: {
    position: 'relative',
    flexGrow: 1,
  },
}))

const LiveStreamingPage = () => {
  const { id: cameraId } = useParams()
  const history = useHistory()
  const classes = useStyles()
  const userActions = useUserActions()
  const cameraActions = useCameraActions()
  const user = useUser()
  const streaming = useStreaming()

  useEffect(() => {
    cameraActions.update(cameraId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameraId])

  useEffect(() => {
    return () => {
      const urlChanged = !history.location.pathname.includes('live-streaming')
      if (!urlChanged) {
        return
      }
      streaming.close()
    }
  }, [history.location.pathname, streaming])

  useEffect(() => {
    if (!streaming.streamUrl) {
      userActions.reload(user)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streaming.streamUrl])

  const onEscapeKeyDown = (_, reason) => {
    if (reason === 'escapeKeyDown') {
      history.push(`/camera/${cameraId}`)
    }
  }

  return (
    <Dialog
      fullScreen
      open
      onClose={onEscapeKeyDown}
      PaperProps={{ className: classes.streamingDialog }}
    >
      <ActionBar />

      <div className={classes.aspectRatioContainer}>
        <div className={classes.container}>
          <div className={classes.content}>
            <LiveStreamingPlayer />
          </div>
        </div>
      </div>

    </Dialog>
  )
}

export default LiveStreamingPage
