import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    borderBottom: `solid 1px ${theme.palette.secondary.main}`,
    background: theme.palette.background.default,
  },
  cameraButton: {
    height: '100%',
    width: '50%',
    borderRadius: 0,
    borderRight: `solid 1px ${theme.palette.secondary.main}`,
    padding: '10px 0',
    '&:last-child': {
      borderRight: 'none',
    },
    '&:hover': {
      background: 'transparent',
    },
  },
}))

const SelectedCameraMenuItems = ({ onClose }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  const goToCameraSelection = () => {
    onClose()
    history.push('/')
  }

  const goToAddCamera = () => {
    onClose()
    history.push('/add-camera')
  }

  return (
    <Box
      className={classes.container}
    >
      <Button
        size="small"
        className={classes.cameraButton}
        onClick={goToCameraSelection}
      >
        { t('menu.my_cameras') }
      </Button>

      <Button
        size="small"
        className={classes.cameraButton}
        onClick={goToAddCamera}
      >
        { t('menu.add') }
      </Button>
    </Box>
  )
}

export default SelectedCameraMenuItems
