import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

import TextSmall from 'vosker/src/order-module/order/ui/order-lines/components/TextSmall'
import TextSmallBold from 'vosker/src/order-module/order/ui/order-lines/components/TextSmallBold'
import { getPlanTranslationKey } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.selectors'

const PlanNameLine = ({ product }) => {
  const { t } = useTranslation()
  return (
    <Grid container justifyContent="space-between">
      <Grid item xs>
        <TextSmall>{ t('app:order_page.order_summary.plan') }</TextSmall>
      </Grid>
      <Grid item xs>
        <Box textAlign="right">
          <TextSmallBold style={{ textTransform: 'uppercase' }}>
            { t(`app:plans.${getPlanTranslationKey(product.planId)}`) }
          </TextSmallBold>
        </Box>
      </Grid>
    </Grid>
  )
}

export default PlanNameLine
