import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'
import { useDispatch, useSelector } from 'react-redux'
import dialogState from 'shared-module/dialog/dialogState'
import dialogActions from 'shared-module/dialog/dialogActions'

const canDisplayDialog = (message) => {
  return dialogState.hasErrorToDisplay(message)
}

const ErrorDialog = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const errorMessage = useSelector(state => state.error)

  const [isOpen, setIsOpen] = useState(false)

  if (errorMessage.error && !isOpen) {
    setIsOpen(true)
  }

  const closeDialog = () => {
    dispatch(dialogActions.clear())
    setIsOpen(false)
  }

  return (
    canDisplayDialog(errorMessage) && (
      <ConfirmationDialog
        open={isOpen}
        onClose={() => closeDialog()}
        title={t('app:error_dialog.title')}
        text={t(errorMessage.error)}
        confirmText={t('app:error_dialog.confirm_text').toLocaleUpperCase()}
        onConfirm={() => closeDialog()}
      />
    )
  )
}

export default ErrorDialog
