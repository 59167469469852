import restapi from 'shared-module/api/restapi'
import handleError from 'shared-module/api/apiError'
import buildCountries from 'shared-module/countries/countriesBuilder'

const getCountries = (language) => {
  return restapi.get(`/api/v3/i18n/countryList?language=${language}`)
    .then(response => response.data.countries.map(buildCountries))
    .catch(handleError)
}

const getRegionsByCountryCode = (countryCode, language) => {
  return restapi.get(`/api/v3/i18n/territoryList/code/${countryCode}?language=${language}`)
    .then(response => response.data.territories.map(buildCountries))
    .catch(handleError)
}

const countriesApi = { getCountries: getCountries, getRegionsByCountryCode: getRegionsByCountryCode }
export default countriesApi
