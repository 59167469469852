import SvgIcon from '@material-ui/core/SvgIcon'

const EventPendingIcon = (props) => {
  return (
    <SvgIcon data-testid="EventPendingIcon" width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g filter="url(#filter0_ddd_3082_21609)">
        <path
          d="M6.234 5.24356C6.97847 4.4962 7.9281 3.98675 8.96248 3.7798C9.99686 3.57286 11.0694 3.67774 12.0441 4.08114C13.0188 4.48454 13.8517 5.1683 14.4373 6.04572C15.0229 6.92313 15.3348 7.95468 15.3333 9.00956H16.6667C16.6684 7.69092 16.2786 6.40146 15.5466 5.30466C14.8146 4.20787 13.7733 3.35313 12.5549 2.84884C11.3365 2.34454 9.99584 2.21339 8.70282 2.47202C7.40979 2.73065 6.22268 3.36741 5.292 4.30156L3.66666 2.67603V6.67603H7.66666L6.234 5.24356Z"
          fill="#F9C20A"
        />
        <path
          d="M13.7667 12.7756C13.0221 13.5228 12.0724 14.0322 11.0381 14.2391C10.0037 14.446 8.93115 14.3411 7.95645 13.9377C6.98174 13.5343 6.14876 12.8506 5.56308 11.9733C4.97741 11.0959 4.66542 10.0644 4.66667 9.00956H3.33333C3.33158 10.3283 3.72147 11.6178 4.45357 12.7147C5.18566 13.8115 6.22698 14.6662 7.44549 15.1705C8.664 15.6747 10.0048 15.8057 11.2979 15.547C12.591 15.2882 13.7781 14.6512 14.7087 13.7169L16.3333 15.3427V11.3427H12.3333L13.7667 12.7756Z"
          fill="#F9C20A"
        />
      </g>
      <defs>
        <filter id="filter0_ddd_3082_21609" x="0.333374" y="0.342712" width="19.3333" height="19.3337" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3082_21609" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_3082_21609" result="effect2_dropShadow_3082_21609" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="0.75" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="effect2_dropShadow_3082_21609" result="effect3_dropShadow_3082_21609" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_3082_21609" result="shape" />
        </filter>
      </defs>

    </SvgIcon>
  )
}

export default EventPendingIcon
