import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

const useStyles = makeStyles(theme => ({
  menuLink: {
    fontFamily: theme.typography.menuLink?.fontFamily,
    fontSize: theme.typography.menuLink?.fontSize,
    lineHeight: theme.typography.menuLink?.lineHeight,
  },
  item: {
    paddingLeft: theme.spacing(4),
    '&:hover': {
      background: theme.palette.background.paper,
    },
  },
}))

const MenuItem = ({ Icon, text, onClick, children }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <ListItem button className={classes.item} onClick={onClick}>
      <ListItemIcon><Icon fontSize="small" /></ListItemIcon>
      <ListItemText primary={t(text)} primaryTypographyProps={{ className: classes.menuLink }} />
      <ListItemIcon>{ children }</ListItemIcon>
    </ListItem>
  )
}

export default MenuItem
