import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@mui/material'

const SideSettingsIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="SideSettingsIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18.9911 12C18.9911 11.4167 18.9108 10.9167 18.8304 10.4167L21 8.08332L19.3928 5.41665L16.4197 6.41666C15.7768 5.91666 15.0536 5.49999 14.25 5.16666L13.5268 2H10.4731L9.74995 5.16666C8.94637 5.41666 8.2232 5.83333 7.58034 6.41666L4.60715 5.41665L3 8.08332L5.16961 10.4167C5.08925 10.9167 5.00894 11.5 5.00894 12C5.00894 12.5 5.08925 13.0833 5.16961 13.5833L3 15.9167L4.60715 18.5833L7.58034 17.5833C8.2232 18.0833 8.94637 18.5 9.74995 18.8333L10.4731 22H13.5268L14.25 18.8333C15.0536 18.5833 15.7768 18.1667 16.4197 17.5833L19.3928 18.5833L21 15.9167L18.8304 13.5833C18.9108 13.0833 18.9911 12.5833 18.9911 12ZM12 15.6667C10.0714 15.6667 8.46415 14 8.46415 12C8.46415 10 10.0714 8.33332 12 8.33332C13.9286 8.33332 15.5357 10 15.5357 12C15.5357 14 13.9286 15.6667 12 15.6667Z"
        fill={`${theme.palette.text.primary} !important`}
      />
    </SvgIcon>
  )
}

export default SideSettingsIcon
