import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Tooltip from '@material-ui/core/Tooltip'

import useUser from 'user-module/user/core/useUser'
import LiveStreamIcon from 'assets/icons/navigation/LiveStreamIcon'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import { apiCameraPlans } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.types'
import formatStreamingTime from 'video-module/live-streaming/ui/streamingTime/streamingTimeFormatter'
import InformationBarElement from 'photo-module/photos/ui/photos-gallery/information-bar/InformationBarElement'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  text: {
    color: theme.palette.text.primary,
  },
}))

const StreamingMinutesElement = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const user = useUser()
  const camera = useSelectedCamera()
  let tooltipElement

  camera.subscription.plan.id === apiCameraPlans.empty
    ? tooltipElement = (
      <Tooltip arrow title={t('app:photo_gallery_page.add-on_warning.streaming')} enterDelay={300} leaveDelay={100}>
        <Box px={1} display="flex" alignItems="center">
          <InformationBarElement
            Icon={LiveStreamIcon}
            className={classes.text}
            text={`${(formatStreamingTime(user.addOns.sdStreamingSeconds))} ${t('app:photo_gallery_page.left')}`}
          />
        </Box>
      </Tooltip>
    )
    : tooltipElement = (
      <Tooltip arrow title={t('app:photo_gallery_page.tooltip.streaming')} enterDelay={300} leaveDelay={100}>
        <Box px={1} display="flex" alignItems="center">
          <InformationBarElement
            Icon={LiveStreamIcon}
            className={classes.text}
            text={`${(formatStreamingTime(user.addOns.sdStreamingSeconds))} ${t('app:photo_gallery_page.left')}`}
          />
        </Box>
      </Tooltip>
    )

  return camera && camera.videoCapability
    ? <> { tooltipElement } </>
    : null
}

export default StreamingMinutesElement
