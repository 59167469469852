import { Trans } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import HelpRoundedIcon from '@material-ui/icons/HelpRounded'
import { useTheme } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  activationHelpWrapper: {
    display: 'flex',
    height: '380px',
    width: '261px',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    textAlign: 'center',
    flexDirection: 'column',
  },
  grey: {
    background: theme.palette.text.primary,
  },
  helpImage: {
    minWidth: '100%',
    minHeight: '100%',
    margin: '0 !important',
  },
  paragraph: {
    padding: '0 22px',
    color: theme.palette.background.default,
  },
}))

const ActivationHelpLayout = ({ children, grey }) => {
  const classes = useStyles()

  return <div className={`${classes.activationHelpWrapper} ${grey ? classes.grey : null}`}>{ children }</div>
}

const ActivationHelp = ({ isSelected, helpImage, isCell }) => {
  const classes = useStyles()
  const theme = useTheme()

  if (!isSelected) {
    return (
      <ActivationHelpLayout grey>
        <HelpRoundedIcon fontSize="large" color="primary" />
        <p className={classes.paragraph}><Trans i18nKey="app:active_device.select_device" /></p>
      </ActivationHelpLayout>
    )
  }

  if (isCell) {
    return (
      <ActivationHelpLayout>
        <img src={helpImage} alt="" className={classes.helpImage} />
      </ActivationHelpLayout>
    )
  }

  if (!isCell) {
    return (
      <ActivationHelpLayout grey>
        <CheckCircleIcon fontSize="large" style={{ color: theme.palette.primary.success }} />
        <p className={classes.paragraph}><Trans i18nKey="app:active_device.activation_not_needed" /></p>
      </ActivationHelpLayout>
    )
  }

  return null
}

export default ActivationHelp
