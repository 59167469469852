import { useDispatch } from 'react-redux'
import filtersState from 'photo-module/filters/core/filtersState'
import camerasFilterState from 'photo-module/filters/core/cameras/camerasFilterState'

const useFiltersActions = () => {
  const dispatch = useDispatch()

  return ({
    selected: (state) => filtersState.selected(state),
    setCamerasFilter: (cameraIds) => dispatch(camerasFilterState.set(cameraIds)),
  })
}

export default useFiltersActions
