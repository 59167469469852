import { useEffect, useState } from 'react'

import Box from '@material-ui/core/Box'
import { useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useParams } from 'react-router-dom'

import FilterList from 'vosker/src/photos-module/photos-gallery/ui/filters-bar/FilterList'
import FilterDrawer from 'photo-module/filters-bar/ui/FilterDrawer'
import FiltersTopStatusBar from 'photo-module/filters-bar/ui/FiltersTopStatusBar'
import useFilterCollection from 'photo-module/filters-bar/core/filters.hooks'
import useCamera from 'camera-module/camera/core/useCamera'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginLeft: -10,
    },
  },
  button: {
    margin: '4px 10px 4px 4px',
  },
  clearIcon: {
    margin: '8px 0 -8px 0',
    '&:hover': {
      fill: theme.palette.text.secondary,
    },
  },
}))

const VoskerFiltersBar = () => {
  const { id } = useParams()
  const theme = useTheme()
  const classes = useStyles()
  const breakpoint = useMediaQuery(theme.breakpoints.down('xs'))
  const { filterCollection, selectedFilters, clearSelectedFilters, fetchFilters } = useFilterCollection()

  const [filterKeys, setFilterKeys] = useState([])
  const [filterValues, setFilterValues] = useState([])
  const camera = useCamera(id)

  useEffect(() => {
    fetchFilters()
    clearSelectedFilters()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (filterCollection) {
      setFilterKeys(Object.keys(filterCollection))
      setFilterValues(Object.values(filterCollection))
    }
  }, [filterCollection, selectedFilters])

  return (
    <Box className={classes.container}>
      <FiltersTopStatusBar />

      { !breakpoint
        ? <FilterList filterKeys={filterKeys} filterValues={filterValues} camera={camera} />
        : <FilterDrawer filterKeys={filterKeys} filterValues={filterValues} FilterList={FilterList} /> }

      { /* TODO: Enable this part for filter 2.0 (when we'll have categories)
      { !breakpoint && (
        <Box pl={2} display="flex" flexDirection="row" alignItems="center">
          { selectedFilters && selectedFilters.map(filter => (
            <Button key={filter} className={classes.button} color="secondary" variant="outlined" disableElevation onClick={() => deleteSelectedFilter(filter)}>
              <ClearFiltersIcon className={classes.clearIcon} />
              { t('app:filters.tags.' + filter) }
            </Button>
          )) }
        </Box>
      ) }
      */ }
    </Box>
  )
}

export default VoskerFiltersBar
