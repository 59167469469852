import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme) => ({
  registerationPageWrapper: {
    display: 'flex',
    flexGrow: '1',
    paddingTop: '24px',
    flexDirection: 'column',
    maxWidth: '376px',
    paddingBottom: '24px',
    [theme.breakpoints.only('xs')]: {
      margin: '10px',
      marginTop: 0,
      paddingTop: 0,
    },
  },
}))

const LoginRegisterWrapper = ({ children }) => {
  const classes = useStyles()

  return (
    <Box className={classes.registerationPageWrapper}>
      { children }
    </Box>
  )
}

export default LoginRegisterWrapper
