import { useEffect } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import usePhoto from 'photo-module/photo/core/usePhoto'
import apm from 'shared-module/monitoring/apm'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import usePhotoError from 'photo-module/photo/core/usePhotoError'
import messageActions from 'shared-module/message/messageActions'

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background?.viewer,
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    userSelect: 'none',
  },
}))

const PhotoViewer = ({ ActionsBar, PhotoViewerContainer }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { cameraId, photoId } = useParams()
  const photo = usePhoto(cameraId, photoId)
  const photoError = usePhotoError()
  const selectedCamera = useSelectedCamera()

  useEffect(() => {
    if (selectedCamera) {
      apm.log('photo-viewer', [{ cameraModel: selectedCamera.status.model }])
    }
  }, [selectedCamera, photoId])

  useEffect(() => {
    dispatch(messageActions.showError(t(photoError)))
  }, [photoError, dispatch, t])

  if (photoError) {
    return <Redirect push to={`/camera/${cameraId}`} />
  }

  return (photo
    ? (
      <div className={classes.root}>
        <ActionsBar photo={photo} />
        <PhotoViewerContainer photo={photo} />
      </div>
      )
    : null
  )
}

export default PhotoViewer
