export const OPEN_FREE_TRIAL_MODAL = 'OPEN_FREE_TRIAL_MODAL'
export const CLOSE_FREE_TRIAL_MODAL = 'CLOSE_FREE_TRIAL_MODAL'

export const openFreeTrialModal = camera => ({
  type: OPEN_FREE_TRIAL_MODAL,
  camera,
})

export const closeFreeTrialModal = () => ({
  type: CLOSE_FREE_TRIAL_MODAL,
})
