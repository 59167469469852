import React from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import SubmitButton from 'form-module/ui/inputs/SubmitButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import VoskerPrimaryButton from 'vosker/src/components/buttons/VoskerPrimaryButton'

const useStyles = makeStyles({
  button: {
    width: '5.625rem',
    minHeight: '2.3rem',
  },

})
const CameraSubmission = ({ isNewUi }) => {
  const { t } = useTranslation()
  const { values, initialValues } = useFormikContext()
  const classes = useStyles()
  const disabled = values === initialValues
  const Button = isNewUi ? VoskerPrimaryButton : SubmitButton

  return (
    <Button color="primary" disabled={disabled} type="submit">
      { t('add_camera.create') }
    </Button>
  )
}

export default CameraSubmission
