import axios from 'axios'
import jwtToken from 'shared-module/token/jwtToken'

const restapi = axios.create()

restapi.interceptors.request.use((config) => {
  config.baseURL = window.location.origin.replace('webapp', 'restapi')
  config.headers.post['Content-Type'] = 'application/json'

  const token = jwtToken.get()
  if (token) {
    config.headers.Authorization = `bearer ${token}`
  }

  return config
})

export default restapi
