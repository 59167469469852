import { CardElement } from '@stripe/react-stripe-js'

import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import useTheme from '@material-ui/core/styles/useTheme'
import FormHelperText from '@material-ui/core/FormHelperText'

import WarningIcon from 'assets/icons/navigation/WarningIcon.js'

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: '0.5rem',
    paddingTop: '0.75rem',
    paddingBottom: '0.75rem',
    marginBottom: '0.25rem',
    borderRadius: '0.375rem',
  },
  helper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: `${theme.palette.status?.error} !important`,
  },
  icon: {
    width: '1rem',
    marginRight: '0.313rem',
  },
}))

const CreditCardField = ({ error, onChange, setFocusOnCardElement }) => {
  const theme = useTheme()
  const classes = useStyles()
  const bgColor = error ? theme.palette.status?.error : theme.palette.border?.primary

  const options = {
    style: {
      base: {
        iconColor: theme.palette.text.secondary,
        color: theme.palette.text.primary,
        fontFamily: 'exo2-regular',
        fontSize: '14px',
        '::placeholder': {
          color: theme.palette.text.secondary,
        },
      },
      invalid: {
        iconColor: theme.palette.text.secondary,
        color: theme.palette.text.primary,
      },
    },
  }

  return (
    <>
      <Box border={1.5} borderColor={bgColor} className={classes.root}>
        <CardElement
          id="StripeCardElement"
          options={options}
          onChange={onChange}
          onFocus={setFocusOnCardElement}
          onBlur={() => setFocusOnCardElement(false)}
        />
      </Box>

      { error && (
        <FormHelperText error className={classes.helper}>
          <WarningIcon className={classes.icon} />
          { error }
        </FormHelperText>
      ) }
    </>
  )
}

export default CreditCardField
