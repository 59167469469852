import { useState } from 'react'
import Select from '../inputs/Select'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'

const AlertSelect = ({
  alert,
  ...selectProps
}) => {
  const [alertContent, setAlertContent] = useState()

  const close = () => setAlertContent(undefined)

  const onChange = event => {
    const newValue = event.target.value
    if (alert.options[newValue]) {
      setAlertContent(alert.options[newValue])
    }
  }

  return (
    <>
      <Select
        onChange={onChange}
        {...selectProps}
      />
      <ConfirmationDialog
        open={!!alertContent}
        onClose={close}
        title={alert?.title}
        text={alertContent}
        onConfirm={close}
        confirmText={alert.buttonLabel?.ok}
      />
    </>
  )
}

export default AlertSelect
