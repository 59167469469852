export class PaymentHelper {
  // Use this array to fallback to default error
  static validStripeErrors = ['card_declined', 'expired_card', 'incorrect_cvc', 'processing_error', 'rate_limit', 'incorrect_number']
  static validStripeDeclineErrors = ['generic_decline', 'insufficient_funds']

  /**
   * Get stripe error translation key
   * @param {*} errorResponseData - Axios response data that contains Stripe errors data
   * @returns {string} - Error translation key
   */
  static getErrorTranslationKey (errorResponseData) {
    const errorKey = errorResponseData.code

    if (PaymentHelper.validStripeErrors.includes(errorKey)) {
      return `stripe_error.${errorKey}`
    }

    return 'error'
  }

  /**
   * Get stripe decline error translation key
   * Decline codes: ['fraudulent', 'generic_decline', 'insufficient_funds', 'lost_card', 'stolen_card']
   * @param {*} errorResponseData - Axios response data that contains Stripe errors data
   * @returns {string} - Error translation key
   */
  static getDeclineErrorTranslationKey (errorResponseData) {
    const declineErrorKey = errorResponseData.decline_code

    if (declineErrorKey) {
      // Stripe advices to send a generic_decline error instead of major errors
      return PaymentHelper.validStripeDeclineErrors.includes(declineErrorKey) ? `stripe_error.${declineErrorKey}` : 'stripe_error.generic_decline'
    }

    return ''
  }
}

export const getStripeTranslatedError = (data, t) => {
  const statusError = data.error || data
  const paymentTextKey = PaymentHelper.getErrorTranslationKey(statusError)
  const declineErrorKey = PaymentHelper.getDeclineErrorTranslationKey(statusError)

  return declineErrorKey ? t(`billing.payment_status_message.${declineErrorKey}`) : t(`billing.payment_status_message.${paymentTextKey}`)
}
