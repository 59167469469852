import Grid from '@mui/material/Grid'
import videoState from 'video-module/video-clip/core/videoState'
import downloadStatus from 'photo-module/download/downloadStatus'
import useSelectedPhoto from 'photo-module/photo/core/useSelectedPhoto'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import FullHdVideoPendingButton from 'video-module/video-clip/ui/full-hd-video-status-button/buttons/FullHdVideoPendingButton'
import FullHdVideoAvailableButton from 'video-module/video-clip/ui/full-hd-video-status-button/buttons/FullHdVideoAvailableButton'
import FullHdVideoUnavailableButton from 'video-module/video-clip/ui/full-hd-video-status-button/buttons/FullHdVideoUnavailableButton'
import FullHdVideoRequestableButton from 'video-module/video-clip/ui/full-hd-video-status-button/buttons/FullHdVideoRequestableButton'

const FullHdVideoButton = ({ shouldPrioritizeHDVideoRequest }) => {
  const photo = useSelectedPhoto()
  const camera = useSelectedCamera()
  const isAStream = videoState.isAStream(photo)

  if (isAStream || (camera && !camera.videoCapability)) {
    return null
  }

  const iconByState = {
    [downloadStatus.requestable]: <FullHdVideoRequestableButton />,
    [downloadStatus.pending]: <FullHdVideoPendingButton />,
    [downloadStatus.available]: <FullHdVideoAvailableButton />,
    [downloadStatus.unavailable]: <FullHdVideoUnavailableButton />,
    [downloadStatus.error]: <FullHdVideoRequestableButton />,
  }

  return camera?.videoCapability && shouldPrioritizeHDVideoRequest ? <Grid item xs="auto">{ iconByState[photo.hdVideoStatus] }</Grid> : null
}

export default FullHdVideoButton
