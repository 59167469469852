import restapi from 'shared-module/api/restapi'
import handleError from 'shared-module/api/apiError'

const getStripePublicKey = () => restapi
  .get('/api/v3/paymentservice/publickey')
  .then(payload => payload.data.key)
  .catch(handleError)

const getUserPaymentMethods = () => restapi
  .get('/api/v3/paymentservice/paymentMethods')
  .then(payload => payload.data)
  .catch(handleError)

const updateUserDefaultPaymentMethods = (token) => restapi
  .put('/api/v3/user/payment-methods', { customerToken: token })
  .then(payload => payload.data)
  .catch(handleError)

const userPaymentMethodsApi = { getStripePublicKey, getUserPaymentMethods, updateUserDefaultPaymentMethods }
export default userPaymentMethodsApi
