import React, { useEffect, useState } from 'react'
import { Stage } from 'react-konva'
import { makeStyles } from '@material-ui/core/styles'
import { alpha, useTheme } from '@material-ui/core'

import * as helper from 'detection-zone-module/ui/helper/DetectionZoneHelper'
import DetectionZoneCanvasImage from './DetectionZoneCanvasImage'
import DetectionZoneCanvasEditor from './DetectionZoneCanvasEditor'
import useDetectionZone from 'detection-zone-module/core/detectionZone.hooks'
import ZoneId from 'camera-module/camera/ui/camera-settings-page/detection-zone/ZoneId'
import webview from 'shared-module/webview/webview'
import Box from '@material-ui/core/Box'
import webViewDevice from 'checkout-module/checkout/ui/shared/web-view-bridge/webViewDevice'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
    },
  },
  zoneId: ({ webview }) => ({
    alignSelf: 'start',
    paddingTop: 10,
    [theme.breakpoints.up('lg')]: {
      paddingRight: 30,
    },
    [theme.breakpoints.down('md')]: {
      width: 'auto',
      alignSelf: 'stretch',
      position: 'fixed',
      bottom: webview ? -15 : 0,
      left: 24,
      right: 24,
      zIndex: 2,
      paddingBottom: theme.spacing(2),
      transition: 'transform 0.3s ease-in-out',
    },
  }),
  zoneIdShorten: {
    [theme.breakpoints.down('md')]: {
      width: 'fit-content',
    },
  },
  zoneIdShifted: {
    transform: 'translateY(-20%)',
  },
  androidZoneIdShifted: {
    [theme.breakpoints.down('md')]: {
      top: '50%',
      transform: 'none',
    },
  },
  androidLandscapeZoneIdShifted: {
    [theme.breakpoints.down('md')]: {
      top: '15%',
      transform: 'none',
    },
  },
  landscapeZoneIdShifted: {
    transform: 'translateY(-20%)',
    [theme.breakpoints.down('md')]: {
      transform: 'translateY(5vh)',
    },
  },
  stageContainer: ({ isLandscape, isWebview, isSmallScreen }) => ({
    position: (isLandscape && (isWebview || isSmallScreen)) ? 'absolute' : 'relative',
    top: (isLandscape && (isWebview || isSmallScreen)) ? webViewDevice().isiOSAppWebview ? '1.875rem' : 3 : 0,
    zIndex: (isLandscape && (isWebview || isSmallScreen)) ? 1 : 'auto',
  }),
}))

const DetectionZoneCanvas = ({ photoURL, size, anchorList, setAnchorList, setScale, saveDetectionZone, isLandscape, iosLandscapeOrientation, setInitialImageRect }) => {
  const theme = useTheme()
  const isSmallScreen = window.innerWidth < theme.breakpoints.values.md
  const isWebview = webview.isWebview()
  const isMobileWebBrowser = window.navigator.userAgent.toLowerCase().includes('mobi')
  const classes = useStyles({ isLandscape, iosLandscapeOrientation, isWebview, isSmallScreen, isMobileWebBrowser })

  const { anchorList: anchorsInState, isEditing, saveAnchorList, setInitialAnchorList } = useDetectionZone()

  const [allowsNextUpdate, setAllowsNextUpdate] = useState(true)
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 })
  const [imageRect, setImageRect] = useState({ x: 0, y: 0, width: 0, height: 0 })
  const [stageOptions, setStageOptions] = useState({ width: 0, height: 0, scale: 1 })
  const [isZoneIdFocused, setIsZoneIdFocused] = useState(false)

  useEffect(() => {
    allowsNextUpdate && updateMeasurements()

    if (!allowsNextUpdate) {
      setAnchorList(anchorsInState)
      setAllowsNextUpdate(true)
    }

    setScale(stageOptions.scale)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size, imageSize, stageOptions.scale, anchorsInState])

  const updateMeasurements = () => {
    let newAnchorList = []
    let newStageOptions = helper.addGuttersToStage(helper.getNormalStageOptions(imageSize))
    let newImageRect = helper.getNormalImageRect(imageSize)

    if (helper.isPortraitView(size, imageSize)) {
      newStageOptions = helper.getPortraitStageOptions(imageSize, size.height)
    } else if (helper.isLandscapeView(size, imageSize)) {
      newStageOptions = helper.getLandscapeStageOptions(imageSize, size.width)
    }

    if (newStageOptions.scale < 1) {
      newImageRect = helper.getCustomImageRect(newStageOptions)
    }

    if (newStageOptions.scale === 1) {
      setAnchorList(anchorsInState)
      setStageOptions(newStageOptions)
      setImageRect(newImageRect)

      return
    }

    if (anchorList.length !== 0) {
      newAnchorList = helper.translateAnchorList(anchorList, imageRect, newImageRect, newStageOptions)
      setInitialAnchorList(newAnchorList)
    } else if (anchorsInState && anchorsInState.lenght !== 0) {
      newAnchorList = helper.translateAnchorList(anchorsInState, imageRect, newImageRect, newStageOptions)
      newAnchorList = helper.translateAnchorList(newAnchorList, { x: 0, y: 0, width: 0, height: 0 }, newImageRect, newStageOptions)
      saveAnchorList(newAnchorList)
      setInitialAnchorList(newAnchorList)
      setAllowsNextUpdate(false)
      return
    }

    setAnchorList(newAnchorList)
    setStageOptions(newStageOptions)
    setImageRect(newImageRect)
  }

  const imageLoaded = (size) => {
    const initialImageRect = helper.getNormalImageRect(size)
    setInitialImageRect(initialImageRect)
    setImageSize(size)
    setImageRect(initialImageRect)
  }

  const handleZoneIdFocus = () => setIsZoneIdFocused(true)
  const handleZoneIdBlur = () => setIsZoneIdFocused(false)

  const userAgent = window.navigator.userAgent.toLowerCase()
  const android = /android/.test(userAgent)

  const zoneIdClasses = `${classes.zoneId} 
  ${(isZoneIdFocused && isWebview) && classes.zoneIdShifted} 
  ${(isZoneIdFocused && isWebview && android && !isLandscape) && classes.androidZoneIdShifted} 
  ${(isZoneIdFocused && isWebview && android && isLandscape) && classes.androidLandscapeZoneIdShifted} 
  ${(isZoneIdFocused && isWebview && isLandscape) && classes.landscapeZoneIdShifted} 
  ${isLandscape && !isZoneIdFocused && classes.zoneIdShorten}`

  return (
    <Box className={classes.container}>
      <div className={zoneIdClasses}>
        <ZoneId
          saveDetectionZone={saveDetectionZone}
          onFocus={handleZoneIdFocus}
          onBlur={handleZoneIdBlur}
          isLandscape={isLandscape}
        />
      </div>
      <div className={classes.stageContainer}>
        <Stage width={stageOptions.width} height={stageOptions.height}>
          <DetectionZoneCanvasImage updateImageSize={setImageSize} photoURL={photoURL} size={imageRect} imageLoaded={imageLoaded} />
          <DetectionZoneCanvasEditor
            imageRect={imageRect}
            anchorList={anchorList}
            updateAnchorList={setAnchorList}
            isEditing={isEditing}
            theme={theme}
            alpha={alpha}
            imageSize={imageSize}
          />
        </Stage>
      </div>
    </Box>
  )
}

export default DetectionZoneCanvas
