import { useTranslation } from 'react-i18next'
import useSelectedPhoto from 'photo-module/photo/core/useSelectedPhoto'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'
import useVideoActions from 'video-module/video-clip/core/useVideoActions'
import downloadStatus from 'photo-module/download/downloadStatus'

const DialogFullHdVideoRequest = ({ open, onClose }) => {
  const { t } = useTranslation()
  const actions = useVideoActions()
  const photo = useSelectedPhoto()
  const camera = useSelectedCamera()

  const acknowledgeRequest = async () => {
    onClose()

    if (photo.hdVideoStatus === downloadStatus.error) {
      await actions.clearHdVideo(photo.cameraId, photo.id)
    }

    actions.requestHdVideo(camera.id, photo.id)
  }

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      title={t('photo_viewer.request_full_hd_video.title')}
      text={t('photo_viewer.request_full_hd_video.text', { remaining: (camera?.subscription.videoLimit - camera?.subscription.videoCount) })}
      confirmText={t('photo_viewer.request_full_hd_video.confirm')}
      onConfirm={acknowledgeRequest}
      onCancel={onClose}
      isNewUi
    />
  )
}

export default DialogFullHdVideoRequest
