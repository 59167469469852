// React Core imports

import useTheme from '@material-ui/core/styles/useTheme'

const IconNoPhoto = props => {
  const theme = useTheme()

  const styleIcon = {
    display: 'block',
    margin: '0 auto',
    background: theme.palette.text.primary,
  }

  return (
    <svg
      id="no-photo"
      width={props.width}
      height={props.height}
      viewBox="0 0 100 100"
      style={styleIcon}
    >
      <rect
        id="frame"
        style={{
          fill: 'none',
          stroke: theme.palette.background.light,
          strokeWidth: '4px',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
        }}
        width="60"
        height="45"
        x="16"
        y="26"
      />
      <path
        id="mountains"
        style={{
          fill: theme.palette.background.light,
          fillOpacity: 1,
          stroke: 'none',
        }}
        d="m 21,54 v 12 h 50 v -15 l -15,-15 -15,20 -10,-10 z"
      />
      <circle
        id="sun"
        style={{
          fill: theme.palette.background.light,
          fillOpacity: 1,
          stroke: 'none',
          strokeWidth: '1.8px',
          strokeLinecap: 'butt',
          strokeLinejoin: 'round',
          strokeOpacity: 1,
        }}
        cx="31"
        cy="38"
        r="5"
      />
      { props.notfound && (
        <path
          id="line-shadow"
          style={{
            fill: 'none',
            stroke: theme.palette.text.primary,
            strokeWidth: '4px',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeOpacity: 1,
          }}
          d="m 21,17 c 0.5,0.2 50,67 50,67"
        />
      ) }
      { props.notfound && (
        <path
          id="line-front"
          style={{
            fill: 'none',
            stroke: theme.palette.background.light,
            strokeWidth: '3px',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeOpacity: 1,
          }}
          d="m 23,15 c 0.5,0.2 50,67 50,67"
        />
      ) }
    </svg>
  )
}

IconNoPhoto.defaultProps = {
  width: '120px',
  height: '120px',
  notfound: false,
}

export default IconNoPhoto
