import { useEffect } from 'react'

const addTag = name => {
  const tag = document.createElement('meta')
  tag.name = name
  document.getElementsByTagName('head')[0].appendChild(tag)
}

const tag = name => document.querySelector(`meta[name="${name}"]`)

const Meta = ({ name, content }) => {
  useEffect(() => {
    if (!tag(name)) { addTag(name) }

    tag(name).content = content
  }, [name, content])
  return null
}

export default Meta
