import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const DayNightIcon = () => {
  const theme = useTheme()

  return (
    <SvgIcon style={{ width: 32, height: 32, marginTop: 6 }} data-testid="DayNightIcon">
      <path
        d="m8.172 3.402.004 2.348a4.292 4.292 0 0 0-1.563.02V3.402Zm-3.195 3.07-1.692-1.69-1.101 1.1L3.617 7.32l.238.239v-.004l.008.008a4.06 4.06 0 0 1 1.114-1.09ZM.832 9.216H3.23a4.305 4.305 0 0 0 0 1.558H2.3c-.054.004-.109.004-.163.004H.84v-.004H.832Zm5.781 4.976v2.395h1.559v-2.379c-.219.04-.457.066-.715.066-.289 0-.57-.027-.844-.082Zm1.969-1.328A3.095 3.095 0 0 1 4.363 9.98a3.093 3.093 0 0 1 4.23-2.878 6.233 6.233 0 0 0-.011 5.761Zm-6.398 1.239L3.87 12.41a4.082 4.082 0 0 0 1.094 1.113l-1.68 1.68Zm6.898-4.106a5.043 5.043 0 1 1 10.086 0 5.043 5.043 0 0 1-10.086 0Zm0 0"
        stroke="none"
        fillRule="evenodd"
        fill={theme.palette.text.primary}
        fillOpacity={1}
      />
    </SvgIcon>
  )
}

export default DayNightIcon
