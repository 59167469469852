import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import { getTranslatedSectionTitle } from './helpers/cameraSettings.helper'

const useStyles = makeStyles((theme) => ({
  sectionContent: {
    padding: 24,
    margin: '10px 24px 24px 24px',
    [theme.breakpoints.down('sm')]: {
      padding: 8,
      marginLeft: 8,
      marginRight: 8,
    },
    '& .MuiInputAdornment-root  .MuiSvgIcon-root': {
      color: theme.palette.basic?.white,
    },
  },
  paper: {
    boxShadow: 'none',
  },
}))

const CameraSettingsSection = ({ sectionKey, children, isNewUi }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const hasChildren = () => {
    if (!children) {
      return false
    }
    return !(Array.isArray(children) && children.filter(body => !!body).length === 0)
  }

  if (!hasChildren()) {
    return null
  }

  return (
    <>
      <Typography variant={isNewUi ? 'label' : 'h2'}>
        { getTranslatedSectionTitle(t, sectionKey) }
      </Typography>
      <Paper className={classes.paper} elevation={1}>
        <Box className={classes.sectionContent}>
          { children }
        </Box>
      </Paper>
    </>
  )
}

export default CameraSettingsSection
