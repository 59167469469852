import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'

import CameraMessage from '../camera-message/CameraMessage'
import { formatDate, getHoursDiffBetweenDates } from 'shared-module/common-utils'

const useStyles = makeStyles(theme => ({
  link: {
    '&.MuiLink-root': {
      color: theme.palette.text.primary,
      fontFamily: theme.typography.caption1?.fontFamily,
      fontSize: theme.typography.caption1?.fontSize,
    },
  },
  linkIcon: {
    width: 4,
    marginLeft: 4,
  },
}))

const CameraExpirationNotice = ({ camera }) => {
  const history = useHistory()
  const { t, i18n } = useTranslation()
  const classes = useStyles()

  if (camera?.hasFreePlan || camera?.subscription?.isAutoRenew || camera?.isCompletingTrial) {
    return null
  }

  const hasPlan = camera.subscription?.plan?.id !== 'Empty'
  const endDate = camera.subscription?.endDateBillingCycle
  const hoursLeftBeforeExpiration = getHoursDiffBetweenDates(Date.now(), endDate)

  const choosePlanButton = (
    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
      <Link component="span" underline="always" noWrap className={classes.link}>
        { t('no_plan_expiration.chooseAPlanButton') }
      </Link>
    </Box>
  )

  const onClick = () => {
    history.push(`/camera/${camera.id}/plan`)
  }

  if (!hasPlan || hoursLeftBeforeExpiration <= 0) {
    return (
      <CameraMessage
        severity="error"
        onClick={onClick}
        title={t('no_plan_expiration.expired')}
        showNotificationArrow
      >
        { choosePlanButton }
      </CameraMessage>
    )
  }

  if (hoursLeftBeforeExpiration <= 24) {
    return (
      <CameraMessage
        severity="error"
        onClick={onClick}
        message={t('no_plan_expiration.expLessThan24h')}
        showNotificationArrow
      >
        { choosePlanButton }
      </CameraMessage>
    )
  }

  if (hoursLeftBeforeExpiration <= 72) {
    return (
      <CameraMessage
        severity="warning"
        onClick={onClick}
        message={t('no_plan_expiration.exp3Days', { endDate: formatDate(endDate, i18n) })}
        showNotificationArrow
      >
        { choosePlanButton }
      </CameraMessage>
    )
  }

  return null
}

export default CameraExpirationNotice
