import { Layer, Image } from 'react-konva'
import { useState, useEffect } from 'react'

import { GUTTER_SIZE } from './helper/DetectionZoneHelper'

const DetectionZoneCanvasImage = ({ photoURL, size, imageLoaded }) => {
  const [backgroundImage, setBackgroundImage] = useState(null)

  useEffect(() => {
    if (!photoURL) return

    const image = new window.Image()
    image.src = photoURL
    image.addEventListener('load', () => {
      setBackgroundImage(image)
      imageLoaded({ width: image.width, height: image.height })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoURL])

  return (
    <Layer x={GUTTER_SIZE} y={GUTTER_SIZE}>
      <Image width={size.width} height={size.height} image={backgroundImage} />
    </Layer>
  )
}

export default DetectionZoneCanvasImage
