const GUTTER_SIZE = 15

const getInitialDetectionZoneAnchorList = (imageRect) => {
  if (!imageRect) {
    return []
  }

  const zoneSize = Math.round(imageRect.width / 3)
  const zoneCenter = Math.round(zoneSize / 2)

  const startX = Math.round(imageRect.width / 2) - zoneCenter + imageRect.x
  const startY = Math.round(imageRect.height / 2) - zoneCenter + imageRect.y

  return [
    { x: startX, y: startY },
    { x: startX + zoneCenter, y: startY },
    { x: startX + zoneSize, y: startY },
    { x: startX + zoneSize, y: startY + zoneCenter },
    { x: startX + zoneSize, y: startY + zoneSize },
    { x: startX + zoneCenter, y: startY + zoneSize },
    { x: startX, y: startY + zoneSize },
    { x: startX, y: startY + zoneCenter },
  ]
}

const validateMovementInStage = (position, imageRect) => {
  const validatedPosition = { ...position }

  if (position.x > imageRect.width + imageRect.x) {
    validatedPosition.x = imageRect.width + imageRect.x - 1
  } else if (position.x < imageRect.x) {
    validatedPosition.x = imageRect.x
  }

  if (position.y > imageRect.height + imageRect.y) {
    validatedPosition.y = imageRect.height + imageRect.y - 1
  } else if (position.y < imageRect.y) {
    validatedPosition.y = imageRect.y
  }

  return validatedPosition
}

const translateAnchorListForExport = (list, imageSize, scale) => {
  if (scale !== 1) {
    return list.map((anchor) => {
      return { x: Math.round((anchor.x - GUTTER_SIZE) / scale), y: Math.round((anchor.y - GUTTER_SIZE) / scale) }
    })
  } else {
    return list.map((anchor) => {
      return { x: anchor.x - GUTTER_SIZE, y: anchor.y - GUTTER_SIZE }
    })
  }
}

const translateAnchorList = (list, previousImageRect, imageRect, landscape) => {
  let scale = 1

  if (landscape) {
    scale = imageRect.width / previousImageRect.width
  } else {
    scale = imageRect.height / previousImageRect.height
  }

  return list.map((anchor) => {
    let newX = Math.round(anchor.x * scale)
    let newY = Math.round(anchor.y * scale)

    if (anchor.x === previousImageRect.x) {
      newX = imageRect.x
    } else if (newX >= imageRect.width) {
      newX = imageRect.width - 1 + imageRect.x
    }

    if (anchor.y === previousImageRect.y) {
      newY = imageRect.y
    } else if (newY >= imageRect.height) {
      newY = imageRect.height - 1 + imageRect.y
    }

    return { x: newX, y: newY }
  })
}

const addGuttersToStage = (stageOptions) => {
  return {
    ...stageOptions,
    width: stageOptions.width + GUTTER_SIZE * 2,
    height: stageOptions.height + GUTTER_SIZE * 2,
  }
}

const getNormalStageOptions = (imageSize) => {
  return { width: imageSize.width, height: imageSize.height, scale: 1 }
}

const getLandscapeStageOptions = (imageSize, canvasHeight) => {
  const newScale = (canvasHeight - GUTTER_SIZE) / imageSize.height
  const newStageWidth = Math.round(imageSize.width * newScale)
  return {
    scale: newScale,
    width: newStageWidth,
    height: canvasHeight - GUTTER_SIZE * 2,
    landscape: true,
  }
}

const getPortraitStageOptions = (imageSize, canvasWidth) => {
  const newScale = canvasWidth / imageSize.width
  const newStageHeight = Math.round(imageSize.height * newScale)
  return { scale: newScale, width: canvasWidth, height: newStageHeight }
}

const getNormalImageRect = (imageSize) => {
  return {
    x: GUTTER_SIZE,
    y: GUTTER_SIZE,
    width: imageSize.width,
    height: imageSize.height,
  }
}

const getCustomImageRect = (stageOptions) => {
  return {
    x: GUTTER_SIZE,
    y: GUTTER_SIZE,
    width: stageOptions.width,
    height: stageOptions.landscape ? stageOptions.height + GUTTER_SIZE : stageOptions.height,
  }
}

export {
  getInitialDetectionZoneAnchorList,
  validateMovementInStage,
  translateAnchorListForExport,
  getNormalStageOptions,
  getPortraitStageOptions,
  getLandscapeStageOptions,
  getNormalImageRect,
  getCustomImageRect,
  addGuttersToStage,
  translateAnchorList,
  GUTTER_SIZE,
}
