import { useState, useEffect, useLayoutEffect, useRef } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'

import PrimaryButton from 'shared-module/components/button/PrimaryButton'
import ExportButton from './ExportButton'
import DetectionZoneCanvas from './LegacyDetectionZoneCanvas'

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.default,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    userSelect: 'none',
  },
  hidden: {
    display: 'none',
  },
}))

const LegacyDetectionZonePage = () => {
  const classes = useStyles()
  const photoRef = useRef()
  const canvasContainerRef = useRef()
  const [photoURL, setPhotoURL] = useState('')
  const [photoName, setPhotoName] = useState('')
  const [canvasContainerSize, setCanvasContainerSize] = useState({ width: 0, height: 0 })

  const handleWindowResize = (event) => {
    if (!canvasContainerRef.current) return
    const rect = canvasContainerRef.current.getBoundingClientRect()
    setCanvasContainerSize({ width: rect.width, height: rect.height })
  }

  useLayoutEffect(() => {
    handleWindowResize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasContainerRef.current])

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  const onChangePhoto = (event) => {
    const objectURL = URL.createObjectURL(event.target.files[0])
    setPhotoName(event.target.files[0].name)
    setPhotoURL(objectURL)
  }

  const uploadImage = () => photoRef.current.click()

  return (
    <Dialog fullScreen open PaperProps={{ style: { padding: 0 } }}>
      <div className={classes.root}>
        <Box component="div" display="flex" mb={2}>
          <Box component="div" mr={2}>
            <PrimaryButton value="import" onClick={uploadImage} />
          </Box>

          <ExportButton photoName={photoName} disabled={!photoURL} />

          <input ref={photoRef} type="file" onChange={onChangePhoto} className={classes.hidden} accept="image/*" />
        </Box>
        <Box
          ref={canvasContainerRef}
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100vw"
          flexGrow={1}
          overflow="hidden"
        >
          <DetectionZoneCanvas photoURL={photoURL} size={canvasContainerSize} />
        </Box>
      </div>
    </Dialog>
  )
}

export default LegacyDetectionZonePage
