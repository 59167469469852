import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({ bold: { fontWeight: 'bold' } })

const TextSmallBold = ({ variant = 'label', children, ...props }) => {
  const classes = useStyles()

  return (
    <Typography {...props} variant={variant} className={clsx(props.className, classes.bold)}>
      { children }
    </Typography>
  )
}

export default TextSmallBold
