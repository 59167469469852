import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { FORBIDDEN } from 'shared-module/api/httpStatusCode'
import cameraApi from 'camera-module/camera/api/cameraApi'
import messageActions from 'shared-module/message/messageActions'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import VoskerCameraActivationForm from 'vosker/src/camera-module/camera/ui/add-camera-form/VoskerCameraActivationForm'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'
import useCameraModels from 'camera-module/model/core/useCameraModels'

const useStyles = makeStyles({
  dialog: {
    maxWidth: '820px',
    margin: 'auto',
  },
  title: {
    padding: '2px 24px 16px 0',
  },
})

const VoskerCameraActivationPage = ({ onClose, onAddCameraSuccess }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const cameraModels = useCameraModels()
  const simToMigrateActions = useConfirmationDialogActions()
  const [isOpenAddCameraDialog, setIsOpenAddCameraDialog] = useState(false)

  const toggleAddCamera = () => setIsOpenAddCameraDialog(!isOpenAddCameraDialog)

  const handleError = (error) => {
    if (error.response?.status === FORBIDDEN) {
      dispatch(messageActions.showError(t('add_camera.error.camera_already_exist')))
    } else if (error.response?.data.message === 'sim-to-migrate') {
      simToMigrateActions.open()
    } else dispatch(messageActions.showError())
  }

  const handleStateChange = (data) => {
    const newCamera = { name: data?.deviceName, modelName: data?.deviceModel, ucid: data?.activationCode.replace(/-/g, '') }

    cameraApi
      .add(newCamera)
      .then((camera) => {
        onClose()
        onAddCameraSuccess(t('add_camera.camera_created'), camera)
      })
      .catch(error => handleError(error))
  }

  return (
    <Dialog open={!!cameraModels} onClose={toggleAddCamera} maxWidth={false} className={classes.dialog}>
      <DialogContent>

        <DialogTitle className={classes.title}>{ t('add_camera.title') }</DialogTitle>

        { cameraModels && <VoskerCameraActivationForm onSubmit={handleStateChange} onClose={onClose} /> }

      </DialogContent>
    </Dialog>
  )
}

export default VoskerCameraActivationPage
