import SvgIcon from '@material-ui/core/SvgIcon'

const ClearFiltersIcon = (props) => {
  return (
    <SvgIcon {...props} data-testid="ClearFiltersIcon">
      <path d="M9.36575 8.04167L6.33192 5.00463L9.375 1.95833L8.04308 0.625L5 3.6713L1.95692 0.625L0.625 1.95833L3.66808 5.00463L0.634249 8.04167L1.96617 9.375L5 6.33796L8.03383 9.375L9.36575 8.04167Z" />
    </SvgIcon>
  )
}

export default ClearFiltersIcon
