import * as actions from './notifications.actions'
import notificationState from './notificationState'

const reducer = (state = notificationState.defaultState, action) => {
  switch (action.type) {
    case actions.SET_NOTIFICATIONS:
      return notificationState.set(state, action)
    case actions.SORT_NOTIFICATION:
      return notificationState.sort(state)
    case actions.MARK_AS_READ:
      return notificationState.isRead(state, action)

    default:
      return state
  }
}

export default reducer
