import * as Yup from 'yup'
import INPUT_TYPES from '../../core/cameraSettingsInputTypes'

const requiredFieldTypes = [
  INPUT_TYPES.TEXT,
  INPUT_TYPES.NUMBER,
  INPUT_TYPES.DURATION,
  INPUT_TYPES.TIME,
]

export default settings => () => {
  const rules = {}

  Object.values(settings).flat().forEach(settingInput => {
    rules[settingInput?.name] = Yup.mixed()
    if (requiredFieldTypes.includes(settingInput?.type)) {
      rules[settingInput?.name] = rules[settingInput?.name].required()
    }
  })

  return Yup.object().shape(rules)
}
