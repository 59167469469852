import { useTranslation } from 'react-i18next'
import TimeField from './TimeField'
import { makeStyles } from '@material-ui/core/styles'

import { TableRow, TableCell, InputLabel } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  styleTitles: {
    fontWeight: '600',
  },
  styleLabel: {
    fontWeight: '18px',
  },
  styleTableCell: {
    borderBottom: `1px solid ${theme.palette.border?.divider} !important`,
  },
}))

const SettingsScheduleDay = ({ settings, day, onChange }) => {
  const { t } = useTranslation()
  const { schedule } = settings
  const classes = useStyles()

  const handleOnChange = (time, name) => {
    const dayEvent = name
    let hour = time.split(':')[0]

    if (typeof hour === 'string' && hour.length > 0) hour = parseInt(hour)
    if (hour === '' || hour === null || hour === 'undefined') hour = 0

    if (dayEvent === 'start') {
      onChange({ index: day, schedule: [hour, settings.schedule[day][1]] })
    } else if (dayEvent === 'end') {
      onChange({ index: day, schedule: [settings.schedule[day][0], hour] })
    }
  }

  return (
    <TableRow>
      <TableCell className={classes.styleTableCell}>
        <Typography className={classes.styleTitles}>{ t(`days.${day}`) }</Typography>
      </TableCell>
      <TableCell margin="dense" className={classes.styleTableCell}>
        <InputLabel className={classes.styleLabel} shrink>
          { t('camera.settings.schedule.start') }
        </InputLabel>
        <TimeField
          dataTestId="schedule-day-start-time"
          id="schedule-day-start-time"
          name="start"
          time={{ hour: schedule[day][0] }}
          onChange={handleOnChange}
        />
      </TableCell>
      <TableCell margin="dense" className={classes.styleTableCell}>
        <InputLabel shrink>{ t('camera.settings.schedule.end') }</InputLabel>
        <TimeField id="schedule-day-end-time" dataTestId="schedule-day-end-time" name="end" time={{ hour: schedule[day][1] }} onChange={handleOnChange} />
      </TableCell>
    </TableRow>
  )
}

export default SettingsScheduleDay
