import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import { THEME_MODE_CONTEXT } from 'shared-module/ThemeContextProvider'
import { useContext } from 'react'

const useStyles = makeStyles((theme) => ({
  tooltip: {
    color: theme.palette.secondary?.dark,
    background: theme.palette.basic?.grey,
  },
  tooltip_black: {
    color: theme.palette.basic?.white,
    background: theme.palette.background?.shadow,
  },
  arrow: {
    color: theme.palette.basic?.grey,
  },
  arrow_black: {
    color: theme.palette.background?.shadow,
  },
  popper: {
    top: '-5px !important',
  },
}))

const VoskerTooltip = ({ children, title, arrow, ...props }) => {
  const classes = useStyles()
  const colorMode = useContext(THEME_MODE_CONTEXT)
  return (
    <Tooltip
      arrow={arrow}
      title={title}
      classes={{
        tooltip: colorMode.isDarkModeActive ? classes.tooltip : classes.tooltip_black,
        arrow: colorMode.isDarkModeActive ? classes.arrow : classes.arrow_black,
        popper: classes.popper,
      }}
      {...props}
    >
      { children }
    </Tooltip>
  )
}

export default VoskerTooltip
