import { useEffect, useRef, useState } from 'react'
import { useField, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import TextField from '@material-ui/core/TextField'
import { getFieldError } from '../util'
import WarningIcon from 'assets/icons/navigation/WarningIcon'
import makeStyles from '@material-ui/core/styles/makeStyles'
import PasswordHintFormik from 'vosker/src/form-module/ui/PasswordHintFormik'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import YesIcon from 'vosker/src/form-module/ui/icons/YesIcon'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import webview from 'shared-module/webview/webview'

const useStyles = makeStyles((theme) => ({
  icon: {
    width: '1rem',
    top: '0.375rem',
    position: 'relative',
    marginRight: '0.313rem',
  },
  helper: {
    marginLeft: '-0.874rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  iconColor: {
    '& .MuiSvgIcon-root': {
      fill: theme.palette.text?.secondary + ' !important',
    },
  },
  largeIcon: {
    width: '20px',
    height: '15px',
  },
}))

const PasswordInput = ({
  name,
  maxLength,
  min,
  max,
  children,
  className,
  showHints,
  showSuccessTickIcon,
  notShowSuccessColor,
  showErrorIcon = false,
  showError = true,
  successStyle,
  ...controlProps
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const inputRef = useRef()

  const [hasFocus, setHasFocus] = useState(false)
  const [changed, setChanged] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const { isSubmitting } = useFormikContext()
  const [field, meta] = useField(name)
  const error = getFieldError(meta, t)

  useEffect(() => {
    if (hasFocus) {
      webview.scrollViewForAndroidInputField(inputRef, () => setHasFocus(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFocus])

  const toggleShowPassword = () => setShowPassword(!showPassword)

  const passwordRequired = error === 'password_required'

  const helperText = error && showError && (
    <>
      <WarningIcon className={classes.icon} />
      { error }
    </>
  )

  const helperTextPassWordRequired = error && showError && changed && (
    <>
      <WarningIcon className={classes.icon} />
      { t('register.form.hints.required') }
    </>
  )

  const eyeAdornment = {
    endAdornment: (
      <>
        { !meta.error && changed && showSuccessTickIcon && successStyle && (
          <InputAdornment position="end" className={classes.largeIcon}>
            <YesIcon />
          </InputAdornment>
        ) }
        <InputAdornment position="end">
          <IconButton className={classes.iconColor} onClick={toggleShowPassword} disabled={controlProps.disabled}>
            { showPassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" /> }
          </IconButton>
        </InputAdornment>
      </>
    ),
  }

  return (
    <div ref={inputRef}>
      <TextField
        fullWidth
        variant="outlined"
        className={[className, !meta.error && changed && successStyle]}
        inputProps={{ maxLength, min, max }}
        {...controlProps}
        {...field}
        value={field.value ?? ''}
        onChange={(e) => {
          setChanged(true)
          field.onChange(e)
        }}
        type={showPassword ? 'text' : 'password'}
        InputProps={eyeAdornment}
        error={!!error && showError && changed}
        helperText={
          showErrorIcon && changed && !passwordRequired && !showHints
            ? helperText
            : showErrorIcon && passwordRequired
              ? helperTextPassWordRequired
              : ''
        }
        disabled={field.disabled || isSubmitting}
        onFocus={() => setHasFocus(true)}
      >
        { children }
      </TextField>
      { showHints && changed && meta.error && <PasswordHintFormik name={name} /> }
    </div>
  )
}

export default PasswordInput
