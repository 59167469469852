import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import enTranslation from 'assets/locales/en/translation.en.json'
import frTranslation from 'assets/locales/fr/translation.fr.json'
import enCommon from 'assets/locales/en/common.en.json'
import frCommon from 'assets/locales/fr/common.fr.json'
import deCommon from 'assets/locales/de/common.de.json'
import esCommon from 'assets/locales/es/common.es.json'
import deTranslation from 'assets/locales/de/translation.de.json'
import esTranslation from 'assets/locales/es/translation.es.json'

const generateI18N = (namespaces, appResources) => i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    ns: ['translation', 'common', ...namespaces],
    defaultNS: 'translation',

    fallbackLng: 'en',
    resources: {
      en: { translation: enTranslation, common: enCommon, ...appResources.en },
      fr: { translation: frTranslation, common: frCommon, ...appResources.fr },
      de: { translation: deTranslation, common: deCommon, ...appResources.de },
      es: { translation: esTranslation, common: esCommon, ...appResources.es },
    },
    whitelist: ['fr', 'en', 'de', 'es'],
  })

export default generateI18N
