import { useState, useEffect } from 'react'
import countriesApi from 'shared-module/countries/countriesApi'
import useLanguage from 'shared-module/components/language/useLanguage'

const useRegions = (countryCode) => {
  const language = useLanguage()
  const [regions, setRegions] = useState(null)
  const [fetching, setFetching] = useState(false)
  const [currentLanguage, setCurrentLanguage] = useState(language)
  const [currentCountryCode, setCurrentCountryCode] = useState(countryCode)

  useEffect(() => {
    const languageChanged = language !== currentLanguage
    const countryChanged = countryCode !== currentCountryCode

    if (countryCode && !fetching && (!regions || languageChanged || countryChanged)) {
      setFetching(true)
      setCurrentLanguage(language)
      setCurrentCountryCode(countryCode)
      countriesApi.getRegionsByCountryCode(countryCode, language)
        .then(list => setRegions(list))
        .finally(() => setFetching(false))
    }
  }, [regions, countryCode, fetching, currentCountryCode, language, currentLanguage])

  return regions
}

export default useRegions
