import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: '2rem',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0.375rem',
    marginTop: '1rem',
    boxShadow: 'none',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('xs')]: {
      margin: 'auto -1rem',
      padding: '2rem 1rem',
    },
  },
}))

const OrderSection = ({ children }) => {
  const classes = useStyles()

  return <Box component={Paper} className={classes.paper}>{ children }</Box>
}

export default OrderSection
