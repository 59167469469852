import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const HdShadowIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon {...props} data-testid="HdShadowIcon" height="16" width="16" viewBox="0 0 16 16">
      <g
        filter="url(#filter0_ddd)"
        fill={theme.palette.text.primary}
        clipPath="url(#clip0)"
      >
        <path d="M9.863 5.198v5.604h.773c.437 0 .699-.216.699-.815V6.013c0-.6-.262-.815-.699-.815h-.773z" />
        <path
          d="M12.716 1.5h-9.36C2.605 1.5 2 2.1 2 2.842v10.316c0 .741.607 1.342 1.355 1.342h9.361c.749 0 1.355-.6 1.355-1.342V2.842c0-.741-.606-1.342-1.355-1.342zM4.674 12H3.337V4h1.337v3.302h1.518V4h1.361v8h-1.36V8.5h-1.52V12zM8.49 4h2.17c1.372 0 2.046.73 2.046 2.072v3.856c0 1.341-.674 2.072-2.046 2.072h-2.17V4z"
          clipRule="evenodd"
          fillRule="evenodd"
        />
      </g>
      <defs id="defs48">
        <clipPath id="clip0">
          <path
            id="rect45"
            fill={theme.palette.text.primary}
            d="M0 0h16v16H0z"
          />
        </clipPath>
        <filter colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse" height="17" width="16.071" y=".5" x="0" id="filter0_ddd">
          <feFlood id="feFlood10" result="BackgroundImageFix" floodOpacity="0" />
          <feColorMatrix id="feColorMatrix12" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" type="matrix" in="SourceAlpha" />
          <feOffset id="feOffset14" dy="1" />
          <feGaussianBlur id="feGaussianBlur16" stdDeviation="1" />
          <feColorMatrix id="feColorMatrix18" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" type="matrix" />
          <feBlend id="feBlend20" result="effect1_dropShadow" in2="BackgroundImageFix" mode="normal" />
          <feColorMatrix id="feColorMatrix22" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" type="matrix" in="SourceAlpha" />
          <feOffset id="feOffset24" dy="1" />
          <feGaussianBlur id="feGaussianBlur26" stdDeviation=".5" />
          <feColorMatrix id="feColorMatrix28" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" type="matrix" />
          <feBlend id="feBlend30" result="effect2_dropShadow" in2="effect1_dropShadow" mode="normal" />
          <feColorMatrix id="feColorMatrix32" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" type="matrix" in="SourceAlpha" />
          <feOffset id="feOffset34" dy=".75" />
          <feGaussianBlur id="feGaussianBlur36" stdDeviation=".5" />
          <feColorMatrix id="feColorMatrix38" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" type="matrix" />
          <feBlend id="feBlend40" result="effect3_dropShadow" in2="effect2_dropShadow" mode="normal" />
          <feBlend id="feBlend42" result="shape" in2="effect3_dropShadow" in="SourceGraphic" mode="normal" />
        </filter>
      </defs>
    </SvgIcon>
  )
}

export default HdShadowIcon
