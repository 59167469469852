import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { alpha } from '@material-ui/core/styles/colorManipulator'

import PlayIcon from 'assets/icons/video/PlayIcon'
import videoState from 'video-module/video-clip/core/videoState'

const useStyles = makeStyles(theme => ({
  item: {
    position: 'absolute',
    left: 'calc(48% - 1rem)',
    top: 'calc(44% - 1rem)',
  },
  playIconWrapper: {
    width: '55px',
    height: '55px',
    borderRadius: '3rem',
    background: theme.palette.background?.shadow,
    backdropFilter: 'blur(0.2rem)',
    '&:hover': {
      background: theme.palette.background?.shadow,
    },
  },
  playIcon: {
    width: '3rem',
    height: '3.5rem',
    opacity: 1,
    marginLeft: '0.125rem',
    fill: theme.palette.basic?.white + ' !important',
  },
  containerPreview: {
    width: '68px',
    height: '24px',
    padding: '4px 8px 5px 8px',
    marginTop: '15.9px',
    marginLeft: '16px',
    background: alpha(theme.palette.background?.shadow, 0.7),
    borderRadius: '4px',
    alignItems: 'center',
    gap: '8px',
    position: 'absolute',
  },
  textPreview: {
    color: theme.palette.basic?.white,
    fontSize: '12px',
    fontFamily: 'Exo 2',
    fontWeight: '600',
    lineHeight: '16px',
  },
}))

const PreviewPlayOverlay = ({ photo, PlayPreview }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const viadeoPreview = videoState.hasPreview(photo)

  return viadeoPreview
    ? (
      <Grid container>
        <Grid item className={classes.containerPreview}>
          <div className={classes.textPreview}>
            { t('app:filters.tags.hdpreview').toUpperCase() }
          </div>
        </Grid>
        <Grid item className={classes.item}>
          <IconButton size="small" className={classes.playIconWrapper} onClick={PlayPreview}>
            <PlayIcon className={classes.playIcon} />
          </IconButton>
        </Grid>
      </Grid>
      )
    : null
}

export default PreviewPlayOverlay
