import { useTranslation } from 'react-i18next'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const isCardExpired = (year, month) => {
  const now = new Date()
  const expirationDate = new Date(year, month)
  return now > expirationDate
}

const CreditCardExpirationDate = ({ month, year }) => {
  const { t } = useTranslation()
  const expired = isCardExpired(year, month)
  const label = expired ? t('app:payment_information_page.fields.expired') : t('app:payment_information_page.fields.expires')

  return (
    <Typography component="div" noWrap color={expired ? 'error' : 'initial'}>
      <Box fontWeight="fontWeightBold">
        { `${label} ${month.toString().padStart(2, '0')}/${year.toString().slice(-2)}` }
      </Box>
    </Typography>
  )
}

export default CreditCardExpirationDate
