import { useDispatch, useSelector } from 'react-redux'

import * as selectors from './notifications.selectors'
import notificationActions from './notifications.actions'
import notificationState from './notificationState'

export const useNotifications = () => useSelector(selectors.getNotifications)
export const useNbUnreadNotifications = () => useSelector(selectors.getNbUnreadNotifications)

export const useCriticalNotification = (cameraId) => {
  const criticalNotifications = useSelector(selectors.getCriticalNotifications)

  return criticalNotifications.find((notification) => notification.targetId === cameraId)
}

export const useNotificationsActions = () => {
  const state = useSelector((state) => state.notifications)
  const dispatch = useDispatch()

  if (!notificationState.hasNotifications(state)) {
    dispatch(notificationActions.getAllNotifications())
  }

  return {
    getAllNotifications: () => dispatch(notificationActions.getAllNotifications()),
    sortNotification: notifications => dispatch(notificationActions.getSortedNotification(notifications)),
    markAsRead: notification => dispatch(notificationActions.setNotificationAsRead(notification)),

    // Keeps the logic in place for future usage, but remove deprecated HSPA call to action
    handleNotificationAction: (notification) => {
      switch (notification.action) {
        default:
          return () => { }
      }
    },
  }
}
