import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Switch from 'react-ios-switch'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { useTheme } from '@material-ui/core'

import paymentFrequency from 'vosker/src/order-module/order/core/paymentFrequency'
import TextSmallBold from 'vosker/src/order-module/order/ui/order-lines/components/TextSmallBold'
import TextSmall from 'vosker/src/order-module/order/ui/order-lines/components/TextSmall'
import * as helper from 'vosker/src/camera-module/transmission-plan/ui/helper/PlanHelper'
import { useLoadCameraPlans } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.hook'

const PaymentFrequencyLine = ({ camera, product, onFrequencyChange }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const plans = useLoadCameraPlans()

  const [productPlan, setProductPlan] = useState()
  const [frequencySwitchDisabled, setFrequencySwitchDisabled] = useState(false)
  const [frequencyBooleanState, setFrequencyBooleanState] = useState(product.frequency === paymentFrequency.yearly)

  useEffect(() => {
    plans && setProductPlan(plans.plans.filter(plan => plan.id === product.planId)[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plans])
  useEffect(() => {
    if (camera.subscription.paymentFrequency === 'annual') {
      setFrequencySwitchDisabled(true)
    } else {
      if (productPlan && camera && product) {
        const trial = helper.onFreePlan(camera)
        const upgradeable = camera.subscription.plan.pricePerMonthIfPaidPerMonth < productPlan.pricePerMonthIfPaidPerMonth
        setFrequencySwitchDisabled(!trial && !upgradeable)
      } else {
        setFrequencySwitchDisabled(false)
      }
    }
  }, [camera, camera?.subscription?.paymentFrequency, productPlan, product, frequencyBooleanState])

  const onChange = () => {
    setFrequencyBooleanState(!frequencyBooleanState)
    onFrequencyChange(frequencyBooleanState
      ? paymentFrequency.monthly
      : paymentFrequency.yearly)
  }

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={3} sm>
        <TextSmall>{ t('app:order_page.order_summary.frequency') }</TextSmall>
      </Grid>
      <Grid item xs={9} sm>
        <Box display="flex" flexDirection="row" justifyContent="flex-end">
          <TextSmallBold>{ t('app:order_page.order_summary.monthly') }</TextSmallBold>
          <div style={{ marginTop: '-4px' }}>
            <Switch
              offColor={theme.palette.secondary.light}
              onColor={theme.palette?.status?.info}
              checked={frequencyBooleanState}
              onChange={onChange}
              disabled={frequencySwitchDisabled}
              style={{ transform: 'scale(0.6)' }}
            />
          </div>
          <TextSmallBold>{ t('app:order_page.order_summary.yearly') }</TextSmallBold>
        </Box>
      </Grid>
    </Grid>
  )
}

export default PaymentFrequencyLine
