import { combineReducers } from 'redux'
import photoSelected from 'photo-module/photo/core/photoSelected'
import photoLoading from 'photo-module/photo/core/photoLoading'
import photoError from 'photo-module/photo/core/photoError'
import photoApi from 'photo-module/photo/api/photoApi'
import { updatePhotoInList } from 'photo-module/photos/core/photosActions'
import messageActions from 'shared-module/message/messageActions'
import hdErrorBuilder from 'photo-module/photo/api/hdErrorBuilder'
import downloadStatus from 'photo-module/download/downloadStatus'
import fixVideoClipStatus from 'photo-module/photo/core/fixVideoClipStatus'

const getPhoto = (cameraId, photoId) => (dispatch, getState) => {
  const isVideoClip = getState().selectedCamera?.camera?.videoCapability
  dispatch(photoLoading.set(true))

  return photoApi.getPhotoById(cameraId, photoId)
    .then(newPhoto => {
      fixVideoClipStatus(newPhoto, isVideoClip)
      return dispatch(photoSelected.set(newPhoto))
    })
    .catch(() => dispatch(photoError.set('photo_viewer.photo_not_found')))
}

const isPhotoReady = state => !!state.photo || state.isFetching || !!state.error

const cantDeletePhoto = photo => photo.hdStatus === downloadStatus.pending || photo.sdVideoStatus === downloadStatus.pending || photo.hdVideoStatus === downloadStatus.pending

const requestHdPhoto = (cameraId, photoId) => dispatch => photoApi.requestHdPhoto(cameraId, photoId)
  .then(() => {
    dispatch(updatePhotoInList(photoId, { hdStatus: downloadStatus.pending }))
    dispatch(messageActions.showMessage('photo_viewer.request_full_hd.success'))
  })
  .catch((error) => {
    const hdError = hdErrorBuilder(error)
    if (hdError.incompatibleCamera) {
      dispatch(messageActions.showError('photo_viewer.request_full_hd.incompatible_camera'))
    } else {
      dispatch(messageActions.showError())
    }
  })

const reducer = combineReducers({
  photo: photoSelected.reducer,
  isFetching: photoLoading.reducer,
  error: photoError.reducer,
})

const photoState = {
  getPhoto: getPhoto,
  isPhotoReady: isPhotoReady,
  cantDeletePhoto: cantDeletePhoto,
  requestHdPhoto: requestHdPhoto,
  reducer: reducer,
}
export default photoState
