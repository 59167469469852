import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  container: ({ isFreePlan }) => ({
    margin: isFreePlan ? '24px 0 0 0' : '32px 0',
    padding: '0 18px',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 16px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  }),
  item: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: '0.375rem',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up('md')]: {
      minWidth: 700,
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
  },
}))

const CardLayout = ({ isFreePlan = false, children }) => {
  const classes = useStyles({ isFreePlan })

  return (
    <Grid container className={classes.container}>
      <Grid container item xs={12} sm={9} md={2} className={classes.item}>
        { children }
      </Grid>
    </Grid>
  )
}

export default CardLayout
