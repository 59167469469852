import messageState from 'shared-module/message/messageState'
import { CLEAR_MESSAGE, SET_MESSAGE } from 'shared-module/message/messageActionsTypes'

const messageReducer = (state = messageState.defaultState, action) => {
  switch (action.type) {
    case SET_MESSAGE:
      return messageState.set(action.message, action.messageType, action.action, action.countdown)
    case CLEAR_MESSAGE:
      return messageState.clear()
    default:
      return state
  }
}

export default messageReducer
