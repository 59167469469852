import useSelectedCamera from '../../../core/useSelectedCamera'
import CameraSettingsResetDialog from '../CameraSettingsResetDialog'
import useCameraActions from '../../../core/useCameraActions'
import useMessageActions from 'shared-module/message/useMessageActions'

const FactoryResetButton = ({ open, onClose }) => {
  const camera = useSelectedCamera()
  const cameraActions = useCameraActions()
  const { showMessage } = useMessageActions()

  const updateSettings = (settings) => {
    cameraActions.updateSettings(camera, settings)
    showMessage('camera.settings.messages.delayedSave')
  }

  return open && <CameraSettingsResetDialog camera={camera} onResetSettings={updateSettings} onClose={onClose} />
}
export default FactoryResetButton
