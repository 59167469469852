import makeStyles from '@material-ui/styles/makeStyles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  message: {
    fontFamily: theme.typography.fontFamilyBold,
    color: theme.palette.text.primary,
    textAlign: 'center',
  },
  isWhiteMessage: {
    fontFamily: theme.typography.fontFamilyBold,
    color: theme.palette.basic?.white,
    textAlign: 'center',
  },
}))

const InformationMessage = ({ message, inStreamingPage }) => {
  const classes = useStyles()

  return (
    <Typography className={inStreamingPage ? classes.isWhiteMessage : classes.message}>
      { message }
    </Typography>
  )
}

export default InformationMessage
