const fonts = {
  regular: 'exo2-regular',
  semiBold: 'exo2-semibold',
  extraBold: 'exo2-extrabold',
}

export const typographies = {
  fontFamily: fonts.regular,
  fontFamilyBold: fonts.extraBold,
  fontFamilyTabs: fonts.regular,
  fontSemiBold: fonts.semiBold,
  h1: {
    fontSize: '1.125rem !important',
    fontFamily: fonts.extraBold,
    textTransform: 'uppercase',
  },
  h2: {
    fontSize: '1rem',
    fontFamily: fonts.extraBold,
    textTransform: 'uppercase',
  },
  h3: {
    fontSize: '0.75rem',
    fontFamily: fonts.extraBold,
    textTransform: 'uppercase',
  },
  h4: {
    lineHeight: '1rem',
    fontSize: '1rem !important',
    fontFamily: fonts.regular,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  h6: {
    lineHeight: '1rem',
    fontSize: '1.063rem !important',
    fontFamily: fonts.regular,
    fontWeight: 600,
  },
  button: {
    fontSize: '0.875rem',
    fontFamily: fonts.extraBold,
    textTransform: 'uppercase',
  },
  body1: {
    fontSize: '0.938rem',
    fontFamily: fonts.regular,
    fontFamilyBold: fonts.semiBold,
  },
  body2: {
    fontSize: '0.875rem',
    fontFamily: fonts.regular,
  },
  caption1: {
    fontSize: '0.75rem',
    fontFamily: fonts.regular,
    fontFamilyBold: fonts.semiBold,
  },
  caption2: {
    fontSize: '0.688rem',
    fontFamily: fonts.regular,
    fontFamilyBold: fonts.semiBold,
  },
  label: {
    fontSize: '0.875rem',
    fontFamily: fonts.regular,
  },
}
