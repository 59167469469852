import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import makeStyles from '@material-ui/core/styles/makeStyles'

import Price from 'vosker/src/camera-module/transmission-plan/ui/components/Price'
import CurrentItem from 'vosker/src/camera-module/transmission-plan/ui/components/CurrentItem'
import { getHistoryText, showCurrentPlan } from 'vosker/src/camera-module/transmission-plan/ui/helper/PlanHelper'
import { getPlanTranslationKey } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.selectors'
import CardLayout from 'vosker/src/camera-module/transmission-plan/ui/components/layout/CardLayout'

const useStyles = makeStyles(theme => ({
  titleContainer: {
    width: '100%',
    padding: '0 1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.light,
    borderRadius: '0.375rem 0.375rem 0rem 0rem',
  },
  descriptionContainer: {
    padding: '1rem 0.625rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '0 0.25rem',
  },
  currentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '0 0.5rem',
  },
  description: {
    fontSize: theme.typography.body1?.fontSize,
    fontFamily: theme.typography.body1?.fontFamily,
    padding: '0 0.25rem',
  },
}))

const PlanCard = ({ camera, plan }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const isCurrentPlan = showCurrentPlan(camera, plan, true)

  return (
    <CardLayout isFreePlan>
      <Grid item className={classes.titleContainer}>
        <Box display="inline-flex">
          <Typography variant="h4">{ t(`app:plans.${getPlanTranslationKey(plan.name)}`) }</Typography>
        </Box>

        <Price noPadding price={plan.isFree && 0} isFree />
      </Grid>

      <Grid item className={classes.descriptionContainer}>
        <Grid item xs className={classes.textContainer}>
          <Typography className={classes.description}>
            { t(`app:plan.${getPlanTranslationKey(plan.name)}.description`,
              { count: plan.photoCountPerMonth, hour: getHistoryText(t, camera, plan) }) }
          </Typography>
        </Grid>

        <Grid container item xs className={classes.currentContainer}>
          { isCurrentPlan && <CurrentItem plan={plan} /> }
        </Grid>
      </Grid>
    </CardLayout>
  )
}

export default PlanCard
