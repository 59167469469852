import { Grid } from '@material-ui/core'
import VoskerInputField from 'vosker/src/components/buttons/VoskerInputField'
import VoskerSecondaryButton from 'vosker/src/components/buttons/VoskerSecondaryButton'
import VoskerPrimaryButton from 'vosker/src/components/buttons/VoskerPrimaryButton'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { useFormikContext } from 'formik'

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: 8,
  },
  flex: {
    display: 'flex',
    gap: 8,
  },
}))

const ProfilePasswordForm = ({ setShowEditPasswordForm }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { handleSubmit, isValid, dirty } = useFormikContext()
  return (
    <Grid container spacing={2}>
      <Grid xs={12} item>
        <VoskerInputField
          name="current"
          label={`${t('input.password_current')} *`}
          margin="none"
          type="password"
          notShowSuccessColor
        />
      </Grid>
      <Grid xs={12} item>
        <VoskerInputField
          name="new"
          label={`${t('change_password.form.password')} *`}
          margin="none"
          type="password"
          showHints
          showSuccessTickIcon
        />
      </Grid>
      <Grid xs={12} item>
        <VoskerInputField
          name="confirm"
          label={`${t('input.password_new_confirm')} *`}
          margin="none"
          type="password"
          showSuccessTickIcon
        />
      </Grid>
      <Grid container className={classes.padding} justifyContent="flex-end">
        <Grid item className={classes.flex}>
          <VoskerSecondaryButton size="large" onClick={() => setShowEditPasswordForm(false)}>
            { t('button.cancel') }
          </VoskerSecondaryButton>
          <VoskerPrimaryButton size="large" onClick={handleSubmit} disabled={(!isValid && dirty) || !dirty}>
            { t('button.save') }
          </VoskerPrimaryButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ProfilePasswordForm
