import Grid from '@mui/material/Grid'
import downloadStatus from 'photo-module/download/downloadStatus'
import useSelectedPhoto from 'photo-module/photo/core/useSelectedPhoto'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import HdPhotoPendingButton from 'vosker/src/photos-module/photo/ui/photo-viewer/hd-photo-status-button/buttons/HdPhotoPendingButton'
import HdPhotoAvailableButton from 'vosker/src/photos-module/photo/ui/photo-viewer/hd-photo-status-button/buttons/HdPhotoAvailableButton'
import HdPhotoRequestableButton from 'vosker/src/photos-module/photo/ui/photo-viewer/hd-photo-status-button/buttons/HdPhotoRequestableButton'
import HdPhotoUnavailableButton from 'vosker/src/photos-module/photo/ui/photo-viewer/hd-photo-status-button/buttons/HdPhotoUnavailableButton'

const HdPhotoButton = ({ shouldPrioritizeHDVideoRequest }) => {
  const photo = useSelectedPhoto()
  const camera = useSelectedCamera()

  const iconByState = {
    [downloadStatus.requestable]: <HdPhotoRequestableButton />,
    [downloadStatus.pending]: <HdPhotoPendingButton />,
    [downloadStatus.available]: <HdPhotoAvailableButton />,
    [downloadStatus.unavailable]: <HdPhotoUnavailableButton />,
    [downloadStatus.error]: <HdPhotoRequestableButton />,
  }
  const icon = iconByState[photo.hdStatus] || null

  return camera?.hdPhotoCapability && shouldPrioritizeHDVideoRequest ? <Grid item xs="auto">{ icon }</Grid> : null
}

export default HdPhotoButton
