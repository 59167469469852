import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'

import { apiCameraPlans } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.types'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import NoPlan from './current-subscription/NoPlan'
import CurrentPlan from './current-subscription/CurrentPlan'
import UpcomingPlan from './current-subscription/UpcomingPlan'
import CurrentTrial from './current-subscription/CurrentTrial'
import PaymentInfo from './current-subscription/PaymentInfo'
import AutoRenew from './current-subscription/AutoRenew'
import PaymentStatus from './current-subscription/PaymentStatus'
import Warning from './current-subscription/Warning'
import LinearProgress from '@material-ui/core/LinearProgress'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  dataUsage: {
    fontFamily: theme.typography.fontWeightMedium,
    fontWeight: 'bold',
    fontSize: '1em',
    color: theme.palette.text.primary,
    marginBottom: 16,
  },
  progressBar: {
    marginBottom: 7.5,
    height: 8,
    backgroundColor: theme.palette.secondary.light,
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#1B76FD',
    },
  },
  pourcentage: {
    borderRadius: 4,
    width: 46,
    padding: '0 8px 0 8px',
    fontFamily: theme.typography.fontWeightMedium,
    fontWeight: 'bold',
    fontSize: '1em',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.light,
    textAlign: 'center',
  },
  sectionContainer: {
    marginBottom: 13.5,
    display: 'flex',
    alignItems: 'center',
  },
  list: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    fontFamily: theme.typography.fontFamily,
    fontSize: '1em',
    color: theme.palette.text.primary,
    '&> li': {
      padding: '.3rem 0',
    },
  },
}))

const CurrentSubscription = ({ isNewUi }) => {
  const classes = useStyles()
  const camera = useSelectedCamera()
  const { t } = useTranslation()

  const subscription = camera?.subscription
  const planId = subscription?.plan?.id
  const hasPlan = planId && planId !== apiCameraPlans.empty
  const count = camera?.subscription?.photoCount || 0
  const limit = camera?.subscription?.photoLimit || 0
  const usage = limit > 0 ? (count / limit) * 100 : 0

  if (!hasPlan) {
    return <NoPlan isNewUi={isNewUi} />
  }

  return (
    <div>
      <Typography className={classes.dataUsage}>
        { t('app:plan.current_subscription.data_usage') }
      </Typography>
      <LinearProgress
        className={classes.progressBar}
        variant="determinate"
        value={usage}
      />
      <Grid container className={classes.sectionContainer}>
        <Grid item xs={6} md={6}><div className={classes.pourcentage}>{ Math.floor(usage) }%</div>
        </Grid>
        <Grid item xs={6} md={6}><div style={{ textAlign: 'end' }}>{ count } /{ limit } { t('billing.photos') }</div></Grid>
      </Grid>
      <Divider />
      <CurrentPlan />
      <UpcomingPlan />
      <CurrentTrial />
      <PaymentInfo />
      <AutoRenew />
      <PaymentStatus />

      <Warning />
    </div>
  )
}

export default CurrentSubscription
