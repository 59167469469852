import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'

const OrderDivider = ({ className }) => (
  <Box my={1}>
    <Divider className={className} />
  </Box>
)

export default OrderDivider
