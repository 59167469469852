import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const FaTimesOctagon = ({ style }) => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="fal-times-octagon" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M14.4666 0.905127C14.1282 0.566748 13.6695 0.375 13.192 0.375H7.81177C7.33428 0.375 6.87559 0.566748 6.53721 0.905127L1.40513 6.03345C1.06675 6.37183 0.875 6.83052 0.875 7.30801V12.6882C0.875 13.1657 1.06675 13.6244 1.40513 13.9628L6.53721 19.0949C6.87559 19.4333 7.33428 19.625 7.81177 19.625H13.192C13.6695 19.625 14.1282 19.4333 14.4666 19.0949L19.5986 13.9628C19.937 13.6244 20.1288 13.1657 20.1288 12.6882V7.31177C20.1288 6.83428 19.937 6.37559 19.5986 6.03721L14.4666 0.905127ZM14.0643 13.2447L13.7447 13.5643C13.568 13.741 13.2822 13.741 13.1055 13.5643L10.5 10.9587L7.89448 13.5643C7.71777 13.741 7.43203 13.741 7.25532 13.5643L6.93574 13.2447C6.75903 13.068 6.75903 12.7822 6.93574 12.6055L9.54126 10L6.93574 7.39448C6.75903 7.21777 6.75903 6.93203 6.93574 6.75532L7.25532 6.43574C7.43203 6.25903 7.71777 6.25903 7.89448 6.43574L10.5 9.04126L13.1055 6.43574C13.2822 6.25903 13.568 6.25903 13.7447 6.43574L14.0643 6.75532C14.241 6.93203 14.241 7.21777 14.0643 7.39448L11.4587 10L14.0643 12.6055C14.2372 12.7822 14.2372 13.068 14.0643 13.2447Z"
        fill={theme.palette.text.primary}
      />
    </SvgIcon>
  )
}

export default FaTimesOctagon
