import { useSelector } from 'react-redux'
import userState from 'user-module/user/core/userState'
import jwtToken from 'shared-module/token/jwtToken'
import useUserActions from 'user-module/user/core/useUserActions'

const useUser = () => {
  const user = useSelector(state => state.user)
  const userActions = useUserActions()
  const token = jwtToken.get()

  if (!userState.isReady(user) && !userState.isFetching(user) && token) {
    userActions.autologin(token)
  }

  return user
}

export default useUser
