import TextSmallBold from 'vosker/src/order-module/order/ui/order-lines/components/TextSmallBold'
import TextSmall from 'vosker/src/order-module/order/ui/order-lines/components/TextSmall'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  noteTitle: {
    '&:after': {
      content: '" "',
    },
  },
}))

const OrderNote = ({ note }) => {
  const classes = useStyles()
  return (
    <Box>
      <TextSmallBold className={classes.noteTitle} component="span">{ note.title }</TextSmallBold>
      <TextSmall component="span">{ note.text }</TextSmall>
    </Box>
  )
}

export default OrderNote
