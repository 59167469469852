import { useState } from 'react'

const useConfirmationDialogActions = () => {
  const [isOpen, setIsOpen] = useState(false)
  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)

  return { isOpen: isOpen, open: open, close: close }
}

export default useConfirmationDialogActions
