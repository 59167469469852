import restapi from 'shared-module/api/restapi'
import cameraModel from 'camera-module/model/core/cameraModel'

const get = () => {
  return restapi.get('/api/v3/camera/models')
    .then(response => buildCameraModels(response.data))
}

const getSortedModels = user => {
  return restapi
    .get(`/api/v3/camera/models?grouped=true&country=${user.country}`)
    .then(firstResponse => {
      return restapi
        .get(`/api/v3/camera/models?grouped=true&country=${user.country}&nonCellular=true`)
        .then(secondResponse => {
          firstResponse.data.nonCellularModels = secondResponse.data.showcaseModels
          return buildSortedCameraModels(firstResponse.data)
        })
    })
}

const buildSortedCameraModels = apiModels => {
  return Object.values(apiModels).map(sortedApiModels => {
    return sortedApiModels.reduce((models, model) => {
      models[model.name] = buildCameraModel(model)
      return models
    }, {})
  })
}

const buildCameraModels = apiModels => {
  return apiModels.reduce((models, model) => {
    models[model.name] = buildCameraModel(model)
    return models
  }, {})
}

const buildCameraModel = apiModel => (
  cameraModel({
    name: apiModel.name,
    isCellular: apiModel.isCellular,
    icon: apiModel.iconUrl,
    helpImage: apiModel.scanImageWebUrl,
    variants: apiModel.variants,
    isShowcase: apiModel.isShowcase,
  })
)

const cameraModelsApi = { get, getSortedModels }
export default cameraModelsApi
