import SvgIcon from '@material-ui/core/SvgIcon'
import useTheme from '@material-ui/core/styles/useTheme'

const VoskerNoImgIcon = ({ className, inViewer = false, props }) => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="VoskerNoImgIcon" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.19531 1.43847L1.4375 3.19617L3.50952 5.2683C3.20103 5.79246 3.02437 6.40335 3.02437 7.0555V28.232C3.02437 30.1812 4.60442 31.7614 6.55361 31.7614H29.4948C29.5504 31.7614 29.6058 31.7601 29.6607 31.7575C29.7653 31.7527 29.8688 31.7433 29.9708 31.7296L32.8006 34.5594L34.5584 32.8017L3.19531 1.43847ZM8.03655 22.0433L13.3137 15.0723L20.2845 22.0433H8.03655Z"
        fill={inViewer ? '#B3B8CC80' : theme.palette.secondary.main}
      />
      <path
        d="M33.0243 27.7037V7.0555C33.0243 5.10625 31.444 3.52609 29.4948 3.52609H8.84668L18.9036 13.583L20.3854 11.5818L27.4483 22.0433H27.3639L33.0243 27.7037Z"
        fill={inViewer ? '#B3B8CC80' : theme.palette.secondary.main}
      />
    </SvgIcon>
  )
}

export default VoskerNoImgIcon
