import { combineReducers } from 'redux'
import camerasFilterState from 'photo-module/filters/core/cameras/camerasFilterState'
import speciesFilterState from 'photo-module/filters/core/species/speciesFilterState'
import favoriteFilterState from 'photo-module/filters/core/favorite/favoriteFilterState'
import hdFilterState from 'photo-module/filters/core/hd/hdFilterState'

const clearFiltersActionType = 'CLEAR_FILTERS'
const clear = () => ({ type: clearFiltersActionType })
const clearFiltersReducer = filterReducer => (state, action) => action.type === clearFiltersActionType
  ? filterReducer(undefined, action)
  : filterReducer(state, action)

const selected = state => (
  speciesFilterState.selected(state.species) ||
  favoriteFilterState.selected(state.favorite) ||
  hdFilterState.selected(state.hd)
)

const filterReducer = combineReducers({
  cameras: camerasFilterState.reducer,
  species: speciesFilterState.reducer,
  favorite: favoriteFilterState.reducer,
  hd: hdFilterState.reducer,
})

const reducer = clearFiltersReducer(filterReducer)

const filtersState = { selected: selected, clear: clear, reducer: reducer }
export default filtersState
