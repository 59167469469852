import { useTranslation } from 'react-i18next'
import ForwardBackLink from 'shared-module/components/FowardBackLink'
import { Box } from '@material-ui/core'

const ForgotPasswordLink = () => {
  const { t } = useTranslation()

  return (
    <Box display="flex" flexDirection="column" mt={3}>
      <ForwardBackLink id="lnk_password" pathname="/reset-password" align="right">{ t('common:login.forgot_password') }</ForwardBackLink>
    </Box>
  )
}

export default ForgotPasswordLink
