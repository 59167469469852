import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import useTheme from '@material-ui/core/styles/useTheme'

import Title from 'shared-module/new-components/layout/Title'
import PaymentPanel from 'vosker/src/user-module/payment-information/ui/payment-information/PaymentPanel'

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'auto',
    marginTop: '4rem',
    height: 'calc(100vh - 64px)',
    backgroundColor: theme.palette.background.default,
  },
  paper: {
    padding: '2rem',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '-1rem',
      marginRight: '-1rem',
    },
  },
  title: {
    marginLeft: '1rem',
    marginBottom: '2rem',
  },
}))

const PaymentInformationPage = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Container maxWidth="sm">
        <Box display="flex" flexDirection="column" mt={6} pb={2} className={classes.container}>
          <Title h="h1" className={classes.title}>{ t('app:payment_information_page.title') }</Title>

          <Box component={Paper} display="flex" flexDirection="column" elevation={0} mx={-2} px={4} py={4} bgcolor={theme.palette.background.paper}>
            <Title h="h2">{ t('app:payment_information_page.sub_title') }</Title>
            <Typography variant="body1">{ t('app:payment_information_page.info') }</Typography>
            <PaymentPanel />
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default PaymentInformationPage
