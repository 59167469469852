import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { alpha } from '@material-ui/core/styles/colorManipulator'

const useStyles = makeStyles((theme) => ({
  button: {
    width: '2.5rem',
    height: '2.75px',
    borderRadius: '0.375rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: ' 1rem 0.75rem 1rem 0.625rem',
  },
  enabledButton: {
    '&:hover': {
      backgroundColor: alpha(theme.palette.basic?.dark, 0.75),
    },
  },
}))

const VoskerButtonWrapper = ({ children, onClick, className, isDisabled }) => {
  const classes = useStyles()

  return (
    <Box
      onClick={onClick}
      className={isDisabled
        ? clsx(classes.button, className)
        : clsx(classes.button, classes.enabledButton, className)}
    >
      { children }
    </Box>
  )
}

export default VoskerButtonWrapper
