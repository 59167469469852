import cameraState from 'camera-module/camera/core/cameraState'
import { CLEAR_CAMERA, FETCH_CAMERA, SET_CAMERA } from 'camera-module/camera/core/cameraActionTypes'

export default function (state = cameraState.init(), action) {
  switch (action.type) {
    case FETCH_CAMERA:
      return cameraState.fetching()
    case SET_CAMERA:
      return cameraState.set(action.camera)
    case CLEAR_CAMERA:
      return cameraState.init()

    default:
      return state
  }
}
