import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'
import TagManager from 'react-gtm-module'

const MarketingCampaign = () => {
  if (appConfig.gtm.switch === switches.on) {
    const tagManagerArgs = {
      gtmId: appConfig.gtm.id,
      auth: appConfig.gtm.auth,
      preview: appConfig.gtm.preview,
      dataLayerName: 'purchasedProduct',
    }
    TagManager.initialize(tagManagerArgs)
  }
  return null
}

export default MarketingCampaign
