import { useDispatch, useSelector } from 'react-redux'
import cameraModelsActions from 'camera-module/model/core/cameraModelsActions'
import cameraModelsState from 'camera-module/model/core/cameraModelsState'

const useCameraModels = () => {
  const state = useSelector(state => state.models)
  const dispatch = useDispatch()

  if (!cameraModelsState.isReady(state)) {
    dispatch(cameraModelsActions.getModels())
  }

  return state.models
}

export default useCameraModels
