import restapi from 'shared-module/api/restapi'
import handleError from 'shared-module/api/apiError'

export const disableAutoRenew = (cameraId, subscriptionId) => {
  return restapi.delete(`/api/v3/subscription/${cameraId}/${subscriptionId}`)
    .catch(handleError)
}

export const enableAutoRenew = (cameraId, subscriptionId) => {
  return restapi.post(`/api/v3/subscription/${cameraId}/${subscriptionId}`)
    .catch(handleError)
}
