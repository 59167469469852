import { Box } from '@material-ui/core'

import FilterCategory from 'vosker/src/photos-module/photos-gallery/ui/filters-bar/FilterCategory'
import { FILTER_ICON_BY_KEY } from '../../core/filters.types'
import FilterItem from './FilterItem'

const FilterList = ({ filterKeys, filterValues, camera }) => {
  return (
    <Box display="flex" flexWrap="wrap" alignItems="center" margin="12px 20px 0px">
      { filterValues && filterValues.map((filterValue, index) => {
        return filterValue.length === 1
          ? <FilterItem key={filterValue} Icon={FILTER_ICON_BY_KEY[filterKeys[index]]} hasCategory={false} label={filterValue[0]} />
          : <FilterCategory key={filterValue} Icon={FILTER_ICON_BY_KEY[filterKeys[index]]} label={filterKeys[index]} values={filterValue} camera={camera} />
      }) }
    </Box>
  )
}

export default FilterList
