import { useTranslation } from 'react-i18next'

import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  container: {
    padding: '0.75rem 2rem 1.5rem',
  },
  paper: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0.75rem 2rem',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  noCameraMessage: {
    padding: '10px 20px',
    textAlign: 'left',
  },
}))

const LoadingCamera = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Container maxWidth="md" className={classes.container}>
      <Paper className={classes.paper} elevation={0}>
        <Typography variant="body1" className={classes.noCameraMessage}>{ t('camera_list.loading') }</Typography>
      </Paper>
    </Container>
  )
}

export default LoadingCamera
