import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const BatteryEmpty = ({ props }) => {
  const theme = useTheme()
  return (
    <SvgIcon {...props} width="32" height="32" viewBox="0 0 32 32" fill="none">
      <g id="surface1">
        <path
          stroke="none"
          fillRule="evenodd"
          fill={theme.palette.status?.error}
          fillOpacity={1}
          d="M 2.632812 4 L 1.414062 5.21875 L 3.523438 7.332031 L 2.667969 7.332031 C 1.960938 7.332031 1.28125 7.613281 0.78125 8.113281 C 0.28125 8.613281 0 9.292969 0 10 L 0 22 C 0 22.707031 0.28125 23.386719 0.78125 23.886719 C 1.28125 24.386719 1.960938 24.667969 2.667969 24.667969 L 20.859375 24.667969 L 24.191406 28 L 25.414062 26.78125 Z M 6.191406 10 L 3.332031 10 C 3.15625 10 2.988281 10.070312 2.863281 10.195312 C 2.738281 10.320312 2.667969 10.488281 2.667969 10.667969 L 2.667969 21.332031 C 2.667969 21.511719 2.738281 21.679688 2.863281 21.804688 C 2.988281 21.929688 3.15625 22 3.332031 22 L 18.191406 22 Z M 6.191406 10 "
        />
        <path
          stroke="none"
          fillRule="nonzero"
          fill={theme.palette.status?.error}
          fillOpacity={1}
          d="M 24.667969 22 L 23.078125 22 L 25.71875 24.636719 C 26.28125 24.554688 26.808594 24.292969 27.21875 23.886719 C 27.71875 23.386719 28 22.707031 28 22 L 28 20.667969 L 29.332031 20.667969 C 30.039062 20.667969 30.71875 20.386719 31.21875 19.886719 C 31.71875 19.386719 32 18.707031 32 18 L 32 14 C 32 13.292969 31.71875 12.613281 31.21875 12.113281 C 30.71875 11.613281 30.039062 11.332031 29.332031 11.332031 L 28 11.332031 L 28 10 C 28 9.292969 27.71875 8.613281 27.21875 8.113281 C 26.71875 7.613281 26.039062 7.332031 25.332031 7.332031 L 8.414062 7.332031 L 11.078125 10 L 24.667969 10 C 24.84375 10 25.011719 10.070312 25.136719 10.195312 C 25.261719 10.320312 25.332031 10.488281 25.332031 10.667969 L 25.332031 12.667969 C 25.332031 13.019531 25.472656 13.359375 25.722656 13.609375 C 25.972656 13.859375 26.3125 14 26.667969 14 L 29 14 C 29.089844 14 29.171875 14.035156 29.234375 14.097656 C 29.296875 14.160156 29.332031 14.246094 29.332031 14.332031 L 29.332031 17.667969 C 29.332031 17.753906 29.296875 17.839844 29.234375 17.902344 C 29.171875 17.964844 29.089844 18 29 18 L 26.667969 18 C 26.3125 18 25.972656 18.140625 25.722656 18.390625 C 25.472656 18.640625 25.332031 18.980469 25.332031 19.332031 L 25.332031 21.332031 C 25.328125 21.507812 25.257812 21.675781 25.132812 21.800781 C 25.011719 21.925781 24.84375 21.996094 24.667969 22 Z M 24.667969 22 "
        />
      </g>
    </SvgIcon>
  )
}

export default BatteryEmpty
