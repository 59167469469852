
const getfetchedDate = (list) => list.map(photoBlock => photoBlock.date)

const buildPhotoByDateList = (newPhotosList) => {
  const photoDateList = []

  const dates = getfetchedDate(photoDateList)
  const photoByDates = photoDateList

  newPhotosList.forEach((photo) => {
    // Fix wrong date convertion
    const date = `${photo.date.split('T')[0]}T01:00:00.000Z`
    if (dates.includes(date)) {
      const index = photoByDates.findIndex(item => item.date === date)
      !photoByDates[index].photos.includes(photo) &&
          photoByDates[index].photos.push(photo)
    } else {
      photoByDates.push({ date, photos: [photo] })
      dates.push(date)
    }
  })

  return photoByDates
}

export const sortPhotosBydate = list => [...list].sort((a, b) => {
  const dateA = new Date(a.date)
  const dateB = new Date(b.date)
  return dateB - dateA
})

export const sortPhotoListByDate = (photosList) => {
  const sortedList = sortPhotosBydate(photosList)

  return buildPhotoByDateList(sortedList)
}
