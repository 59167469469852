import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import SvgIcon from '@mui/material/SvgIcon'
import EditIcon from '@mui/icons-material/Edit'
import CancelIcon from '@mui/icons-material/Cancel'
import YellowZone from './YellowZone'
import useDetectionZone from 'detection-zone-module/core/detectionZone.hooks'
import webview from 'shared-module/webview/webview'

const useStyles = makeStyles(theme => ({
  textField: ({ isLandscape, isEditing }) => ({
    userSelect: 'text',
    width: isLandscape ? '100%' : '18.75rem',
    borderRadius: 8,
    '& .MuiInputBase-root, & .MuiOutlinedInput-root': {
      paddingRight: 8,
      paddingLeft: 16,
    },
    '& .MuiInputBase-input': {
      color: theme.palette.text?.primary,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      maxWidth: isEditing ? '100%' : 'auto',
      fontSize: theme.typography.body1?.fontSize,
      fontWeight: 600,
      fontFamily: theme.typography.body1?.fontFamily,
      userSelect: 'initial',
    },
    '& .MuiInputBase-input::placeholder': {
      color: theme.palette.text.secondary,
      fontFamily: theme.typography.body1?.fontFamily,
      opacity: 1,
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.text?.primary,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      color: theme.palette.text?.primary,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.background?.contrast,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.background?.contrast,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.border?.secondary,
        boxShadow: `0px 4px 16px 0px ${theme.palette.background?.boxShadow}`,
      },
      borderRadius: 8,
    },
    backgroundColor: theme.palette.background.contrast,
    [theme.breakpoints.down('md')]: {
      bottom: 0,
      zIndex: 2,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  }),
  characterNumber: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.caption1?.fontSize,
    fontFamily: theme.typography.body1?.fontFamily,
    marginTop: 3,
  },
  cancelIcon: {
    fill: `${theme.palette.border?.secondary} !important`,
    marginTop: 1,
  },
}))

function ZoneId ({ saveDetectionZone, onFocus, onBlur, isLandscape }) {
  const { t } = useTranslation()
  const { zoneName, isEditing: isAnchorEditing, setZoneName, lastSavedZoneName, setLastSavedZoneName } = useDetectionZone()

  const isWebview = webview.isWebview()
  const isMobileWebBrowser = window.navigator.userAgent.toLowerCase().includes('mobi')
  const [isEditing, setIsEditing] = useState(false)
  const classes = useStyles({ isLandscape: isLandscape && isMobileWebBrowser, isEditing, isAnchorEditing, isWebview })

  const defaultPlaceholder = t('app:camera.settings.fields.zoneId.placeholder.default')
  const [placeholder, setPlaceholder] = useState(defaultPlaceholder)
  const [noZoneId, setNoZoneId] = useState(true)

  useEffect(() => {
    if (!zoneName) {
      setPlaceholder(defaultPlaceholder)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoneName])

  useEffect(() => {
    if (zoneName === undefined) {
      setZoneName(noZoneId ? defaultPlaceholder : '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoneName])

  const handleChange = event => setZoneName(event.target.value.slice(0, 30) || '')

  const handleFocus = () => {
    if (!zoneName) setZoneName(defaultPlaceholder)
    setIsEditing(true)
    onFocus && onFocus()
  }

  const handleBlur = event => {
    setIsEditing(false)
    onBlur && onBlur()

    if (event.relatedTarget?.dataset?.testid !== 'cancel-button' && zoneName !== lastSavedZoneName) {
      if (zoneName === '' && lastSavedZoneName === undefined) {
        setNoZoneId(true)
        setZoneName(undefined)
        return
      } else if (!zoneName) {
        setZoneName(lastSavedZoneName || '')
        return
      }

      saveDetectionZone(false)
      setLastSavedZoneName(zoneName)
    } else {
      setZoneName(lastSavedZoneName || '')
    }
  }

  const handleCancelClick = () => {
    if (placeholder === defaultPlaceholder) setNoZoneId(false)
    setZoneName(undefined)
  }

  const handleKeyAction = event => {
    if (event.key === 'Enter') {
      event.target.blur()
      event.preventDefault()
    }
  }

  const inputProperties = {
    startAdornment: (
      <InputAdornment position="start" style={{ marginTop: '5px' }}>
        <SvgIcon component={YellowZone} viewBox="0 0 600 476.6" />
      </InputAdornment>
    ),
    endAdornment: (
      <InputAdornment position="end" disablePointerEvents={!isEditing}>
        { isEditing
          ? (
            <>
              <span className={classes.characterNumber}>{ (zoneName || '').length }/30</span>
              <IconButton data-testid="cancel-button" onMouseDown={(e) => e.preventDefault()} onTouchStart={(e) => e.preventDefault()} onClick={handleCancelClick}>
                <CancelIcon className={classes.cancelIcon} />
              </IconButton>
            </>
            )
          : <IconButton data-testid="edit-button"><EditIcon /></IconButton> }
      </InputAdornment>
    ),
  }

  return (
    <TextField
      value={zoneName || ''}
      placeholder={!zoneName ? placeholder : zoneName}
      type="text"
      variant="outlined"
      className={classes.textField}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onKeyUp={handleKeyAction}
      InputProps={inputProperties}
    />
  )
}

export default ZoneId
