import { useTranslation } from 'react-i18next'
import { formatDateTime } from 'shared-module/common-utils'
import CameraStatusTile from 'camera-module/camera/ui/camera-status-page/CameraStatusTile'
import LastUpdate from 'assets/icons/status/LastUpdate'
import LastSync from 'assets/icons/status/LastSync'

const LastUpdateTile = ({ value }) => {
  const { i18n } = useTranslation()
  return <CameraStatusTile label="app:camera.last_communication" icon={<LastUpdate />} value={formatDateTime(value, i18n)} useSVGIcon />
}

export const LastSyncTile = ({ value, isNewUi }) => {
  const { i18n } = useTranslation()
  return <CameraStatusTile label="app:camera.last_communication" icon={<LastSync />} value={formatDateTime(value, i18n).toUpperCase()} useSVGIcon isNewUi={isNewUi} />
}

export default LastUpdateTile
