import React from 'react'
import SolarBatteryEmpty from 'assets/icons/status/new-status-icons/SolarBatteryEmpty'
import SolarBatteryLow from 'assets/icons/status/new-status-icons/SolarBatteryLow'
import SolarBatteryMedium from 'assets/icons/status/new-status-icons/SolarBatteryMedium'
import SolarBatteryHigh from 'assets/icons/status/new-status-icons/SolarBatteryHigh'
import BatteryEmpty from 'assets/icons/status/new-status-icons/BatteryEmpty'
import BatteryLow from 'assets/icons/status/new-status-icons/BatteryLow'
import BatteryMedium from 'assets/icons/status/new-status-icons/BatteryMedium'
import BatteryHigh from 'assets/icons/status/new-status-icons/BatteryHigh'
import CameraStatusBatteryTile from 'camera-module/camera/ui/camera-status-page/CameraStatusBatteryTile'
import { useTranslation } from 'react-i18next'

const VoskerFirstBatteryTile = ({ value, label, type, activePowerSource }) => {
  const { t } = useTranslation()
  const batteryLabel = type === 'SOLAR' ? t('app:camera.battery2') : label
  const batteryValue = activePowerSource ? `${value}% - ${t('camera.in_use')}` : `${value}%`

  let Battery = SolarBatteryEmpty
  if (value <= 0) Battery = BatteryEmpty
  if (value >= 1 && value <= 34) Battery = BatteryLow
  if (value >= 35 && value <= 74) Battery = BatteryMedium
  if (value >= 75 && value <= 100) Battery = BatteryHigh

  if (value <= 0 && type === 'SOLAR') Battery = SolarBatteryEmpty
  if (value >= 1 && value <= 34 && type === 'SOLAR') Battery = SolarBatteryLow
  if (value >= 35 && value <= 74 && type === 'SOLAR') Battery = SolarBatteryMedium
  if (value >= 75 && value <= 100 && type === 'SOLAR') Battery = SolarBatteryHigh

  return (
    <CameraStatusBatteryTile label={batteryLabel} icon={<Battery />} value={value} batteryValue={batteryValue} useSVGIcon isNewUi />
  )
}
export default VoskerFirstBatteryTile
