import SvgIcon from '@material-ui/core/SvgIcon'

const FullHdVideoShadowIcon = (props) => {
  return (
    <SvgIcon {...props} data-testid="SdShadowIcon" width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2985_27529)">
        <g filter="url(#filter0_ddd_2985_27529)">
          <path
            d="M14.794 11.7416V17.4342H15.9541C16.609 17.4342 17.0019 17.1242 17.0019 16.2632V12.9125C17.0019 12.0515 16.609 11.7416 15.9541 11.7416H14.794Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.0743 2.15625H5.03282C3.91014 2.15625 3 3.01977 3 4.08493V18.9151C3 19.9802 3.91014 20.8438 5.03282 20.8438H19.0743C20.197 20.8438 21.1071 19.9802 21.1071 18.9151V4.08493C21.1071 3.01977 20.197 2.15625 19.0743 2.15625ZM5.00223 8.77654H6.17229V7.09839H7.45502V5.97333H6.17229V4.94057H7.68903V3.84375H5.00223V8.77654ZM17.5876 7.67972V3.84375H16.4176V8.77654H18.931V7.67972H17.5876ZM7.01033 19.1563H5.0055V10.0196H7.01033V13.7269H9.28854V10.0196H11.3298V19.1563H9.28854V15.449H7.01033V19.1563ZM12.7361 10.0196H15.9918C18.05 10.0196 19.0604 11.07 19.0604 12.9987V16.1772C19.0604 18.1059 18.05 19.1563 15.9918 19.1563H12.7361V10.0196ZM14.004 3.84375H12.834V8.77654H15.3474V7.67972H14.004V3.84375ZM10.6568 3.84375H11.8268V7.40348C11.8268 8.30531 11.2028 8.90653 10.2234 8.90653C9.24404 8.90653 8.62001 8.30531 8.62001 7.40348V3.84375H9.79007V7.40348C9.79007 7.64722 9.94608 7.80971 10.2234 7.80971C10.5008 7.80971 10.6568 7.64722 10.6568 7.40348V3.84375Z"
          />
        </g>
      </g>
      <defs>
        <filter id="filter0_ddd_2985_27529" x="1" y="1.15625" width="22.1071" height="22.6875" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2985_27529" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_2985_27529" result="effect2_dropShadow_2985_27529" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="0.75" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="effect2_dropShadow_2985_27529" result="effect3_dropShadow_2985_27529" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_2985_27529" result="shape" />
        </filter>
        <clipPath id="clip0_2985_27529">
          <rect width="24" height="22" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

export default FullHdVideoShadowIcon
