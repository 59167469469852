import Typography from '@material-ui/core/Typography'
import MuiBox from '@material-ui/core/Box'
import React, { ReactNode } from 'react'

export interface ICTypography {
  children: ReactNode
  marginBottom?: number
  noWrap?: boolean
  className?: string
  color?: 'textPrimary' | 'textSecondary'
  variant:
    | 'body1'
    | 'body2'
    | 'button'
    | 'caption'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'overline'
    | 'subtitle1'
    | 'subtitle2'
}

// Bypasses Typescript error: cannot be used as a JSX component
const Box: any = MuiBox

const CTypography = (props: ICTypography) => {
  return (
    <Box mb={props.marginBottom}>
      <Typography variant={props.variant} noWrap={props.noWrap} className={props.className} color={props.color ? props.color : 'textPrimary'}>
        { props.children }
      </Typography>
    </Box>
  )
}

export default CTypography
