import makeStyles from '@material-ui/styles/makeStyles'

import PlayStopButton from './PlayStopButton'
import Volume from './Volume'
import RemainingTime from './RemainingTime'
import QualitySwitch from './QualitySwitch'

const useStyles = makeStyles(theme => ({
  controlsContainer: {
    display: 'flex',
    height: '100%',
    color: theme.palette.basic?.white,
    overflow: 'hidden',
  },
}))

const Controls = () => {
  const classes = useStyles()

  return (
    <div className={classes.controlsContainer}>
      <PlayStopButton />
      <Volume />
      <RemainingTime />
      <QualitySwitch />
    </div>
  )
}

export default Controls
