import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../core/liveStreaming.actions'
import * as selectors from '../core/liveStreaming.selectors'

export const useStreaming = () => {
  const dispatch = useDispatch()

  const play = () => dispatch(actions.play())
  const stop = () => dispatch(actions.stop())
  const close = () => dispatch(actions.close())
  const mute = () => dispatch(actions.mute())
  const unMute = () => dispatch(actions.unMute())
  const changeQuality = quality => dispatch(actions.changeQuality(quality))
  const changeVolume = volume => dispatch(actions.changeVolume(volume))
  const changePlayerPlayingState = isPlaying => dispatch(actions.changePlayerPlayingState(isPlaying))

  const streamActive = useSelector(selectors.isStreamActive)
  const streamStarting = useSelector(selectors.isStreamStarting)
  const streamStarted = useSelector(selectors.isStreamStarted)
  const streamUrl = useSelector(selectors.getStreamUrl)
  const streamRemainingTime = useSelector(selectors.getPlayerRemainingTime)
  const streamRemainingTimePercentage = useSelector(selectors.getPlayerRemainingTimePercentage)
  const streamQuality = useSelector(selectors.getPlayerQuality)
  const showSpinner = useSelector(selectors.shouldShowSpinner)
  const streamIsStopped = useSelector(selectors.isStreamStopped)
  const showPlayButton = useSelector(selectors.shouldShowPlayButton)
  const controlsDisabled = useSelector(selectors.shouldDisableControls)
  const playerMuted = useSelector(selectors.isPlayerMuted)
  const playerVolume = useSelector(selectors.getPlayerVolume)
  const errorMessage = useSelector(selectors.getErrorMessage)
  const ecoModeDeadline = useSelector(selectors.cameraEcoModeDeadline)

  return {
    play,
    stop,
    close,
    mute,
    unMute,
    changeQuality,
    changeVolume,
    changePlayerPlayingState,
    streamActive,
    streamStarting,
    streamStarted,
    streamUrl,
    streamRemainingTime,
    streamRemainingTimePercentage,
    streamQuality,
    showSpinner,
    showPlayButton,
    controlsDisabled,
    errorMessage,
    ecoModeDeadline,
    playerMuted,
    playerVolume,
    streamIsStopped,
  }
}
