import { useTheme } from '@mui/material'
import SvgIcon from '@material-ui/core/SvgIcon'

const TrashInactiveIcon = ({ props }) => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="TrashInactiveIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8125 4.66673H22V6.50007H9.5002C8.80587 5.80612 8.11144 5.1118 7.41671 4.41707V1.80007C7.41671 1.35824 7.77488 1.00007 8.2167 1.00007L15.7834 1.00003C16.2252 1.00003 16.5834 1.3582 16.5834 1.80003V4.43757C16.5834 4.49835 16.6075 4.55663 16.6505 4.59961C16.6935 4.64259 16.7518 4.66673 16.8125 4.66673ZM9.25004 3.1334V4.43757C9.25004 4.49835 9.27418 4.55663 9.31716 4.59961C9.36013 4.64259 9.41842 4.66673 9.4792 4.66673H14.5209C14.5816 4.66673 14.6399 4.64259 14.6829 4.59961C14.7259 4.55663 14.75 4.49835 14.75 4.43757V3.1334C14.75 2.96772 14.6157 2.8334 14.45 2.8334H9.55004C9.38435 2.8334 9.25004 2.96772 9.25004 3.1334Z"
        fill={theme.palette.basic?.lightGrey}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.00269 1.99734L1 2.00003L3.66653 4.66673H1.99997V6.50007H5.49971L6.86918 7.86932L4.65341 7.87473L5.87255 21.3318C5.91385 21.7889 6.12509 22.2138 6.46453 22.5227C6.80398 22.8316 7.24693 23.002 7.70588 23.0001H16.2675C16.7265 23.002 17.1694 22.8316 17.5089 22.5227C17.8483 22.2138 18.0596 21.7889 18.1009 21.3318L18.2946 19.2739L22.0788 23.0547L22.9959 22.1369L1.92118 1.07886L1.92014 1.07989L1.9179 1.07765L1.00085 1.99551L1.00269 1.99734ZM14.3606 15.3433L14.9659 15.9481V19.7918H13.5909V14.5773C13.8474 14.8327 14.104 15.088 14.3606 15.3433ZM9.00754 19.7918H10.3825V11.5418H9.00754V19.7918Z"
        fill={theme.palette.basic?.lightGrey}
      />
      <path
        d="M14.5512 11.5418C13.3203 10.3153 12.0906 9.08794 10.8608 7.85957L19.3711 7.8388L18.6397 15.6079C17.4132 14.3909 16.189 13.1733 14.9659 11.9549V11.5418H14.5512Z"
        fill={theme.palette.basic?.lightGrey}
      />

    </SvgIcon>
  )
}

export default TrashInactiveIcon
