import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import makeStyles from '@material-ui/core/styles/makeStyles'

import useDetectionZone from 'detection-zone-module/core/detectionZone.hooks'
import DetectionZoneActionButtons from './DetectionZoneActionButtons'
import DetectionZoneArrowBackButton from './DetectionZoneArrowBackButton'
import VoskerPrimaryButton from 'vosker/src/components/buttons/VoskerPrimaryButton'
import PrimaryButton from 'shared-module/new-components/buttons/PrimaryButton'

const useStyles = makeStyles(theme => ({
  viewerActionsBar: {
    background: theme.palette.background.default,
    width: '100%',
    height: '3.75rem',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '2.5rem 1.875rem 2.5rem 1.25rem',
    [theme.breakpoints.only('sm')]: {
      padding: '2.5rem 1rem 2.5rem 1rem',
    },
  },
  editContainer: {
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      left: 2,
      paddingTop: 14,
    },
  },
  editButton: {
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      fontSize: 11,
    },
  },
}))

const DetectionZoneTopActionBar = ({ anchorList, saveDetectionZone, resetDetectionZone, isNewUi, isLandscape }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { anchorList: anchorsInState, isEditing, setIsEditing } = useDetectionZone()

  const [hasAnchors, setHasAnchors] = useState(anchorsInState && anchorsInState.length > 0)
  const EditButton = isNewUi ? VoskerPrimaryButton : PrimaryButton

  useEffect(() => {
    anchorsInState && setHasAnchors(anchorsInState.length > 0)
  }, [anchorsInState])

  const toggleEdition = () => setIsEditing(true)

  return (
    <Box className={classes.viewerActionsBar}>
      <DetectionZoneArrowBackButton isNewUi={isNewUi} anchorList={anchorList} />

      { hasAnchors && !isEditing
        ? (
          <Box display="flex" flexDirection="row" justifyContent="flex-end" className={classes.editContainer}>
            <EditButton onClick={toggleEdition} style={{ marginLeft: 16 }} className={classes.editButton}>{ t('button.edit') }</EditButton>
          </Box>
          )
        : (
          <DetectionZoneActionButtons
            isNewUi={isNewUi}
            anchorList={anchorList}
            hasAnchors={hasAnchors}
            saveDetectionZone={saveDetectionZone}
            resetDetectionZone={resetDetectionZone}
            isLandscape={isLandscape}
          />
          ) }
    </Box>
  )
}

export default DetectionZoneTopActionBar
