import { useTranslation } from 'react-i18next'

import { Box, Grid } from '@mui/material'
import Paper from '@material-ui/core/Paper'
import { Typography } from '@material-ui/core'

import PaymentPanel from 'vosker/src/user-module/payment-information/ui/payment-information/PaymentPanel'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '1.5rem',
    padding: '1.5rem 2rem 2rem',
    [theme.breakpoints.down('xs')]: {
      padding: '1.5rem 1rem 2rem',
    },
  },
}))

const PaymentTab = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Box component={Paper} data-testid="accountPayment" elevation={0} className={classes.root}>
      <Grid container>
        <Box component={Grid} item>
          <Typography variant="h2" style={{ marginBottom: 20 }}>{ t('app:payment_information_page.title') }</Typography>
          <Typography variant="body1">{ t('app:payment_information_page.info') }</Typography>
          <PaymentPanel />
        </Box>
      </Grid>
    </Box>
  )
}

export default PaymentTab
