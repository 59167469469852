import { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import CircularProgress from '@material-ui/core/CircularProgress'
import webview from 'shared-module/webview/webview'

const styles = (props) => ({
  progress: {
    width: '40px',
    height: '40px',
    zIndex: '20',
    margin: 'auto',
    display: 'block',
  },
  divSpinner: {
    textAlign: 'center',
  },
  divSpinnerWebview: {
    marginTop: '24px',
  },
  backDrop: {
    position: 'absolute',
    backgroundColor: props.bgcolor,
    top: 0,
    left: 0,
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    zIndex: '19',
  },
})

class Spinner extends Component {
  isWebview = webview.isWebview()

  backdropColor = {
    backgroundColor: this.props.bgcolor,
    opacity: '0.5',
  }

  spinnerPosition = {
    position: this.props.position,
    top: this.props.spinnerTop,
    left: this.props.spinnerLeft,
    marginTop: this.props.spinnerMarginTop,
    marginLeft: this.props.spinnerMarginLeft,
  }

  render () {
    const hidden = {
      display: 'none',
    }

    const { classes } = this.props
    return (
      <div className={this.isWebview ? classes.divSpinnerWebview : classes.divSpinner}>
        <CircularProgress
          className={classes.progress}
          color="primary"
          variant="indeterminate"
          style={this.isWebview ? {} : this.spinnerPosition}
        />
        <div className={this.props.Backdrop ? classes.backDrop : hidden} style={this.backdropColor} />
      </div>
    )
  }
}

Spinner.defaultProps = {
  position: 'absolute',
  bgcolor: '#000000',
  spinnerTop: '50%',
  spinnerLeft: '50%',
  spinnerMarginTop: '-35px',
  spinnerMarginLeft: '-26px',
}

export default withStyles(styles)(Spinner)
