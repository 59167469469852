import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const NewSignalFive = ({ props }) => {
  const theme = useTheme()
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="none"
        fillRule="evenodd"
        fill={theme.palette.text.primary}
        fillOpacity={1}
        d="M29 9.332c-.55 0-1 .45-1 1v17.336c0 .55.45 1 1 1h1.332a1 1 0 0 0 1-1V10.332a1 1 0 0 0-1-1Zm-6.668 3.93c-.55 0-1 .449-1 1v13.406c0 .55.45 1 1 1h1.336c.55 0 1-.45 1-1V14.262c0-.551-.45-1-1-1Zm-7.664 4.93a1 1 0 0 1 1-1H17c.55 0 1 .449 1 1v9.476c0 .55-.45 1-1 1h-1.332a1 1 0 0 1-1-1ZM8 22.121c0-.552.45-1 1-1h1.332a1 1 0 0 1 1 1v5.546a1 1 0 0 1-1 1H9c-.55 0-1-.45-1-1ZM1.332 26.05c0-.551.45-1 1-1h1.336c.55 0 1 .449 1 1v1.617c0 .55-.45 1-1 1H2.332c-.55 0-1-.45-1-1Zm0 0"
      />
    </SvgIcon>
  )
}

export default NewSignalFive
