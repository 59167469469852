import { TextField } from '@material-ui/core'
import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js/max'
import { useTranslation } from 'react-i18next'

import WarningIcon from 'assets/icons/navigation/WarningIcon'
import makeStyles from '@material-ui/core/styles/makeStyles'

export const getInitialCell = (user) => {
  if (!user.cell) return ''
  try {
    return parsePhoneNumberFromString(user.cell, user.country || 'CA').nationalNumber
  } catch (error) {
    return user.cell
  }
}

export const validateCell = (values) => {
  try {
    const phoneNumber = parsePhoneNumberFromString(values.cell, values.country || 'CA')
    if (phoneNumber.country !== values.country) return { cell: 'input.phone_invalidCountryMatch' }
    if (!phoneNumber.isValid()) return { cell: 'input.phone_invalid' }
    return {}
  } catch (e) {
    return { cell: 'input.phone_required' }
  }
}

const useStyles = makeStyles(() => ({
  icon: {
    width: '1rem',
    top: '0.375rem',
    position: 'relative',
    marginRight: '0.313rem',
  },
  helper: {
    marginLeft: '-0.874rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
}))

const formatPhoneNumber = (cell, country) => new AsYouType(country).input(cell)

const ProfileCell = ({ form, formik, name, label, disabled, required, className, showErrorIcon = false }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const helperText = !!formik.errors[name] && (
    <>
      <WarningIcon className={classes.icon} />
      { t(formik.errors[name]) }
    </>
  )

  return (
    <TextField
      id={name}
      name={name}
      label={label}
      variant="outlined"
      value={formatPhoneNumber(formik.values[name], formik.values.country)}
      onChange={formik.handleChange}
      error={!!formik.errors[name]}
      helperText={showErrorIcon ? helperText : !!formik.errors[name] && t(formik.errors[name])}
      disabled={disabled}
      fullWidth
      inputProps={{
        form: form,
      }}
      data-testid={name}
      required={!!required}
      className={className || undefined}
    />
  )
}

export default ProfileCell
