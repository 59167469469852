import makeStyles from '@material-ui/styles/makeStyles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles({
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  spinner: {
    width: 40,
    height: 40,
    marginBottom: '.75rem',
  },
})

const Spinner = () => {
  const classes = useStyles()
  return (
    <div className={classes.spinnerContainer} data-testid="spinner">
      <CircularProgress
        className={classes.spinner}
        color="primary"
        variant="indeterminate"
      />
    </div>
  )
}

export default Spinner
