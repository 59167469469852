import React from 'react'
import { useTranslation } from 'react-i18next'

import CInput from '../../components/inputs/CInput'
import { useFormField } from 'shared-module/new-components/form/FormDataContext'

export interface ILastNameField {
  fieldName: string
  label?: string
  defaultValue?: string
  validations?: boolean
  validationSchema?: Object
  className?: string
  disabled?: boolean
  isNewUi?: boolean
  margin?: 'dense' | 'normal' | 'none'
}

const regexText = /^[a-zA-Z\-' \u00C0-\u00FF.]{1,30}$/

const LastNameField = (props: ILastNameField) => {
  const { t } = useTranslation()
  const field = useFormField(props.fieldName)

  const defaultValidation = {
    required: t('input.last_name_required'),
    validate: lastName => regexText.test(lastName) || t('input.last_name_invalid'),
  }

  const fieldRegistration = props.validations
    ? props.validationSchema
      ? field.inputRef(props.validationSchema)
      : field.inputRef(defaultValidation)
    : field.inputRef

  return (
    <CInput
      name={field.name}
      type="text"
      className={props.className}
      defaultValue={props.defaultValue}
      isNewUi={props?.isNewUi}
      label={props.label || t('input.last_name')}
      margin={props.margin}
      register={fieldRegistration}
      onChange={field.onChange}
      error={field.error}
      disabled={props.disabled}
    />
  )
}

export default LastNameField
