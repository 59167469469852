import { isExternalWebApp } from 'shared-module/webview/isExternalWebApp'
import webViewDevice from 'checkout-module/checkout/ui/shared/web-view-bridge/webViewDevice'

const postMessage = message => {
  const jsonMessage = JSON.stringify(message)
  // eslint-disable-next-line camelcase
  const cordovaDetected = window.webkit?.messageHandlers?.cordova_iab

  if (cordovaDetected) {
    window.webkit.messageHandlers.cordova_iab.postMessage(jsonMessage)
  } else if (typeof Android !== 'undefined') {
    // eslint-disable-next-line no-undef
    Android.postMessage(jsonMessage)
  } else if (typeof window.webkit?.messageHandlers?.iOS !== 'undefined') {
    window.webkit.messageHandlers.iOS.postMessage(jsonMessage)
  }
}

const scrollViewForAndroidInputField = (inputRef, onFinishedAction) => {
  const isAndroidWebview = webViewDevice().isAndroidAppWebview
  if (!isAndroidWebview) return

  const inputClientRect = inputRef.current?.getBoundingClientRect()
  const verticalPositionRatio = inputClientRect ? inputClientRect.bottom / window.innerHeight : 0

  if (verticalPositionRatio > 0.4) {
    const elementRect = inputRef.current?.getBoundingClientRect()
    const absoluteElementTop = elementRect.top + window.pageYOffset
    const newPosition = absoluteElementTop - (window.innerHeight / 3)
    window.scrollTo(0, newPosition)

    if (onFinishedAction !== undefined) onFinishedAction()
  }
}

const isWebview = () => isExternalWebApp()

const webview = {
  postMessage: postMessage,
  isWebview: isWebview,
  scrollViewForAndroidInputField: scrollViewForAndroidInputField,
}

export default webview
