import creditCardBrand from 'vosker/src/user-module/payment-information/core/creditCardBrand'
import Box from '@material-ui/core/Box'

const images = {
  [creditCardBrand.americanExpress]: '/images/credit-cards/amex.png',
  [creditCardBrand.dinersClub]: '/images/credit-cards/dinersclub.png',
  [creditCardBrand.discover]: '/images/credit-cards/discover.png',
  [creditCardBrand.jcb]: '/images/credit-cards/jcb.png',
  [creditCardBrand.mastercard]: '/images/credit-cards/mastercard.png',
  [creditCardBrand.unionpay]: '/images/credit-cards/union.png',
  [creditCardBrand.visa]: '/images/credit-cards/visa.png',
  [creditCardBrand.unknown]: '/images/credit-cards/generic.png',
}

const CreditCardImage = ({ brand, height = 'auto' }) => (
  <Box height={height}>
    <img src={images[brand]} style={{ margin: 'auto', display: 'block', maxWidth: '100%', maxHeight: '100%', height }} alt={brand} />
  </Box>
)

export default CreditCardImage
