const typeAddLoading = 'ADD_LOADING'
const typeRemoveLoading = 'REMOVE_LOADING'
const add = key => ({ type: typeAddLoading, key })
const remove = key => ({ type: typeRemoveLoading, key })

const reducer = (state = [], action) => {
  if (action.type === typeAddLoading) {
    return [...state, action.key]
  } else if (action.type === typeRemoveLoading) {
    return state.filter(s => s !== action.key)
  } else {
    return state
  }
}

const isLoading = (state, key) => state.includes(key)

export default { add: add, remove: remove, isLoading: isLoading, reducer: reducer }
