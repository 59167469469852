const VoskerBackArrowIcon = ({ props, color }) => {
  return (
    <svg {...props} width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.33334 0.666367L9.79774 2.13076L2.93768 8.99081L9.80663 15.8598L8.33334 17.333L0.925934 9.92563C0.680387 9.68008 0.542423 9.347 0.542411 8.99971C0.542395 8.65242 0.680377 8.31933 0.925934 8.07378L8.33334 0.666367Z"
        fill={color}
      />
    </svg>
  )
}
export default VoskerBackArrowIcon
