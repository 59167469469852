import { useField, useFormikContext } from 'formik'

import { useTheme } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import IOSSwitch from 'react-ios-switch'
import useDetectionZone from 'detection-zone-module/core/detectionZone.hooks'
import Grid from '@material-ui/core/Grid'

const DetectionSwitch = ({ name }) => {
  const theme = useTheme()
  const [field, , helpers] = useField(name)
  const { setFieldValue } = useFormikContext()
  const { isDefaultZone, isEditing, setIsEnabled } = useDetectionZone()

  const toggleIsEnabled = value => {
    setIsEnabled(value)
    setFieldValue(name, value)
    helpers.setTouched(true, false)
  }

  return (
    <Grid display="flex" flexdirection="row" justifyContent="flex-end" container spacing={1}>
      <Grid style={{ marginRight: -15 }} item>
        <FormControl fullWidth>
          <FormControlLabel
            control={(
              <IOSSwitch
                name={name}
                disabled={isDefaultZone && isEditing}
                checked={field.value}
                onChange={toggleIsEnabled}
                offColor={theme.palette.secondary.light}
                onColor={theme.palette?.status?.info}
                style={{ transform: 'scale(0.8)', marginLeft: '0.375rem' }}
              />
            )}
          />
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default DetectionSwitch
