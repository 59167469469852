import { useEffect, useState } from 'react'
import { useTheme } from '@material-ui/core'

import Box from '@material-ui/core/Box'
import Menu from '@material-ui/core/Menu'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import Collapse from '@material-ui/core/Collapse'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import ExpandedIcon from 'assets/icons/photo/ExpandedIcon'
import ExpansionIcon from 'assets/icons/photo/ExpansionIcon'
import { useTranslation } from 'react-i18next'
import useFilterCollection from 'photo-module/filters-bar/core/filters.hooks'
import { getSelectedFilterCount } from 'photo-module/filters-bar/core/filters.selectors'
import ExclusiveFilterLabel from './ExclusiveFilterLabel'
import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'
import FilterLabel from 'vosker/src/photos-module/photos-gallery/ui/filters-bar/FilterLabel'
import isDayNightFilter from 'vosker/src/photos-module/photos-gallery/core/filters.selector'

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: -4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      marginLeft: -20,
      width: '100%',
      marginBottom: '0px',
    },
  },
  box: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 'max-content',
    margin: '4px 10px 4px 4px',
    borderRadius: 5,
    padding: '5px 5px 5px 10px',
    maxHeight: '2.5rem',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('xs')]: {
      marginRight: -8,
      marginTop: 0,
      marginBottom: 0,
      maxHeight: 'inherit',
      width: '100%',
      justifyContent: 'space-between',
      paddingRight: 10,
      padding: '4px 10px',
    },
  },
  number: {
    width: 12,
    marginRight: '1rem',
    color: theme.palette.text?.primary,
    fontWeight: 600 + ' !important',
  },
  divider: {
    marginLeft: 12,
    marginRight: 12,
    marginBottom: '0px !important',
    marginTop: '0px !important',
    color: theme.palette.border?.divider,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 16,
      width: '100%',
      height: '1px',
      backgroundColor: theme.palette.border?.divider,
    },
  },
  expansion: {
    marginTop: 16,
    marginLeft: 10,
    [theme.breakpoints.down('xs')]: {
      marginRight: -20,
    },
  },

  collapse: {
    marginLeft: 16,
    marginRight: -32,
  },
  title: {
    paddingRight: '0.375rem',
    fontWeight: 600 + ' !important',
    fontSize: theme.typography.body2?.fontSize,
    fontFamily: theme.typography.body2?.fontFamily,
  },
}))

const FilterCategory = ({ Icon, label, values, camera }) => {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const breakpoint = useMediaQuery(theme.breakpoints.down('xs'))
  const { selectedFilters } = useFilterCollection()
  const [checked, setChecked] = useState(Array.from({ length: values.length }, (_, index) => false))
  const [open, setOpen] = useState(false)
  const [menuAnchor, setMenuAnchor] = useState(null)
  const [anchorWidth, setAnchorWidth] = useState(0)
  const [filterCount, setFilterCount] = useState(0)
  const VideoPreviewEnabled = appConfig.videoPreviews?.switch === switches.on
  const VKXModel = camera?.status?.powerSources?.length > 0
  const filteredValues = (VideoPreviewEnabled && VKXModel) ? values.filter(value => value !== 'preview') : values
  // eslint-disable-next-line
  useEffect(() => menuAnchor && setAnchorWidth(menuAnchor.offsetWidth))

  useEffect(() => {
    setFilterCount(getSelectedFilterCount(values, selectedFilters))
  }, [values, selectedFilters])

  const openMenu = (e) => {
    setOpen(true)
    setMenuAnchor(e.currentTarget)
    setAnchorWidth(e.currentTarget.offsetWidth)
  }

  const closeMenu = () => {
    setMenuAnchor(null)
    setOpen(false)
  }

  const handleChange = (index) => {
    setChecked((prevChecked) => {
      return prevChecked.map((isChecked, i) => i === index ? !isChecked : false)
    })
  }

  const renderChildren =
  filteredValues &&
  filteredValues.map((value, index) => {
    const isLatestItem = filteredValues.length === index + 1

    return (
      <div key={value}>
        { isDayNightFilter(filteredValues)
          ? <FilterLabel label={value} checked={checked[index]} />
          : <ExclusiveFilterLabel label={value} checked={checked[index]} handleChange={() => handleChange(index)} /> }
        { !isLatestItem && !breakpoint && <Divider className={classes.divider} /> }
      </div>
    )
  })

  return (
    <Box data-testid={label} className={classes.root}>
      <Box className={classes.box} onClick={open ? closeMenu : openMenu}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Icon style={{ marginBottom: 2, marginRight: 4 }} />
          <Typography className={classes.title} mb={0} noWrap>
            { t('app:filters.tags.' + label) }
          </Typography>
          { filterCount !== 0 && (
            <Typography variant="body2" className={classes.number}>
              ({ filterCount })
            </Typography>
          ) }
        </Box>

        { open
          ? (
            <ExpandedIcon color={theme.palette.text.primary} className={classes.expansion} />
            )
          : (
            <ExpansionIcon color={theme.palette.text.primary} className={classes.expansion} />
            ) }
      </Box>

      { !breakpoint
        ? (
          <Menu
            elevation={1}
            open={!!menuAnchor}
            onClose={closeMenu}
            style={{ top: -6 }}
            anchorEl={menuAnchor}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            MenuListProps={{
              disablePadding: true,
              autoFocus: false,
              autoFocusItem: false,
              style: { width: anchorWidth },
            }}
          >
            { renderChildren }
          </Menu>
          )
        : (
          <Collapse in={open} className={classes.collapse}>
            <List disablePadding>{ renderChildren }</List>
          </Collapse>
          ) }
      { breakpoint && <Divider className={classes.divider} style={{ marginRight: 0 }} /> }
    </Box>
  )
}

export default FilterCategory
