import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const AnimalsIcon = () => {
  const theme = useTheme()

  return (
    <SvgIcon style={{ width: 32, height: 32, marginTop: 6 }} data-testid="AnimalsIcon">
      <path
        d="M15.2748 13.1932H14.5334C14.9694 12.7521 15.2748 12.1835 15.2748 11.6591C15.2748 11.0227 14.7455 10.3932 14.1278 10.1947V7.82385H15.2748C15.7638 7.82385 16.1305 7.37057 16.0282 6.89213C15.7779 5.72454 14.9601 4.75567 13.7412 4.75567V3.98862C12.5904 3.98862 11.9621 5.49227 11.5982 6.58435L10.032 11.295C9.99872 11.3954 9.89013 11.4498 9.7897 11.4165L9.42559 11.2954C9.32516 11.2621 9.27074 11.1535 9.30406 11.0529L10.1318 8.56357C11.2634 5.16052 8.90616 2.45453 6.83993 2.45453C5.14596 2.45453 3.77271 3.82826 3.77271 5.52271C3.77271 7.21716 5.14596 8.59089 6.83993 8.59089C6.8464 8.59089 6.85239 8.58897 6.85886 8.58897C6.35717 9.25223 6.05443 10.0742 6.07408 10.975C6.12034 13.0944 7.98786 14.7273 10.1073 14.7273H15.6583C15.87 14.7273 16.0416 14.5556 16.0416 14.3437V13.9602C16.0416 13.5367 15.6983 13.1932 15.2748 13.1932ZM14.1247 5.90623C14.3364 5.90623 14.508 6.07786 14.508 6.28976C14.508 6.50165 14.3364 6.67328 14.1247 6.67328C13.913 6.67328 13.7412 6.50165 13.7412 6.28976C13.7412 6.07786 13.913 5.90623 14.1247 5.90623Z"
        fill={theme.palette.text.primary}
      />

    </SvgIcon>
  )
}

export default AnimalsIcon
