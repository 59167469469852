import useCameraModels from './useCameraModels'

export const useCameraModelsAndVariants = modelName => {
  const models = useCameraModels()

  if (models) {
    for (const [key, model] of Object.entries(models)) {
      if (key === modelName || (model.variants && model.variants.includes(modelName))) {
        return model
      }
    }
  }
  return undefined
}

export default useCameraModelsAndVariants
