import currencyBuilder from 'user-module/user/api/currencyBuilder'

const buildUser = (apiUser) => {
  apiUser.isAddressComplete = buildIsAddressComplete(apiUser)
  apiUser.spydollars = buildSpydollars(apiUser.credit)
  apiUser.currencyCode = buildCurrencyCode(apiUser.currency, apiUser.country)
  apiUser.currencySymbol = buildCurrencySymbol(apiUser.currencyCode)
  apiUser.addOns = buildAddOns(apiUser.addOns)
  return apiUser
}

const buildIsAddressComplete = (apiUser) => {
  const fields = ['firstName', 'lastName', 'address', 'city', 'postalCode', 'country', 'territory']
  return fields.every(field => apiUser[field])
}
const buildSpydollars = (credit) => credit ? Number.parseFloat((credit / 1000).toFixed(2)) : 0
const buildCurrencyCode = (currency, country) => currency || currencyBuilder.getCurrencyCode(country)
const buildCurrencySymbol = (currencyCode) => currencyBuilder.getCurrencySymbol(currencyCode)

const buildAddOns = addOns => ({
  hdVideos: addOns?.hdVideos?.videos || addOns?.hdVideos?.count || 0,
  sdStreamingSeconds: addOns?.streaming?.sdSeconds || 0,
  hdStreamingSeconds: addOns?.streaming?.hdSeconds || 0,
})
export default buildUser
export const deleteUserCompleted = (apiUser) => ({ camerasDeleted: apiUser.camerasDeleted, userDeleted: apiUser.userDeleted })
