import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'

import useQueryParams from 'shared-module/url/useQueryParams'
import { InstructionsKeys, InstructionsTitle, InstructionsCartTitle, InstructionsProfileTitle, InstructionsPaymentsTitle, InstructionsPlansTitle } from 'user-module/user/core/InstructionsKeys'

const useInstructions = (page) => {
  const location = useLocation()
  const { i18n } = useTranslation()
  const queryParams = useQueryParams()
  const { instruction, lng } = useParams()
  const isFrom = {
    CART: location?.state?.from?.pathname === '/cart',
    PROFILE: location?.state?.from?.pathname === '/account/profile',
    PAYMENTS: location?.state?.from?.pathname === '/account/payment',
    SHARE_CAMERAS: location?.state?.pathname === '/shared-cameras',
  }

  if (lng && i18n.language !== lng) {
    i18n.changeLanguage(lng)
  }

  const getKeyByParams = {
    [queryParams.become_member]: isFrom.CART ? 'displayCartBecomeMemberInstructions' : 'displayBecomeMemberInstructions',
    [queryParams.transmission_plan]: isFrom.CART ? 'displayCartTransmissionPlanInstructions' : 'displayTransmissionPlanInstructions',
    [queryParams.photo_hd]: isFrom.CART ? 'displayCartPhotoHDInstructions' : 'displayPhotoHDInstructions',
    [queryParams.video_hd]: isFrom.CART ? 'displayCartVideoHDInstructions' : '',
    [queryParams.active_account]: 'displayActiveAccountInstructions',
    [queryParams.accept]: 'displaySharedCamerasInstructions',
  }

  const getKeyByInstruction = {
    'become-member': isFrom.CART ? 'displayCartBecomeMemberInstructions' : 'displayBecomeMemberInstructions',
    'transmission-plan': isFrom.CART ? 'displayCartTransmissionPlanInstructions' : 'displayTransmissionPlanInstructions',
    'photo-hd': isFrom.CART ? 'displayCartPhotoHDInstructions' : 'displayPhotoHDInstructions',
    'video-hd': isFrom.CART ? 'displayCartVideoHDInstructions' : '',
    account: 'displayActiveAccountInstructions',
    v: 'displaySharedCamerasInstructions',
  }

  const getActiveInstructions = getKeyByParams.true || getKeyByParams.false || getKeyByInstruction[instruction] || ''

  const getInstructionTranslationKeys = () => getActiveInstructions !== '' ? InstructionsKeys[page][getActiveInstructions] : []

  const getTitleKey = () => {
    if (isFrom.CART) { return InstructionsCartTitle[page] }
    if (isFrom.PROFILE) { return InstructionsProfileTitle[page] }
    if (isFrom.PAYMENTS) { return InstructionsPaymentsTitle[page] }
    return InstructionsTitle[getActiveInstructions]
  }
  const shouldDisplayTitle = isFrom.PAYMENTS || isFrom.PROFILE || isFrom.SHARE_CAMERAS || getInstructionTranslationKeys().length > 0
  return { getActiveInstructions, getInstructionTranslationKeys, getTitleKey, shouldDisplayTitle }
}

export default useInstructions
