import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import filtersActions from './filters.actions'
import filterCollectionState from './filters.state'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'

const useFilterCollection = () => {
  const dispatch = useDispatch()
  const camera = useSelectedCamera()
  const [isFetching, setIsFetching] = useState(false)
  const state = useSelector((state) => state.filterCollection)
  const fetchFilters = () => {
    if (camera && !isFetching && !filterCollectionState.hasFetchFilterCollection(state)) {
      setIsFetching(true)
      dispatch(filtersActions.fetchAllFilters(camera))
    }
  }

  return {
    filterCollection: state.filters,
    selectedFilters: state.selectedFilters,
    fetchFilters,
    addSelectedFilter: (filter) => dispatch(filtersActions.setSelectedFilters(filter)),
    deleteSelectedFilter: (filter) => dispatch(filtersActions.removeSelectedFilters(filter)),
    clearSelectedFilters: () => dispatch(filtersActions.clearSelectedFilters()),
    setFilters: (filters) => dispatch(filtersActions.setFilters(filters)),
  }
}

export default useFilterCollection
