// External import
import { Component, Children, cloneElement } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

class DialogBox extends Component {
  onSubmit = (e) => {
    e.preventDefault()
    this.props.onSubmit()
  }

  render () {
    const children = Children.map(this.props.children, (child) => {
      return cloneElement(child, { onClose: this.props.onClose })
    })

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby="form-dialog-title"
        maxWidth={this.props.maxWidth}
        fullWidth
        className={this.props.className}
      >
        <DialogTitle id="form-dialog-title">{ this.props.title }</DialogTitle>
        <DialogContent classes={this.props.contentClasses}>
          <DialogContentText>{ this.props.contentText }</DialogContentText>
          { children }
        </DialogContent>
      </Dialog>
    )
  }
}

DialogBox.defaultProps = {
  title: 'Your Title',
  contentText: '',
  maxWidth: 'sm',
}

export default DialogBox
