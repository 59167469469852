import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const LastSync = ({ props }) => {
  const theme = useTheme()
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" {...props} data-testid="LastUpdate" style={{ marginBottom: '4px' }}>
      <path
        d="M7.93876 7.93911C9.33464 6.53782 11.1152 5.5826 13.0547 5.19457C14.9941 4.80654 17.0051 5.00319 18.8327 5.75957C20.6602 6.51595 22.222 7.79801 23.32 9.44316C24.418 11.0883 25.0027 13.0225 25 15.0004H27.5C27.5033 12.5279 26.7723 10.1102 25.3998 8.05368C24.0273 5.99719 22.075 4.39456 19.7905 3.44901C17.506 2.50345 14.9922 2.25755 12.5678 2.74247C10.1434 3.2274 7.91754 4.42132 6.17251 6.17286L3.125 3.125V10.625H10.625L7.93876 7.93911ZM22.0625 22.0616C20.6665 23.4627 18.8858 24.4178 16.9464 24.8057C15.0069 25.1936 12.9959 24.997 11.1683 24.2406C9.34078 23.4843 7.77893 22.2024 6.68079 20.5573C5.58265 18.9123 4.99767 16.9782 5.00001 15.0004H2.50001C2.49672 17.473 3.22777 19.8908 4.60045 21.9474C5.97313 24.004 7.92561 25.6066 10.2103 26.5521C12.495 27.4975 15.009 27.7432 17.4335 27.258C19.8581 26.7728 22.0839 25.5785 23.8288 23.8266L26.875 26.875V19.375H19.375L22.0625 22.0616Z"
        fill={theme.palette.text.primary}
      />

    </SvgIcon>
  )
}

export default LastSync
