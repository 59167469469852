import Paper from '@material-ui/core/Paper'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ProfileForm from 'user-module/user/ui/profile/ProfileForm'

const useStyles = makeStyles((theme) => ({
  generalInfo: {
    padding: '24px 16px 32px 16px',
    marginTop: 24,
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 15,
      paddingRight: 0,
    },
  },
}))

const FormProfileSection = () => {
  const classes = useStyles()

  return (
    <Paper elevation={1} className={classes.generalInfo}>
      <ProfileForm form="formProfile" />
    </Paper>
  )
}

export default FormProfileSection
