import React, { ReactElement, ReactNode } from 'react'

import MuiBox from '@material-ui/core/Box'
import { TextField } from '@material-ui/core'

import { WarningIcon } from 'assets/icons/Icons'
import NewWarningIcon from 'assets/icons/navigation/WarningIcon'
import { makeStyles } from '@material-ui/core/styles'

export interface ICInput {
  label: string
  name: string
  type?: string
  select?: boolean
  value?: string
  defaultValue?: string
  register?: any
  disabled?: boolean
  error?: { message: string }
  className?: string
  showHints?: boolean
  hints?: ReactElement
  margin?: 'dense' | 'normal' | 'none'
  onChange?: () => any
  inputProps?: Object
  selectProps?: Object
  children?: ReactNode
  isNewUi?: boolean
}
const useStyles = makeStyles((theme) => ({
  icon: {
    width: '1rem',
    top: '0.375rem',
    position: 'relative',
    marginRight: '0.313rem',
  },
  spypointIcon: {
    marginRight: '5px',
  },

}))
// Bypasses Typescript error: cannot be used as a JSX component
const Box: any = MuiBox

const CInput = (props: ICInput) => {
  const classes = useStyles()
  const VoskerWarningIcon = props?.isNewUi ? NewWarningIcon : WarningIcon
  const errorMessage = props.error?.message && <><VoskerWarningIcon className={props?.isNewUi ? classes.icon : classes.spypointIcon} />{ props.error?.message }</>

  return (
    <Box display="flex" flexDirection="column" mb={0.5}>
      <TextField
        id={props.name}
        name={props.name}
        margin={props.margin || 'dense'}
        variant="outlined"
        className={props.className}
        value={props.value}
        defaultValue={props.defaultValue}
        label={props.label}
        type={props.type}
        select={props.select ?? false}
        disabled={props.disabled}
        inputRef={props.register}
        error={!!props.error}
        helperText={props.error && errorMessage}
        onChange={props.onChange}
        InputProps={props.inputProps}
        FormHelperTextProps={{ style: { marginLeft: '5px' } }}
        children={props.children}
        SelectProps={props.selectProps}
      />

      { props.showHints && props.hints }
    </Box>
  )
}

export default CInput
