import { plans } from './cameraPlans.types'
import { apiCameraAddOns, cameraAddOns } from './cameraAddOns.types'

const addOnsId = {
  [apiCameraAddOns.streaming]: cameraAddOns.streaming,
}

export const getAddOnId = id => addOnsId[id]
export const getApiAddOnId = id => Object.keys(addOnsId).find(key => addOnsId[key] === id)
export const getPlanTranslationKey = name => plans[name] || plans.Empty
