// React Core imports
import { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
// Material-UI imports
import withStyles from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
// Utils import
import { getCountryCurrency, getCurrencySymbol } from 'shared-module/common-utils.js'
import Box from '@material-ui/core/Box'

const styles = {
  error: {
    width: '100%',
    textAlign: 'center',
    fontSize: '12pt',
  },
}

class PlanWarningDiffCurrency extends Component {
  /**
   * Rendering of the component.
   */
  render () {
    const { classes, t, user } = this.props

    const currencies = {
      forced_currency: user.currency,
      wished_currency: getCountryCurrency(user.country),
    }

    return (
      <Box display="flex" justifyContent="center">
        <Paper elevation={1} style={{ maxWidth: '1000px', padding: '16px', marginBottom: '24px' }}>
          <span className={classes.error}>{ t('billing.different_currencies', currencies) }</span>
        </Paper>
      </Box>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
})

export default compose(
  withStyles(styles),
  withTranslation(),
  connect(mapStateToProps),
)(PlanWarningDiffCurrency)
