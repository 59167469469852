import FaBell from 'assets/icons/notification/FaBell'
import FaTimesOctagon from 'assets/icons/notification/FaTimesOctagon'
import FaExclamationTriangle from 'assets/icons/notification/FaExclamationTriangle'

const ICON_TYPES = {
  INFO: FaBell,
  MID: FaTimesOctagon,
  HIGH: FaExclamationTriangle,
  DANGER: FaExclamationTriangle,
}

export default ICON_TYPES
