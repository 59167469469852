import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import OrderNote from 'vosker/src/order-module/order/ui/order-lines/components/OrderNote'

const OrderNotes = ({ order }) => (
  <Box mt={2}>
    <Grid container direction="column" wrap="nowrap" spacing={1}>
      { /* fixme : do not use index as key */ }
      { /* eslint-disable-next-line react/no-array-index-key */ }
      { order.notes?.map((note, i) => <Grid key={i} item><OrderNote note={note} /></Grid>) }
    </Grid>
  </Box>
)

export default OrderNotes
