import { currencies, countries } from 'country-data'

const defaultCurrencyCode = 'CAD'

const currencySymbol = (currencyCode) => {
  switch (currencyCode) {
    case 'CAD':
      return currencies.CAD.symbol
    case 'EUR':
      return currencies.EUR.symbol
    default:
      return currencies.USD.symbol
  }
}

const getCurrencyCode = (countryCode) => {
  return countryCode && countries[countryCode] ? countries[countryCode].currencies[0] : defaultCurrencyCode
}

const getCurrencySymbol = (currencyCode) => {
  return currencySymbol(currencyCode)
}

// eslint-disable-next-line import/no-anonymous-default-export
export default { getCurrencySymbol, getCurrencyCode }
