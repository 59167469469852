import { FETCH_CAMERA_MODELS, SET_CAMERA_MODELS } from 'camera-module/model/core/cameraModelsActionTypes'
import cameraModelsState from 'camera-module/model/core/cameraModelsState'

const cameraModelsReducer = (state = cameraModelsState.init(), action) => {
  switch (action.type) {
    case FETCH_CAMERA_MODELS:
      return cameraModelsState.fetching()
    case SET_CAMERA_MODELS:
      return cameraModelsState.set(action.models)
    default:
      return state
  }
}

export default cameraModelsReducer
