import Grid from '@material-ui/core/Grid'
import CreditCardImage from 'vosker/src/user-module/payment-information/ui/payment-information/details/CreditCardImage'
import creditCardBrand from 'vosker/src/user-module/payment-information/core/creditCardBrand'
import { Box } from '@material-ui/core'

const CreditCardImages = () => (
  <Grid container justifyContent="flex-end">
    <Grid item><Box px={0.25}><CreditCardImage brand={creditCardBrand.visa} height={20} /></Box></Grid>
    <Grid item><Box px={0.25}><CreditCardImage brand={creditCardBrand.mastercard} height={20} /></Box></Grid>
    <Grid item><Box px={0.25}><CreditCardImage brand={creditCardBrand.americanExpress} height={20} /></Box></Grid>
  </Grid>
)

export default CreditCardImages
