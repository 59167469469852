import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

import { validateIntersections } from 'detection-zone-module/ui/helper/DetectionZoneHelper'
import useDetectionZone from 'detection-zone-module/core/detectionZone.hooks'
import PrimaryButton from 'shared-module/new-components/buttons/PrimaryButton'
import SecondaryButton from 'shared-module/new-components/buttons/SecondaryButton'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'
import VoskerSecondaryButton from 'vosker/src/components/buttons/VoskerSecondaryButton'
import VoskerPrimaryButton from 'vosker/src/components/buttons/VoskerPrimaryButton'

const useStyles = makeStyles(theme => ({
  box: ({ hasAnchors, isDefaultZone, isLandscape, isMobileWebBrowser }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: isLandscape && isMobileWebBrowser && 'column-reverse',
    paddingTop: isLandscape && isMobileWebBrowser && hasAnchors && !isDefaultZone ? 70 : 0,
    [theme.breakpoints.only('md')]: {
      flexDirection: 'row',
      paddingTop: 0,
    },
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      left: 10,
    },
  }),
  saveButton: {
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      fontSize: 11,
    },
  },
  deleteButton: ({ isLandscape, isMobileWebBrowser }) => ({
    marginRight: isLandscape && isMobileWebBrowser ? 0 : 8,
    marginTop: isLandscape && isMobileWebBrowser ? 8 : 0,
    [theme.breakpoints.only('md')]: {
      marginRight: 8,
      marginTop: 0,
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      fontSize: 11,
    },
  }),
}))

const DetectionZoneActionButtons = ({ anchorList, hasAnchors, saveDetectionZone, resetDetectionZone, isNewUi, isLandscape }) => {
  const { t } = useTranslation()
  const { isDefaultZone, zoneName, saveZoneName } = useDetectionZone()
  const deleteDialogActions = useConfirmationDialogActions()
  const invalidDialogActions = useConfirmationDialogActions()

  const isMobileWebBrowser = window.navigator.userAgent.toLowerCase().includes('mobi')
  const classes = useStyles({ hasAnchors, isDefaultZone, isLandscape, isMobileWebBrowser })

  const onDeleteConfirm = () => {
    resetDetectionZone()
    deleteDialogActions.close()
  }
  const onDeleteClose = () => deleteDialogActions.close()
  const validateNewZone = () => validateIntersections(anchorList) ? invalidDialogActions.open() : saveDetectionZone()
  const onInvalidClose = () => invalidDialogActions.close()

  const handleSaveClick = () => {
    saveZoneName(zoneName)
    validateNewZone()
  }

  const DeleteButton = isNewUi ? VoskerSecondaryButton : SecondaryButton
  const SaveButton = isNewUi ? VoskerPrimaryButton : PrimaryButton

  return (
    <>
      <Box className={classes.box}>
        { hasAnchors && !isDefaultZone && (
          <DeleteButton
            onClick={deleteDialogActions.open}
            color="primary"
            variant="outlined"
            className={classes.deleteButton}
          >
            { t('button.reset') }
          </DeleteButton>
        ) }

        <SaveButton onClick={handleSaveClick} style={{ marginTop: 0 }} className={classes.saveButton}>
          { t('button.save') }
        </SaveButton>
      </Box>

      <ConfirmationDialog
        isNewUi={isNewUi}
        open={deleteDialogActions.isOpen}
        onClose={onDeleteClose}
        title={t('detection-zone.dialog.delete.title')}
        text={t('detection-zone.dialog.delete.message')}
        onConfirm={onDeleteConfirm}
        onCancel={onDeleteClose}
        confirmText={t('detection-zone.dialog.delete.confirm')}
        cancelText={t('detection-zone.dialog.delete.cancel')}
        useSecondaryButtonForConfirm
      />

      <ConfirmationDialog
        isNewUi={isNewUi}
        open={invalidDialogActions.isOpen}
        onClose={onInvalidClose}
        title={t('detection-zone.dialog.invalid.title')}
        text={t('detection-zone.dialog.invalid.message')}
        onConfirm={onInvalidClose}
        confirmText={t('detection-zone.dialog.invalid.confirm')}
      />
    </>
  )
}

export default DetectionZoneActionButtons
