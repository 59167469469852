import { useDispatch, useSelector } from 'react-redux'
import { openFreeTrialModal, closeFreeTrialModal } from './freeTrialActivationModalActions'

/* note : If we decide to display modal only for v150 + v300, we must add " && !camera.hasFreePlan " */

export const cameraHasFreeTrial = camera => !!camera?.subscription?.isFree

export default () => {
  const dispatch = useDispatch()
  const camera = useSelector(state => state.freeTrialActivationModal.camera)

  return {
    camera,
    openFreeTrialActivationModal: camera => dispatch(openFreeTrialModal(camera)),
    closeFreeTrialActivationModal: () => dispatch(closeFreeTrialModal()),
    cameraHasFreeTrial,
  }
}
