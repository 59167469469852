import Title from 'shared-module/new-components/layout/Title'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'
import PlanNameLine from 'vosker/src/order-module/order/ui/camera-plan/components/PlanNameLine'
import CameraNameLine from 'vosker/src/order-module/order/ui/camera-plan/components/CameraNameLine'
import PaymentFrequencyLine from 'vosker/src/order-module/order/ui/camera-plan/components/PaymentFrequencyLine'

const CameraPlanDescriptionSection = ({ product, camera, setProduct }) => {
  const { t } = useTranslation()

  const changeFrequency = (frequency) => setProduct({ ...product, frequency })

  return (
    <>
      <Title h="h2">{ t('app:order_page.order_summary.title') }</Title>
      <Grid container direction="column" wrap="nowrap" spacing={1}>
        <Grid item><PlanNameLine product={product} /></Grid>
        <Grid item><CameraNameLine camera={camera} /></Grid>
        <Grid item><PaymentFrequencyLine camera={camera} product={product} onFrequencyChange={changeFrequency} /></Grid>
      </Grid>
    </>
  )
}

export default CameraPlanDescriptionSection
