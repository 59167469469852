import { useCallback, useEffect, useState } from 'react'

const useKeyPress = (targetKey) => {
  const [keyPressed, setKeyPressed] = useState(false)

  const downHandler = useCallback(({ key }) => {
    if (key === targetKey) {
      setKeyPressed(true)
    }
  }, [targetKey])

  const upHandler = useCallback(({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false)
    }
  }, [targetKey])

  useEffect(() => {
    document.addEventListener('keydown', downHandler, false)
    document.addEventListener('keyup', upHandler, false)
    return () => {
      document.removeEventListener('keydown', downHandler, false)
      document.removeEventListener('keyup', upHandler, false)
    }
  }, [downHandler, upHandler])

  return keyPressed
}

export default useKeyPress
