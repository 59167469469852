const SECOND = 1000
const MINUTE = 60 * SECOND

export const STREAMING_LOOP_INTERVAL = SECOND
export const STREAM_READY_INTERVAL = 2 * SECOND
export const KEEP_ALIVE_INTERVAL = 2 * SECOND
export const FIRST_KEEPALIVE = 3 * SECOND
export const IDLE_STREAM_WARNING_TIME = 3 * MINUTE
export const IDLE_STREAM_WARNING_COUNT_DOWN = 10 * SECOND

export const ECO_MODE_BUFFER = 10 * SECOND
export const ECO_MODE_DEEP_SLEEP = 10 * MINUTE

export const streamStatus = {
  starting: 'starting',
  started: 'started',
  stopping: 'stopping',
  stopped: 'stopped',
}

export const streamQuality = {
  sd: 'sd',
  hd: 'hd',
}

export const streamRequests = {
  isReady: 'isReady',
  start: 'start',
  stop: 'stop',
  keepAlive: 'keepAlive',
}

export const streamErrors = {
  noPlan: 'NO_PLAN',
  noStreamTime: 'NO_STREAM_TIME',
  streamDisabled: 'STREAM_DISABLED',
  cameraOffline: 'CAMERA_OFFLINE',
  streamStoppedGracefully: 'STREAM_STOPPED_GRACEFULLY',
  streamStoppedUnexpectedly: 'STREAM_STOPPED_UNEXPECTEDLY',
}

export const streamEcoModeWarnings = {
  ecoModeExpired: 'ECO_MODE_EXPIRED',
  ecoModeDeepSleep: 'ECO_MODE_DEEP_SLEEP',
}
