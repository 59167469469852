import clsx from 'clsx'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CButton from 'storybook-component-module/src/components/buttons/CButton'

const useStyles = makeStyles(theme => ({
  button: {
    width: '100%',
    minHeight: '3rem',
    borderRadius: 6,
    background: `linear-gradient(180deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.light} 100%)`,
    '&:hover': {
      background: theme.palette.primary.light,
      transition: 'opacity .3s',
    },
    '&:disabled': {
      color: theme.palette.basic?.black,
      opacity: 0.5,
    },
  },
}))

const VoskerPrimaryButton = ({ className, children, ...props }) => {
  const classes = useStyles()

  return (
    <CButton {...props} variety="primary" disableElevation className={clsx(classes.button, className)}>
      { children }
    </CButton>
  )
}

export default VoskerPrimaryButton
