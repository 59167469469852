import { createContext, useReducer, useState } from 'react'
import { DeleteSelectedPhotos } from 'photo-module/photos/ui/photos-gallery/GalleryHelperFunctions'

export const PhotosContext = createContext(null)

const deleteMultiplePhotosReducer = (state, action) => {
  switch (action.type) {
    case 'add': {
      const filterOutDuplicates = [...new Set([...state, ...action.id])]
      return filterOutDuplicates
    }
    case 'delete':
      return DeleteSelectedPhotos(state, ...action.id)
    case 'reset':
      return []
    default:
      return state
  }
}

export const PhotosProvider = ({ children }) => {
  const [multiSelectEnabled, setmultiSelectEnabled] = useState(false)
  const [photoIdsToDelete, dispatchPhotoIdsToDelete] = useReducer(deleteMultiplePhotosReducer, [])

  return (
    <PhotosContext.Provider value={{ multiSelectEnabled, setmultiSelectEnabled, photoIdsToDelete, dispatchPhotoIdsToDelete }}>
      { children }
    </PhotosContext.Provider>
  )
}
