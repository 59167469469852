import { useTranslation } from 'react-i18next'

import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'
import DialogHdPhotoRequest from 'vosker/src/photos-module/photo/ui/photo-viewer/hd-photo-status-button/dialog/DialogHdPhotoRequest'
import DialogHdPhotoRequestWithNoPhotoLeft from 'vosker/src/photos-module/photo/ui/photo-viewer/hd-photo-status-button/dialog/DialogHdPhotoRequestWithNoPhotoLeft'
import FullHdVideoIcon from 'assets/icons/video/FullHdVideoIcon'
import VoskerOutlinedButton from 'vosker/src/components/buttons/VoskerOutlinedButton'
import makeStyles from '@material-ui/core/styles/makeStyles'

export const useStyles = makeStyles(theme => ({
  button: {
    height: '2.5rem',
    whiteSpace: 'nowrap',
    borderColor: theme.palette.basic?.lightGrey + ' !important',
    '&:hover': {
      background: theme.palette.basic?.dark + ' !important',
    },
  },
  icon: {
    height: '1.25rem !important',
  },
}))

const HdPhotoRequestableButton = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const camera = useSelectedCamera()
  const dialogHdPhotoRequestActions = useConfirmationDialogActions()
  const dialogHdPhotoRequestWithNoPhotoLeftActions = useConfirmationDialogActions()

  const remainingPhotos = camera.subscription.plan.hdCountPerMonth - camera.subscription.hdPhotoCount
  const actionHandler = remainingPhotos > 0 ? dialogHdPhotoRequestActions : dialogHdPhotoRequestWithNoPhotoLeftActions

  return (
    <>
      <VoskerOutlinedButton
        className={classes.button}
        onClick={actionHandler.open}
        icon={<FullHdVideoIcon className={classes.icon} />}
      >
        { t('photo_viewer.request_full_hd_photo.button') }
      </VoskerOutlinedButton>

      { remainingPhotos > 0
        ? <DialogHdPhotoRequest open={dialogHdPhotoRequestActions.isOpen} onClose={dialogHdPhotoRequestActions.close} />
        : <DialogHdPhotoRequestWithNoPhotoLeft open={dialogHdPhotoRequestWithNoPhotoLeftActions.isOpen} onClose={dialogHdPhotoRequestWithNoPhotoLeftActions.close} /> }
    </>
  )
}

export default HdPhotoRequestableButton
