import { streamErrors, STREAMING_LOOP_INTERVAL, streamQuality, streamStatus } from './liveStreaming.states'
import * as streamingApi from '../api/liveStreaming.api'
import {
  getCameraId,
  getElapsedTime,
  getIntervalId,
  getStreamQuality,
  getStreamRemainingTime,
  getStreamRequestedQuality,
  getStreamUrl,
  getUserStreamingTime,
  isCameraReady,
  isStreamStarted,
  shouldConsumeStreamingTime,
  shouldSendIsReadyRequest,
  shouldSendKeepAliveRequest,
  getCameraEcoModeWarning,
} from './liveStreaming.selectors'

export const SET_STREAM_STATUS_ACTION = 'SET_STREAM_STATUS_ACTION'
export const SET_STREAM_QUALITY_ACTION = 'SET_STREAM_QUALITY_ACTION'
export const SET_STREAM_REQUESTED_QUALITY_ACTION = 'SET_STREAM_REQUESTED_QUALITY_ACTION'
export const SET_STREAM_URL_ACTION = 'SET_STREAM_URL_ACTION'
export const SET_STREAM_REMAINING_TIME_ACTION = 'SET_STREAM_REMAINING_TIME_ACTION'
export const SET_STREAM_INTERVAL_ID_ACTION = 'SET_STREAM_INTERVAL_ID_ACTION'
export const SET_STREAM_ELAPSED_TIME = 'SET_STREAM_ELAPSED_TIME'
export const SET_STREAM_ERROR_ACTION = 'SET_STREAM_ERROR_ACTION'
export const SET_CAMERA_LAST_UPDATE_ACTION = 'SET_CAMERA_LAST_UPDATE_ACTION'
export const SET_CAMERA_READY_ACTION = 'SET_CAMERA_READY_ACTION'
export const SET_CAMERA_HAS_ACKNOWLEDGED_ACTION = 'SET_CAMERA_HAS_ACKNOWLEDGED_ACTION'
export const SET_PLAYER_PLAYING_ACTION = 'SET_PLAYER_PLAYING_ACTION'
export const SET_PLAYER_VOLUME_ACTION = 'SET_PLAYER_VOLUME_ACTION'
export const SET_PLAYER_MUTED_ACTION = 'SET_PLAYER_MUTED_ACTION'

const setStreamStatus = status => ({ type: SET_STREAM_STATUS_ACTION, status })
const setStreamQuality = quality => ({ type: SET_STREAM_QUALITY_ACTION, quality })
const setStreamRequestedQuality = requestedQuality => ({ type: SET_STREAM_REQUESTED_QUALITY_ACTION, requestedQuality })
const setStreamUrl = url => ({ type: SET_STREAM_URL_ACTION, url })
const setStreamRemainingTime = remainingTime => ({ type: SET_STREAM_REMAINING_TIME_ACTION, remainingTime })
const setStreamIntervalId = intervalId => ({ type: SET_STREAM_INTERVAL_ID_ACTION, intervalId })
const setStreamElapsedTime = elapsedTime => ({ type: SET_STREAM_ELAPSED_TIME, elapsedTime })
const setStreamError = error => ({ type: SET_STREAM_ERROR_ACTION, error })
const setCameraLastUpdate = lastUpdate => ({ type: SET_CAMERA_LAST_UPDATE_ACTION, lastUpdate })
const setCameraReady = isReady => ({ type: SET_CAMERA_READY_ACTION, isReady })
const setCameraHasAcknowledged = hasAcknowledged => ({ type: SET_CAMERA_HAS_ACKNOWLEDGED_ACTION, hasAcknowledged })
const setPlayerPlaying = isPlaying => ({ type: SET_PLAYER_PLAYING_ACTION, isPlaying })
const setPlayerVolume = volume => ({ type: SET_PLAYER_VOLUME_ACTION, volume })
const setPlayerMuted = isMuted => ({ type: SET_PLAYER_MUTED_ACTION, isMuted })

/* api actions */

const sendIsReadyRequest = () => async (dispatch, getState) => {
  try {
    const {
      isReady = false,
      lastUpdate,
    } = await streamingApi.isReady(getCameraId(getState()))
    if (isReady) {
      await dispatch(cameraIsReady())
    }
    if (lastUpdate) {
      dispatch(setCameraLastUpdate(lastUpdate))
    }
  } catch (error) {
    dispatch(handleStreamError(error))
  }
}

const sendStartRequest = () => async (dispatch, getState) => {
  try {
    dispatch(setStreamError(undefined))
    const requestedQuality = getStreamRequestedQuality(getState())
    const currentQuality = getStreamQuality(getState())

    const {
      url,
      quality,
      remainingStreamingTime,
      lastUpdate,
    } = await streamingApi.start(getCameraId(getState()), requestedQuality || currentQuality)

    dispatch(setStreamQuality(quality))
    dispatch(setStreamRemainingTime(remainingStreamingTime))
    dispatch(receivedStreamUrl(url))
    if (lastUpdate) {
      dispatch(setCameraLastUpdate(lastUpdate))
    }
  } catch (error) {
    dispatch(handleStreamError(error))
  }
}

const sendStopRequest = () => async (dispatch, getState) => {
  try {
    const {
      remainingStreamingTime,
      lastUpdate,
    } = await streamingApi.stop(getCameraId(getState()))
    dispatch(resetLiveStreamingState())
    dispatch(setStreamRemainingTime(remainingStreamingTime))
    if (lastUpdate) {
      dispatch(setCameraLastUpdate(lastUpdate))
    }
  } catch (error) {
    dispatch(resetLiveStreamingState())
  }
}

const sendKeepAliveRequest = () => async (dispatch, getState) => {
  try {
    const requestedQuality = getStreamRequestedQuality(getState())

    const {
      url,
      quality,
      remainingStreamingTime,
      cameraHasAcknowledged,
      lastUpdate,
    } = await streamingApi.keepAlive(getCameraId(getState()), requestedQuality)

    if (requestedQuality) {
      dispatch(setStreamRequestedQuality(undefined))
      dispatch(setStreamQuality(requestedQuality))
    }

    dispatch(setStreamError(undefined))
    dispatch(setStreamRemainingTime(remainingStreamingTime))
    dispatch(receivedStreamUrl(url))

    if (!requestedQuality) {
      dispatch(setStreamQuality(quality))
    }
    if (cameraHasAcknowledged) {
      dispatch(setCameraHasAcknowledged(true))
    }
    if (lastUpdate) {
      dispatch(setCameraLastUpdate(lastUpdate))
    }
  } catch (error) {
    dispatch(handleStreamError(error))
  }
}

/* playing loop actions */

const startPlayingInterval = () => async dispatch => {
  const intervalId = setInterval(() => dispatch(playingInterval()), STREAMING_LOOP_INTERVAL)
  dispatch(setStreamIntervalId(intervalId))
  dispatch(setStreamElapsedTime(0))
  await dispatch(playingInterval())
}

const stopPlayingInterval = () => (dispatch, getState) => {
  clearTimeout(getIntervalId(getState()))
  dispatch(setStreamIntervalId(undefined))
  dispatch(setStreamElapsedTime(0))
}

const restartPlayingInterval = () => dispatch => {
  dispatch(stopPlayingInterval())
  dispatch(startPlayingInterval())
}

const playingInterval = () => async (dispatch, getState) => {
  const elapsedTime = getElapsedTime(getState())

  if (shouldSendIsReadyRequest(getState())) {
    await dispatch(sendIsReadyRequest())
  }
  if (shouldSendKeepAliveRequest(getState())) {
    await dispatch(sendKeepAliveRequest())
  }
  if (shouldConsumeStreamingTime(getState())) {
    await dispatch(consumeStreamingTime())
  }

  dispatch(setStreamElapsedTime(elapsedTime + STREAMING_LOOP_INTERVAL))
}

/* stream actions */

const resetLiveStreamingState = () => dispatch => {
  dispatch(setStreamStatus(streamStatus.stopped))
  dispatch(setStreamUrl(undefined))
  dispatch(setStreamRemainingTime({
    [streamQuality.sd]: 0,
    [streamQuality.hd]: 0,
  }))
  dispatch(setCameraReady(false))
  dispatch(setCameraHasAcknowledged(false))
  dispatch(setPlayerPlaying(false))
}

const handleStreamError = error => (dispatch, getState) => {
  const ecoModeWarning = getCameraEcoModeWarning(getState())
  if (error.message === streamErrors.cameraOffline && !!ecoModeWarning) {
    dispatch(setStreamError(ecoModeWarning))
  } else {
    dispatch(setStreamError(error.message || error.toString()))
  }

  if (error.message === streamErrors.noStreamTime) {
    dispatch(setStreamRemainingTime({
      [streamQuality.sd]: 0,
      [streamQuality.hd]: 0,
    }))
  }

  if (isCameraReady(getState())) {
    dispatch(stopPlayingInterval())
    dispatch(resetLiveStreamingState())
  }
}

const receivedStreamUrl = url => (dispatch, getState) => {
  if (!url) {
    return
  }
  const currentUrl = getStreamUrl(getState())
  if (url !== currentUrl) {
    dispatch(setStreamUrl(url))
  }
  if (!currentUrl) {
    dispatch(setStreamStatus(streamStatus.started))
    dispatch(restartPlayingInterval())
  }
}

const consumeStreamingTime = () => (dispatch, getState) => {
  const currentStreamingTime = getStreamRemainingTime(getState())
  dispatch(setStreamRemainingTime({
    [streamQuality.sd]: Math.max(currentStreamingTime[streamQuality.sd] - 1, 0),
    [streamQuality.hd]: Math.max(currentStreamingTime[streamQuality.hd] - 1, 0),
  }))
}

/* camera actions */

const cameraIsReady = () => async dispatch => {
  dispatch(setCameraReady(true))
  await dispatch(sendStartRequest())
}

/* player actions */

export const play = () => (dispatch, getState) => {
  dispatch(setStreamRemainingTime(getUserStreamingTime(getState())))
  dispatch(setStreamError(undefined))
  dispatch(setStreamStatus(streamStatus.starting))
  dispatch(startPlayingInterval())
}

export const stop = () => async dispatch => {
  dispatch(stopPlayingInterval())
  dispatch(setStreamStatus(streamStatus.stopping))
  await dispatch(sendStopRequest())
}

export const close = () => async (dispatch, getState) => {
  const hasInterval = !!getIntervalId(getState())
  if (isStreamStarted(getState())) {
    await dispatch(stop())
  } else if (hasInterval) {
    dispatch(stopPlayingInterval())
  }
  dispatch(resetLiveStreamingState())
  dispatch(setStreamError(undefined))
}

export const mute = () => dispatch => dispatch(setPlayerMuted(true))

export const unMute = () => dispatch => dispatch(setPlayerMuted(false))

export const changeQuality = quality => dispatch => dispatch(setStreamRequestedQuality(quality))

export const changeVolume = volume => dispatch => dispatch(setPlayerVolume(volume))

export const changePlayerPlayingState = isPlaying => (dispatch, getState) => {
  if (isStreamStarted(getState())) {
    dispatch(setPlayerPlaying(isPlaying))
  }
}
