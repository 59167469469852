import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const ActivationCode = ({ props }) => {
  const theme = useTheme()
  return (
    <SvgIcon width="32" height="42" viewBox="0 0 32 32" {...props} data-testid="ActivationCode">
      <path fillRule="evenodd" clipRule="evenodd" d="M27.6898 0.406982H2.31016C1.03469 0.406982 -0.00012207 1.21184 -0.00012207 2.20387V17.7769C-0.00012207 18.7689 1.03469 19.5737 2.31016 19.5737H27.6898C28.9652 19.5737 30 18.7689 30 17.7769V2.20387C30 1.21184 28.9652 0.406982 27.6898 0.406982ZM6.83008 4.00074H3.7497V15.98H6.83008V4.00074ZM13.5937 4.00074H12.0535C12.0535 7.99381 12.0535 11.9869 12.0535 15.98H13.5937C13.5937 11.9869 13.5937 7.99381 13.5937 4.00074ZM16.406 4.00074H19.4864C19.4864 7.99381 19.4864 11.9869 19.4864 15.98H16.406C16.406 11.9869 16.406 7.99381 16.406 4.00074ZM23.0358 4.00074H26.1162V15.98H23.0358V4.00074ZM8.67011 4.00074H10.2103V15.98L8.67011 15.9558V4.00074Z" fill={theme.palette.text.primary} />

    </SvgIcon>
  )
}

export default ActivationCode
