import notificationApi from 'notifications-module/api/notifications.api'

export const MARK_AS_READ = 'MARK_AS_READ'
export const SORT_NOTIFICATION = 'SORT_NOTIFICATION'
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'

const markAsRead = id => ({ type: MARK_AS_READ, id })
const setNotifications = notifications => ({ type: SET_NOTIFICATIONS, notifications })
const sortNotification = notifications => ({ type: SORT_NOTIFICATION, notifications })

const getAllNotifications = () => dispatch =>
  notificationApi.getAllNotifications()
    .then(notifications => (notifications.length > 0) &&
      dispatch(setNotifications(notifications)) && dispatch(sortNotification(notifications)))

const setNotificationAsRead = (notification) => dispatch => {
  dispatch(markAsRead(notification.id))

  return notificationApi.setAsRead(notification)
}

const getSortedNotification = (notifications) => dispatch =>
  dispatch(sortNotification(notifications))

const notificationActions = {
  getAllNotifications,
  setNotificationAsRead,
  getSortedNotification,
}

export default notificationActions
