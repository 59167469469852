import CameraStatusTile from 'camera-module/camera/ui/camera-status-page/CameraStatusTile'
import PhotoIcon from 'assets/icons/status/PhotoIcon'
import CameraModelIcon from 'assets/icons/status/CameraModelIcon'

const ModelTile = ({ value }) => (
  <CameraStatusTile label="camera.camera_model" icon={<PhotoIcon />} value={value} useSVGIcon />
)

export const CameraTile = ({ value, isNewUi }) => (
  <CameraStatusTile label="camera.camera_model" icon={<CameraModelIcon />} value={value} useSVGIcon isNewUi={isNewUi} />
)

export default ModelTile
