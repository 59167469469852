const INPUT_TYPES = {
  TEXT: 'text',
  NUMBER: 'number',
  SELECT: 'select',
  TOGGLE: 'toggle',
  BOOLEAN: 'boolean',
  TIME: 'time',
  DURATION: 'duration',
  SCHEDULE: 'schedule',
  DETECTION: 'custom',
  BUTTON: 'button',
}

export default INPUT_TYPES
