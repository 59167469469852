import { useTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  item: {
    width: '85%',
    padding: '0.25rem',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '0.375rem',
    background: theme.palette.status?.background?.success,
  },
  text: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: theme.typography?.label?.size,
    color: theme.palette.status?.success,
  },
}))

const DiscountBanner = ({ discount }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid item className={classes.item}>
      <Typography className={classes.text}>{ t('app:plan.item.discount_banner', { discount }) }</Typography>
    </Grid>
  )
}

export default DiscountBanner
