import useInstructions from 'user-module/user/core/useInstruction'
import ExternalPurchaseInstructions from 'storybook-component-module/src/wrappers/headers/ExternalPurchaseInstructions'

const ExternalPageContainer = ({ Layout, Content, instructionTypes }) => {
  const instructions = useInstructions(instructionTypes)
  return (
    <Layout>
      <ExternalPurchaseInstructions
        titleTranslationKey={instructions.getTitleKey()}
        instructionsTranslationKeys={instructions.getInstructionTranslationKeys()}
        fromCart={instructions.isFromCart}
        shouldDisplayTitle={instructions.shouldDisplayTitle}
      />

      { Content }
    </Layout>
  )
}

export default ExternalPageContainer
