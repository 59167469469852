import { useFormikContext } from 'formik'
import MuiButton from '@material-ui/core/Button'

const Button = ({
  children,
  ...buttonProps
}) => {
  const { isSubmitting } = useFormikContext()

  return (
    <MuiButton
      disableElevation
      variant="contained"
      type="button"
      {...buttonProps}
      disabled={isSubmitting}
    >
      { children }
    </MuiButton>
  )
}

export default Button
