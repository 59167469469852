const cameraModel = (m) => ({
  name: m.name,
  isCellular: m.isCellular,
  icon: m.icon,
  helpImage: m.helpImage,
  variants: m.variants,
  isShowcase: m.isShowcase,
})

export default cameraModel
