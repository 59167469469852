import photoApi from 'photo-module/photo/api/photoApi'

const type = 'SET_SUPPORTED_FILTERS'

const set = filters => ({ type, filters })

const getSupportedFilters = (supportedSpecies, filters) => supportedSpecies
  .map(s => filters.find(f => f.filter === s))
  .filter(s => s)

const get = (supportedSpecies) => dispatch => photoApi
  .getPhotoFilters()
  .then(filters => dispatch(set(getSupportedFilters(supportedSpecies, filters))))

const reducer = (state = null, action) => action.type === type ? action.filters : state

const supportedFiltersState = { get: get, reducer: reducer }
export default supportedFiltersState
