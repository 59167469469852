import { useEffect } from 'react'
import Meta from 'shared-module/new-components/head/Meta'

const Head = ({ title, description = '', children }) => {
  useEffect(() => {
    document.title = title
  }, [title])

  return (
    <>
      <Meta name="description" content={description} />
      { children }
    </>
  )
}

export default Head
