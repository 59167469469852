import { useTranslation } from 'react-i18next'
import Badge from '@material-ui/core/Badge'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import useFilterCollection from 'photo-module/filters-bar/core/filters.hooks'
import Box from '@material-ui/core/Box'
import ClearFiltersIcon from 'assets/icons/photo/ClearFiltersIcon'

const useStyles = makeStyles((theme) => ({
  badge: {
    marginLeft: 18,
    borderRadius: 14,
    transform: 'scale(1.5)',
    '& > span': {
      paddingBottom: 2,
      fontSize: 11,
      fontWeight: 'bold',
      lineHeight: 'normal',
      fontFamily: theme.typography.fontFamilyBold,
      color: theme.palette.background?.shadow || theme.palette.background.default,
      background: theme.palette.primary.main,
    },
  },
  badgeShowZero: {
    marginLeft: 18,
    borderRadius: 14,
    transform: 'scale(1.5)',
    '& > span': {
      paddingBottom: 2,
      fontSize: 11,
      fontWeight: 'bold',
      lineHeight: 'normal',
      fontFamily: theme.typography?.fontFamilyBold,
      color: theme.palette.text?.primary,
      background: theme.palette.secondary?.main,
    },
  },
  badgeContainer: {
    [theme.breakpoints.down('xs')]: {
      marginRight: '23px',
      marginTop: '-2px',
    },
  },
  boxContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0px 20px',
      position: 'relative',
    },
  },
  title: {
    fontSize: theme.typography.h2.fontSize,
    textAlign: 'center',
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      marginLeft: '20px',
    },
  },
  clearIcon: {
    fontSize: 45,
    marginTop: 3,
    [theme.breakpoints.down('xs')]: {
      fontSize: '40px !important',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-21%)',
      marginTop: '0',
    },
  },
}))

const FilterCounterDrawer = ({ close }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { selectedFilters, clearSelectedFilters } = useFilterCollection()
  const onClose = () => {
    clearSelectedFilters()
    close()
  }
  return (
    <Box className={classes.boxContainer}>
      <Box>
        <ClearFiltersIcon onClick={onClose} className={classes.clearIcon} />
      </Box>
      <Box>
        <Typography className={classes.title}>{ t('app:filters.title') }</Typography>
      </Box>
      <Box className={classes.badgeContainer}>
        <Badge
          showZero
          badgeContent={selectedFilters.length}
          className={selectedFilters.length === 0 ? classes.badgeShowZero : classes.badge}
        />
      </Box>
    </Box>
  )
}

export default FilterCounterDrawer
