import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { alpha } from '@material-ui/core/styles/colorManipulator'

import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'
import DialogFullHdVideoPendingRequest from 'video-module/video-clip/ui/full-hd-video-status-button/dialog/DialogFullHdVideoPendingRequest'
import VoskerOutlinedButton from 'vosker/src/components/buttons/VoskerOutlinedButton'
import EventPendingIcon from 'vosker/src/photos-module/photos-gallery/ui/events-overlay/icons/EventPendingIcon'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles(theme => ({
  button: {
    height: '2.5rem',
    whiteSpace: 'nowrap',
    borderColor: theme.palette.basic?.lightGrey + ' !important',
    '& .MuiButton-startIcon': {
      marginRight: 0,
    },
    '&:hover': {
      background: alpha(theme.palette.basic?.dark, 0.75) + ' !important',
    },
  },
  icon: {
    '& path': {
      fill: '#F9C20A !important',
    },
    marginTop: '1rem',
    height: '2.25rem !important',
  },
}))

const FullHdVideoPendingButton = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dialogActions = useConfirmationDialogActions()
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  return (
    <>
      <VoskerOutlinedButton
        className={classes.button}
        onClick={dialogActions.open}
        icon={<EventPendingIcon className={classes.icon} />}
      >
        { smallScreen
          ? t('photo_viewer.full_hd_video.short_pending_button')
          : t('photo_viewer.full_hd_video.pending_button').toUpperCase() }
      </VoskerOutlinedButton>

      <DialogFullHdVideoPendingRequest open={dialogActions.isOpen} onClose={dialogActions.close} />
    </>
  )
}

export default FullHdVideoPendingButton
