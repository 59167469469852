import Button from '@material-ui/core/Button'

const AppButton = (props) => {
  const { value, ...childProps } = props

  return (
    <Button disableElevation variant="contained" {...childProps}>
      { value }
    </Button>
  )
}

export default AppButton
