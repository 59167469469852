import * as actions from './detectionZone.actions'
import detectionZoneState from './detectionZone.state'

const detectionZoneReducer = (state = detectionZoneState.defaultState, action) => {
  switch (action.type) {
    case actions.SET_ANCHOR_LIST_ACTION:
      return detectionZoneState.set(state, action)
    case actions.SET_INITIAL_ANCHOR_LIST_ACTION:
      return detectionZoneState.setInitialAnchors(state, action)
    case actions.SET_IS_EDITING_ACTION:
      return detectionZoneState.setIsEditing(state, action)
    case actions.SET_IS_DEFAULT_ZONE_ACTION:
      return detectionZoneState.setIsDefaultZone(state, action)
    case actions.SET_IS_ENABLED_ACTION:
      return detectionZoneState.setIsEnabled(state, action)
    case actions.SET_ZONE_NAME:
      return detectionZoneState.setZoneName(state, action)
    case actions.SET_INITIAL_ZONE_NAME:
      return detectionZoneState.setInitialZoneName(state, action)
    case actions.SET_LAST_SAVED_ZONE_NAME:
      return detectionZoneState.setLastSavedZoneName(state, action)

    default:
      return state
  }
}

export default detectionZoneReducer
