import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Switch } from 'react-router-dom'

import ReactGA from 'react-ga'
import switches from 'shared-module/config/switches'
import appConfig from 'shared-module/config/appConfig'
import Head from 'shared-module/new-components/head/Head'
import PrivateRoute from 'shared-module/private-route/PrivateRoute'

import Profile from 'user-module/user/ui/profile/Profile'
import { LiveStreamingPage } from 'video-module/live-streaming'
import DetectionZonePage from 'detection-zone-module/ui/DetectionZonePage'
import CameraDashboard from 'photo-module/photos/ui/photos-gallery/Dashboard'
import CamerasPage from 'camera-module/cameras/ui/cameras-page/CamerasPage'
import CameraStatusPage from 'camera-module/camera/ui/camera-status-page/CameraStatusPage'
import CameraSettingsPage from 'camera-module/camera/ui/camera-settings-page/CameraSettingsPage'
import PhotoViewerContainer from 'photo-module/photo/ui/photo-viewer/PhotoViewerContainer'
import PhotoContainer from 'photo-module/photos/ui/photos-gallery/PhotoContainer'
import LegacyDetectionZonePage from 'detection-zone-module/legacy/ui/LegacyDetectionZonePage'
import ExternalPageContainer from 'user-module/user/ui/ExternalPageContainer'
import LoginPage from 'user-module/user/ui/login/LoginPage'
import PublicRoute from 'shared-module/public-route/PublicRoute'
import RegisterPage from 'user-module/user/ui/register/RegisterPage'
import ResetPasswordPage from 'user-module/user/ui/reset-password/ResetPasswordPage'
import ChangePasswordPage from 'user-module/user/ui/change-password/ChangePasswordPage'

import VoskerAppBar from 'vosker/src/shared-module/navigation/app-bar/VoskerAppBar'
import VoskerGalleryTopBar from 'photos-module/photos-gallery/ui/VoskerGalleryTopBar'
import VoskerFiltersBar from 'photos-module/photos-gallery/ui/VoskerFiltersBar'
import VoskerViewerActionsBar from 'vosker/src/photos-module/photo/ui/photo-viewer/VoskerViewerActionsBar'
import VoskerPlansPage from 'vosker/src/camera-module/transmission-plan/ui/VoskerPlansPage'
import VoskerMobileAppSplashScreen from 'vosker/src/shared-module/download-app/VoskerMobileAppSplashScreen'
import PaymentInformationPage from 'vosker/src/user-module/payment-information/ui/payment-information/PaymentInformationPage'
import VoskerOrderPage from 'vosker/src/order-module/order/ui/VoskerOrderPage'
import VoskerCameraActivationPage from 'vosker/src/camera-module/camera/ui/add-camera-form/VoskerCameraActivationPage'
import Account from 'vosker/src/user-module/account/Account'
import ManagementPanel from 'user-module/account/management/ManagementPanel'
import VoskerPublicPageLayout from 'vosker/src/shared-module/ui/public-page/VoskerPublicPageLayout'
import INSTRUCTION_TYPES from 'user-module/user/core/instructionTypes'
import PaymentTab from 'vosker/src/user-module/payment-information/ui/payment-information/PaymentTab'
import webview from 'shared-module/webview/webview'

const Routes = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, window.location.search])

  return (
    <Switch>
      <PublicRoute exact path="/login" component={ExternalPageContainer} Layout={VoskerPublicPageLayout} Content={<LoginPage isNewUi />} instructionTypes={INSTRUCTION_TYPES.LOGIN} />
      <PublicRoute exact path="/register" component={ExternalPageContainer} Layout={VoskerPublicPageLayout} Content={<RegisterPage isComplexPassword isNewUi isVosker />} instructionTypes={INSTRUCTION_TYPES.REGISTER} />
      <PublicRoute exact path="/reset-password" component={ExternalPageContainer} Layout={VoskerPublicPageLayout} Content={<ResetPasswordPage isNewUi />} />
      <PublicRoute exact path="/reset-password/:token" component={ExternalPageContainer} Layout={VoskerPublicPageLayout} Content={<ChangePasswordPage isNewUi isComplexPassword />} />
      <Route exact path="/download-app" component={VoskerMobileAppSplashScreen} />
      <PrivateRoute exact path="/camera/:id/detection-zone" component={PrivateRoutesWithoutHead} />
      <PrivateRoute path="/" component={PrivateRoutes} />
    </Switch>
  )
}

const PrivateRoutes = () => {
  const { t } = useTranslation()
  const isWebview = webview.isWebview()

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, window.location.search])

  return (
    <>
      <Head title={t('app:head.title')} />
      { !isWebview && <VoskerAppBar /> }
      <Switch>
        <PrivateRoute exact path="/camera/:id/live-streaming" component={LiveStreamingPage} />
        <PrivateRoute exact path="/camera/:id/status" component={CameraStatusPage} isNewUi />
        <PrivateRoute exact path="/camera/:id/settings" component={CameraSettingsPage} isNewUi isVosker />
        <PrivateRoute exact path="/camera/:id/plan" component={VoskerPlansPage} />
        { appConfig.detectionZone.switch === switches.on && <PrivateRoute exact path="/detection-zone" component={LegacyDetectionZonePage} /> }
        <PrivateRoute
          path="/camera/:id"
          component={CameraDashboard}
          TopBar={VoskerGalleryTopBar}
          FiltersBar={VoskerFiltersBar}
          PhotoViewerActionsBar={VoskerViewerActionsBar}
          PhotoViewerContainer={PhotoViewerContainer}
          PhotoContainer={PhotoContainer}
          isNewUi
        />

        <PrivateRoute exact path="/profile" component={Account} />
        <PrivateRoute path="/account" component={Account} />
        <PrivateRoute exact path="/account/profile" component={Profile} />
        <PrivateRoute exact path="/account/payment" component={PaymentTab} />
        <PrivateRoute exact path="/account/management" component={ManagementPanel} isNewUi />
        <PrivateRoute exact path="/payment-information" component={PaymentInformationPage} />
        <PrivateRoute exact path="/order" component={VoskerOrderPage} />
        <PrivateRoute exact path="/add-camera" key="add" component={CamerasPage} NewCameraForm={VoskerCameraActivationPage} showCameraMenu={null} addCamera />
        <PrivateRoute path="/" key="list" component={CamerasPage} NewCameraForm={VoskerCameraActivationPage} />
      </Switch>
    </>
  )
}

const PrivateRoutesWithoutHead = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, window.location.search])

  return (
    <Switch>
      { appConfig.detectionZone.switch === switches.on && <PrivateRoute exact path="/camera/:id/detection-zone" isNewUi component={DetectionZonePage} /> }
    </Switch>
  )
}
export default Routes
