import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'
import messageActions from 'shared-module/message/messageActions'
import cameraCommandsApi from '../../../api/cameraCommandsApi'
import useSelectedCamera from '../../../core/useSelectedCamera'
import DialogCameraCommandsRequest from './DialogCameraCommandsRequest'

const useStyles = makeStyles((theme) => ({
  disabledBtnStyling: {
    '&:hover': {
      border: '1px solid rgba(255, 255, 255, 0.12)',
    },
  },
}))

const CameraCommandButton = ({ command }) => {
  const classes = useStyles()
  const [requested, setRequested] = useState()
  const { t } = useTranslation()
  const dialogActions = useConfirmationDialogActions()
  const camera = useSelectedCamera()
  const dispatch = useDispatch()
  const [disabledUntil, setDisabledUntil] = useState()
  const [countDown, setCountDown] = useState()

  useEffect(() => {
    const showCountDown = () => {
      const currentTime = new Date().getTime()
      if (currentTime < disabledUntil) {
        setCountDown(new Date(disabledUntil - currentTime))
        return true
      } else {
        setCountDown(undefined)
        return false
      }
    }
    if (disabledUntil) {
      showCountDown()
      const interval = setInterval(() => { if (!showCountDown()) clearInterval(interval) }, 1000)
      return () => {
        clearInterval(interval)
      }
    }
  }, [disabledUntil])

  useEffect(() => {
    setRequested(command.isSet)
    setDisabledUntil(command.disabledUntil)
    return () => {
      setRequested(false)
      setDisabledUntil(undefined)
    }
  }, [command])

  const sendCommand = (cancel) => {
    cameraCommandsApi
      .requestCommand(camera.id, command.name, cancel)
      .then((command) => {
        setRequested(command.isSet)
        setDisabledUntil(command.disabledUntil)
      })
      .catch(() => dispatch(messageActions.showError('errors.catch_all')))
  }
  const onConfirm = () => {
    dialogActions.close()
    sendCommand()
  }
  const cancelCommand = () => sendCommand(true)
  const shouldBeDisabled = requested

  const renderProcessing = () => (
    <Button
      data-testid="cancelButton"
      align="right"
      color="primary"
      variant="outlined"
      onClick={cancelCommand}
      disabled={shouldBeDisabled}
      className={shouldBeDisabled && classes.disabledBtnStyling}
    >
      { shouldBeDisabled && t('camera.settings.buttons.processing') }
    </Button>
  )

  const renderRequestCommandButton = () => (
    <>
      <Button
        data-testid="requestButton"
        align="right"
        color="primary"
        variant="outlined"
        disabled={!!(countDown && disabledUntil)}
        onClick={() => dialogActions.open()}
      >
        { t(`camera.settings.commands.${command.name}`) }
      </Button>
      <DialogCameraCommandsRequest
        camera={camera}
        commandName={command.name}
        open={dialogActions.isOpen}
        onClose={dialogActions.close}
        onConfirm={onConfirm}
      />
    </>
  )

  return (
    <>
      { countDown && (
        <span
          data-testid="countdown"
          style={{
            paddingRight: 10,
            lineHeight: '40px',
          }}
        >
          { countDown.getMinutes() + ':' + ('0' + countDown.getSeconds()).slice(-2) }
        </span>
      ) }
      { requested ? renderProcessing() : renderRequestCommandButton() }
    </>
  )
}
export default CameraCommandButton
