import MediaQuery from 'react-responsive'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'

import { useStreaming } from '../../../core/liveStreaming.hooks'
import formatStreamingTime from '../../streamingTime/streamingTimeFormatter'

const useStyles = makeStyles(theme => ({
  controlContainer: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    paddingLeft: '1rem',
    '@media only screen and (max-width: 300px)': {
      padding: 0,
    },
  },
  progressBarContainer: {
    width: '100%',
  },
  progressBar: {
    height: 5,
    borderRadius: 5,
  },
  label: {
    width: 85,
    textAlign: 'center',
    '@media only screen and (max-width: 350px)': {
      width: 'auto',
      padding: '0 .5rem',
    },
  },
  labelText: {
    fontFamily: theme.typography.fontFamilyBold,
    wrap: 'no-wrap',
  },
}))

const RemainingTime = () => {
  const streaming = useStreaming()
  const classes = useStyles()

  return (
    <div className={classes.controlContainer}>
      <div className={classes.progressBarContainer}>
        <MediaQuery minDeviceWidth={350}>
          <LinearProgress
            data-testid="streaming-time-bar"
            className={classes.progressBar}
            variant="determinate"
            value={streaming.streamRemainingTimePercentage || 0}
          />
        </MediaQuery>
      </div>
      <MediaQuery minDeviceWidth={300}>
        <div className={classes.label}>
          <Typography variant="body2" className={classes.labelText} data-testid="streaming-time-text">
            { formatStreamingTime(streaming.streamRemainingTime) }
          </Typography>
        </div>
      </MediaQuery>
    </div>
  )
}

export default RemainingTime
