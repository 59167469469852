export const isExternalWebApp = () => {
  const location = window.location

  if (!location || !location.search) {
    return false
  }

  const params = new URLSearchParams(location.search)
  return params.has('w') && Number.parseInt(params.get('w')) === 1
}
