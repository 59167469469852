import AddonDescriptionSection from 'vosker/src/order-module/order/ui/camera-add-on/AddonDescriptionSection'
import CameraPlanDescriptionSection from 'vosker/src/order-module/order/ui/camera-plan/CameraPlanDescriptionSection'

const ProductDescriptionSection = ({ camera, product, setProduct }) => {
  const products = {
    AddOn: AddonDescriptionSection,
    CameraPlan: CameraPlanDescriptionSection,
  }

  const DescriptionSection = products[product.id]
  return <DescriptionSection camera={camera} product={product} setProduct={setProduct} />
}

export default ProductDescriptionSection
