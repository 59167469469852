import restapi from 'shared-module/api/restapi'
import * as mapper from './liveStreaming.mapper'
import { streamRequests } from '../core/liveStreaming.states'

const sendStreamingRequest = async (cameraId, action, request = {}) => {
  try {
    const { data } = await restapi.post(
        `/api/v3/camera/${cameraId}/stream`,
        {
          request: action,
          ...request,
        },
    )
    return data
  } catch (error) {
    throw new Error(error.response?.data.message)
  }
}

export const isReady = async cameraId => (
  mapper.buildIsReadyResponse(await sendStreamingRequest(cameraId, streamRequests.isReady))
)

export const start = async (cameraId, quality) => (
  mapper.buildStartResponse(await sendStreamingRequest(cameraId, streamRequests.start, { quality }))
)

export const stop = async cameraId => (
  mapper.buildStopResponse(await sendStreamingRequest(cameraId, streamRequests.stop))
)

export const keepAlive = async (cameraId, quality = undefined) => (
  mapper.buildKeepAliveResponse(await sendStreamingRequest(cameraId, streamRequests.keepAlive, { quality }))
)
