import { Link as RouterLink, useLocation } from 'react-router-dom'
import Link from '@material-ui/core/Link'

const ForwardBackLink = ({ pathname, children, search, ...props }) => {
  const location = useLocation()
  const { from } = location.state || { from: { pathname: '/' } }
  const to = { pathname, state: { from }, search }

  return <Link component={RouterLink} to={to} {...props}>{ children }</Link>
}

export default ForwardBackLink
