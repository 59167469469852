import { combineReducers } from 'redux'
import { streamStatus, streamQuality } from './liveStreaming.states'
import * as actions from './liveStreaming.actions'

const DEFAULT_STREAM_QUALITY = streamQuality.sd
const DEFAULT_PLAYER_VOLUME = 0.25

export const defaultState = {
  stream: {
    status: streamStatus.stopped,
    quality: DEFAULT_STREAM_QUALITY,
    requestedQuality: undefined,
    url: undefined,
    remainingTime: {
      [streamQuality.sd]: 0,
      [streamQuality.hd]: 0,
    },
    intervalId: undefined,
    elapsedTime: 0,
    error: undefined,
  },
  camera: {
    isReady: false,
    hasAcknowledged: false,
    lastUpdate: undefined,
  },
  player: {
    isPlaying: false,
    volume: DEFAULT_PLAYER_VOLUME,
    isMuted: false,
  },
}

const streamReducer = (state = defaultState.stream, action) => {
  switch (action.type) {
    case actions.SET_STREAM_STATUS_ACTION: {
      return { ...state, status: action.status }
    }
    case actions.SET_STREAM_QUALITY_ACTION: {
      return { ...state, quality: action.quality }
    }
    case actions.SET_STREAM_REQUESTED_QUALITY_ACTION: {
      return { ...state, requestedQuality: action.requestedQuality }
    }
    case actions.SET_STREAM_URL_ACTION: {
      return { ...state, url: action.url }
    }
    case actions.SET_STREAM_REMAINING_TIME_ACTION: {
      return { ...state, remainingTime: action.remainingTime }
    }
    case actions.SET_STREAM_INTERVAL_ID_ACTION: {
      return { ...state, intervalId: action.intervalId }
    }
    case actions.SET_STREAM_ELAPSED_TIME: {
      return { ...state, elapsedTime: action.elapsedTime }
    }
    case actions.SET_STREAM_ERROR_ACTION: {
      return { ...state, error: action.error }
    }
    default:
      return state
  }
}

const cameraReducer = (state = defaultState.camera, action) => {
  switch (action.type) {
    case actions.SET_CAMERA_READY_ACTION: {
      return { ...state, isReady: action.isReady }
    }
    case actions.SET_CAMERA_HAS_ACKNOWLEDGED_ACTION: {
      return { ...state, hasAcknowledged: action.hasAcknowledged }
    }
    case actions.SET_CAMERA_LAST_UPDATE_ACTION: {
      return { ...state, lastUpdate: action.lastUpdate }
    }
    default:
      return state
  }
}

const playerReducer = (state = defaultState.player, action) => {
  switch (action.type) {
    case actions.SET_PLAYER_PLAYING_ACTION: {
      return { ...state, isPlaying: action.isPlaying }
    }
    case actions.SET_PLAYER_VOLUME_ACTION: {
      return { ...state, volume: action.volume }
    }
    case actions.SET_PLAYER_MUTED_ACTION: {
      return { ...state, isMuted: action.isMuted }
    }
    default:
      return state
  }
}

export default combineReducers({
  stream: streamReducer,
  camera: cameraReducer,
  player: playerReducer,
})
