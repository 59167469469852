import { useDispatch } from 'react-redux'
import hdPhotoState from 'video-module/video-clip/core/hd-photo-state/hdPhotoState'

const useVoskerPhotoActions = () => {
  const dispatch = useDispatch()

  return {
    requestHdPhoto: (cameraId, photoId) => dispatch(hdPhotoState.requestHdPhoto(cameraId, photoId)),
    clearHdPhoto: (cameraId, photoId) => dispatch(hdPhotoState.clearHdPhoto(cameraId, photoId)),
  }
}

export default useVoskerPhotoActions
