import SvgIcon from '@material-ui/core/SvgIcon'

const LogoutIcon = (props) => (
  <SvgIcon {...props} data-testid="LogoutIcon">
    <g>
      <path
        d="M10.527 18.145H6.93a.45.45 0 01-.45-.45V6.18c0-.246.204-.45.45-.45h3.597a.991.991 0 100-1.98H6.93c-1.34 0-2.43 1.09-2.43 2.43v11.515a2.43 2.43 0 002.43 2.43h3.597a.99.99 0 100-1.98zm0 0"
      />
      <path
        d="M14.867 14.117a.993.993 0 001.403 1.403l2.878-2.88a.993.993 0 00.29-.69v-.02a.998.998 0 00-.075-.371 1.026 1.026 0 00-.215-.32l-2.878-2.88a.993.993 0 00-1.403 0 .988.988 0 000 1.399l1.188 1.191H9.809a.99.99 0 000 1.98h6.246zm0 0"
      />
    </g>
  </SvgIcon>
)

export default LogoutIcon
