/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import messageActions from 'shared-module/message/messageActions'

const POLLING_INTERVAL = 18000
const MAX_ATTEMPTS = 3

const useCameraCommand = (camera, cameraCommandsApi, dispatch, isNewUi) => {
  const [requested, setRequested] = useState(false)
  const [commandName, setCommandName] = useState('')

  useEffect(() => {
    async function fetchData () {
      try {
        const data = await cameraCommandsApi.getCommandForm(camera?.id)

        const isUpdateStatusSet = data.find(item => item.name === 'updateStatus' && item.isSet)
        const isUpdateFirmwareSet = data.find(item => item.name === 'update' && item.isSet)
        const isFormatStorageSet = data.find(item => item.name === 'formatSD' && item.isSet)

        if (isUpdateStatusSet?.isSet || isUpdateFirmwareSet?.isSet || isFormatStorageSet?.isSet) {
          const newName = isUpdateStatusSet?.name || isUpdateFirmwareSet?.name || isFormatStorageSet?.name
          setCommandName(newName)
          setRequested(true)
        } else {
          setRequested(false)
        }
      } catch (error) {
        console.error('Error fetching command form:', error)
      }
    }

    if (camera?.id && isNewUi) {
      fetchData()
    }
  }, [camera?.id])

  const sendCommands = async (command, unset) => {
    setCommandName(command)
    setRequested(true)
    let attempts = 0

    const pollForUpdates = async () => {
      try {
        const data = await cameraCommandsApi.getCommandForm(camera?.id)

        const isUpdateStatusSet = data.find(item => item.name === 'updateStatus' && item.isSet)
        const isUpdateFirmwareSet = data.find(item => item.name === 'update' && item.isSet)
        const isFormatStorageSet = data.find(item => item.name === 'formatSD' && item.isSet)
        if (isUpdateStatusSet?.isSet || isUpdateFirmwareSet?.isSet || isFormatStorageSet?.isSet) {
          setRequested(true)
          if (++attempts < MAX_ATTEMPTS) {
            setTimeout(pollForUpdates, POLLING_INTERVAL)
          }
        } else {
          setRequested(false)
          dispatch(messageActions.showSuccess('camera.settings.messages.updateStatus'))
        }
      } catch (error) {
        console.error('Error fetching command form:', error)
        dispatch(messageActions.showError('camera.settings.messages.failedUpdate'))
        setRequested(false)
      }
    }

    try {
      const requestBody = {
        command: command,
        unset: unset,
      }
      const response = await cameraCommandsApi.requestCommandWithBody(camera?.id, requestBody)

      if (response.success) {
        setTimeout(pollForUpdates, POLLING_INTERVAL)
      } else {
        console.error('Command failed:', response.message)
        dispatch(messageActions.showError('camera.settings.messages.failedUpdate'))
        setRequested(false)
      }
    } catch (error) {
      console.error('Error sending command:', error)
      dispatch(messageActions.showError('camera.settings.messages.failedUpdate'))
      setRequested(false)
    }
  }
  return { requested, commandName, sendCommands }
}

export default useCameraCommand
