import { useDispatch } from 'react-redux'
import camerasState from 'camera-module/cameras/core/camerasState'

const useCamerasActions = () => {
  const dispatch = useDispatch()

  return ({
    get: () => dispatch(camerasState.get()),
    getNonCellular: (cameras) => camerasState.getNonCellular(cameras),
  })
}

export default useCamerasActions
