import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import CreditCardImage from 'vosker/src/user-module/payment-information/ui/payment-information/details/CreditCardImage'
import CreditCardLast4Digits from 'vosker/src/user-module/payment-information/ui/payment-information/details/CreditCardLast4Digits'
import CreditCardExpirationDate from 'vosker/src/user-module/payment-information/ui/payment-information/details/CreditCardExpirationDate'
import { useUserPaymentInformation } from 'vosker/src/user-module/payment-information/core/useUserPaymentInformation'
import VoskerSecondaryButton from 'vosker/src/components/buttons/VoskerSecondaryButton'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  cardInfo: {
    '& div': {
      lineHeight: 1,
      fontWeight: 'normal',
    },
  },
}))

const PaymentInformationDetails = ({ showChange, onChange }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const paymentInformation = useUserPaymentInformation()

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        <CreditCardImage brand={paymentInformation.brand} height={33} />
      </Grid>
      <Grid item xs container className={classes.cardInfo} direction="column">
        <Grid item>
          <CreditCardLast4Digits last4={paymentInformation.last4} />
        </Grid>
        <Grid item>
          <CreditCardExpirationDate month={paymentInformation.expirationMonth} year={paymentInformation.expirationYear} />
        </Grid>
      </Grid>
      <Grid item xs={4} sm={3}>
        { showChange && <VoskerSecondaryButton onClick={onChange}>{ t('app:payment_information_page.buttons.change') }</VoskerSecondaryButton> }
      </Grid>
    </Grid>
  )
}

export default PaymentInformationDetails
