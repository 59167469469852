import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const ExclamationMessageIcon = ({ ...props }) => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="ExclamationMessageIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="11.899" cy="11.8989" r="10" fill={theme.palette.status?.warning} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8244 17.3989L12.9672 17.3989L12.9672 15.4279L10.8244 15.4279L10.8244 17.3989ZM10.8212 14.0561L12.9612 14.0561L12.9612 6.55613L10.8212 6.55613L10.8212 14.0561Z"
        fill={theme.palette.basic?.dark}
      />
    </SvgIcon>
  )
}

export default ExclamationMessageIcon
