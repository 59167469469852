import { useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { alpha } from '@material-ui/core/styles/colorManipulator'

import VoskerCheckbox from 'vosker/src/components/VoskerCheckbox'
import CCheckbox from 'storybook-component-module/src/components/buttons/CCheckbox'
import usePhotosDelete from 'photo-module/photos/ui/photos-gallery/usePhotosDelete'

const useStyles = makeStyles(theme => ({
  unchecked: {
    width: '1.5rem',
    height: '1.5rem',
    margin: '-0.375rem',
    fill: (isNewUi) => alpha(isNewUi ? theme.palette?.background?.shadow : theme.palette?.basic?.lightGrey, 0.25) + ' !important',
    border: `1.6px solid ${theme.palette.basic?.lightGrey}`,
    [theme.breakpoints.down('xs')]: {
      width: '1.5rem',
      height: '1.5rem',
    },
  },
  checked: {
    width: '1.5rem',
    height: '1.5rem',
    margin: '-0.375rem',
    border: 'none',
    [theme.breakpoints.down('xs')]: {
      width: '1.5rem',
      height: '1.5rem',
    },
  },
  selectAllChecked: {
    width: '1.5rem',
    height: '1.5rem',
    margin: '-0.375rem',
    border: 'none',
    [theme.breakpoints.down('xs')]: {
      width: '1.5rem',
      height: '1.5rem',
    },
  },
  selectAllUnchecked: {
    width: '1.5rem',
    height: '1.5rem',
    margin: '-0.375rem',
    fill: theme.palette.background?.paper + ' !important',
    border: `2px solid ${theme.palette.checkbox?.primary}`,
    [theme.breakpoints.down('xs')]: {
      width: '1.5rem',
      height: '1.5rem',
    },
  },
}))

const SelectPhoto = ({ label, photoId, disable, isNewUi = false, allShouldBeChecked, selectAllUnchecked, selectorPerDay }) => {
  const classes = useStyles(isNewUi)
  const theme = useTheme()
  const { multiSelectEnabled, photoIdsToDelete } = usePhotosDelete()

  const renderControls = isNewUi && !allShouldBeChecked && !selectorPerDay
    ? <VoskerCheckbox selectAllUnchecked={selectAllUnchecked} checked={photoIdsToDelete.includes(photoId)} uncheckedClassName={classes.unchecked} checkedClassName={classes.checked} />
    : isNewUi && allShouldBeChecked || selectorPerDay
      ? <VoskerCheckbox checked={allShouldBeChecked} uncheckedClassName={classes.selectAllUnchecked} checkedClassName={classes.selectAllChecked} />
      : (
        <CCheckbox
          roundedCheckbox
          checked={photoIdsToDelete?.includes(photoId)}
          style={{ background: alpha(theme.palette.background.default, 0.4), padding: 0 }}
        />
        )

  return (
    multiSelectEnabled && (
      <FormControlLabel
        control={renderControls}
        label={label}
        disabled={disable}
      />
    )
  )
}

export default SelectPhoto
