import { useTheme } from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'

const VoskerPhotosIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="VoskerPhotosIcon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3732 17.8359C17.4561 17.8359 18.334 16.9581 18.334 15.8751L18.334 4.11044C18.334 3.02753 17.4561 2.14966 16.3732 2.14966L3.62811 2.14966C2.5452 2.14965 1.66733 3.02753 1.66733 4.11044L1.66732 15.8751C1.66732 16.9581 2.5452 17.8359 3.62811 17.8359L16.3732 17.8359ZM4.45191 12.437L9.84406 12.437L15.2362 12.437L11.3124 6.62502L10.068 8.30569L8.82369 9.98637L7.57935 8.30569L4.45191 12.437Z"
        fill={theme.palette.text.primary}
      />

    </SvgIcon>
  )
}

export default VoskerPhotosIcon
