import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'

import EmailField from 'storybook-component-module/src/wrappers/fields/EmailField'
import PublicPageText from 'shared-module/new-components/public-page/PublicPageText'
import ResetPasswordButton from 'user-module/user/ui/reset-password/components/ResetPasswordButton'
import { useFormData } from 'shared-module/new-components/form/FormDataContext'
import { VoskerInputStyles } from 'vosker/src/components/buttons/VoskerInputField'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  input: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.background?.paper,
    },
  },
}))

const ResetPasswordForm = ({ isNewUi }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const form = useFormData()
  const importedClasses = VoskerInputStyles()

  return (
    <Box display={(form.formState.touched.reset) ? 'none' : 'flex'} flexDirection="column">
      <PublicPageText>{ t('reset_password.details') }</PublicPageText>

      <EmailField className={isNewUi ? clsx(importedClasses.input, classes.input) : ''} isNewUi={isNewUi} fieldName="email" validations />

      <ResetPasswordButton isNewUi={isNewUi} />
    </Box>
  )
}

export default ResetPasswordForm
