import { useSelector } from 'react-redux'
import usePhotoActions from 'photo-module/photo/core/usePhotoActions'

const usePhoto = (cameraId, photoId) => {
  const state = useSelector(state => state.photo)
  const photoAction = usePhotoActions()

  if (!photoAction.isPhotoReady(state)) {
    photoAction.get(cameraId, photoId)
  }

  return state.photo
}

export default usePhoto
