import { useEffect } from 'react'
import apm from 'shared-module/monitoring/apm'
import { useLocation } from 'react-router-dom'

const AppMonitoring = () => {
  const location = useLocation()

  useEffect(() => {
    const params = new URLSearchParams(location.search)

    if (params.has('w')) {
      apm.addLabels({ source: 'mobile' })
    } else if (params.has('t')) {
      apm.addLabels({ source: 'techsupport' })
    } else {
      apm.addLabels({ source: 'webapp' })
    }

    window.onerror = (message, source, lineno, colno, error) => {
      apm.log('error', [{ message: message }, { error: error && error.stack }])
    }
  }, [location.search])

  return null
}
export default AppMonitoring
