import { parse } from 'qs'
import { useLocation, useHistory } from 'react-router-dom'
import jwtToken from 'shared-module/token/jwtToken'

const useLoginRedirect = () => {
  const history = useHistory()
  const location = useLocation()

  const urlParams = location.search.replace('?', '')
  const params = parse(urlParams, { encode: false })

  const redirect = () => {
    if (params.redirectTo) {
      const token = jwtToken.get()
      window.location.assign(params.redirectTo + (token ? ('?t=' + token) : ''))
    } else {
      const { from } = location.state || { from: { pathname: '/' } }
      history.replace(from)
    }
  }

  return {
    redirect,
  }
}

export default useLoginRedirect
