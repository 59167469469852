import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'

const useStyles = makeStyles(theme => ({
  cameraName: {
    fontWeight: 600,
    textOverflow: 'ellipsis',
    [theme.breakpoints.only('sm')]: {
      maxWidth: '300px',
    },
    [theme.breakpoints.only('xs')]: {
      maxWidth: '100px',
    },
  },
}))

const VoskerGalleryTopBarCameraName = () => {
  const classes = useStyles()
  const camera = useSelectedCamera()

  return (
    <span>
      <Typography
        title={camera.config.name}
        className={classes.cameraName}
        color="textPrimary"
        noWrap
        data-testid="top-bar-camera-name"
      >
        { camera.config.name }
      </Typography>
    </span>
  )
}

export default VoskerGalleryTopBarCameraName
