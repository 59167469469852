const OK = 200
const CREATED = 201
const BAD_REQUEST = 400
const UNAUTHORIZED = 401
const FORBIDDEN = 403
const NOT_FOUND = 404
const INTERNAL_SERVER_ERROR = 500
const BAD_GATEWAY = 502
const SERVER_UNREACHABLE = 503

export { OK, CREATED, BAD_REQUEST, UNAUTHORIZED, FORBIDDEN, NOT_FOUND, INTERNAL_SERVER_ERROR, BAD_GATEWAY, SERVER_UNREACHABLE }
