import clsx from 'clsx'

import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  item: ({ count }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'space-between',
    justifyContent: 'center',
    margin: '0 4px',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up('lg')]: {
      minWidth: count > 3 ? 200 : 230,
    },
    [theme.breakpoints.down('md')]: {
      minWidth: count > 3 ? 185 : 230,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '8px 0',
      minWidth: count > 3 ? 135 : 165,
      margin: '0 1.5px',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 107.5,
    },
  }),
  horizontalItem: ({ count }) => ({
    [theme.breakpoints.up('md')]: {
      minWidth: count > 3 ? 185 : 230,
    },
  }),
  verticalItem: ({ count }) => ({
    minWidth: 700,
    [theme.breakpoints.between('md', 'xs')]: {
      minWidth: count > 3 ? 185 : 230,
    },
  }),
}))

const DescriptorItem = ({ plans, verticalAlignment = false, horizontalAlignment = false, className, children }) => {
  const classes = useStyles({ count: plans.length })

  return verticalAlignment
    ? (
      <Grid item className={clsx(classes.item, classes.verticalItem, className)}>
        { children }
      </Grid>
      )
    : (
      <Grid item xs sm md="auto" className={clsx(classes.item, horizontalAlignment && classes.horizontalItem, className)}>
        { children }
      </Grid>
      )
}

export default DescriptorItem
