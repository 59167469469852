import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'
import { apiCameraPlans } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.types'

const DialogHdPhotoRequestWithNoPhotoLeft = ({ open, onClose }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const camera = useSelectedCamera()

  const translationKeysByPlan = {
    [apiCameraPlans.free]: 'request_hd_photo_free_plan',
    [apiCameraPlans.elite]: 'request_hd_photo_elite_plan',
  }
  const translationKey = translationKeysByPlan[camera.subscription.plan.name] || 'request_hd_photo_other_plan'

  const upsellPlan = () => history.push(`/camera/${camera.id}/plan`)

  const confirmActions = () => {
    onClose()
    if (camera.subscription.plan.name !== apiCameraPlans.elite) { upsellPlan() }
  }

  return (
    <ConfirmationDialog
      isNewUi
      open={open}
      onClose={onClose}
      title={t('photo_viewer.request_hd_photo.' + translationKey + '.title')}
      text={t('photo_viewer.request_hd_photo.' + translationKey + '.text')}
      confirmText={t('photo_viewer.request_hd_photo.' + translationKey + '.confirm')}
      onConfirm={confirmActions}
      onCancel={camera.subscription.plan.name === apiCameraPlans.elite ? null : onClose}
    />
  )
}

export default DialogHdPhotoRequestWithNoPhotoLeft
