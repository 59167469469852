import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'
import useTheme from '@material-ui/core/styles/useTheme'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'

import orderApi from 'vosker/src/order-module/order/api/orderApi'
import PlusIcon from 'assets/icons/checkout/PlusIcon'
import MinusIcon from 'assets/icons/checkout/MinusIcon'
import Title from 'shared-module/new-components/layout/Title'
import PromoCodeForm from 'vosker/src/order-module/order/ui/promo-code/PromoCodeForm'
import PromoCodeApplied from 'vosker/src/order-module/order/ui/promo-code/PromoCodeApplied'

const useStyles = makeStyles(theme => ({
  root: {
    '& .Mui-expanded:last-child': {
      marginTop: '1rem',
      marginBottom: 0,
    },
    '& .MuiAccordion-root.Mui-expanded': {
      [theme.breakpoints.down('xs')]: {
        marginLeft: '-1rem',
        marginRight: '-1rem',
      },
    },
  },
  container: {
    padding: '1rem',
    marginTop: '1rem',
    boxShadow: 'none',
    [theme.breakpoints.down('xs')]: {
      padding: '1rem 0rem',
      marginLeft: '-1rem',
      marginRight: '-1rem',
    },
  },
  details: {
    display: 'grid',
    paddingBottom: 8,
  },
}))

const PromoCodeSection = ({ resetPreview, createPreviewWithPromocode, product }) => {
  const theme = useTheme()
  const classes = useStyles()
  const { t } = useTranslation()

  const [expanded, setExpanded] = useState(false)
  const [submitted, setIsSubmitted] = useState(false)

  const verifyPromoCode = (promocode, formik) => {
    orderApi.verify(promocode, [product])
      .then(() => {
        setIsSubmitted(!submitted)
        createPreviewWithPromocode(promocode)
      })
      .catch(error => {
        error.response.data.error === 'This code is expired' && formik.setFieldError('promocode', t('app:order_page.promo_code.error.expired'))
        error.response.data.error === 'This code has already been used' && formik.setFieldError('promocode', t('app:order_page.promo_code.error.duplicate'))
        error.response.data.error === 'This code is invalid' && formik.setFieldError('promocode', t('app:order_page.promo_code.error.invalid'))
      })
  }

  const removePromoCode = () => {
    setIsSubmitted(!submitted)
    resetPreview()
  }

  const handleExpansionChange = (panel) => (event, isExpanded) => setExpanded(isExpanded ? panel : false)
  const handlePromoCodeSubmission = (data, formik) => submitted ? removePromoCode() : verifyPromoCode(data.promocode, formik)

  return (
    <div className={classes.root}>
      <Accordion
        className={classes.container}
        style={{ backgroundColor: theme.palette.background.paper }}
        expanded={expanded === 'panel'}
        onChange={handleExpansionChange('panel')}
      >
        <AccordionSummary expandIcon={expanded === false ? <PlusIcon /> : <MinusIcon />}>
          <Title h="h2" mb={0}>{ t('app:order_page.promo_code.title') }</Title>
        </AccordionSummary>

        <AccordionDetails className={classes.details}>
          { submitted ? <PromoCodeApplied onClick={handlePromoCodeSubmission} /> : <PromoCodeForm onSubmit={handlePromoCodeSubmission} /> }
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default PromoCodeSection
