const init = () => ({ fetching: false })
const fetching = () => ({ fetching: true })
const set = camera => ({ fetching: false, camera })

const isReady = (state, id) => isFetching(state.fetching) || sameSelectedCamera(state.camera, id)
const isFetching = fetching => fetching === true
const sameSelectedCamera = (camera, id) => camera !== undefined && (camera.id === id || camera.ucid === id)

const cameraState = { init, fetching, isReady, set }
export default cameraState
