import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useField, useFormikContext } from 'formik'
import Box from '@mui/material/Box'
import TextField from '@material-ui/core/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker'
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { useTheme } from '@material-ui/core'

import { getFieldError } from '../util'

const setDefaultValue = (value) => {
  const date = new Date()
  date.setHours(value.hour)
  date.setMinutes(value.minute)
  return date
}

const timeToObject = (date) => {
  const time = new Date(date)
  const [hour = 0, minute = 0] = [time.getHours(), time.getMinutes()]
  return { hour: parseInt(hour), minute: parseInt(minute) }
}

const TimeObjectInput = ({ name, children, defaultValue, ...controlProps }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [field, meta] = useField(name)
  const { isSubmitting, setTouched, setFieldValue } = useFormikContext()
  const error = getFieldError(meta, t)
  const [value, setValue] = useState('')
  const transitTimeRef = useRef()
  useEffect(() => {
    setValue(setDefaultValue(field.value))
  }, [field.value])

  const onTimePickerChange = (newValue) => {
    setValue(newValue)
    setFieldValue(name, timeToObject(newValue), true)
    setTouched({ name: true })
  }

  return (
    <Box ref={transitTimeRef} theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopTimePicker
          defaultValue={defaultValue}
          ampm={false}
          value={value}
          views={['hours', 'minutes']}
          onChange={onTimePickerChange}
          error={!!error}
          helperText={error}
          disabled={field.disabled || isSubmitting}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
          slots={{
            textField: (params) => (
              <TextField fullWidth variant="outlined" value={value} {...controlProps} {...field} {...params}>
                { children }
              </TextField>
            ),
          }}
          slotProps={{
            popper: {
              placement: 'bottom',
              anchorEl: transitTimeRef.current,
            },
            desktopPaper: {
              sx: {
                inset: '0px auto auto 25px !important',
                width: 350,
                background: theme.palette.background.paper,
                '& .MuiClockNumber-root': {
                  color: theme.palette.text.primary,
                },
              },
            },
          }}
        />
      </LocalizationProvider>
    </Box>
  )
}

export default TimeObjectInput
