import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import useLanguage from 'shared-module/components/language/useLanguage'
import { useLoadUserPaymentInformation } from 'vosker/src/user-module/payment-information/core/useUserPaymentInformation'
import useUserPaymentInformationActions from 'vosker/src/user-module/payment-information/core/useUserPaymentInformationActions'
import messageActions from 'shared-module/message/messageActions'
import orderApi from 'vosker/src/order-module/order/api/orderApi'
import PaymentInformationForm from 'vosker/src/user-module/payment-information/ui/payment-information/form/PaymentInformationForm'
import PaymentInformationDetails from 'vosker/src/user-module/payment-information/ui/payment-information/details/PaymentInformationDetails'
import TermsAndConditions from 'vosker/src/order-module/order/ui/place-order/components/TermsAndConditions'
import PlaceOrderButton from 'vosker/src/order-module/order/ui/place-order/components/PlaceOrderButton'
import Title from 'shared-module/new-components/layout/Title'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'
import OrderSuccessDialog from 'vosker/src/order-module/order/ui/place-order/components/OrderSuccessDialog'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import webview from 'shared-module/webview/webview'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: '2rem',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0.375rem',
    marginTop: '1rem',
    boxShadow: 'none',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('xs')]: {
      margin: '1rem -1rem',
      padding: '2rem 1rem',
    },
  },
}))

const OrderPaymentSection = ({ isPurchaseDisabled, product, promocode }) => {
  const language = useLanguage()
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation()
  const paymentInformation = useLoadUserPaymentInformation()
  const paymentInformationActions = useUserPaymentInformationActions()
  const [changeCard, setChangeCard] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const stripe = useStripe()
  const elements = useElements()
  const successDialogActions = useConfirmationDialogActions()

  const bottomRef = useRef()
  const [divStyle, setDivStyle] = useState({})
  const [focusOnCardElement, setFocusOnCardElement] = useState(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (paymentInformation && paymentInformation.noCard) {
      setDivStyle({ marginBottom: 200 })
    }
  }, [paymentInformation])

  useEffect(() => {
    if (focusOnCardElement) {
      webview.scrollViewForAndroidInputField(bottomRef, () => {})
    }
  }, [focusOnCardElement])

  const onPlaceOrder = () => {
    setSubmitting(true)

    if (paymentInformation?.noCard) {
      paymentInformationActions.addPaymentMethod(stripe, elements)
        .then(() => placeOrder())
        .catch(() => {
          setSubmitting(false)
        })
    } else {
      placeOrder()
    }
  }

  const placeOrder = () => {
    promocode === undefined
      ? orderApi.placeOrder(language, [product])
        .then(() => successDialogActions.open())
        .catch(() => {
          dispatch(messageActions.showError())
          setSubmitting(false)
        })
      : orderApi.placeOrder(language, [product], promocode)
        .then(() => successDialogActions.open())
        .catch(() => {
          dispatch(messageActions.showError())
          setSubmitting(false)
        })
  }

  const resetChangeCard = () => {
    setChangeCard(false)
    setDivStyle({})
  }

  const renderNewCard = () => paymentInformation !== null &&
    paymentInformation.noCard === true &&
      <PaymentInformationForm setFocusOnCardElement={setFocusOnCardElement} />

  const renderChangeCard = () => changeCard && (
    <PaymentInformationForm
      onSuccess={() => resetChangeCard()}
      onCancel={() => resetChangeCard()}
      setFocusOnCardElement={setFocusOnCardElement}
    />
  )

  const onChangeCard = () => {
    setDivStyle({ marginBottom: 200 })
    setChangeCard(true)
  }

  const renderCardInfo = () => !changeCard && paymentInformation !== null && paymentInformation.noCard !== true &&
    <Box mb={3}><PaymentInformationDetails showChange={!changeCard} onChange={onChangeCard} /></Box>

  return (
    <>
      <Box component={Paper} className={classes.paper}>
        <Title h="h2">{ t('app:payment_information_page.sub_title') }</Title>
        { renderNewCard() }
        { renderChangeCard() }
        { renderCardInfo() }
        <div ref={bottomRef} />
        { !changeCard && <TermsAndConditions product={product} /> }
        { !changeCard && <PlaceOrderButton product={product} disabled={isPurchaseDisabled || submitting} onPlaceOrder={onPlaceOrder} /> }
      </Box>
      <OrderSuccessDialog
        isAddOnStreaming={product.addOnId === 'Streaming'}
        isAddOnAddOn={product.id === 'AddOn' && product.addOnId !== 'Streaming'}
        isOpen={successDialogActions.isOpen}
      />
      <div style={divStyle} />
    </>
  )
}

export default OrderPaymentSection
