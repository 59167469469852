import { useTheme } from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'

const SmallEraseIcon = () => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="SmallEraseIcon" width="18" height="18" viewBox="0 0 18 18" fill={theme.palette?.icon?.primary}> xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.99996 0.666992C7.35179 0.666992 5.74062 1.15573 4.37021 2.07141C2.9998 2.98709 1.9317 4.28858 1.30097 5.8113C0.670237 7.33401 0.505209 9.00957 0.826752 10.6261C1.1483 12.2426 1.94197 13.7274 3.10741 14.8929C4.27284 16.0583 5.7577 16.852 7.37421 17.1735C8.99072 17.4951 10.6663 17.3301 12.189 16.6993C13.7117 16.0686 15.0132 15.0005 15.9289 13.6301C16.8446 12.2597 17.3333 10.6485 17.3333 9.00033C17.3309 6.79093 16.4521 4.67273 14.8898 3.11045C13.3276 1.54817 11.2094 0.669419 8.99996 0.666992ZM12.9225 11.7445L11.7441 12.9228L8.99996 10.1787L6.2558 12.9228L5.07746 11.7445L7.82163 9.00033L5.07746 6.25616L6.2558 5.07783L8.99996 7.82199L11.7441 5.07783L12.9225 6.25616L10.1783 9.00033L12.9225 11.7445Z"
        fill={theme.palette?.icon?.primary}
      />
    </SvgIcon>
  )
}

export default SmallEraseIcon
