import IconButton from '@material-ui/core/IconButton'
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded'
import webview from 'shared-module/webview/webview'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(() => ({
  menuButton: {
    marginLeft: -8,
    marginRight: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}))

const WebviewBackButton = () => {
  const classes = useStyles()

  return (
    <IconButton id="main-menu-button" className={classes.menuButton} aria-label="Menu" onClick={() => webview.postMessage({ action: 'close' })}>
      <ArrowBackIosRoundedIcon />
    </IconButton>
  )
}

export default WebviewBackButton
