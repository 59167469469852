import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Tooltip from '@material-ui/core/Tooltip'

import FullHdVideoIcon from 'assets/icons/video/FullHdVideoIcon'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import InformationBarElement from 'photo-module/photos/ui/photos-gallery/information-bar/InformationBarElement'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  text: {
    color: theme.palette.text.primary,
  },
}))

const FullHdVideoCountElement = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const camera = useSelectedCamera()

  const formatVideoCount = subscription => `${subscription.videoCount}/${subscription.videoLimit} ${t('app:photo_gallery_page.videos')}`

  return camera && camera.subscription.plan.videoCountPerMonth
    ? (
      <Tooltip arrow title={t('app:photo_gallery_page.tooltip.nbVideo')} enterDelay={300} leaveDelay={100}>
        <Box px={1} display="flex" alignItems="center">
          <InformationBarElement text={formatVideoCount(camera.subscription)} Icon={FullHdVideoIcon} className={classes.text} />
        </Box>
      </Tooltip>
      )
    : null
}

export default FullHdVideoCountElement
