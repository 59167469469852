import { Trans } from 'react-i18next'
import { FormControl, FormControlLabel, FormGroup, FormLabel } from '@material-ui/core'
import VoskerCheckbox from 'vosker/src/components/VoskerCheckbox'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  textTheme: {
    color: theme.palette.text?.primary,
    marginBottom: '5px',
  },
  unchecked: {
    fill: 'transparent !important',
    border: `1.5px solid ${theme.palette.checkbox?.primary}`,
  },
}))

const NotificationEmail = ({ form, formik, items, name, label, legendKey }) => {
  const classes = useStyles()

  return (
    <div>
      <FormControl component="fieldset" data-testid={name}>
        <FormLabel component="legend" className={classes.textTheme}>
          <Trans i18nKey={legendKey} />
        </FormLabel>

        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={(
              <VoskerCheckbox
                form={form}
                name={name}
                legendKey={legendKey}
                items={items}
                checked={formik.values[name]}
                uncheckedClassName={classes.unchecked}
                onChange={(event) => {
                  if (name === 'notificationEmail') {
                    formik.setFieldValue('notificationEmail', event.target.checked)
                  }
                }}
              />
            )}
            label={label}
          />
        </FormGroup>
      </FormControl>
    </div>
  )
}

export default NotificationEmail
