import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const HumanIcon = () => {
  const theme = useTheme()

  return (
    <SvgIcon style={{ width: 32, height: 32, marginTop: 6 }} data-testid="HumanIcon">
      <path
        d="M10.4409 4.53266C11.1642 4.53266 11.751 3.93251 11.751 3.19278C11.751 2.45306 11.1642 1.85291 10.4409 1.85291C9.71768 1.85291 9.13088 2.45306 9.13088 3.19278C9.13088 3.93251 9.71768 4.53266 10.4409 4.53266ZM13.0202 8.69465L12.3842 8.36527L12.1195 7.54459C11.7183 6.29962 10.5992 5.4287 9.33012 5.42591C8.34756 5.42312 7.80443 5.70784 6.78366 6.12935C6.19413 6.3722 5.71104 6.83278 5.42719 7.41898L5.24433 7.79861C5.03144 8.23965 5.20339 8.7756 5.63189 8.99613C6.05766 9.21665 6.57623 9.038 6.79185 8.59695L6.97471 8.21732C7.07024 8.02192 7.22854 7.8684 7.42505 7.78744L8.15651 7.48597L7.74165 9.18036C7.59973 9.76097 7.75257 10.3779 8.14832 10.8217L9.78318 12.6473C9.97969 12.8678 10.1189 13.133 10.1899 13.4205L10.6893 15.4666C10.8067 15.9439 11.2816 16.237 11.7483 16.117C12.215 15.997 12.5016 15.5113 12.3842 15.0339L11.7783 12.5496C11.7074 12.2621 11.5682 11.9941 11.3716 11.7764L10.1298 10.389L10.5992 8.47134L10.7494 8.93192C10.894 9.38134 11.2052 9.7526 11.6146 9.96474L12.2505 10.2941C12.6763 10.5147 13.1948 10.336 13.4104 9.89496C13.6206 9.45671 13.4487 8.91517 13.0202 8.69465ZM6.77274 12.6222C6.68541 12.8483 6.5544 13.052 6.38518 13.2223L5.02052 14.6208C4.67936 14.9697 4.67936 15.5364 5.02052 15.8853C5.36169 16.2343 5.91301 16.2343 6.25417 15.8853L7.87539 14.2272C8.04188 14.057 8.17288 13.8532 8.26295 13.6271L8.63141 12.6836C7.1221 11.0004 7.57516 11.5168 7.33771 11.1846L6.77274 12.6222Z"
        fill={theme.palette.text.primary}
      />
    </SvgIcon>
  )
}

export default HumanIcon
