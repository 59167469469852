import SvgIcon from '@material-ui/core/SvgIcon'

const PreviousArrowIcon = ({ props, color }) => {
  return (
    <SvgIcon data-testid="PreviousArrowIcon" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_3860_7342)">
        <path
          d="M13.833 1.66637L15.2974 3.13076L8.43732 9.99081L15.3063 16.8598L13.833 18.333L6.42557 10.9256C6.18002 10.6801 6.04206 10.347 6.04205 9.99971C6.04203 9.65242 6.18001 9.31933 6.42557 9.07378L13.833 1.66637Z"
          fill={color || '#F0F1F5'}
          fillOpacity="0.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_3860_7342">
          <rect
            width="20"
            height="20"
            fill={color || '#F0F1F5'}
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

export default PreviousArrowIcon
