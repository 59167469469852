import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'

import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'

const useStyles = makeStyles(theme => ({
  trialDuration: {
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.fontWeightMedium,
    fontWeight: 'bold',
    fontSize: '1em',
  },
}))

const CurrentTrial = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const camera = useSelectedCamera()

  const subscription = camera?.subscription
  const plan = subscription?.plan

  const isCompletingTrial = camera.isCompletingTrial
  const onTrial = !isCompletingTrial && subscription.isFree && !plan.isFree

  if (!onTrial) {
    return null
  }

  return (
    <Typography className={classes.trialDuration}>
      { t('app:plan.trial.title.' + subscription.paymentFrequency) }*
    </Typography>
  )
}

export default CurrentTrial
