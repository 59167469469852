import { useDispatch } from 'react-redux'
import loadingState from 'shared-module/loading/loadingState'

const useLoadingActions = () => {
  const dispatch = useDispatch()

  return ({
    isLoading: (state, key) => loadingState.isLoading(state, key),
    add: key => dispatch(loadingState.add(key)),
    remove: key => dispatch(loadingState.remove(key)),
  })
}

export default useLoadingActions
