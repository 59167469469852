import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const VehiclesIcon = () => {
  const theme = useTheme()

  return (
    <SvgIcon style={{ width: 32, height: 32, marginTop: 6 }} data-testid="VehiclesIcon">
      <path
        d="M16.0373 6.37498H14.4109L13.9588 5.2227C13.4943 4.03802 12.3856 3.27271 11.1341 3.27271H7.68399C6.43271 3.27271 5.32379 4.03802 4.85897 5.2227L4.40693 6.37498H2.78076C2.56859 6.37498 2.41293 6.57829 2.46454 6.78797L2.62754 7.45274C2.66367 7.60065 2.79407 7.70452 2.94375 7.70452H3.48898C3.12414 8.02943 2.88915 8.5017 2.88915 9.03407V10.3636C2.88915 10.8101 3.05649 11.2131 3.32381 11.525V13.0227C3.32381 13.5121 3.7131 13.9091 4.19313 13.9091H5.06245C5.54248 13.9091 5.93177 13.5121 5.93177 13.0227V12.1363H12.8863V13.0227C12.8863 13.5121 13.2756 13.9091 13.7556 13.9091H14.625C15.105 13.9091 15.4943 13.5121 15.4943 13.0227V11.525C15.7616 11.2134 15.9289 10.8104 15.9289 10.3636V9.03407C15.9289 8.5017 15.694 8.02943 15.3294 7.70452H15.8746C16.0243 7.70452 16.1547 7.60065 16.1908 7.45274L16.3538 6.78797C16.4052 6.57829 16.2495 6.37498 16.0373 6.37498ZM6.47319 5.88111C6.67123 5.37643 7.15072 5.04543 7.68399 5.04543H11.1341C11.6674 5.04543 12.1469 5.37643 12.3449 5.88111L12.8863 7.26134H5.93177L6.47319 5.88111ZM5.06245 10.3581C4.54086 10.3581 4.19313 10.0046 4.19313 9.47448C4.19313 8.94432 4.54086 8.59089 5.06245 8.59089C5.58404 8.59089 6.36643 9.38612 6.36643 9.91628C6.36643 10.4464 5.58404 10.3581 5.06245 10.3581ZM13.7556 10.3581C13.234 10.3581 12.4517 10.4464 12.4517 9.91628C12.4517 9.38612 13.234 8.59089 13.7556 8.59089C14.2772 8.59089 14.625 8.94432 14.625 9.47448C14.625 10.0046 14.2772 10.3581 13.7556 10.3581Z"
        fill={theme.palette.text.primary}
      />
    </SvgIcon>
  )
}

export default VehiclesIcon
