import Grid from '@material-ui/core/Grid'
import { useTheme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import Form from 'form-module/ui/Form'
import { validateForm } from './promoCodeValidationSchema'
import useMediaQuery from '@mui/material/useMediaQuery'
import makeStyles from '@material-ui/core/styles/makeStyles'
import VoskerInputField from 'vosker/src/components/buttons/VoskerInputField'
import VoskerSecondaryButton from '../../../../components/buttons/VoskerSecondaryButton'

const useStyles = makeStyles(theme => ({
  button: {
    width: '100%',
    height: '3.5rem',
    borderRadius: 6,
    '&:hover': {
      background: theme.palette.secondary.light,
      transition: 'opacity .3s',
    },
  },
}))

const PromoCodeForm = ({ onSubmit }) => {
  const theme = useTheme()
  const classes = useStyles()
  const { t } = useTranslation()
  const breakpoint = useMediaQuery(theme.breakpoints.down('xs'))

  const initialValues = { promocode: '' }

  return (
    <Form
      data-testid="PromoCodeForm"
      data={initialValues}
      onSubmit={onSubmit}
      validationSchema={validateForm()}
    >
      <Grid container spacing={2} direction={breakpoint ? 'column' : 'row'}>
        <Grid item xs={12} sm={8}>
          <VoskerInputField maxLength={18} name="promocode" label={t('app:order_page.promo_code.placeholder')} />
        </Grid>

        <Grid item xs={12} sm={4}>
          <VoskerSecondaryButton type="submit" variant="contained" color="secondary" className={classes.button}>
            { t('app:order_page.promo_code.submit') }
          </VoskerSecondaryButton>
        </Grid>
      </Grid>
    </Form>
  )
}

export default PromoCodeForm
