import SvgIcon from '@material-ui/core/SvgIcon'

const EmptyLineIcon = ({ className, props }) => {
  return (
    <SvgIcon data-testid="EmptyLineIcon" className={className} style={{ ...props }} viewBox="0 0 24 2" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5539 0.09091H1.24143C0.85688 0.09091 0.54541 0.243467 0.54541 0.431819V1.11364C0.54541 1.30199 0.85688 1.45454 1.24143 1.45454H16.5539C16.9385 1.45454 17.25 1.30199 17.25 1.11364V0.431819C17.25 0.243467 16.9385 0.09091 16.5539 0.09091Z"
      />
    </SvgIcon>
  )
}

export default EmptyLineIcon
