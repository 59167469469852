import * as actions from './cameraPlans.actions'
import cameraPlansState from './cameraPlans.state'

const reducer = (state = cameraPlansState.defaultState, action) => {
  switch (action.type) {
    case actions.SET_CAMERA_PLANS:
      return cameraPlansState.set(state, action)
    case actions.CLEAR_CAMERA_PLANS:
      return cameraPlansState.clear(state, action)

    default:
      return state
  }
}

export default reducer
