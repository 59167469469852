import { useTranslation } from 'react-i18next'
import CameraMessage from 'camera-module/cameras/ui/cameras-page/camera-message/CameraMessage'

const CameraUpdateMessage = () => {
  const { t } = useTranslation()

  return (
    <CameraMessage
      severity="warning"
      title={t('app:camera.warning.title')}
      subtitle={t('app:camera.warning.subtitle')}
      message={t('app:camera.warning.message')}
    />
  )
}

export default CameraUpdateMessage
