import { useSelector } from 'react-redux'
import DeleteMultiplePhotos from 'photo-module/photos/ui/photos-gallery/actions-buttons/DeleteMultiplePhotos'
import Grid from '@material-ui/core/Grid'
import usePhotosDelete from 'photo-module/photos/ui/photos-gallery/usePhotosDelete'
import usePhotosActions from 'photo-module/photos/core/usePhotosActions'
import photoApi from 'photo-module/photo/api/photoApi'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'
import DeletePhotoDialog from './DeletePhotoDialog'
import GalleryOnDemandWrapper from './GalleryOnDemandWrapper'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'

const DeletePhotosContainer = ({ isNewUi = false }) => {
  const dialogActions = useConfirmationDialogActions()
  const { photoIdsToDelete, photosToDeleteActions } = usePhotosDelete()
  const photosActions = usePhotosActions()
  const camera = useSelectedCamera()

  const photos = useSelector((state) => state.galleryPhoto.photos)
  const cameraId = useSelector((state) => state.selectedCamera?.camera?.id)
  const instandModeCapability = camera?.status?.capability?.instantMode
  const galleryOnDemandEnabled = appConfig.galleryOnDemand.switch === switches.on

  const updatePhotoListStore = (list) => {
    const updatedPhotosList = photos.filter((item) => !list.includes(item.id))
    photosActions.set(updatedPhotosList)
  }

  const handleConfirm = () => {
    dialogActions.close()
    photoApi.deletePhotos(cameraId, photoIdsToDelete).then(() => {
      updatePhotoListStore(photoIdsToDelete)
      photosToDeleteActions.reset()
    })
  }

  return (
    <>
      <Grid container display="flex" justifyContent="space-between">
        <Grid item spacing={1} style={{ marginBottom: '1rem' }}>
          { instandModeCapability && galleryOnDemandEnabled && (
            <GalleryOnDemandWrapper
              camera={camera}
              isNewUi={isNewUi}
            />
          ) }
        </Grid>
        <Grid item>
          <DeleteMultiplePhotos disabled={photos.length === 0} isNewUi={isNewUi} handleDelete={() => dialogActions.open()} />
        </Grid>
      </Grid>
      <DeletePhotoDialog
        isNewUi={isNewUi}
        open={dialogActions.isOpen}
        onClose={dialogActions.close}
        onConfirm={handleConfirm}
        photoIdsToDelete={photoIdsToDelete}
      />
    </>
  )
}

export default DeletePhotosContainer
