import SvgIcon from '@material-ui/core/SvgIcon'

const EventErrorIcon = (props) => {
  return (
    <SvgIcon data-testid="EventErrorIcon" width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g filter="url(#filter0_ddd_3254_10567)">
        <path
          d="M6.23363 5.14108C6.9781 4.39373 7.92773 3.88427 8.96211 3.67733C9.99649 3.47038 11.069 3.57526 12.0437 3.97866C13.0184 4.38207 13.8514 5.06583 14.437 5.94324C15.0226 6.82066 15.3344 7.8522 15.333 8.90708H16.6663C16.6681 7.58845 16.2782 6.29898 15.5462 5.20219C14.8142 4.10539 13.773 3.25066 12.5546 2.74636C11.3362 2.24206 9.99548 2.11091 8.70245 2.36954C7.40942 2.62817 6.22231 3.26493 5.29163 4.19908L3.66629 2.57356V6.57356H7.66629L6.23363 5.14108Z"
          fill="#FF6961"
        />
        <path
          d="M13.7663 12.6731C13.0217 13.4203 12.0721 13.9297 11.0377 14.1366C10.0033 14.3435 8.93078 14.2386 7.95608 13.8352C6.98138 13.4318 6.14839 12.7481 5.56271 11.8708C4.97704 10.9934 4.66505 9.96195 4.6663 8.90708H3.33297C3.33121 10.2258 3.7211 11.5153 4.4532 12.6122C5.18529 13.709 6.22662 14.5637 7.44513 15.068C8.66363 15.5722 10.0044 15.7033 11.2975 15.4445C12.5906 15.1857 13.7777 14.5488 14.7083 13.6144L16.333 15.2402V11.2402H12.333L13.7663 12.6731Z"
          fill="#FF6961"
        />
        <path
          d="M10.667 12.4617L9.33702 12.4617V11.1309H10.667V12.4617Z"
          fill="#FF6961"
        />
        <path
          d="M9.33337 10.3369H10.6657L10.6657 5.3369H9.33337L9.33337 10.3369Z"
          fill="#FF6961"
        />
      </g>
      <defs>
        <filter id="filter0_ddd_3254_10567" x="0.333008" y="0.240234" width="19.333" height="19.334" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3254_10567" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_3254_10567" result="effect2_dropShadow_3254_10567" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="0.75" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="effect2_dropShadow_3254_10567" result="effect3_dropShadow_3254_10567" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_3254_10567" result="shape" />
        </filter>
      </defs>

    </SvgIcon>
  )
}

export default EventErrorIcon
