import { Grid, Typography } from '@material-ui/core'

const LineTitle = ({ bold, align, full, overflow, children }) => {
  return (
    <Grid item xs={full ? 12 : 6}>
      <Typography
        variant="body2"
        style={{
          fontWeight: bold ? 'bold' : 'normal',
          overflow: overflow || 'inherit',
          textOverflow: overflow ? 'ellipsis' : 'inherit',
        }}
        align={align}
      >
        { children }
      </Typography>
    </Grid>
  )
}

export default LineTitle
