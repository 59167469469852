
import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const CameraModelIcon = ({ props }) => {
  const theme = useTheme()
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" {...props} data-testid="PhotoIcon">

      <path
        d="M1.28448 2.61611C1.47184 2.53884 1.67264 2.49938 1.8753 2.50001C2.28424 2.50001 2.67643 2.66246 2.9656 2.95163C3.25476 3.24079 3.41722 3.63298 3.41722 4.04192V22.497H3.26064C3.26064 22.497 0.333381 22.497 0.333381 19.5697V4.04192C0.332755 3.83926 0.372204 3.63848 0.449471 3.45112C0.526738 3.26377 0.64029 3.09354 0.783595 2.95024C0.9269 2.80693 1.09713 2.69338 1.28448 2.61611Z"
        fill={theme.palette.text.primary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.8011 8.59765L27.9687 10.2693C28.4812 10.4799 28.9191 10.8388 29.2261 11.3001C29.533 11.7613 29.6951 12.3038 29.6914 12.8579V23.0453C29.6913 23.6004 29.525 24.1428 29.2139 24.6026C28.9027 25.0623 28.461 25.4183 27.9456 25.6246L23.778 27.3009C23.4493 27.4313 23.099 27.4989 22.7454 27.5H13.3498C12.9961 27.4993 12.6458 27.4317 12.3172 27.3009L8.14956 25.6246C7.6342 25.4183 7.19245 25.0623 6.88129 24.6026C6.57013 24.1428 6.40381 23.6004 6.40379 23.0453V12.8579C6.40306 12.2995 6.57056 11.754 6.88444 11.2922C7.19832 10.8305 7.64402 10.474 8.16345 10.2693L12.3311 8.59765C12.6597 8.46679 13.01 8.39924 13.3637 8.39853H22.7685C23.1222 8.39968 23.4724 8.46722 23.8011 8.59765ZM24.6128 15.6411H20.1256V20.1283H24.6128V15.6411Z"
        fill={theme.palette.text.primary}
      />

    </SvgIcon>
  )
}

export default CameraModelIcon
