import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Tab, Tabs } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

export interface ICTabs {
  tabs: Array<any>,
  tabIndex: string,
  color?: 'primary' | 'secondary',
  variant?: 'scrollable' | 'standard' | 'fullWidth',
}

const useStyles = makeStyles(theme => ({
  tabTitle: {
    textTransform: 'capitalize',
    color: theme.palette.text.primary,
    fontSize: theme.typography.body1.fontSize,
    fontFamily: (theme.typography as any).fontFamilyTabs,
  },
}))

const CTabs = (props: ICTabs) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [currentTabIndex, setCurrentTabIndex] = useState(props.tabIndex)

  const handleChange = (event, newIndex) => setCurrentTabIndex(newIndex)

  useEffect(() => setCurrentTabIndex(props.tabIndex), [props.tabIndex])

  return (
    <Tabs
      value={currentTabIndex}
      variant={props.variant || 'standard'}
      indicatorColor={props.color || 'primary'}
      onChange={handleChange}
      centered
    >
      {
        props.tabs.map(({ id, path, label }) => (
          <Tab
            to={id}
            key={id}
            value={path}
            component={Link}
            label={t(`${label}`)}
            className={classes.tabTitle}
          />
        ))
      }
    </Tabs>
  )
}

export default CTabs
