export const hasLowercaseCharacter = password => /[a-z]/.test(password)
export const hasUppercaseCharacter = password => /[A-Z]/.test(password)
export const hasNumber = password => /[0-9]/.test(password)
export const hasSpecialCharacter = password => /[^a-zA-z0-9 ]/.test(password)
export const hasMinimum10Characters = password => password.length >= 10
export const hasMaximum64Characters = password => password.length <= 64

export const isPasswordValid = password => (
  hasLowercaseCharacter(password) &&
  hasUppercaseCharacter(password) &&
  hasNumber(password) &&
  hasSpecialCharacter(password) &&
  hasMinimum10Characters(password) &&
  hasMaximum64Characters(password)
)
