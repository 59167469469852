import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  styleIcon: {
    display: 'block',
    margin: '0 auto',
  },
  styleBatteryBody: {
    fill: 'none',
    stroke: theme.palette.text.primary,
    strokeWidth: 10,
    strokeLinejoin: 'round',
    strokeOpacity: 1,
  },
  styleBatteryPin: {
    fill: 'none',
    stroke: theme.palette.text.primary,
    strokeWidth: 10,
    strokeLinejoin: 'round',
    strokeOpacity: 1,
  },
  styleBatteryLevel: {
    fill: theme.palette.text.primary,
    fillOpacity: 1,
    stroke: 'none',
  },
}))

const BatteryIcon = ({ battery, disabled = false }) => {
  const classes = useStyles()

  const batteryLevelWidth = battery && !disabled
    ? 65 * (battery.percentage / 100)
    : 0

  return (
    <svg width="25" height="15" viewBox="0 0 105 60" className={classes.styleIcon} style={{ opacity: disabled ? 0.5 : 1 }}>
      <rect width="80" height="50" x="5" y="5" className={classes.styleBatteryBody} />
      <rect width="15" height="24" x="85" y="18" className={classes.styleBatteryPin} />
      <rect width={batteryLevelWidth} height="36" x="12" y="12" className={classes.styleBatteryLevel} />
    </svg>
  )
}

export default BatteryIcon
