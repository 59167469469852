import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const CheckoutCardIcon = () => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="CheckoutCardIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 5C1.67157 5 1 5.67157 1 6.5V17.5C1 18.3284 1.67157 19 2.5 19H21.5C22.3284 19 23 18.3284 23 17.5V6.5C23 5.67157 22.3284 5 21.5 5H2.5ZM3.5 9C3.22386 9 3 9.22386 3 9.5V12C3 12.2761 3.22386 12.5 3.5 12.5H7C7.27614 12.5 7.5 12.2761 7.5 12V9.5C7.5 9.22386 7.27614 9 7 9H3.5ZM3 15.1818H6V16.4545H3V15.1818ZM11 15.1818H8V16.4545H11V15.1818ZM13 15.1818H16V16.4545H13V15.1818ZM21 15.1818H18V16.4545H21V15.1818Z"
        fill={theme.palette.text.primary}
      />
      <rect opacity="0.5" x="3" y="15.1818" width="3" height="1.27273" fill={theme.palette.text.primary} />
      <rect opacity="0.5" x="8" y="15.1818" width="3" height="1.27273" fill={theme.palette.text.primary} />
      <rect opacity="0.5" x="13" y="15.1818" width="3" height="1.27273" fill={theme.palette.text.primary} />
      <rect opacity="0.5" x="18" y="15.1818" width="3" height="1.27273" fill={theme.palette.text.primary} />
    </SvgIcon>
  )
}

export default CheckoutCardIcon
