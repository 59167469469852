export const title = {
  hspa_3g_sunset: 'app:notificationCenter.notificationTitle.hspa_3g_sunset',
  post_hspa_3g_sunset: 'app:notificationCenter.notificationTitle.post_hspa_3g_sunset',
  ota_update_started: 'app:notificationCenter.notificationTitle.ota_update_started',
  ota_update_finished: 'app:notificationCenter.notificationTitle.ota_update_finished',
  flex_activation_promo: 'app:notificationCenter.notificationTitle.flex_activation_promo',
}
export const content = {
  hspa_3g_sunset: 'app:notificationCenter.notificationContent.hspa_3g_sunset',
  post_hspa_3g_sunset: 'app:notificationCenter.notificationContent.post_hspa_3g_sunset',
  ota_update_started: 'app:notificationCenter.notificationContent.ota_update_started',
  ota_update_finished: 'app:notificationCenter.notificationContent.ota_update_finished',
  flex_activation_promo: 'app:notificationCenter.notificationContent.flex_activation_promo',
}
