import { useTranslation } from 'react-i18next'

import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  container: {
    padding: '0.75rem 2rem 1.5rem',
  },
  paperNoCamera: {
    marginTop: '50px',
    padding: '20px',
    margin: '110px 20px',
    justifyContent: 'center',
    textAlign: 'center',
  },
  content: {
    width: '100%',
  },
  noCameraMessage: {
    padding: '20px',
    textAlign: 'center',
  },
}))

const NoCameraTile = () => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Container maxWidth="md" className={classes.container}>
      <Paper className={classes.paperNoCamera} elevation={0}>
        <Typography variant="body1" className={classes.noCameraMessage}>{ t('camera_list.no_camera') }</Typography>
      </Paper>
    </Container>
  )
}

export default NoCameraTile
