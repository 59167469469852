import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'

import Title from 'shared-module/new-components/layout/Title'
import makeStyles from '@material-ui/core/styles/makeStyles'
import VoskerSecondaryButton from 'vosker/src/components/buttons/VoskerSecondaryButton'

const useStyles = makeStyles(theme => ({
  grid: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    color: theme.palette.status?.success,
  },
  button: {
    height: '3.5rem',
  },
}))

const PromoCodeApplied = ({ onClick }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Grid container spacing={2} className={classes.grid}>
      <Grid item xs={8}>
        <Title h="button" mb={0} className={classes.text}>{ t('app:order_page.promo_code.success') }</Title>
      </Grid>

      <Grid item xs={4}>
        <VoskerSecondaryButton onClick={onClick} className={classes.button}>
          { t('app:order_page.promo_code.remove') }
        </VoskerSecondaryButton>
      </Grid>
    </Grid>
  )
}

export default PromoCodeApplied
