import makeStyles from '@material-ui/core/styles/makeStyles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import LogoutMenuItem from 'shared-module/navigation/menu/items/LogoutMenuItem'
import LinkMenuItem from 'shared-module/navigation/menu/items/LinkMenuItem'
import MenuHeader from 'shared-module/navigation/menu/items/MenuHeader'
import VoskerLogoLight from 'vosker/src/assets/icons/VoskerLogoLight'
import VoskerHelpMenuItem from 'vosker/src/shared-module/navigation/menu/items/VoskerHelpMenuItem'
import TransmissionPlanIcon from '@material-ui/icons/ShoppingCart'
import CameraInfo from 'shared-module/navigation/menu/camera-items/CameraInfo'
import LiveStreamMenuItem from 'vosker/src/shared-module/navigation/menu/camera-items/LiveStreamMenuItem'

import LanguageOptions from './LanguageOptions'
import SelectedCameraMenuItems from './camera-items/SelectedCameraMenuItems'
import VoskerThemeMenuItem from './items/VoskerThemeMenuItem'
import VoskerEventIcon from 'vosker/src/assets/icons/VoskerEventIcon'
import SideSettingsIcon from 'vosker/src/assets/icons/SideSettingsIcon'
import CameraStatusIcon from 'vosker/src/assets/icons/CameraStatusIcon'
import NewActivateCameraIcon from 'vosker/src/assets/icons/NewActivateCameraIcon'
import CameraIcon from 'camera-module/cameras/ui/cameras-page/icons/CameraIcon'
import AccountIcon from 'vosker/src/assets/icons/AccountIcon'

const useStyles = makeStyles(theme => ({
  drawer: {
    height: '100%',
    background: theme.palette.background.paper,
    width: '322px',
    overflow: 'auto',
    scrollbarWidth: 'thin',
    scrollbarColor: 'transparent transparent',
    '&::-webkit-scrollbar': {
      width: '0px',
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'transparent',
    },
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },
  Wrapper: {
    borderBottom: `solid 1px ${theme.palette.secondary.main}`,
  },
  divider: {
    borderTop: `solid 1px ${theme.palette.secondary.main}`,
  },
  selectedCamera: {
    borderBottom: `solid 1px ${theme.palette.secondary.main}`,
    padding: '0 12px 12px 12px',
    background: theme.palette.background.default,
  },
}))

const VoskerMenu = ({ open, onClose }) => {
  const classes = useStyles()
  const camera = useSelectedCamera()

  return (
    <Drawer open={open} onClose={onClose} PaperProps={{ className: classes.drawer }}>
      <Grid container className={classes.container}>
        <Grid item>
          <MenuHeader onClick={onClose} Logo={VoskerLogoLight} />

          { camera && (
            <Box className={classes.selectedCameraWrapper}>
              <Box className={classes.selectedCamera}>
                <List>
                  <CameraInfo />
                </List>
              </Box>
              <SelectedCameraMenuItems onClose={onClose} />
            </Box>
          ) }

          { camera && (
            <Box className={classes.Wrapper}>
              <List>
                <LiveStreamMenuItem onClose={onClose} />
                <LinkMenuItem Icon={VoskerEventIcon} text="app:photo_gallery_page.title" path={`/camera/${camera.id}`} onClick={onClose} />
              </List>
            </Box>
          ) }
          <Box className={classes.Wrapper}>
            <List>
              { camera
                ? (
                  <>
                    <LinkMenuItem Icon={TransmissionPlanIcon} text="app:plan.page_title" path={`/camera/${camera.id}/plan`} onClick={onClose} />
                    { camera?.isCellular && <LinkMenuItem Icon={CameraStatusIcon} text="menu.camera_status" path={`/camera/${camera.id}/status`} onClick={onClose} /> }
                    { camera?.isCellular && <LinkMenuItem Icon={SideSettingsIcon} text="menu.camera_settings" path={`/camera/${camera.id}/settings`} onClick={onClose} /> }
                  </>
                  )
                : (
                  <Box className={classes.divider}>
                    <LinkMenuItem Icon={CameraIcon} text="menu.select_camera" onClick={onClose} path="/" />
                    <LinkMenuItem Icon={NewActivateCameraIcon} text="menu.add_camera" onClick={onClose} path="/add-camera" />
                  </Box>
                  ) }
            </List>
          </Box>

          <List>
            <VoskerHelpMenuItem onClick={onClose} />
            <LinkMenuItem Icon={AccountIcon} text="app:menu.account" onClick={onClose} path="/account/profile" />
            <LanguageOptions newLanguages closeMenu={onClose} />
            <LogoutMenuItem onClick={onClose} />
          </List>
        </Grid>
        <Box className={classes.divider}>
          <List>
            <VoskerThemeMenuItem closeMenu={onClose} />
          </List>
        </Box>
      </Grid>
    </Drawer>
  )
}

export default VoskerMenu
