import SvgIcon from '@material-ui/core/SvgIcon'

const NextArrowIcon = ({ props }) => {
  return (
    <SvgIcon data-testid="NextArrowIcon" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_3855_1605)">
        <path
          d="M7.16702 18.3336L5.70263 16.8692L12.5627 10.0092L5.69374 3.14025L7.16702 1.66696L14.5744 9.07437C14.82 9.31992 14.9579 9.653 14.958 10.0003C14.958 10.3476 14.82 10.6807 14.5744 10.9262L7.16702 18.3336Z"
          fill="#F0F1F5"
          fillOpacity="0.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_3855_1605">
          <rect
            width="20"
            height="20"
            fill="#F0F1F5"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

export default NextArrowIcon
