import { Trans } from 'react-i18next'
import { isEqual, sortBy } from 'lodash'
import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'

const capitalizeFirstLetter = string => String(string).charAt(0).toUpperCase() + String(string).slice(1)

const translatedOrDefault = (t, key, defaultValue) => t(key) !== key
  ? `app:${t(key)}` !== key ? t(key) : defaultValue
  : defaultValue
const cameraSettingsEnabled = appConfig.cameraSettings.switch === switches.on
export const getTranslatedSectionTitle = (t, sectionKey) => translatedOrDefault(t, `camera.settings.sectionTitles.${sectionKey}`, sectionKey)

export const getTranslatedLabel = (t, name, label) => cameraSettingsEnabled ? translatedOrDefault(t, `camera.settings.fields.${name}.label`, capitalizeFirstLetter(label)) : translatedOrDefault(t, `app:camera.settings.fields.${name}.label`, capitalizeFirstLetter(label))

export const getTranslatedHelpText = (t, name) => {
  if (name === 'powerMode') {
    return getPowerModeHelpText(t)
  }

  return cameraSettingsEnabled ? translatedOrDefault(t, `camera.settings.fields.${name}.help`) : translatedOrDefault(t, `app:camera.settings.fields.${name}.help`)
}

export const getTranslatedAlert = (t, fieldName, fieldLabel, options) => {
  const textByOption = {}
  options.forEach((option) => {
    textByOption[option] = (
      <>
        <p>{ t(`app:camera.settings.fields.${fieldName}.alert.options.${option}`) }</p>
        <p>{ t('camera.settings.messages.delayedSave') }</p>
      </>
    )
  })
  return {
    title: getTranslatedLabel(t, fieldName, fieldLabel),
    options: textByOption,
    buttonLabel: {
      ok: t('camera.settings.buttons.ok'),
    },
  }
}

export const getTranslatedConfirmation = (t, fieldName, fieldLabel, options) => {
  const textByOption = {}
  options.forEach((option) => {
    textByOption[option] = <p>{ t(`app:camera.settings.fields.${fieldName}.confirmation.options.${option}`) }</p>
  })
  return {
    title: getTranslatedLabel(t, fieldName, fieldLabel),
    options: textByOption,
    buttonLabel: {
      accept: t('camera.settings.buttons.confirm'),
      decline: t('camera.settings.buttons.cancel'),
    },
  }
}

export const getTranslatedOption = (t, fieldName, name, label) =>
  translatedOrDefault(t, `app:camera.settings.fields.${fieldName}.options.${name?.toLowerCase()}`, label)

export const cameraSettingsAlertContents = {
  batteryType: {
    title: <Trans i18nKey="camera_settings.confirmation.batteryTypes.title" />,
    text: <Trans i18nKey="camera_settings.confirmation.batteryTypes.text" />,
    confirmText: <Trans i18nKey="camera_settings.confirmation.batteryTypes.confirmText" />,
  },
  captureModeVideo: {
    title: <Trans i18nKey="camera_settings.confirmation.video.title" />,
    text: <Trans i18nKey="camera_settings.confirmation.video.text" />,
    confirmText: <Trans i18nKey="camera_settings.confirmation.video.confirmText" />,
  },
  restrictedInstantWith12V: {
    title: <Trans i18nKey="camera_settings.confirmation.restrictedInstant.title" />,
    text: <Trans i18nKey="camera_settings.confirmation.restrictedInstant.text" />,
    confirmText: <Trans i18nKey="camera_settings.confirmation.restrictedInstant.confirmText" />,
  },
  restrictedInstantWithout12V: {
    title: <Trans i18nKey="camera_settings.confirmation.restrictedInstant.title" />,
    text: <Trans i18nKey="camera_settings.confirmation.restrictedInstant.textRequired" />,
    confirmText: <Trans i18nKey="camera_settings.confirmation.restrictedInstant.confirmText" />,
  },
}

const getPowerModeHelpText = (t) => {
  return (
    <div style={{ padding: '0 10px' }}>
      <h3>{ t('app:powerMode.eco.title') }</h3>

      <ul>
        <li>{ t('app:powerMode.eco.batteryLife') }</li>
        <li>{ t('app:powerMode.eco.motionDetection') }</li>
      </ul>

      <h3>{ t('app:powerMode.performance.title') }</h3>

      <ul>
        <li>{ t('app:powerMode.performance.batteryLife') }</li>
        <li>{ t('app:powerMode.performance.onDemand') }</li>
      </ul>
    </div>
  )
}

export const diff = (obj1, obj2) => {
  if (!obj2 || Object.prototype.toString.call(obj2) !== '[object Object]') {
    return obj1
  }
  const diffs = {}
  let key
  const arraysMatch = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false
    }
    return true
  }
  const compare = (item1, item2, key) => {
    const type1 = Object.prototype.toString.call(item1)
    const type2 = Object.prototype.toString.call(item2)
    if (type2 === '[object Undefined]') {
      diffs[key] = null
      return
    }
    if (type1 !== type2) {
      diffs[key] = item2
      return
    }
    if (type1 === '[object Object]') {
      const objDiff = diff(item1, item2)
      if (Object.keys(objDiff).length > 0) {
        diffs[key] = objDiff
      }
      return
    }
    if (type1 === '[object Array]') {
      if (!arraysMatch(item1, item2)) {
        diffs[key] = item2
      }
      return
    }
    if (type1 === '[object Function]') {
      if (item1.toString() !== item2.toString()) {
        diffs[key] = item2
      }
    } else {
      if (item1 !== item2) {
        diffs[key] = item2
      }
    }
  }
  for (key in obj1) {
    if (Object.prototype.hasOwnProperty.call(obj1, key)) {
      compare(obj1[key], obj2[key], key)
    }
  }
  for (key in obj2) {
    if (Object.prototype.hasOwnProperty.call(obj2, key)) {
      if (!obj1[key] && obj1[key] !== obj2[key]) {
        diffs[key] = obj2[key]
      }
    }
  }
  return diffs
}
export const getPayload = (difference, initialSettings, formData, detectionZoneEnabled) => {
  if (difference.transmitTime) {
    difference.transmitTime = { ...initialSettings.transmitTime, ...difference.transmitTime }
  }
  if (initialSettings.sensibility && difference.sensibility) {
    difference.sensibility = { ...initialSettings.sensibility, ...difference.sensibility }
  }

  if (isEqual(sortBy(initialSettings.schedule), sortBy(formData.schedule))) {
    difference.schedule = undefined
  }
  if (detectionZoneEnabled && Object.keys(formData).includes('detectionZoneNotificationEnabled')) {
    formData.detectionZone.isEnabled = formData.detectionZoneNotificationEnabled
    difference.detectionZone.isEnabled = formData.detectionZoneNotificationEnabled
  }
  if (
    !isEqual(sortBy(initialSettings?.detectionZone?.anchors), sortBy(formData?.detectionZone?.anchors)) ||
    initialSettings?.detectionZone?.isDefaultZone !== formData?.detectionZone?.isDefaultZone ||
    initialSettings?.detectionZone?.isEnabled !== formData.detectionZone?.isEnabled
  ) {
    difference.detectionZone = { ...initialSettings.detectionZone, ...difference.detectionZone }
  } else {
    difference.detectionZone = undefined
    difference.detectionZoneNotificationEnabled = undefined
  }
}
