const buildUserUpdate = user => ({
  firstName: user.firstName,
  lastName: user.lastName,
  consent: user.consent,
  company: user.company,
  cell: user.cell,
  address: user.address,
  apartment: user.apartment,
  city: user.city,
  country: user.country,
  territory: user.territory,
  postalCode: user.postalCode,
  email: user.email,
  notificationEmail: user.notificationEmail,
  marketingEmail: user.marketingEmail,
  language: user.language,
  addressConfirmedDate: user.addressConfirmedDate || undefined,
})

export default buildUserUpdate
