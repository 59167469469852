import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const LiveStreamIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon width="21" height="20" viewBox="0 0 21 20" fill="none" {...props} data-testid="LiveStreamIcon">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6815 2.5H1.91784C1.13507 2.50114 0.500923 3.283 0.5 4.2481V15.7519C0.500923 16.717 1.13507 17.4989 1.91784 17.5H13.6815C14.4641 17.4989 15.0984 16.717 15.0993 15.7519V4.2481C15.0984 3.283 14.4641 2.50114 13.6815 2.5ZM5.92608 7.80538C5.63637 8.50208 5.48436 9.25748 5.47992 10.0225C5.48374 10.7758 5.6307 11.5201 5.91152 12.2084C6.19234 12.8967 6.60092 13.5141 7.11149 14.0215L6.15152 15C5.51728 14.3695 5.0096 13.6026 4.66042 12.7477C4.31125 11.8927 4.12814 10.9682 4.12264 10.0322C4.12805 9.08038 4.31708 8.14042 4.67745 7.27346C5.03782 6.4065 5.56146 5.63195 6.21432 5.00019L7.16147 5.97864C6.63671 6.48627 6.21579 7.10868 5.92608 7.80538ZM8.3256 8.84395C8.17102 9.21413 8.0897 9.61569 8.08695 10.0224C8.09006 10.4218 8.169 10.8162 8.31877 11.1806C8.46855 11.5451 8.68593 11.8718 8.9572 12.1401L7.99851 13.1185C7.60281 12.7259 7.28608 12.2481 7.06834 11.7151C6.8506 11.1822 6.73659 10.6059 6.73351 10.0224C6.73701 9.42937 6.85487 8.84373 7.07943 8.30359C7.30399 7.76344 7.63023 7.28085 8.03696 6.88718L8.98411 7.87403C8.70456 8.14329 8.48018 8.47377 8.3256 8.84395ZM9.89012 8.82571C9.73448 8.97564 9.60962 9.15973 9.5237 9.36592C9.43779 9.57211 9.39275 9.79575 9.39155 10.0222C9.39276 10.2451 9.43632 10.4652 9.51949 10.6687C9.60265 10.8723 9.72362 11.0548 9.87474 11.2048L11.0385 10.0222L9.89012 8.82571Z"
        fill={theme.palette.text.primary}
      />

      <path
        d="M16.1753 12.4503L20.4503 15V5L16.1753 7.54967V12.4503Z"
        fill={theme.palette.text.primary}
      />
    </SvgIcon>
  )
}

export default LiveStreamIcon
