import clsx from 'clsx'
import apm from 'shared-module/monitoring/apm'
import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

import { useFormData } from 'shared-module/new-components/form/FormDataContext'
import useUserActions from 'user-module/user/core/useUserActions'
import FormButton from 'user-module/user/ui/login/components/FormButton'
import useLoginRedirect from 'user-module/user/core/useLoginRedirect'
import EmailField from 'storybook-component-module/src/wrappers/fields/EmailField'
import PasswordField from 'storybook-component-module/src/wrappers/fields/PasswordField'
import { VoskerInputStyles } from 'vosker/src/components/buttons/VoskerInputField'
import eCommerceActions from 'vosker/src/ecommerce-module/core/eCommerceActions'
import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'
import { Mixpanel } from 'vosker/src/Mixpanel'

const useStyles = makeStyles(theme => ({
  input: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.background?.paper,
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      marginBottom: '0.25rem',
    },
  },
}))

const LoginPageForm = ({ isNewUi = false }) => {
  const { t } = useTranslation()
  const form = useFormData()
  const userActions = useUserActions()
  const redirection = useLoginRedirect()
  const classes = useStyles()
  const importedClasses = VoskerInputStyles()

  const eCommerceEnabled = appConfig.eCommerce.switch === switches.on
  const requiredPassword = { required: t('input.password_required') }

  const login = (data) => {
    const { email, password } = data

    userActions.login(email, password)
      .then(async () => {
        await Mixpanel.track('Login', { Email: data.email, 'Event Type': 'Button Clicked' })
        redirection.redirect()
        eCommerceEnabled && eCommerceActions.authenticateUser()
      })
      .catch(e => {
        form.setError([
          { name: 'email', message: '' },
          { name: 'password', message: t('common:login.unauthorized') }])
        apm.log('loggingError', [{ loggingError: e }])
      })
  }

  return (
    <form onSubmit={form.handleSubmit(login)}>
      <Box display="flex" flexDirection="column">
        <EmailField
          isNewUi={isNewUi}
          fieldName="email"
          validations
          className={isNewUi ? clsx(importedClasses.input, classes.input) : ''}
        />

        <PasswordField
          isNewUi={isNewUi}
          fieldName="password"
          validations
          validationSchema={requiredPassword}
          className={isNewUi ? clsx(importedClasses.input, classes.input) : ''}
        />

        <FormButton isNewUi={isNewUi}>{ t('common:login.form.button') }</FormButton>
      </Box>
    </form>
  )
}

export default LoginPageForm
