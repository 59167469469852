import Box from '@material-ui/core/Box'
import OrderDivider from 'vosker/src/order-module/order/ui/order-lines/components/OrderDivider'
import OrderLines from 'vosker/src/order-module/order/ui/order-lines/components/OrderLines'
import OrderNotes from 'vosker/src/order-module/order/ui/order-lines/components/OrderNotes'

const OrderLinesSection = ({ order }) => (
  <>
    <Box pt={0.5} pb={1.5}><OrderDivider /></Box>
    <OrderLines order={order} />
    { order.notes && <OrderNotes order={order} /> }
  </>
)

export default OrderLinesSection
