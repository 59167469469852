import apm from 'shared-module/monitoring/apm'
import userApi from 'user-module/user/api/userApi'
import jwtToken from 'shared-module/token/jwtToken.js'
import appResetState from 'shared-module/app-reset/appResetState'
import messageActions from 'shared-module/message/messageActions'

import { FETCH_USER, SET_USER } from 'user-module/user/core/userActionTypes'

const get = (userId, useActions) => dispatch => {
  dispatch(({ type: FETCH_USER }))

  return dispatch(useActions.getAndSet(userId))
}

const set = user => ({ type: SET_USER, user })

// Utility method
const decodeUserId = dispatch => {
  try {
    return jwtToken.decodeUserId()
  } catch {
    dispatch(userActions.logout())
  }
}

const reload = userId => dispatch =>
  userApi
    .getById(userId)
    .then(user => dispatch(set(user)))

const login = (token, useActions) => dispatch => {
  jwtToken.set(token)

  const userId = decodeUserId(dispatch)

  return dispatch(get(userId, useActions))
}

const register = (user, useActions) => dispatch =>
  userApi.register(user)
    .then(response => dispatch(login(response.data.token, useActions)))

const loginUser = (username, password, useActions) => dispatch => {
  apm.addLabels({ source: 'webapp' })

  return userApi.login({ username, password })
    .then(response => dispatch(login(response.data.token, useActions)))
}

const logout = () => dispatch => {
  window.location = '/'
  jwtToken.remove()
  dispatch(appResetState.reset())
}

const deleteUser = () => dispatch =>
  userApi
    .deleteUser()
    .catch(() => dispatch(messageActions.showError('app:account.management.delete.message.error')))

const updatePassword = (password, newPassword) => (dispatch) =>
  userApi
    .updatePassword(password, newPassword)
    .then(() => dispatch(messageActions.showSuccess('app:account.profile.password.message.success')))
    .catch(() => dispatch(messageActions.showError('app:account.profile.password.message.error')))

const validatePassword = password => dispatch =>
  userApi
    .validatePassword(password)
    .then(response => {
      jwtToken.set(response.data.token)
      return dispatch(messageActions.showSuccess('app:account.management.password.message.success'))
    })
    .catch(() => dispatch(messageActions.showError('app:account.management.password.message.error')))

const userActions =
  {
    get: get,
    set: set,
    reload: reload,
    register: register,
    login: login,
    loginUser: loginUser,
    logout: logout,
    deleteUser: deleteUser,
    updatePassword: updatePassword,
    validatePassword: validatePassword,
  }
export default userActions
