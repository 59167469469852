import { useTheme } from '@material-ui/core'

const YesIcon = ({ props }) => {
  const theme = useTheme()

  return (
    <svg {...props} width="24" height="24" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.56252 11.5713L17.7538 0.399414L20 2.64185L7.68563 14.935C7.38778 15.2324 6.98383 15.3994 6.56252 15.3994C6.14127 15.3994 5.73725 15.2323 5.43936 14.935L0 9.50506L2.24632 7.26257L6.56252 11.5713Z"
        fill={theme.palette.status?.success}
      />
    </svg>
  )
}
export default YesIcon
