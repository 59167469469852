import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const CircleAlert = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon viewBox="0 0 14 14" {...props} data-testid="CircleAlert">
      <path
        d="M0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7Z"
        fill={theme.palette.error.main}
      />
      <path
        d="M6.25 7.75L5.75 5L5.5 2H8.5L8.25 5L7.75 7.75H6.25Z"
        fill={theme.palette.background.default}
      />
      <path
        d="M5.5 9.75C5.5 9.33579 5.83579 9 6.25 9H7.75C8.16421 9 8.5 9.33579 8.5 9.75V11.25C8.5 11.6642 8.16421 12 7.75 12H6.25C5.83579 12 5.5 11.6642 5.5 11.25V9.75Z"
        fill={theme.palette.background.default}
      />
    </SvgIcon>
  )
}

export default CircleAlert
