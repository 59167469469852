import { createContext, useContext } from 'react'

const ActionsContext = createContext(null)

const ActionsProvider = ({ actions, children }) => {
  return (
    <ActionsContext.Provider value={actions}>
      { children }
    </ActionsContext.Provider>
  )
}

const useActions = () => useContext(ActionsContext)

export { useActions, ActionsProvider }
