import store from 'shared-module/redux/store'
import { countries, currencies } from 'country-data'

const SECONDS_IN_AN_HOUR = 60 * 60
const MILLISECONDS_IN_AN_HOUR = SECONDS_IN_AN_HOUR * 1000

/**
 * Format a date coming from Spypoint's camera into a locale specific date
 * format.
 * NOTE: The timezones in the dates created by Spypoint's cameras are
 * incorrect. They are all marked as UTC (Z or -00:00) but they are not. The
 * system should threat those dates as local dates until thos crappy dates are
 * corrected.
 *
 * @param {String} input - String representing the a date in a format recongnized by the Date.pase() method.
 * @param {Object} i18n - A valid i18next object.
 * @param {Boolean} isUTC - A boolean to determine if the input is a real UTC
 * @return {String} The formatted date.
 */
function formatDate (input, i18n, isUTC = false, dateOptions) {
  const options = {
    month: 'long',
    year: 'numeric',
    day: 'numeric',
    ...dateOptions,
  }
  return _formatDate(input, i18n, options, isUTC)
}

/**
 * Format a date coming from Spypoint's camera into a locale specific date
 * format.
 * NOTE: The timezones in the dates created by Spypoint's cameras are
 * incorrect. They are all marked as UTC (Z or -00:00) but they are not. The
 * system should threat those dates as local dates until thos crappy dates are
 * corrected.
 *
 * @param {String} input - String representing the a date in a format recongnized by the Date.pase() method.
 * @param {Object} i18n - A valid i18next object.
 * @return {String} The formatted date.
 */
function formatDateWithDay (input, i18n) {
  const options = {
    weekday: 'long',
    month: 'long',
    year: 'numeric',
    day: 'numeric',
  }
  return _formatDate(input, i18n, options)
}

/**
 * Format a date coming from Spypoint's camera into a locale specific date
 * and time format.
 * NOTE: The timezones in the dates created by Spypoint's cameras are
 * incorrect. They are all marked as UTC (Z or -00:00) but they are not. The
 * system should threat those dates as local dates until thos crappy dates are
 * corrected.
 *
 * @param {String} input - String representing the a date in a format recongnized by the Date.pase() method.
 * @param {Object} i18n - A valid i18next object.
 * @return {String} The formatted date and time.
 */
function formatDateTime (input, i18n) {
  const options = {
    month: 'long',
    year: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }
  return _formatDate(input, i18n, options)
}

/**
 * Correct the date string coming from Spypoint's camera and remove the
 * timezone data that is incorrect in the provided date string.
 * NOTE: The timezones in the dates created by Spypoint's cameras are
 * incorrect. They are all marked as UTC (Z or -00:00) but they are not. The
 * system should threat those dates as local dates until thos crappy dates are
 * corrected.
 *
 * @param {String} input - Date string provided by Spypoint's camera.
 * @return {String} The formatted date string, without the erroneous timezone information.
 */
function _correctDate (input) {
  if (input.slice(-1) === 'Z') {
    return input.slice(0, -1)
  } else if (input.slice(-6) === '-00:00') {
    return input.slice(0, -6)
  } else {
    return input
  }
}

function _formatDate (input, i18n, options, isUTC = false) {
  try {
    if (input && input.length > 0) {
      if (!isUTC) {
        input = _correctDate(input)
      }
      const correctedInput = _correctDate(input)
      const date = new Date(correctedInput)
      if (options.hour) {
        return date.toLocaleTimeString(i18n.language, options)
      } else {
        return date.toLocaleDateString(i18n.language, options)
      }
    } else {
      return i18n.t('n_a')
    }
  } catch (error) {
    console.error('formatDateTime: ' + error)
    return i18n.t('n_a')
  }
}

/**
 * Convert number of seconds to an object with hour, minute, second
 * @param {number} seconds
 * @return {Object} {hour: number, minute: number, second: number}
 */
function convertSecondToHms (seconds) {
  seconds = Number(seconds)

  const hour = Math.floor(seconds / SECONDS_IN_AN_HOUR)
  const minute = Math.floor(seconds % SECONDS_IN_AN_HOUR / 60)
  const second = Math.floor(seconds % SECONDS_IN_AN_HOUR % 60)

  return { hour: hour, minute: minute, second: second }
}

/**
 * Convert an object with hour, minute, second fields to seconds
 * @param {Object} time - Time data
 * @param {number} time.hour
 * @param {number} time.minute
 * @param {number} time.second
 */
function convertHmsToSeconds ({ hour, minute, second }) {
  hour = Number(hour)
  minute = Number(minute)
  second = Number(second)

  return (hour * SECONDS_IN_AN_HOUR) + (minute * 60) + second
}

function getHoursDiffBetweenDates (startDate, endDate) {
  return (new Date(endDate) - new Date(startDate)) / MILLISECONDS_IN_AN_HOUR
}

function getFormattedDate (textDate) {
  const date = new Date(textDate)
  return date.toLocaleDateString('en-US', { timeZone: 'GMT', month: 'long', year: 'numeric' })
}

function getCurrencySymbol (currencyCode) {
  switch (currencyCode) {
    case 'CAD':
      return currencies.CAD.symbol
    case 'EUR':
      return currencies.EUR.symbol
    default:
      return currencies.USD.symbol
  }
}

function getCountryCurrency (countryCode) {
  try {
    return countries[countryCode].currencies[0]
  } catch (error) {
    return 'CAD'
  }
}

function getUserCurrency () {
  const userCurrency = store.getState().user.currency
  const userCountry = store.getState().user.country
  if (userCurrency && typeof userCurrency !== 'undefined') {
    return userCurrency
  } else {
    return getCountryCurrency(userCountry)
  }
}

function doesCurrencyFitWithCountry () {
  const userCurrency = store.getState().user.currency
  const userCountry = store.getState().user.country
  if (userCurrency && typeof userCurrency !== 'undefined') {
    if (userCurrency !== getCountryCurrency(userCountry)) {
      return false
    }
  }
  return true
}

function getUserCurrencySymbol () {
  return getCurrencySymbol(getUserCurrency())
}

const formatAmount = (
  amount,
  {
    currency = 'USD',
    language = 'en',
    hideZerosDecimals = false,
    noSpace = false,
  }) => {
  let formattedAmount = new Intl.NumberFormat(
    `${language}-CA`,
    {
      style: 'currency',
      currency,
    },
  )
    .format(amount)
    .replace(/[a-z]/ig, '')
    .trim()

  if (hideZerosDecimals) {
    formattedAmount = formattedAmount.replace(/[.|,]00/, '')
  }

  if (noSpace) {
    formattedAmount = formattedAmount.replace(/\s/g, '')
  }

  return formattedAmount
}

export {
  formatDate,
  formatDateWithDay,
  formatDateTime,
  convertSecondToHms,
  getHoursDiffBetweenDates,
  getFormattedDate,
  convertHmsToSeconds,
  getCurrencySymbol,
  getCountryCurrency,
  getUserCurrency,
  doesCurrencyFitWithCountry,
  getUserCurrencySymbol,
  formatAmount,
}
