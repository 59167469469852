import * as Yup from 'yup'
import { isAValidActivationId, sanitizeActivationInput } from 'camera-module/camera/ui/add-camera-form/activation-id-field/isActivationIdValid'

export default () => {
  const rules = {}

  rules.deviceModel = Yup.string().required()
  rules.deviceName = Yup.string().required()
  rules.activationCode = Yup.string().required().test('id_validation', value => isAValidActivationId(sanitizeActivationInput(value)))

  return Yup.object().shape(rules)
}
