import { useTranslation } from 'react-i18next'
import { MobileAppSplashScreenStyles } from 'shared-module/download-app/MobileAppSplashScreen.style'
import Head from 'shared-module/new-components/head/Head'
import Meta from 'shared-module/new-components/head/Meta'
import VoskerLogoLight from 'vosker/src/assets/icons/VoskerLogoLight'

const VoskerMobileAppSplashScreen = () => {
  const classes = MobileAppSplashScreenStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      <Head title={t('app:head.title')}>
        <Meta name="robots" content="noindex" />
      </Head>
      <header>
        <VoskerLogoLight style={{ width: '150px', height: '100%' }} />
      </header>
      <main>
        <p>{ t('app:download_app.title') }</p>
        <p>{ t('app:download_app.subtitle') }</p>
        <p>{ t('app:download_app.content') }</p>
        <p>{ t('app:download_app.content_next') }</p>
      </main>
      <footer>
        <span>
          <a href="https://apps.apple.com/ca/app/vosker/id1447158671">
            <img src="/images/app-from-store/vosker-appstore-badge.png" alt={t('app:download_app.alt_text_appstore')} />
          </a>
          <a href=" https://play.google.com/store/apps/details?id=com.vosker.voskerApp">
            <img src="/images/app-from-store/vosker-google-play-badge.png" alt={t('app:download_app.alt_text_google_play')} />
          </a>
        </span>
        <span>
          <img className="img-phone" src="/images/app-from-store/get-vosker-from-store-background.png" alt="vosker mobile app" />
        </span>
      </footer>
    </div>
  )
}

export default VoskerMobileAppSplashScreen
