import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'

import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'

const useStyles = makeStyles(theme => ({
  paymentDetail: {
    fontFamily: theme.typography.fontFamilyBold,
  },
  paymentWarning: {
    color: theme.palette.primary.main,
  },
  paymentError: {
    color: theme.palette.error.main,
  },
}))

const PaymentStatus = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const camera = useSelectedCamera()

  const subscription = camera?.subscription
  const paymentStatus = subscription?.paymentStatus
  const isCompletingTrial = camera.isCompletingTrial

  const activePayment = paymentStatus === 'active'
  const showPaymentDetails = !isCompletingTrial && !activePayment

  if (!showPaymentDetails) {
    return null
  }

  const renderPaymentStatus = () => {
    switch (paymentStatus) {
      case 'incomplete':
        return (
          <Typography display="inline" className={clsx(classes.paymentDetail, classes.paymentWarning)}>
            { t('app:plan.current_subscription.payment_status.pending') }
          </Typography>
        )

      case 'canceled':
        return (
          <Typography display="inline" className={clsx(classes.paymentDetail, classes.paymentWarning)}>
            { t('app:plan.current_subscription.payment_status.canceled') }
          </Typography>
        )

      case 'past_due':
      case 'unpaid':
      case 'error':
      case 'incomplete_expired':
        return (
          <Typography display="inline" className={clsx(classes.paymentDetail, classes.paymentError)}>
            { t('app:plan.current_subscription.payment_status.refused') }
          </Typography>
        )

      default:
        return null
    }
  }

  return (
    <li>
      { t('app:plan.current_subscription.payment_status.title') }: { renderPaymentStatus() }
    </li>
  )
}

export default PaymentStatus
