const getNotificationList = state => state.notifications?.notifications ?? []

export const getNotifications = state => getNotificationList(state)

export const getNbUnreadNotifications = state => {
  return getNotificationList(state).filter((notification) => !notification.read).length
}

export const getCriticalNotifications = state => {
  return getNotificationList(state).filter((notification) => notification.isSticky)
}
