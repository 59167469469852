import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Tooltip from '@material-ui/core/Tooltip'

import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import { apiCameraPlans } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.types'
import InformationBarElement from 'photo-module/photos/ui/photos-gallery/information-bar/InformationBarElement'
import VoskerPhotosIcon from 'vosker/src/assets/icons/VoskerPhotosIcon'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  text: {
    color: theme.palette.text.primary,
  },
}))

const VoskerPhotoCountElement = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const camera = useSelectedCamera()

  const formatPhotoCount = subscription => subscription.photoLimit === 0
    ? t('app:photo_gallery_page.photo_unlimited_counter', { count: subscription.photoCount })
    : t('app:photo_gallery_page.photo_counter', { count: subscription.photoCount, limit: subscription.photoLimit })

  return camera && camera.subscription.plan.name !== apiCameraPlans.empty
    ? (
      <Tooltip arrow title={t('app:photo_gallery_page.tooltip.nbPhoto')} enterDelay={300} leaveDelay={100}>
        <Box px={1} display="flex" alignItems="center">
          <InformationBarElement text={formatPhotoCount(camera.subscription)} Icon={VoskerPhotosIcon} className={classes.text} />
        </Box>
      </Tooltip>
      )
    : null
}

export default VoskerPhotoCountElement
