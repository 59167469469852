import { useTranslation } from 'react-i18next'
import { Box } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import PrimaryButton from 'shared-module/new-components/buttons/PrimaryButton'
import VoskerPrimaryButton from 'vosker/src/components/buttons/VoskerPrimaryButton'
import { useFormData } from 'shared-module/new-components/form/FormDataContext'
import userApi from 'user-module/user/api/userApi'

const ResetPasswordButton = ({ isNewUi }) => {
  const { t } = useTranslation()
  const form = useFormData()

  const resetPassword = data => {
    userApi.resetPassword(data.email)
    form.setValue('reset', true)
  }

  const ResetButton = isNewUi ? VoskerPrimaryButton : PrimaryButton

  return (
    <FormControl margin="dense">
      <Box display="flex" flexDirection="column">
        <ResetButton onClick={form.handleSubmit(resetPassword)} disabled={form.formState.isSubmitting}>
          { t('reset_password.form.button') }
        </ResetButton>
      </Box>
    </FormControl>
  )
}

export default ResetPasswordButton
