export const SET_ANCHOR_LIST_ACTION = 'SET_ANCHOR_LIST'
export const SET_INITIAL_ANCHOR_LIST_ACTION = 'SET_INITIAL_ANCHOR_LIST'
export const SET_IS_EDITING_ACTION = 'SET_IS_EDITING'
export const SET_IS_DEFAULT_ZONE_ACTION = 'SET_IS_DEFAULT_ZONE'
export const SET_IS_ENABLED_ACTION = 'SET_IS_ENABLED'
export const SET_ZONE_NAME = 'SET_ZONE_NAME'
export const SET_INITIAL_ZONE_NAME = 'SET_INITIAL_ZONE_NAME'
export const SET_LAST_SAVED_ZONE_NAME = 'SET_LAST_SAVED_ZONE_NAME'

const setAnchorList = anchorList => ({ type: SET_ANCHOR_LIST_ACTION, anchorList })
const setInitialAnchorList = initialAnchors => ({ type: SET_INITIAL_ANCHOR_LIST_ACTION, initialAnchors })
const setIsEditing = isEditing => ({ type: SET_IS_EDITING_ACTION, isEditing })
const setIsDefaultZone = isDefaultZone => ({ type: SET_IS_DEFAULT_ZONE_ACTION, isDefaultZone })
const setIsEnabled = isEnabled => ({ type: SET_IS_ENABLED_ACTION, isEnabled })
const setZoneName = zoneName => ({ type: SET_ZONE_NAME, zoneName })
const setInitialZoneName = initialZoneName => ({ type: SET_INITIAL_ZONE_NAME, initialZoneName })
const setLastSavedZoneName = zoneName => ({ type: SET_LAST_SAVED_ZONE_NAME, zoneName })

const detectionActions = { setAnchorList, setInitialAnchorList, setIsEditing, setIsDefaultZone, setIsEnabled, setZoneName, setInitialZoneName, setLastSavedZoneName }
export default detectionActions
