import { useSelector, useDispatch } from 'react-redux'

import { getAnchorList } from './detectionZone.selectors'
import * as actions from './detectionZone.actions'

export const useDetectionZone = () => {
  const dispatch = useDispatch()

  const anchorList = useSelector(getAnchorList)
  const saveAnchorList = (anchorList) => dispatch(actions.setAnchorList(anchorList))

  return { anchorList, saveAnchorList }
}
