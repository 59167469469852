import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/styles/makeStyles'

import { useStreaming } from '../../../core/liveStreaming.hooks'
import Spinner from '../Spinner'
import InformationMessage from './InformationMessage'
import ErrorMessage from './ErrorMessage'

const useStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    padding: '.5rem',
    marginTop: -25,
  },
}))

const Message = ({ inStreamingPage = false }) => {
  const classes = useStyles()
  const streaming = useStreaming()
  const { t } = useTranslation()

  const message = useMemo(() => {
    if (streaming.errorMessage) {
      return <ErrorMessage errorCode={streaming.errorMessage} />
    }

    if (streaming.streamStarting) {
      return <InformationMessage message={t('stream.messages.connecting')} inStreamingPage={inStreamingPage} />
    }

    if (!streaming.streamActive) {
      return (
        <>
          <InformationMessage message={t('stream.messages.startPlaying')} inStreamingPage={inStreamingPage} />
          { streaming.ecoModeDeadline && (
            <InformationMessage
              message={t('stream.messages.startPlayingEcoMode', { deadline: streaming.ecoModeDeadline })}
              inStreamingPage={inStreamingPage}
            />
          ) }
        </>
      )
    }

    return null
  }, [streaming.ecoModeDeadline, streaming.errorMessage, streaming.streamActive, streaming.streamStarting, t])

  if (!message) {
    return null
  }

  return (
    <div className={classes.container} data-testid="message">
      <div className={classes.content}>
        { streaming.showSpinner && <Spinner /> }
        { message }
      </div>
    </div>
  )
}

export default Message
