import React from 'react'
import Box from '@material-ui/core/Box'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import useCameraModels from 'camera-module/model/core/useCameraModels'
import Select from 'form-module/ui/inputs/Select'

const useStyles = makeStyles({
  icon: {
    position: 'absolute',
    width: '70%',
    bottom: '0',
  },
  typography: {
    paddingTop: '4px',
  },
})

const CameraModelSelector = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const cameraModels = useCameraModels()

  const CameraModelItem = (model) => {
    return (
      <Box display="flex" alignItems="center">
        <Box position="relative" height="22px" width="22px" marginLeft="15px" overflow="hidden">
          <img alt={model.name} src={model.icon} className={classes.icon} />
        </Box>
        <Typography className={classes.typography}>{ model.name }</Typography>
      </Box>
    )
  }

  return (
    <Select required name="deviceModel" label={t('add_camera.models')} maxLength={20}>
      { Object.values(cameraModels).map(model => {
        return <MenuItem key={model.name} value={model.name}>{ CameraModelItem(model) }</MenuItem>
      }) }
    </Select>
  )
}

export default CameraModelSelector
