import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Arrow from 'assets/icons/NotificationArrow'
import Tooltip from '@material-ui/core/Tooltip'
import TimeAgo from 'shared-module/components/TimeAgo'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { useTheme } from '@material-ui/core'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTranslation } from 'react-i18next'
import { useNotifications, useNotificationsActions } from 'notifications-module/core/notifications.hooks'
import LEVELS from 'notifications-module/core/notificationLevels'
import { title, content } from './helper'

const useStyles = makeStyles(theme => ({
  notification: {
    borderLeft: 'solid 6px',
    borderBottom: `solid 1px ${theme.palette.background.paper}`,
    cursor: 'pointer',
    background: theme.palette.background.default,
    '&:hover': {
      background: theme.palette.background.paper,
    },
  },
  unreadNotification: {
    background: theme.palette.background.paper,
    opacity: '1',
  },
  arrow: {
    width: 8.75,
  },
  title: {
    maxWidth: '27ch',
    fontFamily: theme.typography.fontFamily,
    fontSize: '1.25em',
    lineHeight: '1em',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  content: {
    maxWidth: '30ch',
    fontFamily: theme.typography.fontFamily,
    fontSize: '1em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    webkitLineClamp: 3,
    'line-clamp': '3',
    '-webkit-box-orient': 'vertical',
  },
  unreadText: {
    fontFamily: theme.typography.fontFamilyBold,
  },
  timestamp: {
    display: 'block',
    fontFamily: theme.typography.fontFamily,
    fontSize: '1em',
    color: theme.palette.text.secondary,
    paddingTop: 5,
  },
  info: {
    borderLeftColor: theme.palette.primary.info,
  },
  warning: {
    borderLeftColor: theme.palette.primary.main,
  },
  danger: {
    borderLeftColor: theme.palette.primary.danger,
  },
  critical: {
    borderLeftColor: theme.palette.primary.danger,
    '&:hover': {
      background: theme.palette.primary.danger,
    },
  },
  criticaUnreadl: {
    borderLeftColor: theme.palette.primary.danger,
    background: theme.palette.primary.danger,
    '&:hover': {
      background: theme.palette.primary.danger,
    },
  },
  read: {
    backgroundColor: theme.palette.primary.danger,
    '&:hover': {
      background: theme.palette.background.paper,
    },
  },
}))

const NotificationItem = ({ id, timeSinceCreation, level, read, Icon, onClose, templateName, targetId, cameraList }) => {
  const classes = useStyles()
  const theme = useTheme()
  const { t } = useTranslation()
  const notifications = useNotifications()
  const notificationsActions = useNotificationsActions()
  const breakpoint = useMediaQuery(theme.breakpoints.down('xs'))
  const cameraName = cameraList?.find(camera => camera.id === targetId)?.config?.name ?? ''
  const onClick = () => {
    const notification = notifications.find(n => n.id === id)
    if (!notification.read) { notificationsActions.markAsRead(notification) }
    notificationsActions.handleNotificationAction(notification)
    onClose()
  }

  const getLevelClass = () => {
    if (level === LEVELS.INFO) { return classes.info }
    if (level === LEVELS.MID) { return classes.warning }
    if (level === LEVELS.HIGH) { return classes.danger }
    if (level === LEVELS.DANGER) { return read ? classes.critical : classes.criticaUnreadl }
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      py={2}
      className={clsx(classes.notification,
        getLevelClass(), !read && classes.unreadNotification)}
      onClick={onClick}
    >
      <Box px={2.5}>
        <Icon />
      </Box>

      <Box flex={1} width={breakpoint ? 150 : 'max-content'}>
        <Typography className={clsx(classes.title, !read && classes.unreadText)}>{ t(title[templateName], { cameraName }) }</Typography>
        <Tooltip arrow title={t(content[templateName], { cameraName })} enterDelay={300} leaveDelay={100}>
          <Typography className={clsx(classes.content)}>{ t(content[templateName], { cameraName }) }</Typography>
        </Tooltip>
        { timeSinceCreation && <Typography className={classes.timestamp}><TimeAgo timestamp={timeSinceCreation} /></Typography> }
      </Box>

      <Box px={3.5}>
        <Arrow className={classes.arrow} />
      </Box>
    </Box>
  )
}

export default NotificationItem
