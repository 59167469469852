import {
  OPEN_FREE_TRIAL_MODAL,
  CLOSE_FREE_TRIAL_MODAL,
} from './freeTrialActivationModalActions'

const defaultState = {
  camera: undefined,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case OPEN_FREE_TRIAL_MODAL:
      return { camera: action.camera }
    case CLOSE_FREE_TRIAL_MODAL:
      return { camera: undefined }
    default:
      return state
  }
}
