import { findSelfIntersections } from 'polygon-selfintersect'

const GUTTER_SIZE = 10
const PADDING_SIZE = 34
const PADDING_SIZE_LANDSCAPE = 24

const validateMovementInStage = (position, imageRect) => {
  const validatedPosition = { ...position }

  if (position.x > imageRect.width) {
    validatedPosition.x = imageRect.width - 1
  } else if (position.x < 0) {
    validatedPosition.x = 0
  }

  if (position.y > imageRect.height) {
    validatedPosition.y = imageRect.height - 1
  } else if (position.y < 0) {
    validatedPosition.y = 0
  }

  return validatedPosition
}

const evaluateLimitsAnchorList = (translatedList, initialImageRect) => {
  return translatedList.map(anchor => {
    if (anchor.x > initialImageRect.width) { anchor.x = initialImageRect.width } else if (anchor.y > initialImageRect.height) { anchor.y = initialImageRect.height }
    return anchor
  })
}

const formatAnchorListForSetting = (list, scale) => {
  if (scale !== 1) {
    return list.map((anchor) => {
      return { x: Math.round(anchor.x / scale), y: Math.round(anchor.y / scale) }
    })
  } else {
    return list.map((anchor) => {
      return { x: Math.round(anchor.x), y: Math.round(anchor.y) }
    })
  }
}

const translateAnchorList = (list, previousImageRect, imageRect, stageOptions) => {
  const ratio = Math.round(stageOptions.scale * 1000) / 1000

  const scaleX = imageRect.width / previousImageRect.width
  const scaleY = imageRect.height / previousImageRect.height

  return list.map((anchor) => {
    const newX = previousImageRect.width === 0
      ? Math.round(anchor.x * ratio)
      : Math.round(anchor.x * scaleX)
    const newY = previousImageRect.height === 0
      ? Math.round(anchor.y * ratio)
      : Math.round(anchor.y * scaleY)

    return { x: newX, y: newY }
  })
}

const addGuttersToStage = (stageOptions) => {
  return {
    ...stageOptions,
    width: stageOptions.width + GUTTER_SIZE * 2,
    height: stageOptions.height + GUTTER_SIZE * 2,
  }
}

const getNormalStageOptions = (imageSize) => {
  return { width: imageSize.width, height: imageSize.height, scale: 1, orientation: '' }
}

const getPortraitStageOptions = (imageSize, canvasHeight) => {
  const newScale = (canvasHeight - GUTTER_SIZE * 2) / imageSize.height
  const newStageWidth = Math.round((imageSize.width + (PADDING_SIZE_LANDSCAPE + (2 * newScale))) * newScale)
  return { scale: newScale, width: newStageWidth, height: canvasHeight, orientation: 'portrait' }
}

const getLandscapeStageOptions = (imageSize, canvasWidth) => {
  const newScale = (canvasWidth - GUTTER_SIZE * 2) / imageSize.width
  const newStageHeight = Math.round((imageSize.height + PADDING_SIZE) * newScale)
  return { scale: newScale, width: canvasWidth, height: newStageHeight, orientation: 'landscape' }
}

const getNormalImageRect = (imageSize) => {
  return {
    width: imageSize.width,
    height: imageSize.height,
  }
}

const getCustomImageRect = (stageOptions) => {
  return { width: stageOptions.width - GUTTER_SIZE * 2, height: stageOptions.height - GUTTER_SIZE * 2 }
}

const validateIntersections = (list) => findSelfIntersections(list.map((anchor) => [anchor.x, anchor.y]))
const isPortraitView = (size, imageSize) => size.height < imageSize.height + GUTTER_SIZE * 2 && size.height < size.width
const isLandscapeView = (size, imageSize) => size.width < imageSize.width + GUTTER_SIZE * 2 && size.height > size.width

export {
  validateMovementInStage,
  formatAnchorListForSetting,
  getNormalStageOptions,
  getPortraitStageOptions,
  getLandscapeStageOptions,
  getNormalImageRect,
  getCustomImageRect,
  addGuttersToStage,
  translateAnchorList,
  validateIntersections,
  isPortraitView,
  isLandscapeView,
  GUTTER_SIZE,
  PADDING_SIZE,
  PADDING_SIZE_LANDSCAPE,
  evaluateLimitsAnchorList,
}
