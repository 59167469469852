import SvgIcon from '@material-ui/core/SvgIcon'

const FavoriteIcon = (props) => (
  <SvgIcon {...props} data-testid="FavoriteIcon">
    <path
      d="M18.406 20.484c.098.59-.281 1.145-.843 1.246a.975.975 0 01-.653-.109L12 18.93l-4.906 2.695a1.013 1.013 0 01-1.395-.45 1.127 1.127 0 01-.101-.687l.937-5.71-3.973-4.055a1.108 1.108 0 01-.019-1.524c.16-.168.367-.281.59-.312l5.488-.832 2.453-5.203c.25-.536.871-.754 1.383-.493.203.106.367.278.469.493l2.453 5.203 5.488.832c.563.086.953.633.871 1.218a1.074 1.074 0 01-.3.618l-3.97 4.05zm0 0"
      fillRule="evenodd"
    />
  </SvgIcon>
)

export default FavoriteIcon
