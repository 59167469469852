import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import { useEffect, useState } from 'react'
import PreviewPlayOverlay from 'vosker/src/photos-module/photos-gallery/ui/events-overlay/PreviewPlayOverlay'

const useStyles = makeStyles(theme => ({
  photo: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '600px',
    },
    maxWidth: '100%',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const VoskerVideoPreview = ({ photo }) => {
  const [previewArray, setPreviewArray] = useState(photo.urls?.preview)
  const [previewUrl, setPreviewUrl] = useState(previewArray ? previewArray[0] : null)
  const [showPlayButton, setShowPlayButton] = useState(true)
  const [isPlaying, setIsPlaying] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    if (photo.urls?.preview) {
      setPreviewArray(photo.urls.preview)
    }
  }, [photo])

  useEffect(() => {
    if (previewArray && previewArray.length > 0) {
      setPreviewUrl(previewArray[0])
    }
  }, [previewArray])

  useEffect(() => {
    let previewNo = 0
    let interval

    if (isPlaying) {
      interval = setInterval(() => {
        if (++previewNo === previewArray.length) {
          previewNo = 0
        }
        setPreviewUrl(previewArray[previewNo])
      }, 1000)
    }

    return () => clearInterval(interval)
  }, [isPlaying, previewArray])

  const handlePlayButtonClick = () => {
    setIsPlaying(!isPlaying)
    setShowPlayButton(false)
  }

  return (
    <div className={classes.container}>
      <Grid item style={{ position: 'relative' }}>
        { showPlayButton && <PreviewPlayOverlay photo={photo} PlayPreview={handlePlayButtonClick} /> }
        <img
          className={classes.photo}
          src={previewUrl}
          alt="videoPreview"
          name="videoPreview"
        />
      </Grid>
    </div>
  )
}

export default VoskerVideoPreview
