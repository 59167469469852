import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from 'shared-module/redux/store'
import CssBaseline from '@material-ui/core/CssBaseline'
import ThemeContextProvider from 'shared-module/ThemeContextProvider'
import SnackbarMessage from 'shared-module/components/snackbar/SnackbarMessage'
import FreeTrialActivationModal from 'camera-module/free-trial-activation-modal/ui/FreeTrialActivationModal'
import { ActionsProvider } from 'shared-module/actions/ActionsContext'
import AppMonitoring from 'shared-module/monitoring/AppMonitoring'
import AutoLogin from 'user-module/user/ui/login/AutoLogin'
import MarketingCampaign from 'shared-module/marketing/MarketingCampaign'
import ErrorDialog from 'shared-module/components/dialog/ErrorDialog'
import 'form-module/ui/setupYupLocale'
import CacheBuster from 'react-cache-buster'

const isProduction = process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging'

const App = ({ darkTheme, lightTheme = undefined, currentTheme, actions, version, children, supportNewUi = false }) => (
  <CacheBuster currentVersion={version} isEnabled={isProduction} isVerboseMode={false}>
    <ThemeContextProvider darkTheme={darkTheme} lightTheme={lightTheme} currentTheme={currentTheme}>
      <CssBaseline />
      <Provider store={store}>
        <ActionsProvider actions={actions}>
          <Router>
            <AppMonitoring />
            <MarketingCampaign />
            <AutoLogin>
              { children }
            </AutoLogin>
            <SnackbarMessage supportNewUi={supportNewUi} />
            <ErrorDialog />
            <FreeTrialActivationModal supportNewUi={supportNewUi} />
          </Router>
        </ActionsProvider>
      </Provider>
    </ThemeContextProvider>
  </CacheBuster>
)

export default App
