import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const SolarBatteryHigh = ({ props }) => {
  const theme = useTheme()
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M24 13.5C24 12.9696 23.7893 12.4609 23.4142 12.0858C23.0391 11.7107 22.5304 11.5 22 11.5H21V10.5C21 9.96957 20.7893 9.46086 20.4142 9.08579C20.0391 8.71071 19.5304 8.5 19 8.5H2C1.46957 8.5 0.960859 8.71071 0.585786 9.08579C0.210714 9.46086 0 9.96957 0 10.5L0 19.5C0 20.0304 0.210714 20.5391 0.585786 20.9142C0.960859 21.2893 1.46957 21.5 2 21.5H19C19.5304 21.5 20.0391 21.2893 20.4142 20.9142C20.7893 20.5391 21 20.0304 21 19.5V18.5H22C22.5304 18.5 23.0391 18.2893 23.4142 17.9142C23.7893 17.5391 24 17.0304 24 16.5V13.5ZM22 16.25C22 16.3163 21.9737 16.3799 21.9268 16.4268C21.8799 16.4737 21.8163 16.5 21.75 16.5H20C19.7348 16.5 19.4804 16.6054 19.2929 16.7929C19.1054 16.9804 19 17.2348 19 17.5V19C18.9974 19.1318 18.9439 19.2575 18.8507 19.3507C18.7575 19.4439 18.6318 19.4974 18.5 19.5H2.5C2.36739 19.5 2.24021 19.4473 2.14645 19.3536C2.05268 19.2598 2 19.1326 2 19V11C2 10.8674 2.05268 10.7402 2.14645 10.6464C2.24021 10.5527 2.36739 10.5 2.5 10.5H18.5C18.6326 10.5 18.7598 10.5527 18.8536 10.6464C18.9473 10.7402 19 10.8674 19 11V12.5C19 12.7652 19.1054 13.0196 19.2929 13.2071C19.4804 13.3946 19.7348 13.5 20 13.5H21.75C21.8163 13.5 21.8799 13.5263 21.9268 13.5732C21.9737 13.6201 22 13.6837 22 13.75V16.25Z" fill={theme.palette.text.primary} />
      <path d="M17.5 11.5H3.5C3.22386 11.5 3 11.7239 3 12V18C3 18.2761 3.22386 18.5 3.5 18.5H17.5C17.7761 18.5 18 18.2761 18 18V12C18 11.7239 17.7761 11.5 17.5 11.5Z" fill={theme.palette.text.primary} />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.1213 2.28282C11.1213 2.59801 10.8431 2.85352 10.5 2.85352C10.1568 2.85352 9.87865 2.59801 9.87865 2.28282V0.570704C9.87865 0.255513 10.1568 0 10.5 0C10.8431 0 11.1213 0.255513 11.1213 0.570704V2.28282ZM18.0749 6.62932C17.7777 6.78691 17.3977 6.69339 17.2262 6.42042C17.0546 6.14746 17.1564 5.79842 17.4536 5.64083L19.0679 4.78477C19.365 4.62717 19.745 4.7207 19.9166 4.99366C20.0882 5.26663 19.9864 5.61566 19.6892 5.77326L18.0749 6.62932ZM15.2321 3.60038C15.0605 3.87334 14.6805 3.96687 14.3833 3.80927C14.0862 3.65167 13.9843 3.30264 14.1559 3.02967L15.0879 1.54693C15.2595 1.27397 15.6395 1.18045 15.9367 1.33804C16.2339 1.49564 16.3357 1.84468 16.1641 2.11764L15.2321 3.60038ZM3.54639 5.64083C3.84357 5.79842 3.94539 6.14746 3.77381 6.42042C3.60224 6.69339 3.22223 6.78691 2.92505 6.62932L1.31077 5.77326C1.01359 5.61566 0.911769 5.26663 1.08335 4.99366C1.25492 4.7207 1.63493 4.62717 1.93211 4.78477L3.54639 5.64083ZM6.84405 3.02967C7.01563 3.30264 6.91381 3.65167 6.61663 3.80927C6.31945 3.96687 5.93944 3.87334 5.76786 3.60038L4.83586 2.11764C4.66428 1.84468 4.7661 1.49564 5.06328 1.33804C5.36047 1.18045 5.74047 1.27397 5.91205 1.54693L6.84405 3.02967Z" fill={theme.palette.text.primary} />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.5295 8.94116H5.4707C5.47072 6.47214 7.72246 4.47061 10.5001 4.47061C13.2778 4.47061 15.5295 6.47214 15.5295 8.94116Z" fill={theme.palette.text.primary} />

    </SvgIcon>
  )
}

export default SolarBatteryHigh
