import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'

import downloadStatus from 'photo-module/download/downloadStatus'
import FavoriteShadowIcon from 'assets/icons/photo/FavoriteShadowIcon'
import VideoPlayOverlay from 'vosker/src/photos-module/photos-gallery/ui/events-overlay/VideoPlayOverlay'
import FullHdStatusOverlay from 'vosker/src/photos-module/photos-gallery/ui/events-overlay/FullHdStatusOverlay'
import SavedStreamStatusOverlay from 'vosker/src/photos-module/photos-gallery/ui/events-overlay/SavedStreamStatusOverlay'

const useStyles = makeStyles(theme => ({
  shadow: {
    top: 0,
    left: 0,
    zIndex: 2,
    width: '100%',
    height: '100%',
    position: 'absolute',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 52.6%)',
    borderRadius: '0.375rem',
  },
  statusIcon: {
    width: 'auto',
    height: '1.25rem',
    marginTop: '0.5rem',
    marginRight: '0.5rem',
    marginLeft: '0.5rem',
    fill: theme.palette.basic?.white + ' !important',
  },
}))

const EventsOverlay = ({ photo }) => {
  const classes = useStyles()
  const isFavorite = photo.favorite

  const hasIcons = () => {
    const isPhotoRequestable = photo.hdStatus === downloadStatus.requestable
    const isSDVideoRequestable = photo.sdVideoStatus === downloadStatus.requestable
    const isHDVideoRequestable = photo.hdVideoStatus === downloadStatus.requestable

    return isFavorite || !isPhotoRequestable || !isSDVideoRequestable || !isHDVideoRequestable
  }

  return (
    <Box component={Grid} container className={hasIcons() ? classes.shadow : null} justifyContent="center" data-testid="voskerThumbnail">
      <Box component={Grid} container item justifyContent="flex-end" alignItems="flex-start">
        { isFavorite && <FavoriteShadowIcon className={classes.statusIcon} /> }
        <FullHdStatusOverlay photo={photo} />
        <SavedStreamStatusOverlay photo={photo} />
      </Box>

      <VideoPlayOverlay photo={photo} />
    </Box>
  )
}

export default EventsOverlay
