import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import { useFormData, useFormField } from 'shared-module/new-components/form/FormDataContext'
import PrimaryButton from 'shared-module/new-components/buttons/PrimaryButton'
import userApi from 'user-module/user/api/userApi'
import VoskerPrimaryButton from 'vosker/src/components/buttons/VoskerPrimaryButton'

const ChangePasswordButton = ({ isNewUi }) => {
  const { t } = useTranslation()
  const params = useParams()
  const form = useFormData()
  const success = useFormField('success')
  const error = useFormField('error')

  const SubmitButton = isNewUi ? VoskerPrimaryButton : PrimaryButton

  const changePassword = data => {
    userApi.changePassword(params.token, data.password)
      .then(() => success.setValue(true))
      .catch(() => error.setValue(true))
  }

  return (
    <FormControl margin="dense">
      <Box display="flex" flexDirection="column">
        <SubmitButton onClick={form.handleSubmit(changePassword)} disabled={form.formState.isSubmitting}>
          { t('change_password.form.button') }
        </SubmitButton>
      </Box>
    </FormControl>
  )
}

export default ChangePasswordButton
