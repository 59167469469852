import { useTheme } from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'

const SearchIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="SearchIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0728 1.5C7.79913 1.5 5.61862 2.40934 4.01091 4.02798C2.4032 5.64662 1.5 7.84196 1.5 10.1311C1.5 12.4202 2.4032 14.6155 4.01091 16.2341C5.61862 17.8528 7.79913 18.7621 10.0728 18.7621C11.8707 18.7621 13.6104 18.1935 15.0547 17.1551L20.5064 22.5L22.5 20.4388L17.0711 15.1162C18.0887 13.6682 18.6456 11.9285 18.6456 10.1311C18.6456 7.84196 17.7423 5.64662 16.1346 4.02798C14.5269 2.40934 12.3464 1.5 10.0728 1.5ZM6.03153 6.06234C7.10334 4.98325 8.55701 4.37702 10.0728 4.37702C11.5885 4.37702 13.0422 4.98325 14.114 6.06234C15.1858 7.14143 15.788 8.60499 15.788 10.1311C15.788 11.6571 15.1858 13.1207 14.114 14.1998C13.0422 15.2789 11.5885 15.8851 10.0728 15.8851C8.55701 15.8851 7.10334 15.2789 6.03153 14.1998C4.95973 13.1207 4.35759 11.6571 4.35759 10.1311C4.35759 8.60499 4.95973 7.14143 6.03153 6.06234Z"
        fill={theme.palette.text.secondary}
      />

    </SvgIcon>
  )
}

export default SearchIcon
