import generateI18N from 'shared-module/i18n/i18n'
import enApp from 'assets/locales/en/vosker.en.json'
import frApp from 'assets/locales/fr/vosker.fr.json'
import deApp from 'assets/locales/de/vosker.de.json'
import esApp from 'assets/locales/es/vosker.es.json'

const appResources = {
  en: { app: enApp },
  fr: { app: frApp },
  de: { app: deApp },
  es: { app: esApp },
}

export default generateI18N(['app'], appResources)
