const init = () => ({ fetching: false })
const set = models => ({ fetching: false, models: models })
const fetching = () => ({ fetching: true })

const isReady = state => isFetching(state) || hasModels(state.models)
const isFetching = state => state.fetching === true
const hasModels = models => models !== undefined

const cameraModelsState = { init, fetching, set, isReady, hasModels }
export default cameraModelsState
