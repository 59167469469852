import SvgIcon from '@material-ui/core/SvgIcon'

const FullHdVideoIcon = (props) => (
  <SvgIcon data-testid="FullHdVideoIcon" width="32" height="32" viewBox="0 0 32 32" {...props}>
    <path
      d="M19.7253 16.3361V24.2563H21.2721C22.1453 24.2563 22.6692 23.825 22.6692 22.6271V17.9653C22.6692 16.7673 22.1453 16.3361 21.2721 16.3361H19.7253Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.4324 3H6.71042C5.21352 3 4 4.20142 4 5.68338V26.3166C4 27.7986 5.21352 29 6.71042 29H25.4324C26.9293 29 28.1429 27.7986 28.1429 26.3166V5.68338C28.1429 4.20142 26.9293 3 25.4324 3ZM6.66964 12.2108H8.22972V9.87603H9.94002V8.31072H8.22972V6.87383H10.252V5.34782H6.66964V12.2108ZM23.4502 10.6848V5.34782H21.8901V12.2108H25.2414V10.6848H23.4502ZM9.34711 26.6522H6.674V13.9403H9.34711V19.0983H12.3847V13.9403H15.1064V26.6522H12.3847V21.4942H9.34711V26.6522ZM16.9815 13.9403H21.3224C24.0667 13.9403 25.4139 15.4018 25.4139 18.0851V22.5074C25.4139 25.1907 24.0667 26.6522 21.3224 26.6522H16.9815V13.9403ZM18.6721 5.34782H17.112V12.2108H20.4633V10.6848H18.6721V5.34782ZM14.209 5.34782H15.7691V10.3005C15.7691 11.5552 14.9371 12.3917 13.6312 12.3917C12.3254 12.3917 11.4934 11.5552 11.4934 10.3005V5.34782H13.0534V10.3005C13.0534 10.6396 13.2614 10.8657 13.6312 10.8657C14.001 10.8657 14.209 10.6396 14.209 10.3005V5.34782Z"
    />
  </SvgIcon>
)

export default FullHdVideoIcon
