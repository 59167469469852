import MediaQuery from 'react-responsive'
import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import StopRoundedIcon from '@material-ui/icons/StopRounded'
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded'

import RecordingIcon from 'assets/icons/video/RecordingIcon'
import { useStreaming } from '../../../core/liveStreaming.hooks'

const useStyles = makeStyles(theme => ({
  controlContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '.25rem',
  },
  controlContainerDisabled: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '.25rem',
    opacity: 0.4,
  },
  goLiveText: {
    fontFamily: theme.typography.fontFamilyBold,
    whiteSpace: 'nowrap',
  },
  icon: {
    fill: theme.palette.basic?.white + '!important',
  },
}))

const PlayStopButton = () => {
  const streaming = useStreaming()
  const classes = useStyles()
  const { t } = useTranslation()
  const showPlayButton = streaming.showPlayButton

  const onPlay = () => {
    if (streaming.controlsDisabled) {
      return
    }

    streaming.play()
  }

  const onStop = () => {
    if (streaming.controlsDisabled) {
      return
    }
    streaming.stop()
  }

  return (
    <div className={streaming.controlsDisabled ? classes.controlContainerDisabled : classes.controlContainer}>
      { !showPlayButton && <RecordingIcon /> }

      <MediaQuery minDeviceWidth={600}>
        <Typography className={classes.goLiveText} variant="body2">
          { t(showPlayButton ? 'stream.golive' : 'stream.live') }
        </Typography>
      </MediaQuery>

      { showPlayButton
        ? (
          <PlayArrowRoundedIcon
            className={classes.icon}
            fontSize="large"
            cursor="pointer"
            onClick={onPlay}
            data-testid="play-button"
          />
          )
        : (
          <StopRoundedIcon
            className={classes.icon}
            fontSize="large"
            cursor="pointer"
            onClick={onStop}
            data-testid="stop-button"
          />
          ) }
    </div>
  )
}

export default PlayStopButton
