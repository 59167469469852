import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import structuredClone from '@ungap/structured-clone'
import { diff, getPayload } from './helpers/cameraSettings.helper'

import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import makeStyles from '@material-ui/styles/makeStyles'

import CameraSettingsForm from './CameraSettingsForm'
import CameraSettingsIconNew from 'assets/icons/navigation/CameraSettingsIconNew'
import CameraSettingsResetDialog from './CameraSettingsResetDialog'

import cameraApi from 'camera-module/camera/api/cameraApi'
import useCameraActions from 'camera-module/camera/core/useCameraActions'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'

import apm from 'shared-module/monitoring/apm'
import switches from 'shared-module/config/switches'
import appConfig from 'shared-module/config/appConfig'
import messageActions from 'shared-module/message/messageActions'
import CAppBar from 'storybook-component-module/src/components/containers/CAppBar'
import useFilters from 'photo-module/filters/core/useFilters'
import useFiltersActions from 'photo-module/filters/core/useFiltersActions'
import { getPhotos } from 'photo-module/photos/core/photosActions'

const useStyles = makeStyles((theme) => ({
  appbar: {
    background: theme.palette.background.default,
  },
  pageContainer: {
    marginTop: 128,
    padding: 15,
    [theme.breakpoints.down('xs')]: {
      marginTop: 115,
      padding: 10,
    },
  },
  newPageContainer: {
    marginTop: 128,
    padding: '3rem 1rem 1rem 1rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: 115,
      padding: '1.25rem 0.625rem 0.625rem 0.625rem',
    },
  },
}))

const CameraSettingsPage = ({ isVosker = false, isNewUi = false }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [showResetModal, setShowResetModal] = useState(false)
  const { t } = useTranslation()
  const { id: cameraId } = useParams()
  const cameraActions = useCameraActions()
  const { id } = useParams()
  const camera = useSelectedCamera()
  const dialogActions = useConfirmationDialogActions()
  const filters = useFilters()
  const filtersActions = useFiltersActions()

  const detectionZoneEnabled = appConfig.detectionZone.switch === switches.on

  useEffect(() => {
    if (!camera) {
      cameraActions.update(cameraId)
    }
  }, [camera, cameraId, cameraActions])

  useEffect(() => {
    camera && apm.log('camera-settings', [{ cameraModel: camera.status.model }])
  }, [camera])

  useEffect(() => {
    if (isNewUi && isVosker) {
      filtersActions.setCamerasFilter([id])
      if (camera && filters.cameras.length !== 0) {
        dispatch(getPhotos(filters))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camera])

  const toggleResetModal = () => setShowResetModal(!showResetModal)

  const resetSettings = (settings) => {
    cameraActions.updateSettings(camera, settings)
    dispatch(messageActions.showMessage('camera.settings.messages.delayedSave'))
  }
  const onClose = () => {
    dialogActions.close()
  }
  const onSubmit = async (settings, initialSettings, setInitial) => {
    const difference = diff(initialSettings, settings)
    getPayload(difference, initialSettings, settings, detectionZoneEnabled)

    /* taken from legacy form */
    try {
      const payload = isVosker ? difference : settings
      const updatedCamera = await cameraApi.saveSettings(camera.id, payload)
      cameraActions.updateSettings(camera, updatedCamera ? updatedCamera.config : settings)
      setInitial(structuredClone(updatedCamera ? updatedCamera.config : settings))
      dispatch(messageActions.showMessage('camera.settings.messages.delayedSave'))
    } catch (error) {
      dispatch(messageActions.showError('errors.catch_all'))
    }
  }

  return (
    <Box className={isNewUi ? classes.newPageContainer : classes.pageContainer}>
      <CAppBar
        variant={isNewUi ? 'h1' : 'h2'}
        Icon={CameraSettingsIconNew}
        title={t('menu.camera_settings')}
        className={isNewUi ? classes.appbar : ''}
        showDivider
      />
      <Container>
        <CameraSettingsForm dialogActions={dialogActions} onSubmit={onSubmit} onReset={toggleResetModal} isNewUi={isNewUi} />
      </Container>
      { showResetModal && (
        <CameraSettingsResetDialog
          isNewUi={isNewUi}
          camera={camera}
          onResetSettings={resetSettings}
          onClose={toggleResetModal}
        />
      ) }
      <ConfirmationDialog
        isNewUi={isNewUi}
        useSecondaryButtonForConfirm
        open={dialogActions.isOpen}
        title={t('detection-zone.dialog.no-photo.title')}
        text={t('detection-zone.dialog.no-photo.message')}
        onConfirm={onClose}
        confirmText={t('detection-zone.dialog.no-photo.confirm')}
      />
    </Box>
  )
}

export default CameraSettingsPage
