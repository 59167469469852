import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import Grid from '@material-ui/core/Grid'
import { Box, Typography } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ChangeLanguageList from './ChangeLanguageList'
import NotificationEmail from './NotificationEmail'
import CommunicationEmail from './CommunicationEmail'
import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '150px',
  },
  rowSpacing: {
    marginBottom: 4,
  },
  title: {
    fontWeight: 600,
    paddingLeft: 24,
    paddingTop: 10,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  containerPadding: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  gridItem: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      alignItems: 'stretch',
    },
  },
}))

const ProfilePreferences = ({ form }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const formik = useFormikContext()
  // TODO: Upon releasing the this feature, we can remove all switches
  const spanishLanguageEnabled = appConfig.newLanguagesSwitch?.spanish.switch === switches.on
  const germanLanguageEnabled = appConfig.newLanguagesSwitch?.german.switch === switches.on
  return (
    <div>
      <Box sx={{ p: 3 }} className={classes.title}>
        <Typography variant="h2">{ t('input.preferences') }</Typography>
      </Box>
      <Container className={classes.containerPadding}>
        <Grid container item spacing={3} className={classes.rowSpacing}>
          <Grid item xs={12} sm={4} className={classes.gridItem}>
            <ChangeLanguageList
              form={form}
              formik={formik}
              name="language"
              items={[
                {
                  key: 'en',
                  name: t('language.english'),
                  value: 'en',
                },
                {
                  key: 'fr',
                  name: t('language.french'),
                  value: 'fr',
                },
                ...(spanishLanguageEnabled
                  ? [{
                      key: 'es',
                      name: t('language.spanish'),
                      value: 'es',
                    }]
                  : []),
                ...(germanLanguageEnabled
                  ? [{
                      key: 'de',
                      name: t('language.deutsch'),
                      value: 'de',
                    }]
                  : []),
              ]}
              required
            />
          </Grid>
        </Grid>
        <Grid container item spacing={3} className={classes.rowSpacing}>
          <Grid item xs={12} sm={12} className={classes.gridItem}>
            <NotificationEmail
              form={form}
              formik={formik}
              name="notificationEmail"
              legendKey="input.notification"
              label={t('input.email_notifications_subscription_vosker')}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={3} className={classes.rowSpacing}>
          <Grid item xs={12} sm={12} style={{ paddingTop: 2 }} className={classes.gridItem}>
            <CommunicationEmail
              form={form}
              formik={formik}
              name="marketingEmail"
              legendKey="input.communications"
              label={t('input.communication_subscribe')}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default ProfilePreferences
