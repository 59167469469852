let userContext = null
let labels = []
const logs = []

const transaction = {
  addLabels: (label) => {},
  end: () => {},
}

const apmInactive = {
  setUserContext: (user) => { userContext = user },
  getUserContext: () => userContext,

  addLabels: (label) => labels.push(label),
  getLabels: () => labels,
  clearLabels: () => { labels = [] },

  log: (action, data) => logs.push({ action, data }),
  getLog: () => logs,

  startTransaction: (action, data) => transaction,
}

export default apmInactive
