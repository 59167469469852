import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const NewSignalZero = ({ props }) => {
  const theme = useTheme()

  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="none"
        fillRule="nonzero"
        fill={theme.palette.status?.error}
        fillOpacity={1}
        d="M28 10.332c0-.55.45-1 1-1h1.332a1 1 0 0 1 1 1v17.336a1 1 0 0 1-1 1H29c-.55 0-1-.45-1-1ZM21.332 14.262c0-.551.45-1 1-1h1.336c.55 0 1 .449 1 1v12.222l-3.336-3.332ZM21.332 25.598l2.895 2.894-.004.004 2.277 2.277-1.227 1.23-3.527-3.526a1.003 1.003 0 0 1-.223-.223L18 24.73v2.938c0 .55-.45 1-1 1h-1.332a1 1 0 0 1-1-1v-6.273L3.926 10.656l1.226-1.23 9.516 9.515v-.007L18 22.266v.007l3.332 3.332ZM18 18.191v1.625l-2.59-2.59a.934.934 0 0 1 .258-.035H17c.55 0 1 .45 1 1Zm0 0"
      />
      <path
        fillRule="nonzero"
        fill={theme.palette.status?.error}
        fillOpacity={1}
        d="M8 22.121c0-.55.45-1 1-1h1.332a1 1 0 0 1 1 1v5.547a1 1 0 0 1-1 1H9c-.55 0-1-.45-1-1ZM1.332 26.05c0-.55.45-1 1-1h1.336c.55 0 1 .45 1 1v1.618c0 .55-.45 1-1 1H2.332c-.55 0-1-.45-1-1Zm0 0"
      />
    </SvgIcon>
  )
}

export default NewSignalZero
