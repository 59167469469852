import BatteryEmpty from 'assets/icons/status/new-status-icons/BatteryEmpty'
import BatteryLow from 'assets/icons/status/new-status-icons/BatteryLow'
import BatteryMedium from 'assets/icons/status/new-status-icons/BatteryMedium'
import BatteryHigh from 'assets/icons/status/new-status-icons/BatteryHigh'
import CameraStatusBatteryTile from 'camera-module/camera/ui/camera-status-page/CameraStatusBatteryTile'
import { useTranslation } from 'react-i18next'

const MainBatteryTile = ({ value, label, type, activePowerSource }) => {
  const val = value.percentage
  const { t } = useTranslation()
  const batteryValue = activePowerSource ? `${val}% - ${t('camera.in_use')}` : `${val}%`
  let Battery = BatteryEmpty
  if (val <= 0) Battery = BatteryEmpty
  if (val >= 1 && val <= 34) Battery = BatteryLow
  if (val >= 35 && val <= 74) Battery = BatteryMedium
  if (val >= 75 && val <= 100) Battery = BatteryHigh

  return <CameraStatusBatteryTile label={label} icon={<Battery />} value={val} batteryValue={batteryValue} useSVGIcon isNewUi />
}

export default MainBatteryTile
