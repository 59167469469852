import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import SettingsScheduleDay from './SettingsScheduleDay'

/**
 * Kept from the legacy settings form
 * This field is not integrated in Formik but input/output are properly binded
 */
const SettingsSchedule = ({ settings, onChange }) => {
  const onSettingChange = (event) => {
    let scheduleTemp = settings.schedule
    scheduleTemp[event.index] = event.schedule
    scheduleTemp = { target: { name: 'schedule', value: scheduleTemp } }
    onChange(scheduleTemp)
  }

  // todo: fill empty days in model, not in UI
  const schedules = settings.schedule
  for (let i = 0; i < 7; i++) {
    if (!settings.schedule[i]) {
      schedules[i] = [0, 0]
    }
  }

  return (
    <Table>
      <TableBody>
        <SettingsScheduleDay day="0" settings={settings} onChange={onSettingChange} />
        <SettingsScheduleDay day="1" settings={settings} onChange={onSettingChange} />
        <SettingsScheduleDay day="2" settings={settings} onChange={onSettingChange} />
        <SettingsScheduleDay day="3" settings={settings} onChange={onSettingChange} />
        <SettingsScheduleDay day="4" settings={settings} onChange={onSettingChange} />
        <SettingsScheduleDay day="5" settings={settings} onChange={onSettingChange} />
        <SettingsScheduleDay day="6" settings={settings} onChange={onSettingChange} />
      </TableBody>
    </Table>
  )
}

export default SettingsSchedule
