import React, { ReactElement, ReactNode } from 'react'
import MuiButton from '@material-ui/core/Button'

export interface ICButton {
  children?: ReactElement
  id?:string
  icon?: ReactNode
  variety: 'primary' | 'secondary'
  color?: 'default' | 'primary' | 'secondary'
  type?: 'button' | 'submit'
  size?: 'small' | 'medium' | 'large'
  disabled?: boolean
  disableElevation?: boolean
  onClick?: () => void
  className?: string
  style?:React.CSSProperties
}

const CButton = (props: ICButton) => {
  return (
    <MuiButton
      color={props.color}
      id={props?.id}
      variant={props.variety === 'primary' ? 'contained' : 'outlined'}
      startIcon={props.icon}
      children={props.children}
      type={props.type}
      style={props.style}
      size={props.size}
      disabled={props.disabled}
      disableElevation={props.disableElevation}
      onClick={props.onClick}
      className={props.className}
    />
  )
}

export default CButton
