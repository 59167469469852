import { useState, useEffect, useRef } from 'react'
import { Layer, Circle, Shape } from 'react-konva'

import { alpha, useTheme } from '@material-ui/core'
import { validateMovementInStage } from './LegacyDetectionZoneHelper'

const LegacyDetectionZoneEditor = (props) => {
  const theme = useTheme()
  const zoneRef = useRef(null)
  const [isEditing, setIsEditing] = useState(false)
  const [activeAnchor, setActiveAnchor] = useState(0)

  useEffect(() => {
    zoneRef.current.draw()
  }, [props.anchorList])

  const updateAnchorAndShape = (event, index) => {
    const validatedPosition = validateMovementInStage(
      {
        x: event.target.attrs.x,
        y: event.target.attrs.y,
      },
      props.imageRect,
    )

    // XXX
    // This prevents the circle from being moved outside visually
    event.target.attrs.x = validatedPosition.x
    event.target.attrs.y = validatedPosition.y

    props.anchorListUpdated(
      props.anchorList.map((anchor, anchorIndex) => {
        return anchorIndex === index ? validatedPosition : anchor
      }),
    )
  }

  const detectionZoneSceneFunc = (context, shape) => {
    if (!props.anchorList || props.anchorList.length === 0) return

    context.beginPath()

    context.moveTo(props.anchorList[0].x, props.anchorList[0].y)
    props.anchorList.slice(1).forEach((anchor) => {
      context.lineTo(anchor.x, anchor.y)
    })

    context.closePath()
    context.fillStrokeShape(shape)
  }

  const getRadius = (index) => {
    return isEditing && activeAnchor === index ? 15 : 10
  }

  const renderAnchorPoints = () => {
    return props.anchorList.map((anchor, index) => {
      return (
        <Circle
          {...anchor}
          key={index.toString()}
          radius={getRadius(index)}
          fill={theme.palette.text.primary}
          stroke={theme.palette.primary.main}
          strokeWidth={1}
          draggable
          onDragMove={(event) => updateAnchorAndShape(event, index)}
          onDragStart={(event) => {
            setActiveAnchor(event.target.index - 1)
            setIsEditing(true)
          }}
          onDragEnd={() => {
            setIsEditing(false)
            props.anchorListUpdated(props.anchorList)
          }}
        />
      )
    })
  }

  return (
    <Layer>
      <Shape
        ref={zoneRef}
        fill={alpha(theme.palette.primary.main, 0.25)}
        stroke={theme.palette.primary.main}
        strokeWidth={1}
        sceneFunc={detectionZoneSceneFunc}
      />
      { renderAnchorPoints() }
    </Layer>
  )
}

export default LegacyDetectionZoneEditor
