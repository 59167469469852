import OrderLine from 'vosker/src/order-module/order/ui/order-lines/components/OrderLine'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.status?.success,
  },
}))

const OrderDiscountLine = ({ line }) => {
  const classes = useStyles()

  return <OrderLine line={line} className={classes.text} />
}

export default OrderDiscountLine
