import { useEffect, useRef, useState } from 'react'

import cameraApi from 'camera-module/camera/api/cameraApi'
import helper from './cameraSettingFixer'

import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'

export const useCameraSettings = (camera, formValues) => {
  const cameraSettingsEnabled = appConfig.cameraSettings.switch === switches.on
  const [settings, setSettings] = useState()

  useEffect(() => {
    if (!settings) {
      cameraApi.getSettings(camera.id, formValues?.captureMode, formValues?.operationMode)
        .then(data => cameraSettingsEnabled ? helper.fix(helper.build(data), formValues) : helper.fix(data, formValues))
        .then(setSettings)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings, camera.id, camera.config, formValues?.captureMode, formValues?.operationMode])

  useEffect(() => {
    setSettings(undefined)
  }, [formValues.captureMode, formValues?.operationMode, camera.config])

  return settings
}

// Custom Hook for initial load
export const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false)

  useEffect(() => {
    if (didMount.current) func()
    else didMount.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
