import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import FullScreenBackground from 'shared-module/new-components/layout/FullScreenBackground'

const getBoxAttributes = (isWebview) => {
  return isWebview ? {} : { height: 'calc(100vh - 64px)', mt: 6 }
}

const VoskerPrivatePageLayout = ({ maxWidth = 'sm', isWebview = false, children }) => {
  return (
    <FullScreenBackground>
      <Box {...getBoxAttributes(isWebview)} overflow={isWebview ? '' : 'auto'}>
        <Container maxWidth={maxWidth}>
          <Box display="flex" flexDirection="column" mt={isWebview ? 0 : 8}>
            { children }
          </Box>
        </Container>
      </Box>
    </FullScreenBackground>
  )
}

export default VoskerPrivatePageLayout
