import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'
import useConfirmationDialogActions from 'shared-module/components/confirmation/useConfirmationDialogActions'
import FactoryResetButton from './FactoryResetButton'
import { DELETE_DEVICE, RESTORE_FACTORY_SETTINGS } from '../../../core/cameraSettingsButtonInputTypes'
import DeleteCameraDialog from './DeleteCameraDialog'
import useSelectedCamera from '../../../core/useSelectedCamera'
import { useTheme } from '@material-ui/core'
import useUser from 'user-module/user/core/useUser'

const ButtonSettingsField = ({ settingInput }) => {
  const dialogActions = useConfirmationDialogActions()
  const { t } = useTranslation()
  const user = useUser()
  const camera = useSelectedCamera()
  const theme = useTheme()

  const renderDialog = () => {
    if (settingInput.name === RESTORE_FACTORY_SETTINGS) {
      return <FactoryResetButton open={dialogActions.isOpen} onClose={() => dialogActions.close()} />
    } else if (settingInput.name === DELETE_DEVICE) {
      return <DeleteCameraDialog camera={camera} open={dialogActions.isOpen} onClose={() => dialogActions.close()} />
    }
  }
  return (
    <>
      { renderDialog() }
      <Button style={{ color: theme.palette.background.filter }} variant="outlined" onClick={() => dialogActions.open()} disabled={user.isReadOnlyProfile}>
        { t(`app:camera.settings.fields.${settingInput.name}.button`) }
      </Button>
    </>
  )
}
export default ButtonSettingsField
