import { useEffect, useRef, useState } from 'react'
import { Circle, Layer, Shape } from 'react-konva'

import { alpha } from '@material-ui/core'

import { GUTTER_SIZE, validateMovementInStage } from './helper/DetectionZoneHelper'

const DetectionZoneEditor = ({ imageRect, anchorList, updateAnchorList, isEditing, theme }) => {
  const zoneRef = useRef(null)
  const layerRef = useRef(null)
  const [isDrawing, setIsDrawing] = useState(false)
  const [activeAnchor, setActiveAnchor] = useState(0)

  useEffect(() => {
  }, [anchorList])

  const updateAnchorAndShape = (event, index) => {
    const validatedPosition = validateMovementInStage(
      {
        x: event.target.x(),
        y: event.target.y(),
      },
      imageRect,
    )

    // This prevents the circle from being moved outside visually
    event.target.setAttrs({
      x: validatedPosition.x,
      y: validatedPosition.y,
    })

    updateAnchorList(
      anchorList.map((anchor, anchorIndex) => {
        return anchorIndex === index ? validatedPosition : anchor
      }),
    )
  }

  const detectionZoneSceneFunc = (context, shape) => {
    if (!anchorList || anchorList.length === 0) return

    context.beginPath()

    context.moveTo(anchorList[0].x, anchorList[0].y)
    anchorList.slice(1).forEach((anchor) => {
      context.lineTo(anchor.x, anchor.y)
    })

    context.closePath()
    context.fillStrokeShape(shape)
  }

  const getRadius = (index) => {
    return isDrawing && activeAnchor === index ? 13 : 8.75
  }

  const renderAnchorPoints = () => {
    return anchorList.map((anchor, index) => {
      return (
        <Circle
          {...anchor}
          key={index.toString()}
          radius={getRadius(index)}
          fill={theme.palette.basic.white}
          stroke={theme.palette.primary?.main}
          strokeWidth={1}
          draggable
          onDragMove={(event) => updateAnchorAndShape(event, index)}
          onDragStart={(event) => {
            setActiveAnchor(event.target.index - 1)
            setIsDrawing(true)
          }}
          onDragEnd={() => {
            setIsDrawing(false)
            updateAnchorList(anchorList)
          }}
        />
      )
    })
  }

  return (
    <Layer ref={layerRef} x={GUTTER_SIZE} y={GUTTER_SIZE}>
      <Shape
        ref={zoneRef}
        fill={alpha(theme.palette.primary.main, 0.25)}
        stroke={theme.palette.primary.main}
        strokeWidth={1}
        sceneFunc={detectionZoneSceneFunc}
      />
      { isEditing && renderAnchorPoints() }
    </Layer>
  )
}

export default DetectionZoneEditor
