import { isExternalWebApp } from 'shared-module/webview/isExternalWebApp'

const webViewDevice = () => {
  const isExternal = isExternalWebApp()
  const isAndroidAppWebview = isExternal && (typeof Android !== 'undefined')
  const isiOSAppWebview =
   isExternal && (typeof window.webkit?.messageHandlers?.iOS !== 'undefined')
  const isWebappOrAndroid = !isExternal || isAndroidAppWebview
  const isNativeWebview = isAndroidAppWebview || isiOSAppWebview

  return {
    isAndroidAppWebview,
    isiOSAppWebview,
    isWebappOrAndroid,
    isNativeWebview,
  }
}

export default webViewDevice
