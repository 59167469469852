import usePhotosActionsOld from 'photo-module/photos/core/usePhotosActionsOld'
import useSelectedPhoto from 'photo-module/photo/core/useSelectedPhoto'
import FavoriteButtonLayout from 'photo-module/photo/ui/photo-viewer/actions-bar/FavoriteButtonLayout'
import useFilters from 'photo-module/filters/core/useFilters'

const FavoriteButton = () => {
  const filters = useFilters()
  const photo = useSelectedPhoto()
  const photosAction = usePhotosActionsOld(photo.cameraId)

  const toggleFavorite = () => photo.favorite ? photosAction.deleteFavorite([photo.id], filters) : photosAction.setFavorite([photo.id])

  return <FavoriteButtonLayout onClick={toggleFavorite} />
}

export default FavoriteButton
