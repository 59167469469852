import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@material-ui/core/Typography'

import useFilterCollection from 'photo-module/filters-bar/core/filters.hooks'
import VoskerCheckbox from 'vosker/src/components/VoskerCheckbox'

const useStyles = makeStyles((theme) => ({
  unchecked: {
    width: '1rem',
    height: '1rem',
    fill: 'transparent !important',
    border: `1.5px solid ${theme.palette.checkbox?.primary}`,
    [theme.breakpoints.down('xs')]: {
      width: '1.5rem',
      height: '1.5rem',
    },
  },
  checked: {
    width: '1rem',
    height: '1rem',
    border: 'none !important',
    [theme.breakpoints.down('xs')]: {
      width: '1.5rem',
      height: '1.5rem',
    },
  },
  checkbox: {
    width: '1rem',
    height: '1rem',
    border: `1.5px solid ${theme.palette.checkbox?.primary}`,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '1.5rem',
    },
  },
  text: {
    fontWeight: 600,
  },
  textSmallScreen: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: 15,
    },
  },
}))

const ExclusiveFilterLabel = ({ Icon, hasCategory = true, label, style, handleChange, checked }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const isFirst = useRef(true)
  const sxMenuItem = {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  }
  const { addSelectedFilter, deleteSelectedFilter } = useFilterCollection()

  useEffect(() => {
    if (!isFirst.current) { !checked ? deleteSelectedFilter(label) : addSelectedFilter(label) }
    isFirst.current = false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, isFirst])
  return (
    <MenuItem
      sx={sxMenuItem}
      style={{ ...style, justifyContent: 'space-between', width: '100%' }}
      disableTouchRipple
      onClick={handleChange}
    >
      <Box display="flex" flexDirection="row" alignItems="center">
        { Icon && <Icon style={{ marginBottom: 2, marginRight: 8 }} /> }
        { hasCategory
          ? (
            <Typography variant="body2" className={classes.textSmallScreen}>{ t('app:filters.tags.' + label) }</Typography>
            )
          : (
            <Typography variant="body2" className={classes.text} noWrap>
              { t('app:filters.tags.' + label) }
            </Typography>
            ) }
      </Box>

      <VoskerCheckbox checked={checked} uncheckedClassName={classes.unchecked} checkedClassName={classes.checked} />
    </MenuItem>
  )
}

export default ExclusiveFilterLabel
