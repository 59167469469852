import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import { streamErrors, streamEcoModeWarnings } from '../../../core/liveStreaming.states'

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: '.6rem',
    paddingBottom: 3,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamilyBold,
    color: theme.palette.text.primary,
    textAlign: 'center',
  },
}))

const translatedMessages = {
  [streamErrors.cameraOffline]: 'stream.errors.cameraOffline',
  [streamErrors.noPlan]: 'stream.errors.noPlan',
  [streamErrors.noStreamTime]: 'stream.errors.noStreamTime',
  [streamErrors.streamDisabled]: 'stream.errors.streamDisabled',
  [streamErrors.streamStoppedGracefully]: 'stream.errors.streamStoppedGracefully',
  [streamErrors.streamStoppedUnexpectedly]: 'stream.errors.streamStoppedUnexpectedly',
  [streamEcoModeWarnings.ecoModeExpired]: 'stream.errors.ecoModeExpired',
  [streamEcoModeWarnings.ecoModeDeepSleep]: 'stream.errors.ecoModeDeepSleep',
}

const ErrorMessage = ({ errorCode }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const message = translatedMessages[errorCode] || 'stream.errors.default'

  return (
    <Typography className={classes.message}>
      { message !== 'stream.errors.streamStoppedUnexpectedly' && <WarningRoundedIcon fontSize="large" data-testid="WarningRoundedIcon" color="error" className={classes.icon} /> }
      { t(message) }
    </Typography>
  )
}

export default ErrorMessage
