import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import Hidden from '@material-ui/core/Hidden'
import MenuList from '@material-ui/core/MenuList'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { makeStyles } from '@material-ui/core/styles'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import VoskerEventIcon from 'vosker/src/assets/icons/VoskerEventIcon'
import CAppBar from 'storybook-component-module/src/components/containers/CAppBar'
import VoskerTopBarItems from 'vosker/src/photos-module/photos-gallery/ui/status-bar/VoskerTopBarItems'
import GalleryTopBarCameraName from 'photo-module/photos/ui/photos-gallery/information-bar/elements/GalleryTopBarCameraName'

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: theme.palette.background.default,
  },
  container: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexGrow: 1,
  },
  moreIcon: {
    fill: theme.palette.text.primary,
  },
  popper: {
    top: '6px !important',
  },
}))

const VoskerGalleryTopBar = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const anchorRef = useRef()
  const [open, setOpen] = useState(false)

  const handleClose = () => setOpen(false)
  const handleToggle = () => setOpen(!open)

  return (
    <div>
      <CAppBar variant="h1" Icon={VoskerEventIcon} title={t('app:photo_gallery_page.title')} className={classes.appBar} showDivider>
        <Grid container item xs={10} spacing={2} justifyContent="flex-end" wrap="nowrap">
          { /* Desktop view */ }
          <Hidden smDown>
            <VoskerTopBarItems />
          </Hidden>

          { /* Mobile view */ }
          <Hidden mdUp>
            <GalleryTopBarCameraName />

            <MoreVertIcon
              ref={anchorRef}
              aria-haspopup="true"
              onClick={handleToggle}
              className={classes.moreIcon}
              data-testid="top-bar-mobile-icon"
            />

            <Popper
              open={open}
              anchorEl={anchorRef.current}
              placement="bottom-end"
              transition
              disablePortal
              className={classes.popper}
              data-testid="top-bar-mobile-content"
            >
              { ({ TransitionProps }) => (
                <Grow {...TransitionProps}>
                  <Paper elevation={0}>
                    <ClickAwayListener onClickAway={handleClose}>
                      <Box boxShadow={5}>
                        <MenuList>
                          <VoskerTopBarItems hideCameraName />
                        </MenuList>
                      </Box>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              ) }
            </Popper>
          </Hidden>
        </Grid>
      </CAppBar>
    </div>
  )
}

export default VoskerGalleryTopBar
