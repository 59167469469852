const buildCamera = (apiCamera) => {
  apiCamera.subscription = buildActiveSubscription(apiCamera.subscriptions)
  apiCamera.upcomingSubscription = buildUpcomingSubscription(apiCamera.subscriptions)
  apiCamera.isCompletingTrial = buildIsCompletingTrial(apiCamera.subscription, apiCamera.upcomingSubscription)

  apiCamera.status.memory = buildMemory(apiCamera.status.memory)
  apiCamera.status.mainBattery = buildMainBattery(apiCamera.status.batteries)
  apiCamera.status.solarBattery = buildSolarBattery(apiCamera.status.batteries)
  apiCamera.status.temperature = buildTemperature(apiCamera.status.temperature, apiCamera.config.temperatureUnit)
  apiCamera.status.activationCode = buildActivationCode(apiCamera.dataMatrixKey)
  apiCamera.status.signal = buildCellularSignal(apiCamera, apiCamera.status.signal)
  apiCamera.status.timeSynchronized = buildTimeSynchronized(apiCamera.status.inaccurateDate)
  apiCamera.status.coordinates = buildCoordinates(apiCamera.status.coordinates)
  apiCamera.migrationError = buildMigrationError(apiCamera.jtMigration)

  apiCamera.stream = buildLiveStream(apiCamera)
  apiCamera.videoCapability = buildVideoCapability(apiCamera)
  apiCamera.hdPhotoCapability = buildHdPhotoCapability(apiCamera.subscription)

  apiCamera.hasFreePlan = buildHasFreePlan(apiCamera.hasFreePlan)

  return apiCamera
}

const buildActiveSubscription = subscriptions => subscriptions?.length === 0 ? null : subscriptions?.find(s => s.isActive)

const buildUpcomingSubscription = subscriptions => subscriptions?.length === 0 ? null : subscriptions?.find(s => s.paymentStatus === 'not_started')

const buildIsCompletingTrial = (activeSubscription, upcomingSubscription) => (
  (activeSubscription && activeSubscription.paymentStatus === 'trialing') || !!upcomingSubscription
)

const buildHdPhotoCapability = subscription => !!subscription?.plan?.hdActive

const buildHasFreePlan = hasFreePlan => hasFreePlan !== false

const buildMainBattery = (batteries) => {
  if (batteries?.length > 0) {
    return { percentage: batteries[0] }
  } else {
    return undefined
  }
}

const buildSolarBattery = (batteries) => {
  if (batteries?.length > 1) {
    return { percentage: batteries[1] }
  } else {
    return undefined
  }
}

const buildMemory = (memory) => {
  if (memory?.used && memory?.size) {
    memory.percentage = Math.round((memory.used / memory.size) * 100)
  } else if (memory?.used === 0) {
    memory.percentage = 0
  } else {
    return undefined
  }
  return memory
}

const buildCellularSignal = (camera, signal) => {
  if (camera.status.lastUpdate && !signal) {
    return buildNoSignal()
  }

  return signal?.processed
    ? buildSignalFromProcessed(signal)
    : signal?.bar !== undefined ? buildSignalFromBar(signal) : undefined
}

const buildNoSignal = () => ({
  bar: 0,
  percentage: 0,
  lowSignal: false,
})

const buildSignalFromProcessed = signal => ({
  bar: signal.processed.bar,
  percentage: signal.processed.percentage,
  lowSignal: signal.processed.lowSignal,
})

const buildSignalFromBar = signal => ({
  bar: signal.bar,
  percentage: Math.round((signal.bar / 5) * 100),
  lowSignal: false,
})

const buildTemperature = (temperature, defaultUnit) => {
  if (temperature?.unit) {
    return temperature
  } else if (temperature && defaultUnit) {
    return { value: temperature.value, unit: defaultUnit }
  } else {
    return undefined
  }
}

const buildActivationCode = (dataMatrixKey) => dataMatrixKey === null ? undefined : dataMatrixKey

const buildTimeSynchronized = (inaccurateDate) =>
  inaccurateDate === undefined || inaccurateDate === null
    ? undefined
    : inaccurateDate !== true

const buildLiveStream = apiCamera => apiCamera.status.capability?.stream === undefined ? { available: false } : { available: apiCamera.status.capability.stream }

const buildVideoCapability = apiCamera => apiCamera.status.capability?.video === undefined ? false : apiCamera.status.capability.video

const buildCoordinates = (coordinates) =>
  coordinates?.length > 0
    ? { latitude: coordinates[0].latitude, longitude: coordinates[0].longitude }
    : coordinates

const buildMigrationError = jtMigration => ({
  hasMigrationError: jtMigration ? !jtMigration.isMigrated : false,
  shouldDisplayError: jtMigration ? jtMigration.apocalypseNow : false,
})

export const buildCameraSettings = data => {
  if (!data?.data) {
    return data
  }
  const converted = {}
  data.data?.forEach(({ section, settings }) => {
    converted[section] = settings
  })
  return converted
}

export default buildCamera
