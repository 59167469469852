import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  attribute: {
    marginRight: '0.5rem',
    padding: '1rem 2rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'space-between',
    borderTop: `1.5px solid ${theme.palette.border?.primary}`,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('md')]: {
      borderBottom: 'none',
      justifyContent: 'flex-start',
      padding: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      borderTop: 'none',
    },
  },
}))

const AttributeLabel = ({ children }) => {
  const classes = useStyles()

  return (
    <Grid item xs={12} md className={classes.attribute}>
      { children }
    </Grid>
  )
}

export default AttributeLabel
