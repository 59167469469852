export const getAccountDashboardTabs = [
  {
    id: 'profile',
    path: '/account/profile',
    label: 'Profile',
    maxWidth: 'lg',
    disabled: false,
  },
  {
    id: 'payment',
    path: '/account/payment',
    label: 'app:account.payment.title',
    maxWidth: 'sm',
    disabled: false,
  },
  {
    id: 'management',
    path: '/account/management',
    label: 'app:account.management.title',
    maxWidth: 'lg',
    disabled: false,
  },
]

export const getCurrentTabPath = (tabs, pathname) => {
  // The vosker mobile app may send parameters through URL and that's way
  // the path can be longer than usual
  const path = pathname.includes('/account/management') ? '/account/management' : pathname
  return (
    tabs.filter(({ disabled }) => !disabled).map(tab => tab.path).includes(path) ? path : tabs[0].path
  )
}
