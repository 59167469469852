import CameraStatusTile from 'camera-module/camera/ui/camera-status-page/CameraStatusTile'
import Memory from 'assets/icons/status/Memory'

const MemoryTile = ({ used, size, requested, sendCommands, commandName }) => {
  const usedGB = Math.floor(used / 1024)
  const totalGB = Math.floor(size / 1024)
  const displayValue = `${usedGB}GB / ${totalGB}GB`

  return (
    <CameraStatusTile
      label="app:camera.sd_card"
      icon={<Memory />}
      value={displayValue}
      requested={requested}
      sendCommands={sendCommands}
      commandName={commandName}
      useSVGIcon
      isNewUi
    />
  )
}

export default MemoryTile
