import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CInput from '../../components/inputs/CInput'
import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js'
import { useFormField } from 'shared-module/new-components/form/FormDataContext'

const ONLY_DIGITS = /^[0-9() \-\b]+$/

export interface IPhoneNumberField {
  fieldName: string
  label?: string
  onChange?: (e) => any
  className?: string
  margin?: 'dense' | 'normal' | 'none'
  disabled?: boolean
  validations?: boolean
  validationSchema?: Object
  userInformation?: { country: any, cell: string }
  isNewUi?: boolean
}

const PhoneNumberField = (props: IPhoneNumberField) => {
  const { t } = useTranslation()

  const field = useFormField(props.fieldName)
  const countryField = useFormField('country')

  const [phone, setPhone] = useState(props.userInformation
    ? new AsYouType(props.userInformation.country).input(props.userInformation.cell)
    : '')

  const validatePhoneByCountry = phone => countryField.value
    ? parsePhoneNumberFromString(phone, countryField.value)?.isValid() || t('register.incorrect_phone_format')
    : parsePhoneNumberFromString(phone, props.userInformation?.country)?.isValid() || t('register.incorrect_phone_format')

  const defaultValidation = {
    required: t('input.phone_required'),
    validate: phone => validatePhoneByCountry(phone),
  }

  const fieldRegistration = props.validations
    ? props.validationSchema
      ? field.inputRef(props.validationSchema)
      : field.inputRef(defaultValidation)
    : field.inputRef

  const onChange: any = e => {
    const value = e.target.value

    if (value === '' || ONLY_DIGITS.test(value)) {
      field.onChange()
      setPhone(value)
      props.onChange && props.onChange(e)
      return e.target.value
    }
  }

  useEffect(() => {
    countryField.value
      ? setPhone(new AsYouType(countryField.value).input(phone))
      : props.userInformation && setPhone(new AsYouType(props.userInformation.country).input(phone))
  }, [countryField.value, phone, props.userInformation])

  return (
    <CInput
      type="text"
      name={field.name}
      value={phone}
      register={fieldRegistration}
      label={props.label ?? t('register.form.phone')}
      disabled={props.disabled}
      margin={props.margin}
      className={props.className}
      onChange={onChange}
      error={field.error}
      isNewUi={props?.isNewUi}
    />
  )
}

export default PhoneNumberField
