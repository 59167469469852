import MediaQuery from 'react-responsive'
import { useTranslation } from 'react-i18next'

import makeStyles from '@material-ui/core/styles/makeStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import MuiSwitch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'

import { useStreaming } from '../../../core/liveStreaming.hooks'
import { streamQuality } from '../../../core/liveStreaming.states'

const useStyles = makeStyles(theme => ({
  controlContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 .25rem 0 .5rem',
  },
  labelText: {
    fontFamily: theme.typography.fontFamilyBold,
    wrap: 'no-wrap',
  },
}))

const Switch = withStyles(theme => ({
  switchBase: {
    color: theme.palette.primary.main,
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
  track: {},
}))(MuiSwitch)

const QualitySwitch = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const streaming = useStreaming()

  const handleChange = event => {
    const quality = event.target.checked ? streamQuality.hd : streamQuality.sd
    streaming.changeQuality(quality)
  }

  return (
    <MediaQuery minDeviceWidth={250}>
      <div className={classes.controlContainer}>
        <Typography variant="body2" className={classes.labelText}>{ t('stream.high') }</Typography>
        <Switch
          data-testid="quality-switch"
          size="small"
          checked={streaming.streamQuality === streamQuality.hd}
          onChange={handleChange}
        />
        <Typography variant="body2" className={classes.labelText}>{ t('stream.max') }</Typography>
      </div>
    </MediaQuery>
  )
}

export default QualitySwitch
