import CameraStatusTile from 'camera-module/camera/ui/camera-status-page/CameraStatusTile'
import ModemFirmware from 'assets/icons/status/ModemFirmware'
import FirmwareIcon from 'assets/icons/status/FirmwareIcon'

const ModemFirmwareTile = ({ value, label }) => (
  <CameraStatusTile label={label || 'camera.modem_firmware'} icon={<ModemFirmware />} value={value} useSVGIcon />
)

export const FirmwareTile = ({ value, label, firmwareUpdate, requested, sendCommands, commandName }) => (
  <CameraStatusTile
    label={label || 'camera.modem_firmware'}
    icon={<FirmwareIcon />}
    value={value.split('-')[0] + '-' + value.split('-')[1]}
    useSVGIcon
    isNewUi
    firmwareUpdate={firmwareUpdate}
    requested={requested}
    sendCommands={sendCommands}
    commandName={commandName}
  />
)

export default ModemFirmwareTile
