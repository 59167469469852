import { combineReducers } from 'redux'
import photoState from 'photo-module/photo/core/photoState'
import photoApi from 'photo-module/photo/api/photoApi'
import photoSelected from 'photo-module/photo/core/photoSelected'
import downloadStatus from 'photo-module/download/downloadStatus'
import messageActions from 'shared-module/message/messageActions'
import cameraActions from 'camera-module/camera/core/cameraActions'
import { updatePhotoInList } from 'photo-module/photos/core/photosActions'

const requestHdPhoto = (cameraId, photoId) => dispatch => photoApi
  .requestHdPhoto(cameraId, photoId)
  .then(() => {
    dispatch(updatePhotoInList(photoId, { hdStatus: downloadStatus.pending }))
    dispatch(messageActions.showWarning('photo_viewer.request_hd_photo.success'))
  })
  .catch(() => dispatch(messageActions.showError()))
  .finally(() => dispatch(cameraActions.update(cameraId)))

const clearHdPhoto = (cameraId, photoId) => dispatch => photoApi
  .getHdPhotoStatus(cameraId, photoId)
  .then(() => dispatch(photoState.getPhoto(cameraId, photoId)))
  .then((photo) => dispatch(updatePhotoInList(photo.id, photo)))
  .finally(() => dispatch(cameraActions.update(cameraId)))

const reducer = combineReducers({
  photo: photoSelected.reducer,
})

const hdPhotoState = { requestHdPhoto: requestHdPhoto, clearHdPhoto: clearHdPhoto, reducer: reducer }
export default hdPhotoState
