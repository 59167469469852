import SvgIcon from '@material-ui/core/SvgIcon'

const ThemeIcon = (props) => (
  <SvgIcon data-testid="ThemeIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9999 2C6.47712 2 2 6.47712 2 11.9999C2 17.5227 6.47712 21.9999 11.9999 21.9999C17.5227 21.9999 22.0001 17.5228 22.0001 11.9999C22.0001 6.47713 17.5227 2 11.9999 2ZM11.9999 19.9999L11.9999 4C16.4182 4 20.0001 7.58169 20.0001 11.9999C20.0001 16.4182 16.4182 19.9999 11.9999 19.9999Z"
    />

  </SvgIcon>
)

export default ThemeIcon
