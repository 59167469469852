import { useDispatch } from 'react-redux'
import messageActions from 'shared-module/message/messageActions'

const useMessageActions = () => {
  const dispatch = useDispatch()

  return {
    showMessage: message => dispatch(messageActions.showMessage(message)),
    showSuccess: message => dispatch(messageActions.showSuccess(message)),
    showError: message => dispatch(messageActions.showError(message)),
    clear: dispatch(messageActions.clear()),
  }
}

export default useMessageActions
