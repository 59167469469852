import { useDispatch } from 'react-redux'
import photos from 'photo-module/photos/core/photos'
import { updatePhotoInList } from 'photo-module/photos/core/photosActions'

const usePhotosActions = () => {
  const dispatch = useDispatch()

  return ({
    set: (newPhotos) => dispatch(photos.set(newPhotos)),
    updatePhoto: (photoToUpdate) => dispatch(updatePhotoInList(photoToUpdate.id, photoToUpdate)),
  })
}

export default usePhotosActions
