import { useLocation } from 'react-router-dom'
import { parse } from 'qs'

const useProductsParams = () => {
  const urlParams = useLocation().search.replace('?', '')
  const params = parse(urlParams, { encode: false })

  const reformattedParam = params.p
  params.p?.map(param => {
    if (param.quantity) reformattedParam[0].quantity = parseInt(param.quantity)
    return param
  })

  return reformattedParam
}

export default useProductsParams
