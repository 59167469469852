import { useSelector } from 'react-redux'
import useLoad from 'shared-module/loading/useLoad'
import useCamerasActions from 'camera-module/cameras/core/useCamerasActions'
import useReload from 'shared-module/loading/useReload'

const useCameras = () => useSelector(state => state.cameras)
const useLoadCameras = () => useLoad('cameras', useCameras(), useCamerasActions().get)
const useReloadCameras = () => useReload(useCameras(), useCamerasActions().get)

export { useCameras, useLoadCameras, useReloadCameras }
