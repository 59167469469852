import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Switch from 'react-ios-switch'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useTheme } from '@material-ui/core'

import messageActions from 'shared-module/message/messageActions'
import VoskerSecondaryButton from 'vosker/src/components/buttons/VoskerSecondaryButton'
import VoskerPrimaryButton from 'vosker/src/components/buttons/VoskerPrimaryButton'
import { formatDate } from 'shared-module/common-utils'
import { disableAutoRenew, enableAutoRenew } from 'checkout-module/checkout/api/billingApi'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import useCameraActions from 'camera-module/camera/core/useCameraActions'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(theme => ({
  container: {
    whiteSpace: 'nowrap',
  },
  label: {
    fontFamily: theme.typography.fontWeightMedium,
    fontWeight: 'bold',
    fontSize: '1em',
    color: theme.palette.text.primary,
  },
  switch: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  spinner: {
    marginBottom: -5,
  },
  Button: {
    height: 36.5,
  },

  sectionContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '13.5px 0 0 0',
  },
}))

const AutoRenewSwitch = () => {
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()
  const camera = useSelectedCamera()
  const cameraActions = useCameraActions()
  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [confirmationOpened, setConfirmationOpened] = useState(false)

  const subscription = camera?.upcomingSubscription || camera?.subscription
  const checked = subscription.isAutoRenew

  const openConfirmation = () => {
    setConfirmationOpened(true)
  }
  const closeConfirmation = () => {
    setConfirmationOpened(false)
  }

  const onConfirm = async () => {
    setLoading(true)
    closeConfirmation()
    try {
      await disableAutoRenew(camera.id, subscription.id)
      await cameraActions.update(camera.id)
    } catch (error) {
      dispatch(messageActions.showError('errors.catch_all'))
    } finally {
      setLoading(false)
    }
  }

  const onChange = async (checked) => {
    if (!checked) {
      openConfirmation()
      return
    }

    setLoading(true)
    closeConfirmation()
    try {
      await enableAutoRenew(camera.id, subscription.id)
      await cameraActions.update(camera.id)
    } catch (error) {
      dispatch(messageActions.showError('errors.catch_all'))
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className={classes.container}>
      <Grid item container className={classes.sectionContainer}>
        <Grid item xs={6} md={6}>
          <div className={classes.label}>{ t('app:plan.current_subscription.auto_renewal.label') }*</div>
        </Grid>
        <Grid item xs={6} md={6}>
          <div className={classes.switch}>
            <Switch offColor={theme.palette.secondary.light} onColor={theme.palette?.status?.info} checked={checked} onChange={onChange} disabled={loading} />
          </div>
        </Grid>
      </Grid>

      { loading && <CircularProgress className={classes.spinner} size={25} color="secondary" /> }

      <Dialog open={confirmationOpened} onClose={closeConfirmation}>
        <DialogTitle>{ t('app:plan.current_subscription.auto_renewal.modal.title') }</DialogTitle>
        <DialogContent>
          <DialogContentText>
            { t('app:plan.current_subscription.auto_renewal.modal.content', {
              date: formatDate(subscription.endDateBillingCycle, i18n),
            }) }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item sm={3}><VoskerSecondaryButton onClick={closeConfirmation}>{ t('button.cancel') }</VoskerSecondaryButton></Grid>
            <Grid item sm={3}><VoskerPrimaryButton onClick={onConfirm}>{ t('button.disable') }</VoskerPrimaryButton></Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AutoRenewSwitch
