import LogoutIcon from 'assets/icons/navigation/LogoutIcon'
import MenuItem from 'shared-module/navigation/menu/MenuItem'
import useUserActions from 'user-module/user/core/useUserActions'

const LogoutMenuItem = ({ onClick }) => {
  const userActions = useUserActions()

  const logout = () => {
    onClick()
    userActions.logout()
  }

  return (
    <MenuItem Icon={LogoutIcon} text="menu.logout" onClick={logout} />
  )
}

export default LogoutMenuItem
