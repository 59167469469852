import { DISCOUNT_TYPE } from './discount.types'
import { formatAmount } from 'shared-module/common-utils'
import paymentFrequency from 'vosker/src/order-module/order/core/paymentFrequency'

const formatPercentageDiscount = value => `${value}%`

const formatAmountDiscount = (value, currency, language) =>
  formatAmount(value, { currency, language, hideZerosDecimals: true, noSpace: true })

export const formatDiscount = (discount, currency, language) => {
  switch (discount?.type) {
    case DISCOUNT_TYPE.percentage:
      return formatPercentageDiscount(discount.value)
    case DISCOUNT_TYPE.amount:
      return formatAmountDiscount(discount.value, currency, language)
    default:
      return undefined
  }
}

export const getRawDiscount = (cameraPlan, frequency) => {
  const discount = cameraPlan?.coupon?.[frequency]
  const discountValue = discount?.value || 0
  return discountValue ? discount : undefined
}

export const showMonthlyByDefault = (camera) => {
  const subscription = camera?.upcomingSubscription || camera?.subscription
  const onFreePlan = !!subscription?.isFree
  const onMonthlyBillingCycle = subscription?.paymentFrequency === paymentFrequency.monthly

  return !onFreePlan && onMonthlyBillingCycle
}
