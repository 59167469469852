import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import makeStyles from '@material-ui/core/styles/makeStyles'
import useUser from 'user-module/user/core/useUser'
import apm from 'shared-module/monitoring/apm'
import LoginInformation from 'user-module/user/ui/login-information/LoginInformation'
import profileHelpers, { profileFromUser } from 'user-module/user/ui/profile/ProfileHelpers'
import FormProfileSection from './FormProfileSection'
import FormPreferenceSection from './FormPreferenceSection'
import ProfileFormSave from 'user-module/user/ui/profile/ProfileFormSave'
import { validateCell } from 'shared-module/user/ProfileCell'
import errorState from 'user-module/user/ui/profile/errorState'
import useUserActions from 'user-module/user/core/useUserActions'
import useMessageActions from 'shared-module/message/useMessageActions'
import Form from 'form-module/ui'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '150px',
  },
  rowSpacing: {
    marginBottom: 4,
  },
  title: {
    fontWeight: 600,
    marginBottom: 24,
  },
  generalInfo: {
    padding: '24px 16px 32px 16px',
    marginTop: 24,
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 15,
      paddingRight: 0,
    },
  },
  loginInfo: {
    padding: 32,
    marginTop: 24,
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 15,
      paddingRight: 0,
    },
  },
  paperStyle: {
    padding: 32,
    marginTop: 24,
  },
  buttonContainer: {
    padding: '0 12px',
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  buttonGrid: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
    },
  },
  buttonSpacing: {
    [theme.breakpoints.down('md')]: {
      marginTop: 12,
      marginBottom: 12,
    },
  },
}))
const Profile = () => {
  const classes = useStyles()
  const { i18n } = useTranslation()

  const user = useUser()
  const userActions = useUserActions()
  const { showSuccess, showError } = useMessageActions()

  const [userProfile, setUserProfile] = useState(profileFromUser(user, i18n.language))
  const [validationOnChange, setValidationOnChange] = useState(user.isAddressComplete)

  useEffect(() => {
    apm.log('user-profile')
  }, [])

  useEffect(() => {
    setUserProfile(profileFromUser(user, i18n.language))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    setUserProfile({ ...userProfile, language: i18n.language })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language])

  const buildUpdateUser = (values, userId) => ({
    ...values,
    id: userId,
  })

  const onSubmit = (values) => {
    const profileValue = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      city: values.city,
      company: values.company,
      cell: values?.cell,
      address: values.address,
      apartment: values.apartment,
      country: values.country,
      territory: values.territory,
      postalCode: values.postalCode,
      language: values.language,
      marketingEmail: values.marketingEmail,
      notificationEmail: values.notificationEmail,
    }

    const updateUser = buildUpdateUser(profileValue, user.id)

    userActions
      .update(updateUser)
      .then(() => {
        i18n.changeLanguage(updateUser.language)
        showSuccess('profile.update')
      })
      .catch((error) => {
        if (!errorState.isHandled(error)) {
          showError('profile.error.text')
        }
      })
  }

  const userInitialValues = () => ({
    ...profileHelpers.userInfoInitialValues(user),
    ...profileHelpers.userPreferencesInitialValues(user),
  })

  return (
    <Form
      id="formProfile"
      onSubmit={onSubmit}
      data={{ ...userInitialValues() }}
      validate={validateCell}
      validateOnBlur={user.isAddressComplete}
      validateOnChange={validationOnChange}
      validationSchema={profileHelpers.validationSchema()}
    >
      <FormProfileSection />

      <Paper elevation={1} className={classes.loginInfo} data-testid="accountProfile">
        <Grid container spacing={2} item xs={12} className={classes.rowSpacing}>
          <Grid item xs={12}>
            <LoginInformation
              elevation={0}
              padding="0 30px"
              inherentClasses={classes}
              userProfile={userProfile}
            />
          </Grid>
        </Grid>
      </Paper>

      <FormPreferenceSection />

      <ProfileFormSave setValidationOnChange={setValidationOnChange} />
    </Form>
  )
}

export default Profile
