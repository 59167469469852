import clsx from 'clsx'
import makeStyles from '@material-ui/core/styles/makeStyles'

import Input from 'form-module/ui/inputs/Input'
import LegacyInput from 'form-module/ui/inputs/LegacyInput'
import PasswordInput from 'form-module/ui/inputs/PasswordInput'

export const VoskerInputStyles = makeStyles((theme) => ({
  input: {
    '& .MuiSvgIcon-root': {
      fill: theme.palette.text?.secondary + ' !important',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 20px) scale(1)',
    },
    '& label.Mui-focused': {
      color: theme.palette.text.secondary,
    },
    '& label.Mui-error': {
      color: theme.palette.text.secondary,
    },
    '& .MuiOutlinedInput-input': {
      padding: '27px 12px 10px',
    },
    '&:hover .MuiOutlinedInput-root': {
      borderRadius: 6,
      border: `1.5px solid ${theme.palette.border?.secondary}`,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 6,
      border: `1.5px solid ${theme.palette.border?.primary}`,
      '& .Mui-focused fieldset': {
        border: 'none',
      },
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      borderRadius: 6,
      border: `1.5px solid ${theme.palette.status?.error}`,
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, 10px) scale(0.75)',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: theme.palette.status?.error,
      margin: '0 !important',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      borderRadius: 6,
      opacity: 0.5,
      border: `1.5px solid ${theme.palette.border?.primary}`,
    },
    '& .MuiSelect-select': {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
  },
  inputCameraSetting: {
    '& .MuiSvgIcon-root': {
      fill: theme.palette.text?.secondary + ' !important',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 20px) scale(1)',
    },
    '& label.Mui-focused': {
      color: theme.palette.text.secondary,
    },
    '& label.Mui-error': {
      color: theme.palette.text.secondary,
    },
    '&:hover .MuiOutlinedInput-root': {
      borderRadius: 6,
      border: `1.5px solid ${theme.palette.border?.secondary}`,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 6,
      border: `1.5px solid ${theme.palette.border?.primary}`,
      '& .Mui-focused fieldset': {
        border: 'none',
      },
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      fontSize: theme.typography.h3,
      borderRadius: 6,
      border: `1.5px solid ${theme.palette.status?.error}`,
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, 10px) scale(0.75)',
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: theme.palette.status?.error,
      margin: '0 !important',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      borderRadius: 6,
      opacity: 0.5,
      border: `1.5px solid ${theme.palette.border?.primary}`,
    },
    '& .MuiSelect-select': {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
  },
  success: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 6,
      border: `1.5px solid ${theme.palette.status?.success}`,
    },
    '&:hover .MuiOutlinedInput-root': {
      borderRadius: 6,
      border: `1.5px solid ${theme.palette.status?.success}`,
    },
  },
}))

const VoskerInputField = ({ name, label, showHints, showSuccessTickIcon, notShowSuccessColor, showError, type, isFormikField = true, className, ...props }) => {
  const classes = VoskerInputStyles()
  return isFormikField && type !== 'password'
    ? (
      <Input name={name} label={label} showErrorIcon className={clsx(classes.input, className)} {...props} />
      )
    : type === 'password'
      ? (
        <PasswordInput
          name={name}
          label={label}
          showHints={showHints}
          showErrorIcon
          notShowSuccessColor
          showError={showError}
          className={clsx(classes.input, className)}
          showSuccessTickIcon={showSuccessTickIcon}
          successStyle={!notShowSuccessColor && classes.success}
          {...props}
        />
        )
      : (
        <LegacyInput name={name} label={label} className={clsx(classes.input, className)} {...props} />
        )
}

export default VoskerInputField
