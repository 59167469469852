import Grid from '@material-ui/core/Grid'
import OrderDivider from 'vosker/src/order-module/order/ui/order-lines/components/OrderDivider'
import OrderTitleLine from 'vosker/src/order-module/order/ui/order-lines/components/OrderTitleLine'
import OrderLine from 'vosker/src/order-module/order/ui/order-lines/components/OrderLine'
import OrderDiscountLine from 'vosker/src/order-module/order/ui/order-lines/components/OrderDiscountLine'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  boldDivider: {
    backgroundColor: theme.palette.border?.neutral,
  },
  title: {
    margin: '6px 0px',
  },
  subtitle: {
    margin: '6px 0px',
    fontSize: theme.typography.label?.fontSize,
  },
}))

const orderLines = {
  title: OrderTitleLine,
  line: OrderLine,
  divider: OrderDivider,
  discount: OrderDiscountLine,
}

const OrderLines = ({ order }) => {
  const classes = useStyles()
  const isTitleLine = (line) => line.type === 'title'
  const isOrderTotalLine = (line, index) => isTitleLine && index === order.lines.length - 1
  const isDividerLine = (line) => line.type === 'divider'
  const isOrderLastDivider = (line, index) => isDividerLine(line) && isOrderTotalLine(order.lines[index + 1], index + 1)

  return (
    <Grid container direction="column" wrap="nowrap" spacing={1}>
      { order.lines.map((line, i) => {
        const key = `${line.type} + ${i}`
        const Line = orderLines[line.type]

        const renderLine = () => {
          if (isTitleLine(line)) {
            return isOrderTotalLine(line, i)
              ? <Line line={line} variant="h2" className={classes.title} />
              : <Line line={line} className={classes.subtitle} />
          } else if (isOrderLastDivider(line, i)) {
            return <Line line={line} className={classes.boldDivider} />
          } else {
            return <Line line={line} />
          }
        }

        return <Grid key={key} item>{ renderLine() }</Grid>
      }) }
    </Grid>
  )
}

export default OrderLines
