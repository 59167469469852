import { useState } from 'react'
import { useField, useFormikContext } from 'formik'

import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import IOSSwitch from 'react-ios-switch'
import Switch from '../inputs/Switch'
import ConfirmationDialog from 'shared-module/components/confirmation/ConfirmationDialog'
import { useTheme } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

const ConfirmationSwitch = ({ name, confirmation, isNewUi = false, ...switchProps }) => {
  const theme = useTheme()
  const [field] = useField(name)
  const { setFieldValue } = useFormikContext()
  const [confirmationContent, setConfirmationContent] = useState()

  const close = () => {
    setConfirmationContent(undefined)
  }

  const onConfirm = () => {
    close()
    setFieldValue(name, !field.value)
  }

  const onChange = event => {
    const newValue = !field.value

    if (confirmation?.options[String(newValue)]) {
      !isNewUi && event.preventDefault()
      setConfirmationContent(confirmation?.options[String(newValue)])
    } else {
      setFieldValue(name, !field.value)
    }
  }

  return (
    <>
      { isNewUi
        ? (
          <Grid display="flex" flexdirection="row" justifyContent="flex-end" container spacing={1}>
            <Grid style={{ marginRight: -15 }} item>
              <FormControl fullWidth>
                <FormControlLabel
                  control={(
                    <IOSSwitch
                      checked={field.value}
                      onChange={onChange}
                      offColor={theme.palette.secondary.light}
                      onColor={theme.palette?.status?.info}
                      style={{ transform: 'scale(0.8)', marginLeft: '0.375rem' }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          )
        : (
          <Switch
            name={name}
            onChange={onChange}
            value={field.value}
            {...switchProps}
          />
          ) }
      <ConfirmationDialog
        isNewUi={isNewUi}
        open={!!confirmationContent}
        onClose={close}
        title={confirmation?.title}
        text={confirmationContent}
        onConfirm={onConfirm}
        onCancel={close}
        confirmText={confirmation?.buttonLabel?.accept}
        cancelText={confirmation?.buttonLabel?.decline}
      />
    </>
  )
}

export default ConfirmationSwitch
