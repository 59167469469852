import { useState } from 'react'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import useUserPaymentInformationActions from 'vosker/src/user-module/payment-information/core/useUserPaymentInformationActions'
import messageActions from 'shared-module/message/messageActions'
import { getStripeTranslatedError } from 'checkout-module/checkout/ui/shared/Payment.helper'

const usePaymentInformation = ({ onSuccess = () => {}, onError = () => {} } = {}) => {
  const { t } = useTranslation()
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()
  const paymentInformationActions = useUserPaymentInformationActions()
  const [submitting, setSubmitting] = useState(false)
  const [paymentError, setPaymentError] = useState(undefined)

  const closePaymentErrorDialog = () => setPaymentError(undefined)

  const savePaymentInformation = () => {
    setSubmitting(true)
    const cardElement = elements.getElement(CardElement)
    stripe.createToken(cardElement).then((result) => processStripeResult(result))
  }

  const processStripeResult = (result) => {
    if (result.token) {
      paymentInformationActions
        .add(result.token.id)
        .then(() => {
          dispatch(messageActions.showMessage(t('app:payment_information_page.success')))
          onSuccess()
        })
        .catch((error) => {
          setPaymentError(getStripeTranslatedError(error?.response?.data, t))
          setSubmitting(false)
        })
    } else if (result.error) {
      onError(result.error.message)
      setSubmitting(false)
    }
  }

  return {
    submitting,
    paymentError,
    closePaymentErrorDialog,
    savePaymentInformation,
    onError,
    onSuccess,
  }
}

export default usePaymentInformation
