import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@mui/material'

const NewActivateCameraIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.75 11.25V20H11.25V11.25H20V8.75H11.25V0H8.75V8.75H0V11.25H8.75Z"
        fill={`${theme.palette.text.primary} !important`}
      />
    </SvgIcon>
  )
}

export default NewActivateCameraIcon
