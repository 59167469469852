import { useEffect } from 'react'

import Box from '@material-ui/core/Box'
import Fab from '@material-ui/core/Fab'
import { makeStyles } from '@material-ui/core/styles'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

import usePhotoViewerNavigation from 'photo-module/photo/core/usePhotoViewerNavigation'
import useSelectedPhoto from 'photo-module/photo/core/useSelectedPhoto'
import useKeyPress from 'shared-module/components/key-press/useKeyPress'
import VoskerButtonWrapper from 'vosker/src/photos-module/photo/ui/actions-bar/VoskerButtonWrapper'
import PreviousArrowIcon from 'vosker/src/assets/icons/viewer/PreviousArrowIcon'

const useStyles = makeStyles(theme => ({
  previous: {
    display: 'flex',
    position: 'absolute',
    height: 'calc(100vh - 128px)',
    width: '40vw',
    alignItems: 'center',
    transition: '.2s all',
    cursor: 'ew-resize',
    opacity: 0,
    left: '20px',
    marginLeft: '-20px',
    paddingLeft: '20px',
    justifyContent: 'flex-start',
    zIndex: 1,
    '&:hover': {
      opacity: 1,
    },
    [theme.breakpoints.down('xs')]: {
      height: '20%',
    },
  },
  arrow: {
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: theme.palette.background.tile,
    },
  },
  icon: {
    marginLeft: 2,
  },
}))

const PreviousButton = ({ isNewUi = false }) => {
  const classes = useStyles()
  const arrowLeftPressed = useKeyPress('ArrowLeft')
  const photo = useSelectedPhoto()
  const { previousPhoto, switchPhoto } = usePhotoViewerNavigation(photo?.id)

  const previous = () => switchPhoto(previousPhoto)

  useEffect(() => {
    if (arrowLeftPressed && previousPhoto) { previous() }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrowLeftPressed])

  const renderButton = () => {
    return isNewUi
      ? (
        <Box onClick={previous} className={classes.previous}>
          <VoskerButtonWrapper>
            <PreviousArrowIcon className={classes.icon} fontSize="large" />
          </VoskerButtonWrapper>
        </Box>
        )
      : (
        <Box onClick={previous} className={classes.previous}>
          <Fab className={classes.arrow} color="secondary">
            <ChevronLeftIcon className={classes.icon} fontSize="large" />
          </Fab>
        </Box>
        )
  }

  return previousPhoto
    ? <>{ renderButton() }</>
    : null
}

export default PreviousButton
