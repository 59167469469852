import { useState } from 'react'

import { Box } from '@mui/material'

import StripeProvider from 'vosker/src/user-module/payment-information/ui/payment-information/form/StripeProvider'
import { useLoadUserPaymentInformation } from 'vosker/src/user-module/payment-information/core/useUserPaymentInformation'
import PaymentInformationForm from 'vosker/src/user-module/payment-information/ui/payment-information/form/PaymentInformationForm'
import PaymentInformationDetails from 'vosker/src/user-module/payment-information/ui/payment-information/details/PaymentInformationDetails'

const PaymentPanel = () => {
  const paymentInformation = useLoadUserPaymentInformation()

  const [changeCard, setChangeCard] = useState(false)
  const handleClose = () => setChangeCard(false)
  const handleChange = () => setChangeCard(true)

  const options = {
    fonts: [{
      family: 'exo2-regular',
      src: 'url(https://fonts.gstatic.com/s/exo2/v20/7cH1v4okm5zmbvwkAx_sfcEuiD8jvvKsOdC_jJ7bpAhL.woff2) format("woff2")',
    }],
  }

  return (
    <Box mt={3} mb={1}>
      { paymentInformation && !paymentInformation.noCard && (
        <PaymentInformationDetails showChange={!changeCard} onChange={handleChange} />
      ) }

      { paymentInformation && paymentInformation.noCard && (
        <StripeProvider options={options}>
          <PaymentInformationForm onSuccess={() => {}} />
        </StripeProvider>
      ) }

      { changeCard && (
        <StripeProvider options={options}>
          <PaymentInformationForm onSuccess={handleClose} onCancel={handleClose} />
        </StripeProvider>
      ) }
    </Box>
  )
}

export default PaymentPanel
