import SvgIcon from '@material-ui/core/SvgIcon'

const HelpIcon = (props) => (
  <SvgIcon {...props} data-testid="HelpIcon">
    <path
      d="M12 21.75A9.748 9.748 0 0021.75 12 9.748 9.748 0 0012 2.25 9.748 9.748 0 002.25 12 9.748 9.748 0 0012 21.75zm2.34-9.504c-.504.293-.91.527-.91.895v.254a.473.473 0 01-.473.468H11.16a.47.47 0 01-.469-.468v-.344c0-1.371 1.016-1.942 1.817-2.39l.05-.028c.692-.387 1.114-.653 1.114-1.164 0-.676-.863-1.13-1.563-1.13-.91 0-1.332.43-1.921 1.18a.48.48 0 01-.657.09l-1.094-.832a.479.479 0 01-.105-.648C9.262 6.766 10.445 6 12.285 6c1.93 0 3.988 1.508 3.988 3.492 0 1.64-1.101 2.278-1.933 2.754zm-.63 4.633a1.652 1.652 0 01-3.304 0 1.652 1.652 0 013.305 0zm0 0"
      fillRule="evenodd"
    />
  </SvgIcon>
)

export default HelpIcon
