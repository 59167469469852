import { useTheme } from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  sizing: {
    width: '40px !important',
    height: '40px !important',
  },
}))

const CloseIcon = (props) => {
  const theme = useTheme()
  const classes = useStyles()
  return (
    <SvgIcon {...props} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0007 12.613L18.555 20.1673L20.1673 18.555L12.613 11.0007L20.1673 3.44629L18.555 1.83398L11.0007 9.38835L3.44629 1.83398L1.83398 3.44629L9.38835 11.0007L1.83398 18.555L3.44629 20.1673L11.0007 12.613Z" fill={theme.palette.text?.secondary} />
    </SvgIcon>
  )
}

export default CloseIcon
