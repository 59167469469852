/**
 * State diagram
 *
 * (action)                -> (state)
 * default state           -> unknown
 * user request data       -> pending
 * data is available       -> available
 * data is unavailable     -> unavailable
 * error in getting data   -> error
 */
const downloadStatus = {
  requestable: 'requestable',
  pending: 'pending',
  available: 'available',
  unavailable: 'unavailable',
  error: 'error',
}

export default downloadStatus
