import eCommerceApi from 'vosker/src/ecommerce-module/api/eCommerceApi'
import { eCommerceRefreshTokenID, eCommerceTokenID } from 'vosker/src/ecommerce-module/core/eCommerce.selectors'

const authenticateUser = () => eCommerceApi
  .authenticate()
  .then(data => {
    localStorage.setItem(eCommerceTokenID, data.access_token)
    localStorage.setItem(eCommerceRefreshTokenID, data.refresh_token)
  })
  .catch(error => error)

const eCommerceActions = { authenticateUser }
export default eCommerceActions
