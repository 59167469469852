import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@material-ui/core'

const RecordingIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon {...props} data-testid="RecordingIcon">
      <circle cx="12" cy="12" r="3.5" fill={theme.palette.error.main} />
    </SvgIcon>
  )
}

export default RecordingIcon
