import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import appState from 'shared-module/appState'

const customCompose = process.env.NODE_ENV
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  : compose

const store = createStore(appState.reducer, customCompose(applyMiddleware(thunk)))

export default store
