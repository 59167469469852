import mixpanel from 'mixpanel-browser'

// DEV PROJECT TOKEN FOR TEST ANALYSIS
const MIXPANEL_DEV_TOKEN = 'ebe4f32cb0adecffbfc0d709a839b21f'
const MIXPANEL_PROD_TOKEN = '0c8aecd371dcfd7946928c6cf6e7b533'

const isDevelopment = window.location.origin.includes('dev') ||
  window.location.origin.includes('staging') ||
  window.location.origin.includes('localhost')

mixpanel.init(isDevelopment ? MIXPANEL_DEV_TOKEN : MIXPANEL_PROD_TOKEN)

const actions = {
  identify: id => mixpanel.identify(id),
  track: (name, props) => mixpanel.track(name, props),
  people: { set: props => mixpanel.people.set(props) },
}

export const Mixpanel = actions
