import luhn from 'luhn'

export const isAValidActivationId = inputValue => {
  const simPattern = /^89\d{17,18}$/
  const activationCodePattern = /^[A-Z2-7]{11,14}$/

  const isSimValid = simPattern.test(inputValue) && luhn.validate(inputValue)
  const isActivationCodeValid = activationCodePattern.test(inputValue)

  return isSimValid || isActivationCodeValid
}

export const sanitizeActivationInput = inputValue => {
  return inputValue?.replace(/[^a-zA-Z0-9]/g, '').toUpperCase()
}
