import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import CloseIcon from '@material-ui/icons/Close'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  list: {
    paddingTop: theme.spacing(0.65),
    paddingLeft: theme.spacing(1),
  },
  button: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

const MenuHeader = ({ onClick, Logo }) => {
  const classes = useStyles()

  return (
    <List className={classes.list}>
      <ListItem>
        <ListItemIcon><CloseIcon fontSize="large" onClick={onClick} className={classes.button} /></ListItemIcon>
        <Logo style={{ width: '150px', height: '100%' }} />
      </ListItem>
    </List>
  )
}

export default MenuHeader
