import { useHistory } from 'react-router-dom'
import MenuItem from 'shared-module/navigation/menu/MenuItem'

const LinkMenuItem = ({ Icon, text, path, onClick, children }) => {
  const history = useHistory()

  const navigateToPath = () => {
    onClick()
    history.push(path)
  }

  return <MenuItem Icon={Icon} text={text} onClick={navigateToPath}>{ children }</MenuItem>
}

export default LinkMenuItem
