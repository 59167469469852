const V300_BASIC = 'V300_Basic'
const V300_ALERTED = 'V300_Alerted'
const V300_ELITE = 'V300_Elite'

const v300Plans = {
  Basic: V300_BASIC,
  Alerted: V300_ALERTED,
  Elite: V300_ELITE,
}

const plans = {
  Empty: 'empty',
  Free: 'free',
  Basic: 'basic',
  Alerted: 'alerted',
  Elite: 'elite',
  Unlimited: 'elite',
  [V300_BASIC]: 'basic',
  [V300_ALERTED]: 'alerted',
  [V300_ELITE]: 'elite',
}

const apiCameraPlans = {
  empty: 'Empty',
  free: 'Free',
  basic: 'Basic',
  alerted: 'Alerted',
  elite: 'Elite',
}

export { plans, v300Plans, apiCameraPlans }
