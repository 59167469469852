import { formatDateWithDay } from 'shared-module/common-utils'
import { useTranslation } from 'react-i18next'

export const RanderDatesDay = (date) => {
  const { i18n, t } = useTranslation()
  const todaysDate = new Date()
  const photoDate = new Date(date)
  const yesterday = new Date(todaysDate)
  yesterday.setDate(yesterday.getDate() - 1)
  photoDate.setDate(photoDate.getDate() + 1)

  if (
    photoDate.getDate() === todaysDate.getDate() &&
    photoDate.getMonth() === todaysDate.getMonth() &&
    photoDate.getFullYear() === todaysDate.getFullYear()
  ) {
    return t('photo_gallery.today')
  } else if (
    photoDate.getDate() === yesterday.getDate() &&
    photoDate.getMonth() === yesterday.getMonth() &&
    photoDate.getFullYear() === yesterday.getFullYear()
  ) {
    return t('photo_gallery.yesterday')
  } else {
    return formatDateWithDay(date, i18n)
  }
}

export const CheckEveryItemsAreIncluded = (arr1, arr2) => arr1?.every(item => arr2.includes(item))

export const DeleteSelectedPhotos = (arr, ...theArgs) => arr.filter(val => !theArgs.includes(val))
