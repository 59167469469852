import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import TextSmall from 'vosker/src/order-module/order/ui/order-lines/components/TextSmall'
import TextSmallBold from 'vosker/src/order-module/order/ui/order-lines/components/TextSmallBold'

const CameraNameLine = ({ camera }) => {
  const { t } = useTranslation()
  return (
    <Grid container justifyContent="space-between">
      <Grid item xs>
        <TextSmall>{ t('app:order_page.order_summary.camera') }</TextSmall>
      </Grid>
      <Grid item xs>
        <Box textAlign="right"><TextSmallBold>{ camera.config.name }</TextSmallBold></Box>
      </Grid>
    </Grid>
  )
}

export default CameraNameLine
