import { useMemo, useState } from 'react'

import { useTheme } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useCameraPlans } from '../core/cameraPlans.hook'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import PlanCard from 'vosker/src/camera-module/transmission-plan/ui/content/PlanCard'
import PlanHeader from 'vosker/src/camera-module/transmission-plan/ui/content/PlanHeader'
import GridLayout from 'vosker/src/camera-module/transmission-plan/ui/components/layout/GridLayout'
import { apiCameraPlans } from 'vosker/src/camera-module/transmission-plan/core/cameraPlans.types'
import PlanFrequencySwitch from 'vosker/src/camera-module/transmission-plan/ui/components/PlanFrequencySwitch'
import { showMonthlyByDefault } from 'vosker/src/camera-module/transmission-plan/core/discount/discount.selectors'

const VoskerPlansList = () => {
  const theme = useTheme()
  const camera = useSelectedCamera()
  const { plans: allPlans } = useCameraPlans()
  const plans = useMemo(() => allPlans.filter(plan => plan.id !== apiCameraPlans.empty), [allPlans])

  const breakpoint = useMediaQuery(theme.breakpoints.only('xs'))
  const [displayMonthly, setDisplayMonthly] = useState(showMonthlyByDefault(camera))

  const renderHeaders = plan => {
    if (breakpoint && plan.name === apiCameraPlans.free) {
      return <PlanCard key={plan.id} camera={camera} plan={plan} />
    }
    return <PlanHeader key={plan.id} camera={camera} plan={plan} monthly={displayMonthly} count={plans.length} />
  }

  return (
    <>
      <PlanFrequencySwitch displayMonthly={displayMonthly} setDisplayMonthly={setDisplayMonthly} />
      <GridLayout style={{ marginBottom: '1.5rem' }}>
        { plans.map(plan => renderHeaders(plan)) }
      </GridLayout>
    </>
  )
}

export default VoskerPlansList
