import React, { ReactNode } from 'react'
import CBadge from '../badges/CBadge'
import IconButton from '@material-ui/core/IconButton'

export interface ICButtonIcon {
  children: ReactNode
  color?: 'primary' | 'secondary'
  badgeColor?: 'primary' | 'secondary' | 'error'
  badgeContent?: string
  disabled?: boolean
  onClick?: () => void
}

const CButtonIcon = (props: ICButtonIcon) => {
  return (
    <IconButton {...props}>
      { props.badgeColor
        ? <CBadge badgeColor={props.badgeColor} badgeContent={props.badgeContent} children={props.children} />
        : props.children }
    </IconButton>
  )
}

export default CButtonIcon
