import INPUT_TYPES from './cameraSettingsInputTypes'
import settingsStructure from 'vosker/src/camera-module/camera/core/cameraSettingsCategories'

const FIX_NAMES = {
  sensibility: 'sensibility.level',
}

const FIX_TYPES = {
  photoFirst: INPUT_TYPES.TOGGLE,
  capture: INPUT_TYPES.TOGGLE,
  gps: INPUT_TYPES.TOGGLE,
  transmitTime: INPUT_TYPES.TIME,
  schedule: INPUT_TYPES.SCHEDULE,
}

const CONFIRMATION = {
  gps: ['false'],
}

const ALERT = {
  powerMode: ['eco', 'performance'],
}

const MAXLENGTH = {
  name: 30,
}

const MIN = {
  timeLapse: 180,
}

const DETECTION = {
  detectionZoneNotificationEnabled: ['true'],
}

/**
 * New Camera Setting API for VOSKER requires a specific builder method
 * sorts the different settings into their relevant categories
 * @fixme : create a new version of the settings and add those changes in RestAPI
 */
const build = settings => {
  const categorizedSettings = { management: [], events: [], cell: [], general: [] }

  settings.forEach(setting => {
    Object.keys(settingsStructure).find(key => settingsStructure[key].forEach(item => {
      if (item.name === setting.name) {
        categorizedSettings[key].push(setting)
      }
    }))
  })

  return categorizedSettings
}

/**
 * this fixes some inconsistencies returned by the backend and prevent having to implement hacks on the ui
 * @fixme : create a new version of the settings and add those changes in RestAPI
 */
const fix = (settings, cameraConfig) => {
  if (!settings) {
    return
  }
  const filteredSettings = resolveRequires(settings, cameraConfig)
  Object.values(filteredSettings).flat().forEach(settingInput => {
    fixSelectOptionsValues(settingInput)
    fixName(settingInput)
    fixType(settingInput)
    addConfirmation(settingInput)
    addDetectionTag(settingInput)
    addAlert(settingInput)
    addMaxLength(settingInput)
    addMinValue(settingInput)
  })
  return filteredSettings
}

const fixSelectOptionsValues = setting => {
  if (setting?.type !== INPUT_TYPES.SELECT) {
    return
  }
  setting?.options?.forEach(option => {
    let value = option.value ?? option.name
    if (/^[0-9]+$/.test(value)) {
      value = parseInt(value)
    }
    option.value = value
    option.label = option.label ?? option.name ?? value
  })
}

const fixName = setting => {
  if (FIX_NAMES[setting?.name]) {
    setting.name = FIX_NAMES[setting.name]
  }
}

const fixType = setting => {
  if (FIX_TYPES[setting?.name]) {
    setting.type = FIX_TYPES[setting.name]
  }
}

const addConfirmation = setting => {
  if (CONFIRMATION[setting?.name]) {
    setting.confirmation = CONFIRMATION[setting.name]
  }
}

const addAlert = setting => {
  if (ALERT[setting?.name]) {
    setting.alert = ALERT[setting.name]
  }
}

const addMaxLength = setting => {
  if (MAXLENGTH[setting?.name]) {
    setting.maxLength = MAXLENGTH[setting.name]
  }
}

const addMinValue = setting => {
  if (MIN[setting?.name]) {
    setting.min = MIN[setting.name]
  }
}

const addDetectionTag = setting => {
  if (DETECTION[setting?.name]) {
    setting.isZone = DETECTION[setting.name]
  }
}

const resolveRequires = (settings, cameraConfig = {}) => {
  const filteredSettings = {}
  Object.entries(settings).forEach(([configKey, configValue]) => {
    filteredSettings[configKey] = configValue.filter(({ requires }) => {
      if (!requires) {
        return true
      }
      let meetRequirements = false
      for (const { name, value } of requires) {
        if (cameraConfig[name] === value) {
          meetRequirements = true
          break
        }
      }
      return meetRequirements
    })
  })
  return filteredSettings
}

const helper = { fix: fix, build: build }
export default helper
