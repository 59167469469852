import React, { ReactNode } from 'react'
import CTypography from './CTypography'

export interface ICTitle {
  children: ReactNode
  marginBottom?: number
  noWrap?: boolean
  className?: string
}

const CTitle = (props: ICTitle) => {
  return (
    <CTypography
      variant="h1"
      noWrap={props.noWrap}
      className={props.className}
      marginBottom={props.marginBottom}
      children={props.children}
    />
  )
}

export default CTitle
