import purposeState from 'camera-module/camera/core/purposeState'
import { SET_PURPOSE } from 'camera-module/camera/core/purposeActionType'

export default function (state = purposeState.init(), action) {
  switch (action.type) {
    case SET_PURPOSE:
      return purposeState.set(action.purpose)
    default:
      return state
  }
}
