import { useTranslation } from 'react-i18next'
import VoskerPrimaryButton from 'vosker/src/components/buttons/VoskerPrimaryButton'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import PrimaryButton from 'shared-module/new-components/buttons/PrimaryButton'
import usePaymentInformation from 'vosker/src/user-module/payment-information/ui/payment-information/form/UsePaymentInformation'

const PlaceOrderButton = ({ disabled, product, onPlaceOrder }) => {
  const { t } = useTranslation()

  const text = product.id === 'AddOn' ? 'app:order_page.place_order.buy' : 'app:order_page.place_order.subscribe'
  const { paymentError, closePaymentErrorDialog, savePaymentInformation } = usePaymentInformation()

  const handleButtonClick = () => {
    onPlaceOrder()
    savePaymentInformation()
  }

  return (
    <>
      { paymentError && (
        <Dialog open onClose={closePaymentErrorDialog} maxWidth="xs">
          <DialogTitle>{ t('error_short') }</DialogTitle>
          <DialogContent>
            <DialogContentText>{ paymentError }</DialogContentText>
            <DialogActions>
              <PrimaryButton onClick={closePaymentErrorDialog}>{ t('button.ok') }</PrimaryButton>
            </DialogActions>
          </DialogContent>
        </Dialog>
      ) }

      <VoskerPrimaryButton disabled={disabled} onClick={handleButtonClick}>
        { t(text) }
      </VoskerPrimaryButton>
    </>
  )
}

export default PlaceOrderButton
