import SvgIcon from '@material-ui/core/SvgIcon'
import { useTheme } from '@mui/material'

const SuccessMessageIcon = (props) => {
  const theme = useTheme()

  return (
    <SvgIcon data-testid="SuccessMessageIcon" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12.101C22 17.6238 17.5228 22.101 12 22.101C6.47715 22.101 2 17.6238 2 12.101C2 6.5781 6.47715 2.10095 12 2.10095C17.5228 2.10095 22 6.5781 22 12.101ZM17.5698 9.50427L10.9032 16.171C10.7078 16.3663 10.4429 16.476 10.1666 16.476C9.89036 16.476 9.62541 16.3663 9.43006 16.171L6.45351 13.1846L7.90458 11.6906L10.1666 13.9612L16.0967 8.03113L17.5698 9.50427Z"
        fill={theme.palette.status?.success}
      />

    </SvgIcon>
  )
}

export default SuccessMessageIcon
