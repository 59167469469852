import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'
import { Box, IconButton, InputAdornment, TextField } from '@mui/material'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { Mixpanel } from 'vosker/src/Mixpanel'
import MagnifyingIcon from 'camera-module/cameras/ui/cameras-page/icons/MagnifyingIcon'
import SmallEraseIcon from 'camera-module/cameras/ui/cameras-page/icons/SmallEraseIcon'
import BigEraseIcon from 'camera-module/cameras/ui/cameras-page/icons/BigEraseIcon'

const useStyles = makeStyles(theme => ({
  searchFieldContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    paddingRight: 16,
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      paddingRight: 0,
    },
  },
  searchField: {
    width: 240,
    height: 40,
    backgroundColor: theme.palette.background.contrast,
    borderRadius: 6,
    '& .MuiInputBase-root': {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.body1?.fontSize,
      fontWeight: 400,
      fontFamily: theme.typography.body1?.fontFamily,
      height: '100%',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.border?.secondary,
      borderRadius: 6,
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.border?.secondary,
      borderRadius: 6,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.border?.secondary,
      borderRadius: 6,
    },
    '& .MuiInputBase-input::placeholder': {
      color: theme.palette.text?.secondary,
      opacity: 1,
      fontSize: theme.typography.body1?.fontSize,
      fontWeight: 400,
      fontFamily: theme.typography.body1?.fontFamily,
    },
  },
  searchFieldExpanded: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  magnifyingIcon: {
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  smallEraseIcon: {
    padding: '0px !important',
  },
  bigEraseIconContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10,
  },
  bigEraseIcon: {
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      justifyContent: 'flex-end',
    },
    marginLeft: 20,
  },
}))

const CameraSearch = ({ searchInput, setSearchInput, isSearchFieldExpanded, setIsSearchFieldExpanded }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const isSmallDisplay = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const [editionStarted, setEditionStarted] = useState(false)

  const handleInputChange = async event => {
    setSearchInput(event.target.value)
    if (!editionStarted) {
      await Mixpanel.track('Search Camera', { 'Event Type': 'Input Edited' })
      setEditionStarted(true)
    }
  }

  const clearSearchInput = () => setSearchInput('')
  const handleSearchIconClick = () => setIsSearchFieldExpanded(true)
  const handleBlur = () => setEditionStarted(false)

  const handleBigEraseIconClick = () => {
    setIsSearchFieldExpanded(false)
    setSearchInput('')
  }

  return (
    <Box component="div" className={classes.searchFieldContainer}>
      { isSmallDisplay && !isSearchFieldExpanded
        ? (
          <IconButton onClick={handleSearchIconClick}>
            <MagnifyingIcon className={classes.magnifyingIcon} />
          </IconButton>
          )
        : (
          <>
            <TextField
              data-testid="search-field"
              type="text"
              value={searchInput}
              name="cameraSearch"
              placeholder={t('app:camera_search.title')}
              variant="outlined"
              onChange={handleInputChange}
              onBlur={handleBlur}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    { !searchInput && <MagnifyingIcon /> }
                    { searchInput && (
                      <IconButton onClick={clearSearchInput} className={classes.smallEraseIcon}><SmallEraseIcon /></IconButton>
                    ) }
                  </InputAdornment>
                ),
                autoComplete: 'off',
              }}
              className={`${classes.searchField} ${isSearchFieldExpanded ? classes.searchFieldExpanded : ''}`}
            />

            { isSmallDisplay && isSearchFieldExpanded && (
              <Box component="div" className={classes.bigEraseIconContainer}>
                <IconButton className={classes.bigEraseIcon} onClick={handleBigEraseIconClick}>
                  <BigEraseIcon />
                </IconButton>
              </Box>
            ) }
          </>
          ) }
    </Box>
  )
}

export default CameraSearch
